import React from 'react';
import Image from '../image/image';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import './author.scss';

export default function Author({ primary, uid }) {
  if (!primary || !primary.image || !primary.title || !primary.description) {
    return null;
  }

  return (
    <div className='author container'>
      <div className='content'>
        <Image primary={primary.image} width={96} height={96} lazyload={true} />
        <div>
          <h2 className='author-name'>
            {uid && (
              <a href={`/author/${uid}`} rel='author'>
                {primary.title?.[0]?.text}
              </a>
            )}
            {!uid && <span>{primary.title?.[0]?.text}</span>}
          </h2>
          {PrismicRichText.render(
            primary.description,
            PrismicConfig.linkResolver,
            PrismicConfig.htmlSerializer
          )}
        </div>
      </div>
    </div>
  );
}
