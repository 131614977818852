import * as React from 'react';

const IconPersonWithIv = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)'>
          <path d='M9.677 20.441a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM17.177 35.441v-4.5a7.5 7.5 0 1 0-15 0v4.5h3l1.5 12h6l1.5-12h3ZM36.177 8.441h-8a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-11a2 2 0 0 0-2-2ZM32.177 17.441h6M17.177 32.441h7.5a7.5 7.5 0 0 0 7.5-7.5v-1.5' />
          <path d='M44.177 47.441v-34.5a10.5 10.5 0 0 0-10.5-10.5h-6M41.177 47.441h6' />
        </g>
        <defs>
          <clipPath id='a'>
            <path transform='translate(.677 .941)' d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconPersonWithIv;
