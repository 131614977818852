import React, { useEffect } from 'react';
import PageWrapper from './wrapper';
import Loading from '../components/loading/loading';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoIosMail,
} from 'react-icons/io';
import { withPrismic } from '../prismic/prismic';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../prismic/config';
import Image from '../components/image/image';
import NewsArticles from '../layouts/news/news';
import Separator from '../layouts/separator/separator';
import Error400 from './error-400';
import './author.scss';

function AuthorPage({ prismic, prismicContext, author, uid, error }) {
  const primary = {
    results_per_page: 12,
    title: [
      {
        type: 'heading2',
        text: 'Articles by this author',
        spans: [],
      },
    ],
    content: [],
    author: author?.data?.title?.[0]?.text,
  };

  useEffect(() => {
    if (prismicContext && !author) {
      prismic.getAuthor(uid, true);
    }
  });

  if (error) {
    return <Error400 path={`by/${uid}`} />;
  }

  if (!author || !author.data) {
    return <Loading full={true} testId='author' />;
  }

  if (!prismicContext) {
    return;
  }

  const hasSocialLinks =
    author.data.facebook ||
    author.data.linkedin ||
    author.data.twitter ||
    author.data.email;

  const renderSocialLinks = () => {
    return (
      <>
        {author.data.facebook && (
          <li>
            <a href={author.data.facebook}>
              <IoLogoFacebook title='Facebook' size='2rem' />
            </a>
          </li>
        )}
        {author.data.linkedin && (
          <li>
            <a href={author.data.linkedin}>
              <IoLogoLinkedin title='Linkedin' size='2rem' />
            </a>
          </li>
        )}
        {author.data.twitter && (
          <li>
            <a href={author.data.twitter}>
              <IoLogoTwitter title='Twitter' size='2rem' />
            </a>
          </li>
        )}
        {author.data.email && (
          <li>
            <a href={`mailto:${author.data.email}`}>
              <IoIosMail title='Email' size='2rem' />
            </a>
          </li>
        )}
      </>
    );
  };

  return (
    <PageWrapper
      banner={'Hidden'}
      context={`page -author -${author.uid}`}
      noTransform={true}
      content={author}
      footer_content={'Hidden'}
      full={true}>
      <div className='layout-author container'>
        <div className=''>
          <div className='content'>
            <div className='author'>
              <div>
                {author.data.image && <Image primary={author.data.image} />}
              </div>
              <div>
                {author.data.title?.[0]?.text && (
                  <h1 className='author-name'>
                    {author.data.title?.[0]?.text}
                  </h1>
                )}
                {author.data.description?.[0]?.text && (
                  <>
                    {PrismicRichText.render(
                      author.data.description,
                      PrismicConfig.linkResolver,
                      PrismicConfig.htmlSerializer
                    )}
                    {hasSocialLinks && (
                      <ul className='social-links'>{renderSocialLinks()}</ul>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Separator primary={primary} />

      <NewsArticles primary={primary} />
    </PageWrapper>
  );
}

export default withPrismic(AuthorPage);
