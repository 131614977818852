import React from 'react';
import { PrismicConfig } from '../../prismic/config';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import RateTable from '../../components/rate-table/rate-table';
import Banner from '../banner/banner';
import './rates.scss';

const ratesTables = [
  {
    label: 'Weekdays (Monday - Friday)',
    rows: ['day', 'evening', 'night', 'publicHoliday'],
  },
  {
    label: 'Weekends',
    rows: ['saturday', 'sunday', 'publicHoliday'],
  },
  {
    label: 'Sleepovers',
    rows: [
      'sleepoverWeekday',
      'sleepoverSaturday',
      'sleepoverSunday',
      'sleepoverPublicHoliday',
    ],
  },
  {
    label: 'Pay for shifts under 2 hours',
    rows: [
      'under2HoursDay',
      'under2HoursEvening',
      'under2HoursNight',
      'under2HoursSaturday',
      'under2HoursSunday',
      'under2HoursPublicHoliday',
    ],
  },
];

const Rates = ({ primary, items }) => {
  if (!primary || !items) {
    return null;
  }

  const priceType = 'total';

  const renderTables = (userType, priceType) => {
    return ratesTables.map((table, i) => (
      <RateTable
        key={i}
        table={table}
        priceType={priceType}
        userType={userType}
        primary={primary}
      />
    ));
  };

  const introduction = primary.introduction;

  const userType = primary.rates_type === 'Client' ? 'client' : 'worker';

  return (
    <section className='layout-rates'>
      <div className='container'>
        {userType === 'client' && (
          <>
            <div className='title'>
              <h2>Hourly cost for Hireup bookings</h2>
            </div>
            {/* ToDo: Update helpcentre article link */}
            <p className='introduction'>
              Prices below are for shifts 2 hours and above. Specialised support
              pricing applies to clients with
              <a href='/complex-support/'> specialised support requirements </a>
              who have a Hireup relationship manager. Read more about our{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://help.hireup.com.au/hc/en-us/articles/24604423824665-Specialised-Support-Allowance'>
                specialised support pricing
              </a>
              .
            </p>
          </>
        )}

        {userType === 'worker' && (
          <>
            <div className='title'>
              <h2>Hourly pay rates for support workers</h2>
            </div>
            <div className='worker-intro'>
              {PrismicRichText.render(
                introduction,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
            <div className='rates-banner-wrapper'>
              <Banner primary={primary} />
            </div>
          </>
        )}

        {renderTables(userType, priceType)}
      </div>
    </section>
  );
};

export default Rates;
