const primarySupport = require('./primarySupport');

const studying = {
  supportName: 'Studying',
  supportIcon: 'bookPointing',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'education-and-employment',
};

module.exports = studying;
