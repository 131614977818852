import * as React from 'react';

const IconBowelComplex = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M9 46.5v-3a3 3 0 0 1 3-3c1.5 0 1.5 6 15 6s18-12 18-18c0-13.5-9-21-21-21a3 3 0 0 1-3-3v-3' />
        <path d='M3 46.5v-3a9.01 9.01 0 0 1 9-9c3 0 12.288-11.266 9.474-21.364' />
        <path d='M24 13.5a9.01 9.01 0 0 1-9-9v-3M27 40.5c3 0 9-1.5 9-6S31.5 30 30 30s-9 4.5-9 7.5 3 3 6 3Z' />
      </svg>
    </span>
  );
};

export default IconBowelComplex;
