import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import './statistic.scss';

export default class Statistic extends Component {
  layoutClasses() {
    let classes = {
      'statistic-single': true,
    };
    return classNames(classes);
  }

  render() {
    const { primary } = this.props;

    if (!primary) {
      return null;
    }

    return (
      <div className={this.layoutClasses()}>
        <div className='statistic-text'>
          {primary.statistic_prefix &&
            PrismicRichText.render(
              primary.statistic_prefix,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
        </div>
        <div
          className='statistic-number'
          aria-hidden={
            primary && primary.statistic_screenreader ? true : false
          }>
          {primary.statistic &&
            PrismicRichText.render(
              primary.statistic,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
        </div>
        <div className='statistic-number screen-reader-only'>
          {primary.statistic_screenreader &&
            PrismicRichText.render(
              primary.statistic_screenreader,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
        </div>
        <div className='statistic-text'>
          {primary.description &&
            PrismicRichText.render(
              primary.description,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
        </div>
      </div>
    );
  }
}
