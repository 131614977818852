import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Utilities from '../../config/utilities';
import {
  MdCheckCircle,
  MdHourglassFull,
  MdWarning,
  MdError,
  MdInfo,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import _kebabCase from 'lodash.kebabcase';
import './status.scss';

export default class Status extends Component {
  state = {
    isExpanded: false,
  };

  layoutClasses = () => {
    const { primary } = this.props;
    const icon = primary.icon ? primary.icon.toLowerCase() : null;
    const { isExpanded } = this.state;
    let classes = {
      'status-single': true,
      [`-${icon}`]: icon,
      '-expandable': Utilities.isPrismicContentPopulated(primary.description),
      '-expanded': isExpanded,
    };
    return classNames(classes);
  };

  toggleExpanded = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  renderIcon(icon) {
    switch (icon) {
      case 'Success':
        return <MdCheckCircle className='icon' {...{ 'aria-hidden': true }} />;
      case 'Info':
        return <MdInfo className='icon' {...{ 'aria-hidden': true }} />;
      case 'Waiting':
        return (
          <MdHourglassFull className='icon' {...{ 'aria-hidden': true }} />
        );
      case 'Warning':
        return <MdWarning className='icon' {...{ 'aria-hidden': true }} />;
      case 'Error':
        return <MdError className='icon' {...{ 'aria-hidden': true }} />;
      default:
        return null;
    }
  }

  renderToggleIcon = () => {
    if (this.state.isExpanded) {
      return (
        <MdKeyboardArrowUp className='chevron' {...{ 'aria-hidden': true }} />
      );
    }
    return (
      <MdKeyboardArrowDown className='chevron' {...{ 'aria-hidden': true }} />
    );
  };

  render() {
    const { primary } = this.props;

    if (!primary) {
      return null;
    }

    const { title, description, status, icon } = primary;
    const { isExpanded } = this.state;
    const toggle_id = `${_kebabCase(title)}-toggle`;
    const description_id = `${_kebabCase(title)}-description`;

    // For status items with a description, render an accordion toggle
    if (Utilities.isPrismicContentPopulated(description)) {
      return (
        <div className={this.layoutClasses()}>
          <div className='status-content'>
            <button
              className='toggle'
              aria-expanded={isExpanded}
              aria-controls={description_id}
              id={toggle_id}
              onClick={this.toggleExpanded}>
              <h2>
                {icon && this.renderIcon(icon)}
                {title}
              </h2>
              {status && (
                <div>
                  <span className={`tag -${icon.toLowerCase()}`}>{status}</span>
                </div>
              )}
              {this.renderToggleIcon()}
            </button>
            <div
              className='description'
              role='region'
              aria-labelledby={toggle_id}>
              {PrismicRichText.render(
                description,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          </div>
        </div>
      );
    }

    // For status items without a description, render the content simply
    return (
      <div className={this.layoutClasses()}>
        <div className='status-content'>
          <div className='title'>
            <h2>
              {icon && this.renderIcon(icon)}
              {title}
            </h2>
            {status && (
              <div>
                <span className={`tag -${icon.toLowerCase()}`}>{status}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
