import React, { Component } from 'react';
import classNames from 'classnames';
import _kebabCase from 'lodash.kebabcase';
import Status from '../../components/status/status';
import './status-list.scss';

export default class StatusList extends Component {
  layoutClasses(primary) {
    let classes = {
      'status-list': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-count-${this.props.items.length}`]: true,
    };
    return classNames(classes);
  }

  render() {
    const { primary, items } = this.props;

    if (!primary && !items) {
      return null;
    }

    return (
      <section className={this.layoutClasses(primary)}>
        <div className='container'>
          <div className='status-list-items'>
            {items && items.map((item, i) => <Status primary={item} key={i} />)}
          </div>
        </div>
      </section>
    );
  }
}
