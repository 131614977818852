import * as React from 'react';

const IconHandBrain = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        stroke='currentColor'
        strokeWidth='3'>
        <path d='M1.882 28.248v18M1.882 43.248h30a6 6 0 0 0-6-6h-7.5a6 6 0 0 0-6-6h-10.5M12.382 37.248h6M29.118 6.752a5 5 0 0 0-9.654-1.8 4.97 4.97 0 0 0-5.322 7.8 4.987 4.987 0 0 0 2.976 9h6.258a5.742 5.742 0 0 0 5.742-5.742 5.743 5.743 0 0 0 5.744 5.742h6.256a4.986 4.986 0 0 0 2.976-9 4.978 4.978 0 0 0-3.976-8 5 5 0 0 0-1.346.2 5 5 0 0 0-9.654 1.8v0ZM29.118 16.01V5.752' />
        <path d='M19.118 13.752a3.552 3.552 0 0 1 3 2M24.118 8.752v1M39.118 13.752a3.552 3.552 0 0 0-3 2M34.118 8.752v1' />
      </svg>
    </span>
  );
};

export default IconHandBrain;
