import React, { Component } from 'react';
import PageWrapper from './wrapper';
import Loading from '../components/loading/loading';
import Slices from '../prismic/slices';
import { withPrismic } from '../prismic/prismic';
import { withHireup } from '../hireup/hireup';
import Error400 from './error-400';
import constants from '../config/constants';
import _get from 'lodash.get';

class Page extends Component {
  loadPage() {
    this.props.prismic.getPage(this.props.uid, true);
  }

  componentDidMount() {
    const { prismic, prismicContext, locale } = this.props;

    // Set the initial locale
    if (prismic) {
      prismic.setLocale(locale);
    }

    if (prismicContext) {
      this.loadPage();
    }
  }

  componentDidUpdate(prevProps) {
    const { pages, error, uid, locale, prismicContext, user } = this.props;

    if (prevProps.user !== user) {
      this.redirectLoggedInUsers();
    }

    if (!prismicContext) {
      return;
    }

    if (error) {
      return null;
    } else if (!uid) {
      return null;
    } else if (!_get(pages, `[${uid}_${locale}]`)) {
      return this.loadPage();
    } else if (_get(pages, `[${uid}_${locale}]`) === 'loading') {
      return null;
    } else if (uid !== prevProps.uid) {
      // Reload if the page changes
      return this.loadPage();
    } else if (locale !== prevProps.locale) {
      // Reload if the language changes
      return this.loadPage();
    }
  }

  redirectLoggedInUsers() {
    let { uid, user, hireup } = this.props;

    const wwwReferrer = document.referrer.startsWith(constants.domain);
    const shouldRedirect = uid === 'home' && user && !wwwReferrer;

    if (shouldRedirect) {
      hireup.redirectLoggedInUsers(constants.hireup_web);
    }
  }

  render() {
    // Page Content
    const { pages, error, uid, set_locale, locale } = this.props;

    if (error) {
      return <Error400 path={uid} />;
    }

    const page = _get(pages, `[${uid}_${locale}]`);

    if (!page || page === 'loading') {
      return (
        <Loading full={true} testId={uid === 'home' ? 'homepage' : 'page'} />
      );
    }

    return (
      <PageWrapper
        banner={page.data.settings_banner}
        content={page}
        context={`page -${page.uid}`}
        footer_content={page.data.settings_footer_content}
        footer_cta={page.data.settings_footer_cta}
        breadcrumbs={page.data.breadcrumbs}
        full={true}
        set_locale={set_locale}
        minimal_navigation={page.data.settings_minimal_navigation}>
        {page.data.body && <Slices slices={page.data.body} />}
      </PageWrapper>
    );
  }
}

export default withPrismic(withHireup(Page));
