import * as React from 'react';

const IconMind = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M38.5 46.5V33.97A18.73 18.73 0 0 0 25.75 1.5C11.472 1.5 8.4 13.256 3.3 26.46a1.502 1.502 0 0 0 1.4 2.04h3.8v6a6 6 0 0 0 6 6h3v6' />
        <path d='M18.636 15.4a1.504 1.504 0 0 1-.504-2.236 10.502 10.502 0 0 1 18.354 9.6 1.5 1.5 0 0 1-2.122.864L18.636 15.4Z' />
      </svg>
    </span>
  );
};

export default IconMind;
