import * as React from 'react';

const IconIroning = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M16.5 21h-15M16.5 21l21 21M37.5 21l-21 21M10.5 21v-9A6.68 6.68 0 0 0 6 6a6.68 6.68 0 0 0-4.5 6v9h9ZM46.5 17a4 4 0 0 0-4-4h-26v8h26a4 4 0 0 0 4-4Z' />
      </svg>
    </span>
  );
};

export default IconIroning;
