const primarySupport = require('./primarySupport');

const exercisePhysiology = {
  supportName: 'Exercise physiology',
  supportIcon: 'dumbell',
  primarySupport: primarySupport,
  twoColumnLayout: {
    contentTitle:
      'Exercise physiology support happening throughout our community',
    content: [
      {
        alt: 'Kerrie has blonde hair and is wearing a grey jumper. Behind her is a brick wall and some white doors to a house. Kerrie uses a wheelchair.',
        dimensions: {
          height: 541,
          width: 960,
        },
        linkTo: {
          id: 'YQkxbxEAACMAaCv_',
          type: 'news',
          lang: 'en-au',
          uid: 'back-to-the-gym-kerrie-cant-wait-to-get-moving',
        },
        type: 'image',
        url: 'https://images.prismic.io/hireup/679c669c-7d25-4564-9e37-3246be06f729_kerrie+newton.jpg?auto=compress,format',
      },
    ],
    content_two: [
      {
        type: 'paragraph',
        text: '“For me, it’s an interest in exercise and keeping moving, maybe to have someone that’s training or studying exercise physiology. This way support workers are so much more involved and interested in what I’m interested in."',
        spans: [
          {
            start: 0,
            end: 380,
            type: 'label',
            data: {
              label: 'quote',
            },
          },
        ],
      },
      {
        type: 'paragraph',
        text: 'Read about exercise physiology happening in our community',
        spans: [
          {
            start: 0,
            end: 150,
            type: 'hyperlink',
            data: {
              type: 'news',
              slug: 'back-to-the-gym-kerrie-cant-wait-to-get-moving',
              lang: 'en-au',
              uid: 'back-to-the-gym-kerrie-cant-wait-to-get-moving',
              link_type: 'Document',
            },
          },
        ],
      },
    ],
  },
  faqs: {
    content: [
      {
        type: 'heading2',
        text: 'Learn more about exercise physiology support',
        spans: [],
      },
    ],
    items: [
      {
        question: [
          {
            type: 'heading3',
            text: 'What does exercise physiology support involve?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'With a focus on developing tailored exercise programs, exercise physiology is designed to improve your health and wellbeing. Exercise physiology support allows you to bring your support worker along to the session, allowing you to make the most of your therapy. ',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'What can exercise physiology be used to treat?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Exercise physiology is used to treat a broad range of conditions, from chronic disease to injuries, mental health conditions or physical disabilities.',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'Why is exercise physiology support beneficial?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'By delivering tailored physical activities, exercise physiology can play an important part in living well.',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'Who runs an exercise physiology support session?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Exercise physiology sessions are usually run by accredited exercise physiologists, who are specialised in diagnosing and prescribing exercises for a range of conditions. Bring your Hireup support worker to the session to get the most out of your therapy. ',
            spans: [],
          },
        ],
      },
    ],
  },
  relatedArticlesCategory: 'exercise-physiology',
};

module.exports = exercisePhysiology;
