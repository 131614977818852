import React from 'react';

const ShapeBackgroundMobile = ({ fill }) => {
  return (
    <svg
      width='2504'
      height='1027'
      viewBox='0 0 2504 1027'
      fill='none'
      aria-hidden='true'>
      <path
        d='M1031.03 808.989C1018.52 832.822 1012.27 844.682 1013.26 854.796C1014.16 863.65 1018.53 871.844 1025.44 877.573C1033.29 884.083 1046.68 885.485 1073.46 888.289L1966.18 981.753C1978.44 983.037 1989.74 973.982 1991.03 961.716C2019.91 688.063 1821.3 443.267 1547.74 414.626L1328.08 391.628C1301.63 388.859 1288.47 387.481 1276.65 390.567C1266.14 393.22 1256.49 398.467 1248.45 405.702C1239.29 413.845 1233.17 425.604 1220.7 449.099L1031.03 808.989Z'
        fill={fill}
      />
      <path
        d='M2504 175.312C2301.98 564.381 1874.3 862.225 1350.3 928.217L718.809 1007.75C642.784 1017.32 604.933 1022.09 570.943 1015.76C540.685 1010.36 512.901 998.511 489.739 981.61C463.979 963.053 446.514 935.735 412.187 882.045C411.354 880.742 410.512 879.424 409.658 878.09L0 232.812V0H2504V175.312Z'
        fill='#2214b8'
      />
      <path
        d='M1350.3 928.218C1380.28 924.443 1409.94 919.909 1439.26 914.643C1504.13 753.232 1504.72 566.397 1425.68 396.125C1425.07 394.823 1424.45 393.53 1423.81 392.228L1423.8 392.205C1423.4 391.38 1423 390.55 1422.6 389.712C1422.09 388.608 1421.6 387.498 1421.11 386.388L1421.1 386.379C1420.57 385.173 1420.04 383.968 1419.48 382.771C1418.93 381.615 1418.31 380.495 1417.62 379.42C1261.4 66.0914 884.478 -68.7923 564.361 76.3189L564.332 76.255L51.6919 314.236L409.658 878.09L412.187 882.045C446.514 935.735 463.979 963.053 489.739 981.61C512.901 998.511 540.685 1010.36 570.943 1015.76C604.933 1022.09 642.783 1017.32 718.809 1007.75L1350.3 928.218Z'
        fill='#1920e6'
      />
    </svg>
  );
};

export default ShapeBackgroundMobile;
