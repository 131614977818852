import React, { Component } from 'react';
import classNames from 'classnames';
import { withPrismic } from '../../prismic/prismic';
import axios from 'axios';
import Career from '../../components/career/career';
import Loading from '../../components/loading/loading';
import { format, subHours } from 'date-fns';
import _filter from 'lodash.filter';
import _get from 'lodash.get';
import './careers.scss';

class Careers extends Component {
  state = {
    loading: true,
    error: null,
    empty: false,
    careers: null,
    support_worker: {
      Title: 'Support Worker',
      '@attributes': {
        jid: 'support-worker',
        datePosted: format(subHours(new Date(), 4), 'yyyy-MM-dd'),
        dateUpdated: subHours(new Date(), 4),
      },
      Classifications: {
        Classification: [
          'Disability Support Work',
          'Support Work',
          'Australia',
          'Casual',
        ],
      },
      Fields: {},
    },
  };

  componentDidMount() {
    this.loadCareers();
  }

  layoutClasses() {
    const { careers } = this.state;
    let classes = {
      'layout-careers': true,
    };
    if (careers && careers.length) {
      classes[`-count-${careers.length}`] = true;
    }
    return classNames(classes);
  }

  loadCareers = async () => {
    const displaySupportWorker =
      _get(this.props, 'primary.support_worker_job_posting') === 'Visible';

    await axios
      .get(`https://jobs.hireup.link/xml/`)
      .then((response) => {
        if (response.data) {
          const careers = response.data;
          this.setState({ careers });
          // If the 'Support Worker' role is hidden, and there are no jobs, return an empty state.
          const Job = _get(careers, 'Job');
          if (!displaySupportWorker) {
            // If nothing is returned in careers.Job. set the state to empty
            if (!Job) {
              this.setState({ empty: true });
              // Or if careers.Job returns an empty array, set to empty
            } else if (Job.constructor === Array && Job.length === 0) {
              this.setState({ empty: true });
            }
          }
        } else {
          this.setState({ error: 'true' });
        }
      })
      .catch((error) => {
        console.warn('Careers.loadCareers() error', error);
        this.setState({ error: 'true' });
      });

    this.setState({ loading: false });
  };

  renderCareers() {
    let output = [];

    let { careers, support_worker } = this.state;
    const displaySupportWorker =
      _get(this.props, 'primary.support_worker_job_posting') === 'Visible';

    // Add a support worker as our first career
    if (displaySupportWorker) {
      try {
        let values = _get(careers, 'Fields.Field[1].Values.Value');
        let supportWorkerValue = _filter(values, {
          '@attributes': { name: 'Australia' },
        });
        if (supportWorkerValue.length === 0) {
          careers.Fields.Field[1].Values.Value.push({
            '@attributes': {
              jid: '999999',
              name: 'Australia',
            },
          });
        }
        output.push(
          <Career career={support_worker} fields={careers.Fields} key={999} />
        );
      } catch {
        console.warn('Could not add support worker career to jobs');
      }
    }
    if (careers.Job) {
      // If JobAdder is returning multiple jobs as an array in careers.Job:
      if (careers.Job.constructor === Array) {
        careers.Job &&
          careers.Job.forEach((career, index) => {
            output.push(
              <Career career={career} fields={careers.Fields} key={index} />
            );
          });
        // If JobAdder is returning a single job as an object in careers.Job:
      } else if (_get(careers, 'Job.Title')) {
        output.push(<Career career={careers.Job} fields={careers.Fields} />);
      }
    }

    return output;
  }

  render() {
    const { primary } = this.props;
    const { careers, loading, error, empty } = this.state;

    return (
      <section className={this.layoutClasses(primary)}>
        {careers && (
          <div className='container'>
            <div className='content'>
              <h3>Our Roles</h3>
            </div>
          </div>
        )}
        <div className='container'>
          {loading && <Loading />}
          {(error || empty) && (
            <div className='careers-empty'>
              <h3>We couldn’t find any roles to display</h3>
              <p>Plese check back again soon</p>
            </div>
          )}
          {careers && (
            <div>
              <div className='careers'>{this.renderCareers()}</div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default withPrismic(Careers);
