import React from 'react';
import classNames from 'classnames';
import './card.scss';

const Card = ({ children, className, borderRadius, borderWidth }) => {
  const layoutClasses = () => {
    let classes = {
      card: true,
      [`${className}`]: className,
      [`-radius-${borderRadius}`]: borderRadius,
      [`-width-${borderWidth}`]: borderWidth,
    };
    return classNames(classes);
  };

  return (
    <li className={layoutClasses()}>
      <div className='card-inner'>{children}</div>
    </li>
  );
};

export default Card;
