import React from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { PlatformJobCard } from '../../components/platform-job-card/platform-job-card';
import samplePlatformData from '../../hireup/data/platform-jobs';
import Banner from '../banner/banner';
import constants from '../../config/constants';
import './platform-jobs.scss';

const PlatformJobs = ({ primary }) => {
  const titleContent = primary?.title_content
    ? primary.title_content
    : [
        {
          type: 'heading2',
          text: 'Explore support worker jobs on the Hireup platform',
          spans: [],
        },
        {
          type: 'paragraph',
          text: 'There are hundreds of jobs posted on Hireup every day. Work the hours you choose by picking the jobs, times and clients that suit you.',
          spans: [],
        },
      ];

  const bannerProps = {
    title: 'Become a support worker today to find jobs in your area',
    cta_text: 'Apply today',
    cta_link: {
      link_type: 'Web',
      url: constants.registration,
    },
    background_color: 'Dark Blue',
    padding: 'none',
  };

  return (
    <section className='layout-platform-jobs'>
      <div className='container'>
        <div className='title-content'>
          {PrismicRichText.render(
            titleContent,
            PrismicConfig.linkResolver,
            PrismicConfig.htmlSerializer
          )}
        </div>
      </div>
      <div className='container'>
        <div className='content'>
          <ul className='job-card-items'>
            {samplePlatformData &&
              samplePlatformData.map((job, i) => (
                <PlatformJobCard {...job} key={i} />
              ))}
          </ul>
        </div>
      </div>

      <div className='jobs-banner-wrapper'>
        <Banner primary={bannerProps} />
      </div>
    </section>
  );
};

export default PlatformJobs;
