const primarySupport = require('./primarySupport');

const showering = {
  supportName: 'Showering',
  supportIcon: 'shower',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = showering;
