import React from 'react';

const ShapeBackground = ({ fill }) => {
  return (
    <svg
      className=''
      width='1800'
      height='153'
      viewBox='0 0 1800 153'
      fill='none'
      aria-hidden='true'>
      <g>
        <path
          d='M0 1.20471L0 153H1800V1.20471C1563.9 89.1956 1247.6 143 900 143C552.397 143 236.104 89.1956 0 1.20471Z'
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default ShapeBackground;
