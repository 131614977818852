import React, { useState, useEffect } from 'react';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { withPrismic } from '../../prismic/prismic';
import { supportTypes, isValidSupportType } from '../../seo/data/supportTypes';
import _camelCase from 'lodash.camelcase';
import _kebabCase from 'lodash.kebabcase';
import classNames from 'classnames';
import constants from '../../config/constants';
import Analytics from '../../config/analytics';
import { formatSupportName } from '../../seo/data/helpers';
import { useWindowDimensions } from '../../helpers/useWindowDimensions';
import { IconChevronDown } from '../../components/icons';
import './seo-links.scss';

const SEOLinksLayout = ({ prismic, latitude, longitude }) => {
  const [links, setLinks] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const { width } = useWindowDimensions();

  const path = window.location.pathname;
  const [, primarySupport, secondarySupport, state, suburb] = path.split('/');

  const isValidSupportPage = isValidSupportType(
    _camelCase(primarySupport),
    _camelCase(secondarySupport)
  );

  const primarySupportFormatted = formatSupportName(primarySupport);
  const secondarySupportFormatted = formatSupportName(secondarySupport);
  const suburbFormatted = formatSupportName(suburb);

  const handleClick = (event) => {
    Analytics.link(
      { label: event.currentTarget.href },
      `www.${path}.SEO-links.link`
    );
  };

  useEffect(() => {
    const loadLinks = async () => {
      try {
        let links = await prismic.getLocationLinks(latitude, longitude);
        // Add the incoming links to the current state
        if (links) {
          setLinks(links);
        }
      } catch {
        console.log('Failed to load links.');
      }
    };

    if (prismic && !links) {
      loadLinks();
    }
  }, [latitude, links, longitude, prismic, showAll]);

  const convertUidToUrlPath = (link) => {
    const location = link.uid.toLowerCase().split('-');
    // intentionally mutate the array to remove the state
    const state = location.pop();
    // put multiple word suburbs back together
    const suburb = location.join('-');

    return `${state}/${suburb}/`;
  };

  const renderTopCitiesBlock = () => {
    const renderTopCitiesLinks = () => {
      return links.results
        .filter((location) => location.data?.priority_ranking === '1. High')
        .map((link, key) => (
          <li className='seo-link' key={key}>
            <a
              href={`/${primarySupport}/${secondarySupport}/${convertUidToUrlPath(
                link
              )}`}
              aria-label={`${secondarySupportFormatted} support in ${link.data?.title[0]?.text}`}
              onClick={handleClick}>
              {link.data?.title[0]?.text}
            </a>
          </li>
        ));
    };
    return (
      <>
        <h3>
          Top cities for {secondarySupportFormatted.toLowerCase()} support
        </h3>
        <ul>{renderTopCitiesLinks()}</ul>
      </>
    );
  };

  const renderStateLinksBlock = () => {
    const renderStateLinks = () => {
      return constants.defaults.states.map((state, key) => (
        <li className='seo-link' key={key}>
          <a
            href={`/${primarySupport}/${secondarySupport}/${state.toLowerCase()}`}
            aria-label={`${secondarySupportFormatted} support in ${state}`}
            onClick={handleClick}>
            {state}
          </a>
        </li>
      ));
    };
    return (
      <>
        <h3>Search by state</h3>
        <ul>{renderStateLinks()}</ul>
      </>
    );
  };

  const renderSuburbLinksBlock = () => {
    // Filter by state and sort alphabetically
    const transformedLinksArray = links.results
      .filter(
        (location) => location.data?.location_state === state.toUpperCase()
      )
      .sort((a, b) => {
        return a.uid.localeCompare(b.uid);
      });

    const suburbPreview = {
      tablet: 6,
      desktopSmall: 10,
      desktopLarge: 12,
    };

    const previewCount =
      width < 769
        ? suburbPreview.tablet
        : width < 1200
        ? suburbPreview.desktopSmall
        : suburbPreview.desktopLarge;

    const listItemClasses = (key) => {
      let classes = {
        'seo-link': true,
        hidden: !showAll && key >= previewCount,
      };
      return classNames(classes);
    };

    const renderSuburbLinks = () => {
      return transformedLinksArray.map((link, key) => (
        <li className={listItemClasses(key)} key={key}>
          <a
            href={`/${primarySupport}/${secondarySupport}/${convertUidToUrlPath(
              link
            )}`}
            aria-label={`${secondarySupportFormatted} support in ${link.data?.title[0]?.text}`}
            onClick={handleClick}>
            {link.data?.title[0]?.text}
          </a>
        </li>
      ));
    };

    const showButtonOnClick = (currentValue) => {
      const target = document.getElementsByClassName('seo-links-layout')[0];

      if (target && showAll) {
        scrollIntoViewIfNeeded(target, {
          block: 'start',
          inline: 'center',
          scrollMode: 'if-needed',
          behavior: 'smooth',
        });
      }
      setShowAll(!currentValue);
    };

    return (
      <div>
        <h3>
          {secondarySupportFormatted} support in {state.toUpperCase()}
        </h3>

        <ul className='grid-5'>{renderSuburbLinks()}</ul>
        {transformedLinksArray.length > 6 && (
          <button
            className={`show-all-button ${showAll ? '-active' : ''}`}
            aria-label={showAll ? 'Show less suburbs' : 'Show more suburbs'}
            aria-expanded={showAll}
            onClick={() => showButtonOnClick(showAll)}>
            {showAll ? 'Show less' : 'Show all'}
            <IconChevronDown
              className={`chevron ${showAll ? '-inverted' : ''}`}
              height={24}
              width={24}
              aria-hidden={true}
            />
          </button>
        )}
      </div>
    );
  };

  const renderPopularLocationLinksBlock = () => {
    // Remove the current location and limit to 6 links
    const transformedLinksArray = links.results
      .filter((location) => location.uid.split('-')[0] !== suburb)
      .slice(0, 6);

    const renderPopularLocationLinks = () => {
      return transformedLinksArray.map((link, key) => {
        const suburb = link.data.title[0].text;
        return (
          <li className='seo-link' key={key}>
            <a
              href={`/${primarySupport}/${secondarySupport}/${convertUidToUrlPath(
                link
              )}`}
              aria-label={`${secondarySupportFormatted} support in ${state.toUpperCase()}`}
              onClick={handleClick}>
              {secondarySupportFormatted} support in {suburb}
            </a>
          </li>
        );
      });
    };
    return (
      <>
        <h3>
          Popular locations for {secondarySupportFormatted.toLowerCase()}{' '}
          support in {state.toUpperCase()}
        </h3>
        <ul className='grid-3'>{renderPopularLocationLinks()}</ul>
      </>
    );
  };
  const renderSecondarySupportLinksBlock = () => {
    const renderSecondarySupportLinks = () => {
      const data = Object.entries(supportTypes[_camelCase(primarySupport)]);

      return data
        .filter((support) => !support[1]?.archived)
        .map((support) => (
          <li className='seo-link' key={support[0]}>
            <a
              href={`/${primarySupport}/${_kebabCase(
                support[0]
              )}/${state}/${suburb}`}
              onClick={handleClick}>
              {formatSupportName(support[1].supportName)} in {suburbFormatted}
            </a>
          </li>
        ));
    };
    return (
      <>
        <h3>
          Other types of {primarySupportFormatted.toLowerCase()} services in{' '}
          {suburbFormatted}
        </h3>
        <ul className='grid-3'>
          {renderSecondarySupportLinks(primarySupport)}
        </ul>
      </>
    );
  };

  if (!isValidSupportPage || !links || links.results_size === 0) {
    return null;
  }

  return (
    <section className='layout-seo-links -background-white'>
      <div className='container'>
        <div className='content'>
          {!state && (
            <>
              {renderTopCitiesBlock()}
              {renderStateLinksBlock()}
            </>
          )}

          {state && !suburb && <>{renderSuburbLinksBlock()}</>}

          {suburb && (
            <>
              {renderPopularLocationLinksBlock()}
              {renderStateLinksBlock()}
              {renderSecondarySupportLinksBlock()}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default withPrismic(SEOLinksLayout);
