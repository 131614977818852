import React from 'react';

const ShapeFeature = ({ fill }) => {
  return (
    <svg
      width='1190'
      height='851'
      viewBox='0 0 1190 851'
      fill='none'
      aria-hidden='true'>
      <path
        d='M1159.64 388.384C1179.37 361.77 1189.22 348.531 1189.94 336.041C1190.53 325.104 1186.76 314.309 1179.45 306.031C1171.13 296.624 1155.1 292.379 1123.05 283.89L54.7558 0.914926C40.0773 -2.97317 24.6115 5.89737 20.7224 20.5719C-66.3214 347.917 128.849 683.307 456.22 770.023L719.087 839.652C750.733 848.034 766.489 852.208 781.446 850.694C794.743 849.462 807.479 844.911 818.631 837.635C831.316 829.469 840.995 816.328 860.624 790.118L1159.64 388.384Z'
        fill={fill || '#e7b1ff'}
      />
    </svg>
  );
};

export default ShapeFeature;
