import * as React from 'react';

const IconEdit = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)' strokeLinecap='round'>
          <path d='M40.5 13.498v-3a3 3 0 0 0-3-3h-8M12.5 7.498h-8a3 3 0 0 0-3 3v33a3 3 0 0 0 3 3H12' />
          <path d='M28.842 9.472a1.502 1.502 0 0 1-1.422 1.026H14.582a1.502 1.502 0 0 1-1.424-1.026l-2-6a1.497 1.497 0 0 1 1.424-1.974h16.836a1.498 1.498 0 0 1 1.424 1.974l-2 6ZM13.5 40.498H9a1.5 1.5 0 0 1-1.5-1.5v-25.5M31.504 14.998v4.5M13.5 19.498h9M13.5 28.498h3M26.09 45.438l-7.424 1.06 1.06-7.424 19.092-19.092a4.5 4.5 0 1 1 6.364 6.364L26.09 45.438Z' />
        </g>
        <defs>
          <clipPath id='a'>
            <path fill='#fff' d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconEdit;
