import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { format } from 'date-fns';
import './update.scss';
import _kebabCase from 'lodash.kebabcase';

export default class Update extends Component {
  layoutClasses() {
    let classes = {
      'update-single': true,
    };
    return classNames(classes);
  }

  renderList(list) {
    let output = [];
    list.forEach((item, index) => {
      output.push(
        <div className='update-item' key={index}>
          <div className='key'>
            <span className={`label -${_kebabCase(item.list_label)}`}>
              {item.list_label}
            </span>
          </div>
          <div className='description'>
            <p>{item.list_description}</p>
          </div>
        </div>
      );
    });
    return output;
  }

  render() {
    const { primary } = this.props;
    const publishedDate = primary.data.displayed_publication_date.split('T')[0];

    if (!primary) {
      return null;
    }

    const { data } = primary;

    return (
      <div className={this.layoutClasses()}>
        {publishedDate && (
          <p className='date'>
            {format(new Date(publishedDate), 'do MMMM yyyy')}
          </p>
        )}
        <div className='update-wrapper'>
          {data.content && data.content.length > 0 && (
            <div className='update-content'>
              {PrismicRichText.render(
                data.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          )}
          {data.content && data.content.length > 0 && data.list && (
            <div className='update-separator'></div>
          )}
          {data.list && (
            <div className='update-list'>{this.renderList(data.list)}</div>
          )}
        </div>
      </div>
    );
  }
}
