const primarySupport = require('./primarySupport');

const hydrotherapy = {
  supportName: 'Hydrotherapy',
  supportIcon: 'swimming',
  primarySupport: primarySupport,
  twoColumnLayout: {
    contentTitle: 'Read more about hydrotherapy support at Hireup',
    content: [
      {
        alt: 'Hireup users Katie and Janelle sit poolside on shift as they laugh during a conversation',
        dimensions: {
          height: 541,
          width: 960,
        },
        linkTo: {
          id: 'YQkxbxEAACMAaCv_',
          type: 'news',
          lang: 'en-au',
          uid: 'common-ground-janelle-katie',
        },
        type: 'image',
        url: 'https://images.prismic.io/hireup%2F0d1da23c-d376-4a3f-be99-11787fcba4f9_janelle_katie1.jpg?rect=0,78,4240,2226&w=600&h=315&q=75',
      },
    ],
    content_two: [
      {
        type: 'paragraph',
        text: '"It’s so important because when you’re managing a disability there can be stuff that feels out of your control. And so for there to be things that you feel like you have got some choice over, I think that is one of the things that sets Hireup apart: having that choice."',
        spans: [
          {
            start: 0,
            end: 380,
            type: 'label',
            data: {
              label: 'quote',
            },
          },
        ],
      },
      {
        type: 'paragraph',
        text: 'Read about hydrotherapy happening in our community',
        spans: [
          {
            start: 0,
            end: 150,
            type: 'hyperlink',
            data: {
              type: 'news',
              slug: 'common-ground-janelle-katie',
              lang: 'en-au',
              uid: 'common-ground-janelle-katie',
              link_type: 'Document',
            },
          },
        ],
      },
    ],
  },
  faqs: {
    content: [
      {
        type: 'heading2',
        text: 'Learn more about hydrotherapy support',
        spans: [],
      },
    ],
    items: [
      {
        question: [
          {
            type: 'heading3',
            text: 'What does hydrotherapy support involve?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Hydrotherapy involves hands-on pool (or aquatic) treatment after a diagnosis from a physiotherapist or occupational therapist. Like with every support type you can use on Hireup, every person’s support needs are different. So our disability support workers will work with you to get the most out of your hydrotherapy sessions, whatever support you might need.',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'What can hydrotherapy be used to treat?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Hydrotherapy and water-based treatment can be used to help with a large number of conditions including; arthritis and joint pain, joint replacement and orthopaedic surgery, back and neck pain, muscular aches and weakness, sporting injuries, neurological conditions and many more. ',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'Why is hydrotherapy support beneficial?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'The benefits of hydrotherapy will vary from person to person, as every individual will have a different plan, different needs and respond differently to treatment. Some of the benefits of hydrotherapy could include:',
            spans: [],
          },
          {
            type: 'list-item',
            text: 'Increased joint function without the constraints of gravity, allowing the body to move without the pains solid ground can cause.',
            spans: [],
          },
          {
            type: 'list-item',
            text: 'Improved mobility due to the warmer water that helps tight muscles relax.',
            spans: [],
          },
          {
            type: 'list-item',
            text: 'Reduced aches and pains as the warm water can relax muscle spasms.',
            spans: [],
          },
          {
            type: 'list-item',
            text: 'It feels good! Exercise helps the body release hormones that help you feel more energised and less fatigued. It helps build strength and increase balance of weaker muscle groups and function. Research has shown that exercising in warm water may help alleviate depression or anxiety.',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'Who runs a hydrotherapy session?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Hydrotherapy is normally delivered by a physiotherapist through one-on-one treatments in a specialised, heated pool, and can help a number of conditions ranging from arthritis to cerebral palsy.',
            spans: [],
          },
        ],
      },
    ],
  },
  relatedArticlesCategory: 'hydrotherapy',
};
module.exports = hydrotherapy;
//
