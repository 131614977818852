const primarySupport = require('./primarySupport');

const assistanceDailyLife = {
  supportName: 'Daily life',
  supportIcon: 'dogWalking',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = assistanceDailyLife;
