import * as React from 'react';

const IconKidney = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M6.136 40.5A4.636 4.636 0 0 1 1.5 35.864V22.5a21 21 0 0 1 21-21h17.928A6.072 6.072 0 0 1 46.5 7.572a12 12 0 0 1-8.552 11.494l-15.24 4.572a15.001 15.001 0 0 0-9.108 7.658l-3.32 6.642A4.638 4.638 0 0 1 6.136 40.5v0Z' />
        <path d='M10.33 37.84c4.346.286 12.17-.256 12.17-7.84 0 0 13.266 2.654 14.846-10.754M16.5 37.386V46.5M22.5 30v16.5M28.5 1.5c-4.5 0-9 4.5-9 12' />
      </svg>
    </span>
  );
};

export default IconKidney;
