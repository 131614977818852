import React, { Component } from 'react';
import classNames from 'classnames';
import { withPrismic } from '../../prismic/prismic';
import ShareLinks from '../../components/share-links/share-links';
import './share.scss';

class Share extends Component {
  layoutClasses = () => {
    let classes = {
      'layout-share': true,
    };
    return classNames(classes);
  };

  render() {
    const { primary } = this.props;

    return (
      <section className={this.layoutClasses(primary)}>
        <div className='container'>
          <div className='share-content'>
            <ShareLinks
              size='large'
              caption={primary.share_caption}
              message={primary.share_message}
              url={primary.share_url}
              showFacebook={primary.share_facebook === 'Visible'}
              showTwitter={primary.share_twitter === 'Visible'}
              showWhatsapp={primary.share_whatsapp === 'Visible'}
              showLinkedin={primary.share_linkedin === 'Visible'}
              showCopy={primary.share_copy_link === 'Visible'}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default withPrismic(Share);
