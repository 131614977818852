import React, { Component } from 'react';
import PageWrapper from './wrapper';
import Loading from '../components/loading/loading';
import {
  RichText as PrismicRichText,
  Link as PrismicLink,
} from 'prismic-reactjs';
import { PrismicConfig } from '../prismic/config';
import { withPrismic } from '../prismic/prismic';
import Map from '../components/map/map';
import ShareLinks from '../components/share-links/share-links';
import Error400 from './error-400';
import constants from '../config/constants';
import { format, isBefore } from 'date-fns';
import './event.scss';
import _get from 'lodash.get';
import { MdChevronLeft } from 'react-icons/md';

class EventPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: null,
      eventbriteModalTriggered: false,
    };
  }

  loadEvent() {
    this.setState({ uid: this.props.uid });
    this.props.prismic.getEvent(this.props.uid);
  }

  componentDidUpdate() {
    if (this.state.uid && this.props.uid === this.state.uid) {
      return null;
    }
    this.loadEvent();
  }

  loadEventbrite = (callback) => {
    const existingScript = document.getElementById('eventbriteScript');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://www.eventbrite.com.au/static/widgets/eb_widgets.js';
      script.id = 'eventbriteScript';
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) {
          callback();
        }
      };
    }
    if (existingScript && callback) {
      callback();
    }
  };

  getExternalLink(link) {
    if (!_get(link, 'url')) {
      return false;
    }
    return PrismicLink.url(link, PrismicConfig.linkResolver);
  }

  getEventbriteID(link) {
    // If this is an eventbrite link
    if (_get(link, 'url') && link.url.includes('eventbrite.com')) {
      // Determine the end bit of the URL, the ID
      let lastIndex = link.url.lastIndexOf('-');
      let id = link.url.substr(lastIndex + 1, link.url.length);

      // Load in the eventbrite widget
      this.loadEventbrite(() => {
        if (!this.state.eventbriteModalTriggered && window.EBWidgets) {
          this.setState({ eventbriteModalTriggered: true });
          window.EBWidgets.createWidget({
            widgetType: 'checkout',
            eventId: id,
            modal: true,
            modalTriggerElementId: `eventbrite-widget-modal-trigger-${id}`,
            onOrderComplete: this.eventbriteOrderComplete,
          });
        }
      });

      // Pass the event ID back
      return id;
    }
    return false;
  }

  eventbriteOrderComplete() {
    // We can update the page on this callback if needed
  }

  renderRSVPButton(event_rsvp, size = '') {
    /* TODO: Hide the RSVP button if the event is >24h over */

    if (!event_rsvp) {
      return;
    }

    let external_link = this.getExternalLink(event_rsvp),
      eventbrite_id = this.getEventbriteID(event_rsvp);

    if (!external_link) {
      return;
    }

    return (
      <div className='rsvp'>
        {!eventbrite_id && (
          <a
            className={`button -${size}`}
            href={external_link}
            target='_blank'
            rel='noopener noreferrer'>
            Register
          </a>
        )}
        {eventbrite_id && (
          <a
            className={`button -${size}`}
            href={external_link}
            id={`eventbrite-widget-modal-trigger-${eventbrite_id}`}>
            Register
          </a>
        )}
      </div>
    );
  }

  render() {
    if (this.props.error) {
      return <Error400 path={`event/${this.props.uid}`} />;
    }

    // Page Content
    const { content } = this.props;
    if (!content || !content.data) {
      return <Loading full={true} testId='event' />;
    }

    // Event Variables
    const {
      title,
      event_location,
      event_address,
      event_state,
      event_content,
      event_rsvp,
    } = content.data;
    const start = new Date(
      `${content.data?.event_date}T${content.data?.event_start_time}:00`
    );
    const end = new Date(
      `${content.data?.event_date}T${content.data?.event_end_time}:00`
    );
    const isOver = isBefore(end, new Date());
    const isOnline = event_state === 'Online';

    return (
      <PageWrapper
        context={`page -event -${content.uid}`}
        content={content}
        full={true}>
        <div className='event-header'>
          <div className='container'>
            <div className='content'>
              <a className='navigation-back' href={`/${constants.events_uid}/`}>
                <MdChevronLeft /> All Events
              </a>
              {title && <h1 className='heading'>{title[0].text}</h1>}
              {start && (
                <h2 className='date'>
                  {format(start, 'do MMMM, yyyy - h:mm aaa')}
                </h2>
              )}
              {!isOver && this.renderRSVPButton(event_rsvp)}
            </div>
          </div>
        </div>

        <div className='event-body'>
          <div className='container'>
            <div className='action'>
              {!isOnline && (
                <div className='event-map'>
                  <Map center={event_location} showCenter={true} />
                </div>
              )}
              <div className='action-content'>
                {isOnline && <h3>Online Event</h3>}

                {start && (
                  <>
                    <p className='date'>
                      <strong>Date:</strong>
                      {format(start, 'EEEE, do MMMM yyyy')}
                    </p>
                    <p className='time'>
                      <strong>Time:</strong>
                      {format(start, 'h:mm aaa')}{' '}
                      {end && `- ${format(end, 'h:mm aaa')}`}
                    </p>
                  </>
                )}

                {event_address &&
                  PrismicRichText.render(
                    event_address,
                    PrismicConfig.linkResolver,
                    PrismicConfig.htmlSerializer
                  )}

                {!isOver && this.renderRSVPButton(event_rsvp, 'large')}
                {isOver && (
                  <p>
                    <strong>This event has ended.</strong>
                  </p>
                )}
              </div>
            </div>

            <div className='content'>
              {event_content &&
                PrismicRichText.render(
                  event_content,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
            </div>
          </div>
        </div>

        {/* Share Links */}
        {content.data.title && (
          <div className='event-share'>
            <div className='container'>
              <div className='content'>
                <ShareLinks
                  message={content.data.title[0].text}
                  caption='Share This Event:'
                />
              </div>
            </div>
          </div>
        )}
      </PageWrapper>
    );
  }
}

export default withPrismic(EventPage);
