import * as React from 'react';

const IconMedicationHealth = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)'>
          <path d='M37.5 18.5v-3a2 2 0 0 0-2-2h-10v-10a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v10h-10a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h10v10a2 2 0 0 0 2 2h3M36.328 25.578a5.988 5.988 0 0 1 8.472 8.466L34.218 44.626a5.99 5.99 0 1 1-8.466-8.468l10.576-10.58ZM31.036 30.87l8.466 8.464' />
        </g>
        <defs>
          <clipPath id='a'>
            <path d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconMedicationHealth;
