import React from 'react';
import Analytics from '../../config/analytics';
import constants from '../../config/constants';
import './sidebar.scss';

export default function Sidebar() {
  function handleClick(event) {
    const path = window.location.pathname;
    Analytics.link(
      { url: event.currentTarget.href },
      `www.${path}.Sidebar.cta`,
      true
    );
  }
  return (
    <aside className='layout-sidebar'>
      <div className='container-sticky'>
        <div className='tile'>
          <div>
            <h2>New to Hireup?</h2>
            <img
              src='https://images.prismic.io/hireup/58d39a7c-4504-4090-89dc-222fe4c46c94_square-003.jpg?w=512&h=512&q=75'
              alt=''
            />
          </div>
          <div className='tile-body'>
            <p>
              Hireup is an NDIS-registered online platform for people with
              disability to find, hire and manage support workers who fit their
              needs and share their interests.
            </p>
            <p className='tile-cta'>
              <a
                href='/how-hireup-works'
                className='button -primary'
                onClick={handleClick}>
                Find support
              </a>
              <a
                href={`/${constants.landing_worker}`}
                className='button -secondary'
                onClick={handleClick}>
                Provide support
              </a>
            </p>
          </div>
        </div>
      </div>
    </aside>
  );
}
