const primarySupport = require('./primarySupport');

const feeding = {
  supportName: 'Feeding',
  supportIcon: 'meal',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = feeding;
