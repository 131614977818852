import * as React from 'react';

const IconIvBag = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M17.5 16.5h-6a6 6 0 0 0-6 6v12a6 6 0 0 0 6 6h6a6 6 0 0 0 6-6v-12a6 6 0 0 0-6-6ZM5.5 25.5h18' />
        <path d='M11.5 7.5a3 3 0 0 0 6 0v-3a3 3 0 0 1 3-3h15a6 6 0 0 1 6 6v39M14.5 10.5v6M17.5 31.5h6M14.5 40.5v6' />
      </svg>
    </span>
  );
};

export default IconIvBag;
