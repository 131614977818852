const samplePlatformData = [
  {
    title: 'Meal assistance and evening/bedtime supervision',
    location: 'Chatswood, NSW',
    time: '6pm - 9:30pm (3.5 hours)',
    day: 'Weekdays',
    frequency: 'Ongoing',
    salary: '130.20',
  },
  {
    title: 'Dog walking companion and morning motivation',
    location: 'Clarkson, WA',
    time: '8am - 9:30am (1.5 hours)',
    day: 'Monday',
    frequency: 'Ongoing',
    salary: '60.66',
  },
  {
    title: 'Study support for teenager with OCD and ADHD',
    location: 'Goulburn, NSW',
    time: '3pm - 5pm (2 hours)',
    day: 'Saturday',
    frequency: 'Ongoing',
    salary: '93.86',
  },
  {
    title: 'Help creating my resume and applying for jobs',
    location: 'Belmore, NSW',
    time: '11am - 5pm (6 hours)',
    day: 'Thursday',
    frequency: 'One-off',
    salary: '202.80',
  },
  {
    title: 'Transport to and from hydrotherapy and medical appointments',
    location: 'Bendigo, VIC',
    time: '9am - 1pm (4 hours)',
    day: 'Wednesday',
    frequency: 'Ongoing',
    salary: '135.20',
  },
  {
    title: 'Permanent fortnightly Saturday night bed shift',
    location: 'Kingston, QLD',
    time: '9:30pm - 11pm (1.5 hours)',
    day: 'Saturday',
    frequency: 'Ongoing',
    salary: '84.92',
  },
  {
    title: 'Weekly house cleaning and grocery shop',
    location: 'Mermaid Waters, QLD',
    time: '10:30am - 3pm (4.5 hours)',
    day: 'Tuesday',
    frequency: 'Ongoing',
    salary: '152.10',
  },
  {
    title: 'Help using a lifter for transferring out of bed and dressing',
    location: 'Oakleigh, VIC',
    time: '7am - 9am (2 hours)',
    day: 'Everyday',
    frequency: 'Ongoing',
    salary: '67.60',
  },
  {
    title: 'Sunday night sleepover for female support worker',
    location: 'Port Adelaide, SA',
    time: '8pm - 6am (10 hours, overnight)',
    day: 'Sunday',
    frequency: 'One-off',
    salary: '287.34',
  },
  {
    title: 'A night at the movies',
    location: 'Orange, NSW',
    time: '6pm - 9:30pm (3.5 hours)',
    day: 'Friday',
    frequency: 'One-off',
    salary: '130.20',
  },
];

export default samplePlatformData;
