import * as React from 'react';

const IconBed = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M6 22.5V18a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v4.5M24 22.5V18a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v4.5M5.5 22.5h37a4 4 0 0 1 4 4V36h-45v-9.5a4 4 0 0 1 4-4v0ZM1.5 36v6M46.5 36v6' />
        <path d='M42 22.5V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v13.5' />
      </svg>
    </span>
  );
};

export default IconBed;
