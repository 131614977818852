import React, { Component } from 'react';
import classNames from 'classnames';
import './loading.scss';

export default class Loading extends Component {
  layoutClasses() {
    return classNames({
      'spinner-container': true,
      '-full-screen': this.props.full,
      [`-align-${this.props.align}`]: this.props.align,
    });
  }

  render() {
    const { testId, text } = this.props;

    return (
      <div
        className={this.layoutClasses()}
        data-testid={testId ? testId : null}>
        <div className='spinner-indicator'></div>
        <p className={text ? 'spinner-text' : 'screen-reader-only'}>
          {text ? text : 'Loading'}
        </p>
      </div>
    );
  }
}
