const primarySupport = require('./primarySupport');

const nailCare = {
  supportName: 'Nail care',
  supportIcon: 'nailPolish',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = nailCare;
