import React, { useState } from 'react';
import Analytics from '../../config/analytics';
import Autocomplete from '../../components/autocomplete/autocomplete';
import './search-lite.scss';
import useSessionStorage from '../../helpers/useSessionStorage';

export default function SearchLite() {
  const [error, setError] = useState(false);
  const [locationSearched, setLocationSearched] = useSessionStorage(
    'hu-location',
    null
  );

  const handleSubmit = (event) => {
    if (locationSearched === null) {
      event.preventDefault();
      setError(true);
    } else {
      Analytics.event('click', 'button', 'Search');
    }
  };

  return (
    <div className='search-lite'>
      <h2 className='form-title'>Find NDIS support workers near you</h2>
      <form
        className={error ? 'search-error search-inputs' : 'search-inputs'}
        autoComplete='off'
        method='get'
        action='/pre-registration'
        onSubmit={handleSubmit}
        data-testid='form'>
        <div className='search-location'>
          <label htmlFor='search'>Enter suburb or postcode</label>
          <Autocomplete
            wrapperId='search-lite'
            inputId='search'
            required={true}
            setError={setError}
            setLocationSearched={setLocationSearched}
          />
        </div>
        <div className='search-button'>
          <button type='submit' className='button'>
            <span className='button-primary'>Search</span>
          </button>
        </div>
      </form>

      {error && (
        <p role='alert' className='error-message'>
          Please select from the suggested location options before submitting
          your search.
        </p>
      )}
    </div>
  );
}
