import React, { useEffect } from 'react';
import PageWrapper from '../pages/wrapper';
import Loading from '../components/loading/loading';
import SEOCardsLayout from '../layouts/seo-cards/seo-cards';
import SEOLinksLayout from '../layouts/seo-links/seo-links';
import TwoColumnLayout from '../layouts/two-column/two-column';
import RelatedArticles from '../layouts/related-articles/related-articles';
import FAQs from '../layouts/faqs/faqs';
import Subpage from '../pages/subpage';
import { withPrismic } from '../prismic/prismic';
import { generateHeadingText, generateIntroText } from './data/helpers';
import Error400 from '../pages/error-400';
import constants from '../config/constants';
import _startCase from 'lodash.startcase';
import '../components/question-answer/question-answer.scss';
import './secondary-support-shared.scss';

function SupportPage({ prismic, prismicContext, support, error }) {
  useEffect(() => {
    if (prismic && !prismicContext) {
      prismic.getSupportType();
    }
  }, [prismic, prismicContext]);

  if (!prismicContext) {
    return <Loading full={true} testId='support' />;
  }

  if (error) {
    return <Error400 path={`/${window.location.pathname.split('/')[2]}/`} />;
  }

  const meta = {
    title: `${_startCase(support?.supportName)} | Support Workers Near Me`,
    description: `Hireup's ${support?.supportName} support workers are NDIS certified & trained to provide care & help to meet your personal needs. Find support workers near you.`,
  };

  const title = generateHeadingText(support);
  const intro = generateIntroText(support);
  const headerImage = support?.supportImage || support?.primarySupport?.image;

  return (
    <PageWrapper
      title={meta.title}
      description={meta.description}
      context={`page -location-support`}
      content={prismicContext}
      breadcrumbs={true}>
      <section className='location-header -support -with-background'>
        <div className='container'>
          <div className='content'>
            {title && <h1 className='heading'>{title}</h1>}
            <>{intro}</>
            <div className='cta'>
              <a href={`${constants.registration}`} className='button'>
                Get started
              </a>
            </div>
          </div>
        </div>
        <div
          className='layout-background'
          style={{
            backgroundImage: `url(${headerImage}?auto=format&h=600&q=75)`,
          }}></div>
      </section>

      {support.primarySupport?.introBlock && (
        <TwoColumnLayout primary={support.primarySupport.introBlock} />
      )}

      {support.twoColumnLayout && (
        <TwoColumnLayout primary={support.twoColumnLayout} />
      )}

      {support.faqs && (
        <FAQs primary={support.faqs} items={support.faqs?.items} />
      )}

      <SEOCardsLayout background='white' />

      <SEOLinksLayout />

      <Subpage uid='subpage-support-type' />

      {support.relatedArticlesCategory && (
        <RelatedArticles
          category={support.relatedArticlesCategory}
          title={`Read more about ${support.supportName.toLowerCase()} at Hireup`}
          hideStaticPost={true}
        />
      )}
    </PageWrapper>
  );
}

export default withPrismic(SupportPage);
