import { useEffect } from 'react';
import qs from 'qs';
import { PrismicConfig } from './config';
import { withPrismic } from '../prismic/prismic';

const Preview = ({ history, location, prismicContext }) => {
  useEffect(() => {
    const params = qs.parse(location.search.slice(1));
    if (!params.token) {
      return console.warn(`No token available, check your configuration`);
    }
    if (prismicContext) {
      prismicContext.api
        .previewSession(params.token, PrismicConfig.linkResolver, '/')
        .then((url) => {
          history.push(url);
        });
    }
  });
  return null;
};

export default withPrismic(Preview);
