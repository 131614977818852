import React, { Component } from 'react';
import Analytics from '../../config/analytics';
import { ReactComponent as Backpack } from '../../assets/svg/icon-backpack.svg';
import { ReactComponent as SprayBottle } from '../../assets/svg/icon-spray-bottle.svg';
import { ReactComponent as GraduationHat } from '../../assets/svg/icon-graduation-hat.svg';
import { ReactComponent as Clipboard } from '../../assets/svg/icon-clipboard.svg';
import { ReactComponent as Certificate } from '../../assets/svg/icon-certificate.svg';
import { ReactComponent as Teapot } from '../../assets/svg/icon-teapot.svg';
import { ReactComponent as Bath } from '../../assets/svg/icon-bath.svg';
import { ReactComponent as Car } from '../../assets/svg/icon-car.svg';
import constants from '../../config/constants';
import './searchResults.scss';

export default class SearchResults extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (event) => {
    const path = window.location.pathname;

    Analytics.link(
      { url: event.currentTarget.href },
      `www.${path}.SearchResults.cta`
    );
  };

  renderResults() {
    const data = {
      'Out and about': {
        icon: <Backpack />,
        description:
          'Get out, take a class, exercise, volunteer or go to gatherings.',
        href: '/out-and-about/',
      },
      'Help around the house': {
        icon: <SprayBottle />,
        description: 'Cleaning, yard maintenance, and home-office duties.',
        href: '/help-around-the-house/',
      },
      'Education, training, and employment': {
        title: 'Education and training',
        icon: <GraduationHat />,
        description:
          'Coaching to help you achieve your goals at school or work.',
        href: '/education-and-employment/',
      },
      'Therapy support': {
        icon: <Clipboard />,
        description: 'Support to plan, practice, and enjoy your therapy.',
        href: '/therapy-support/',
      },
      'Specialised support': {
        icon: <Certificate />,
        description:
          'High needs support for more complex, personal requirements.',
        href: '/specialised-complex/',
      },
      'In home care': {
        icon: <Teapot />,
        description: 'Routines, medical prompts and meal preparation.',
        href: '/in-home-care/',
      },
      'Personal care and hygiene': {
        title: 'Personal care',
        icon: <Bath />,
        description: 'Showering, toileting and mealtime assistance.',
        href: '/personal-care/',
      },
      Transport: {
        title: 'Travel',
        icon: <Car />,
        description: 'Support to get your chores done or travel from A to B.',
        href: '/transport/',
      },
    };

    function getNumber(number) {
      if (number >= 5) {
        return Intl.NumberFormat().format(number);
      } else {
        return (
          <>
            <span aria-label='Less than'>&#60;</span>5
          </>
        );
      }
    }

    return Object.entries(this.props.facetCounts.supportAreas).map(
      ([key, value]) => (
        <li className='result-single' key={key}>
          <div>
            <h4 className='result-heading'>
              <a href={data[key].href}>{data[key].title || key}</a>
            </h4>
            <span className='result-icon'>{data[key].icon}</span>
          </div>
          <div className='result-body'>
            <span className='result-total'>{getNumber(value)}</span>
            Available workers
            <p className='result-description'>{data[key].description}</p>
          </div>
        </li>
      )
    );
  }

  render() {
    return (
      <>
        <ul className='layout-search-results'>{this.renderResults()}</ul>

        <div className='search-results-cta'>
          <h3 className='cta-heading'>
            Get started now to connect with local support workers who are
            right&nbsp;for&nbsp;you.
          </h3>
          <a onClick={this.handleClick} href={constants.registrationClient}>
            <span className='button-primary'>Get started</span>
          </a>
        </div>
      </>
    );
  }
}
