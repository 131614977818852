import React, { useEffect } from 'react';
import Error400 from '../pages/error-400';
import Loading from '../components/loading/loading';
import PageWrapper from '../pages/wrapper';
import SEOCardsLayout from '../layouts/seo-cards/seo-cards';
import SEOLinksLayout from '../layouts/seo-links/seo-links';
import Subpage from '../pages/subpage';
import TwoColumnLayout from '../layouts/two-column/two-column';
import constants from '../config/constants';
import { generateHeadingText, generateIntroText } from './data/helpers';
import _startCase from 'lodash.startcase';
import { withPrismic } from '../prismic/prismic';
import './secondary-support-shared.scss';

function LocationPage({ prismic, prismicContext, support, error }) {
  useEffect(() => {
    if (prismic && !prismicContext) {
      prismic.getSupportType();
    }
  }, [prismic, prismicContext]);

  if (!prismicContext) {
    return <Loading full={true} testId='location-state' />;
  }

  if (error) {
    return <Error400 path={`/${window.location.pathname.split('/')[2]}/`} />;
  }

  const locationState = window.location.pathname.split('/')[3].toUpperCase();

  const meta = {
    title: `${_startCase(
      support?.supportName
    )} Support Workers in ${locationState}`,
    description: `Find ${support?.supportName} support workers available in ${locationState}. Find, hire and manage your NDIS disability support workers through Hireup.`,
  };

  const title = generateHeadingText(support, locationState);
  const headerImage = support?.supportImage || support?.primarySupport?.image;

  return (
    <PageWrapper
      title={meta.title}
      description={meta.description}
      context={`page -location-support`}
      content={prismicContext}
      noindex={true}
      breadcrumbs={true}>
      {support?.supportName && locationState !== null && (
        <section className='location-header -with-background'>
          <div className='container'>
            <div className='content'>
              {title && <h1 className='heading'>{title}</h1>}
              <>{generateIntroText(support)}</>
              <div className='cta'>
                <a href={`${constants.registration}`} className='button'>
                  Get started
                </a>
              </div>
            </div>
          </div>
          <div
            className='layout-background'
            style={{
              backgroundImage: `url(${headerImage}?auto=format&h=600&q=75)`,
            }}></div>
        </section>
      )}

      {support.primarySupport.introBlock && (
        <TwoColumnLayout primary={support.primarySupport.introBlock} />
      )}

      <SEOCardsLayout background='white' />

      <SEOLinksLayout />

      <Subpage uid='subpage-support-type' />
    </PageWrapper>
  );
}

export default withPrismic(LocationPage);
