import axios from 'axios';
import startCase from 'lodash.startcase';
import { getEnvBaseUrl, API_BASE_URL_V1_5 } from '../config/api';
import Analytics from '../config/analytics';

// react app does not have access to environment
// variables at run time, so we need to get the
// environment url based on the window.location
const ENV_BASE_URL = getEnvBaseUrl();
const DEFAULT_LOCATION = 'Sydney, NSW 2000';
const DEFAULT_RADIUS = 30000;

const api = axios.create({
  headers: {
    'x-event-anonymous-Id': Analytics.getAnonymousId(),
  },
  baseURL: `${ENV_BASE_URL}${API_BASE_URL_V1_5}`,
  withCredentials: true,
});

export const transformLocationString = (string) => {
  const locationSearchedArr = string.split(', ');
  return `${startCase(locationSearchedArr[0].toLowerCase())}, ${
    locationSearchedArr[1]
  }`;
};

const generateApiParams = (
  latitude,
  longitude,
  radius,
  placeName,
  supportType
) => {
  let supportAreas;
  if (supportType && supportType !== 'All') {
    supportAreas = { supportAreas: [supportType] };
  }
  return {
    ...supportAreas,
    location: {
      latitude: latitude,
      longitude: longitude,
      radius: radius || DEFAULT_RADIUS,
      placeName: placeName
        ? transformLocationString(placeName)
        : DEFAULT_LOCATION,
    },
  };
};

// Returns total workers for the location
const getPublicSupportWorkerFacetCount = async (
  latitude,
  longitude,
  radius,
  placeName,
  supportType
) => {
  const params = generateApiParams(
    latitude,
    longitude,
    radius,
    placeName,
    supportType
  );
  return await api.post('/search/public', params);
};

// Returns the opted-in workers for the location
const getOptedInSupportWorkerProfiles = async (
  latitude,
  longitude,
  radius,
  placeName,
  supportType
) => {
  const params = generateApiParams(
    latitude,
    longitude,
    radius,
    placeName,
    supportType
  );

  return await api.post('/search/public/profiles', params);
};

export const getTotalWorkers = ({ publicSearchResponse }) =>
  publicSearchResponse?.totalResultCount;

export const getSupportWorkerData = async ({
  location,
  locationRadius,
  locationSearched,
  supportType,
}) => {
  const [supportWorkerFacetCount, optedInSupportWorkerProfiles] =
    await Promise.all([
      getPublicSupportWorkerFacetCount(
        location.latitude,
        location.longitude,
        locationRadius,
        locationSearched,
        supportType
      ),
      getOptedInSupportWorkerProfiles(
        location.latitude,
        location.longitude,
        locationRadius,
        locationSearched,
        supportType
      ),
    ]);

  const filteredProfiles = optedInSupportWorkerProfiles.data.items
    // remove profiles with no bio
    .filter((worker) => worker.profile.bio)
    // remove profiles with no avatar
    .filter((worker) => !worker.profile.pictureUrl.includes('default-profile'));

  return {
    publicSearchResponse: supportWorkerFacetCount.data,
    publicSearchProfiles: filteredProfiles,
    placeName: supportWorkerFacetCount.data.filters.location.placeName,
    radius: supportWorkerFacetCount.data.filters.location.radius,
  };
};
