import React, { Component } from 'react';
import classNames from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import { MdLocationOn } from 'react-icons/md';
import { IoMdBriefcase } from 'react-icons/io';
import './career.scss';

export default class Career extends Component {
  componentDidMount() {}

  layoutClasses() {
    let classes = {
      'career-single': true,
    };
    return classNames(classes);
  }

  determineClassificationValue(name) {
    const { career, fields } = this.props;
    let values = [],
      classifications = [];

    // Find the available values for this classification (eg the values for 'Location')
    fields.Field.forEach((field) => {
      if (field['@attributes'].name === name) {
        values = field.Values.Value;
      }
    });

    // Find all classifications for type 'name' for the supplied career
    career.Classifications &&
      career.Classifications.Classification.forEach((classification) => {
        values.forEach((value) => {
          if (value['@attributes'].name === classification) {
            classifications.push(classification);
            // For the moment, just return the one matching categorisation
            return classification;
          }
        });
      });

    // TODO: If multiple categorisations exist on careers, return them all and render them differently
    return classifications;
  }

  render() {
    const { career } = this.props;

    if (!career) {
      return null;
    }

    let postedDate = career['@attributes']?.datePosted + `T00:00:00`,
      location = this.determineClassificationValue('Location'),
      workType = this.determineClassificationValue('Work Type');

    return (
      <a
        className={this.layoutClasses()}
        href={`/careers/${career['@attributes'].jid}`}>
        <h3 className='title'>{career.Title}</h3>

        {postedDate && (
          <p className='meta'>
            Posted{' '}
            {formatDistanceToNow(new Date(postedDate), { addSuffix: true })}
          </p>
        )}
        <p className='meta'>
          {location && (
            <span className='classification'>
              <MdLocationOn /> {location}
            </span>
          )}
          {workType && (
            <span className='classification'>
              <IoMdBriefcase /> {workType}
            </span>
          )}
        </p>
      </a>
    );
  }
}
