import React from 'react';
import { withPrismic } from '../../prismic/prismic';
import { RichText as PrismicRichText, Link } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Slices from '../../prismic/slices';
import SiteLink from '../../components/site-link/site-link';
import Image from '../../components/image/image';
import Nav from '../../components/nav/nav';
import RelatedPages from '../related-pages/related-pages';
import NavLocale from '../../components/nav-locale/nav-locale';
import classNames from 'classnames';
import brand from '../../assets/img/hireup.brand.inverse.svg';
import Analytics from '../../config/analytics';
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoYoutube,
  IoLogoTwitter,
  IoLogoLinkedin,
} from 'react-icons/io';
import AppleAppStoreBadge from '../../assets/img/apple-app-store-badge.svg';
import GooglePlayStoreBadge from '../../assets/img/google-play-store-badge.svg';
import TrustpilotLogo from '../../assets/img/trustpilot-brand.png';
import './footer.scss';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear(),
    };
  }

  componentDidMount() {
    this.props.prismic.getFooter();
  }

  componentDidUpdate(old) {
    const { locale, prismic } = this.props;
    if (old.locale !== locale) {
      prismic.getFooter();
    }
  }

  footerClasses() {
    const isHidden = this.props.footer_cta === 'Hidden';

    return classNames({
      'footer-primary': true,
      '-with-cta': !isHidden,
      '-without-cta': isHidden,
    });
  }

  renderLegalLinks() {
    let output = [];
    const { footer } = this.props;
    footer.legal_links &&
      footer.legal_links.forEach((item, index) => {
        output.push(<SiteLink link={item} key={index} />);
      });
    return output;
  }

  render() {
    const { footer, footer_content, footer_cta, locale, translations } =
      this.props;

    if (!footer) {
      return null;
    }

    const { navigation } = footer;

    return (
      <>
        {this.props.alternative_navigation?.uid && (
          <RelatedPages uid={this.props.alternative_navigation.uid} />
        )}

        {footer_content !== 'Hidden' && (
          <div className='footer-slices'>
            {footer.body && <Slices slices={footer.body} />}
          </div>
        )}

        {footer_cta !== 'Hidden' && (
          <>
            {footer.cta_text && footer.cta_img && (
              <div className='footer-cta -background-rise-alt'>
                <div className='container'>
                  <div className='body'>
                    {footer &&
                      PrismicRichText.render(
                        footer.cta_text,
                        PrismicConfig.linkResolver,
                        PrismicConfig.htmlSerializer
                      )}
                  </div>
                  <div className='img'>
                    <Image primary={footer.cta_img} lazyload={true} />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <div className={this.footerClasses()}>
          <div className='container'>
            <div className='brand'>
              <img src={brand} alt='Hireup' width='208' height='54' />
            </div>
            {navigation && (
              <Nav
                uid={navigation.uid}
                classes={['menu-full']}
                collapsedSubmenus={false}
                ariaLabel='Footer Menu'
              />
            )}
          </div>

          <div className='container'>
            <div className='copyright'>
              <p>
                <span>&copy; {this.state.year}</span> Hireup Pty Ltd
              </p>
              {footer.copyright &&
                PrismicRichText.render(
                  footer.copyright,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
            </div>

            <div className='address'>
              {footer.address &&
                PrismicRichText.render(
                  footer.address,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
            </div>

            <div className='contact'>
              {footer.contact &&
                PrismicRichText.render(
                  footer.contact,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
            </div>

            <div className='social'>
              {footer.link_facebook && (
                <a
                  href={Link.url(
                    footer.link_facebook,
                    PrismicConfig.linkResolver
                  )}
                  target={footer.link_facebook.target}
                  rel={
                    footer.link_facebook.target === '_blank'
                      ? 'noopener noreferrer'
                      : ''
                  }
                  title='Hireup on Facebook'
                  onClick={() => Analytics.link(footer.link_facebook)}>
                  <IoLogoFacebook />
                  <span className='screen-reader-only'>Facebook</span>
                </a>
              )}
              {footer.link_linkedin && (
                <a
                  href={Link.url(
                    footer.link_linkedin,
                    PrismicConfig.linkResolver
                  )}
                  target={footer.link_linkedin.target}
                  rel={
                    footer.link_linkedin.target === '_blank'
                      ? 'noopener noreferrer'
                      : ''
                  }
                  title='Hireup on LinkedIn'
                  onClick={() => Analytics.link(footer.link_linkedin)}>
                  <IoLogoLinkedin />
                  <span className='screen-reader-only'>LinkedIn</span>
                </a>
              )}
              {footer.link_youtube && (
                <a
                  href={Link.url(
                    footer.link_youtube,
                    PrismicConfig.linkResolver
                  )}
                  target={footer.link_facebook.target}
                  rel={
                    footer.link_youtube.target === '_blank'
                      ? 'noopener noreferrer'
                      : ''
                  }
                  title='Hireup on YouTube'
                  onClick={() => Analytics.link(footer.link_youtube)}>
                  <IoLogoYoutube />
                  <span className='screen-reader-only'>YouTube</span>
                </a>
              )}
              {footer.link_twitter && (
                <a
                  href={Link.url(
                    footer.link_twitter,
                    PrismicConfig.linkResolver
                  )}
                  target={footer.link_twitter.target}
                  rel={
                    footer.link_twitter.target === '_blank'
                      ? 'noopener noreferrer'
                      : ''
                  }
                  title='Hireup on Twitter'
                  onClick={() => Analytics.link(footer.link_twitter)}>
                  <IoLogoTwitter />
                  <span className='screen-reader-only'>Twitter</span>
                </a>
              )}
              {footer.link_instagram && (
                <a
                  href={Link.url(
                    footer.link_instagram,
                    PrismicConfig.linkResolver
                  )}
                  target={footer.link_instagram.target}
                  rel={
                    footer.link_linkedin.target === '_blank'
                      ? 'noopener noreferrer'
                      : ''
                  }
                  title='Hireup on Instagram'
                  onClick={() => Analytics.link(footer.link_instagram)}>
                  <IoLogoInstagram />
                  <span className='screen-reader-only'>Instagram</span>
                </a>
              )}
              &nbsp;
              <div className='app-store'>
                <a
                  href='https://apps.apple.com/au/app/hireup/id1484421263'
                  rel='noopener noreferrer'
                  target='_blank'>
                  <span className='screen-reader-only'>
                    Download on the Apple app store (opens in new window)
                  </span>
                  <Image
                    lazyload={true}
                    primary={{
                      url: AppleAppStoreBadge,
                      alt: '',
                    }}
                  />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=au.com.hireup.app'
                  rel='noopener noreferrer'
                  target='_blank'>
                  <span className='screen-reader-only'>
                    Download on Google Play (opens in new window)
                  </span>
                  <Image
                    lazyload={true}
                    primary={{
                      url: GooglePlayStoreBadge,
                      alt: '',
                    }}
                  />
                </a>
              </div>
              <div className='trustpilot'>
                <a
                  href='https://au.trustpilot.com/review/hireup.com.au'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <span className='trustpilot-rating'>
                    <Image
                      lazyload={true}
                      primary={{
                        url: 'https://emailsignature.trustpilot.com/signature/en-AU/3/5d300c7a0249cc000124a84c/stars.png',
                        alt: 'Trustpilot Rating',
                      }}
                    />
                  </span>
                  <span className='trustpilot-brand'>
                    <Image
                      lazyload={true}
                      primary={{
                        url: TrustpilotLogo,
                        alt: 'Trustpilot Logo',
                      }}
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div className='container'>
            {footer.disclaimer && (
              <div className='disclaimer'>
                {footer.disclaimer &&
                  PrismicRichText.render(
                    footer.disclaimer,
                    PrismicConfig.linkResolver,
                    PrismicConfig.htmlSerializer
                  )}
              </div>
            )}
            {footer.legal_links && (
              <div className='sub-links'>{this.renderLegalLinks()}</div>
            )}
          </div>

          {translations && (
            <div className='container'>
              {
                <div className='locales'>
                  <nav
                    className='menu-wide'
                    aria-label='Available Languages'
                    id='locales'>
                    <ul className='menu'>
                      <NavLocale locale={locale} translations={translations} />
                    </ul>
                  </nav>
                </div>
              }
            </div>
          )}
        </div>
      </>
    );
  }
}
export default withPrismic(Footer);
