import React from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import Trustpilot from '../trustpilot/trustpilot';
import ShapeBackground from './shape-background';
import { PrismicConfig } from '../../prismic/config';
import { getShapeFill, getThemeClass } from '../../helpers/campaignHelpers';

import '../../assets/scss/campaign.scss';
import './campaign-trustpilot.scss';

const CampaignTrustpilot = ({ primary }) => {
  return (
    <section
      className={classNames(
        'campaign-trustpilot-section padding-section text-center',
        getThemeClass(primary.background_color)
      )}>
      <div className='shape-background'>
        <ShapeBackground
          fill={
            primary.shape_color ? getShapeFill(primary.shape_color) : '#FFF1BD'
          }
        />
      </div>
      <div className='container'>
        <div className='gap-y-content section-gutter text-center'>
          {primary.content_header && primary.content_header.length ? (
            <div className='section-gutter'>
              <div className='rich-text text-center'>
                {PrismicRichText.render(
                  primary.content_header,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
              </div>
            </div>
          ) : null}
          <div className='theme-white trustpilot-box'>
            <Trustpilot />
          </div>
          {primary.content_footer && primary.content_footer.length ? (
            <div className='section-gutter'>
              <div className='rich-text text-center'>
                {PrismicRichText.render(
                  primary.content_footer,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default CampaignTrustpilot;
