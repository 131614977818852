import React, { useState } from 'react';
import TwoColumnLayout from '../two-column/two-column';
import SupportCards from '../support-cards/support-cards';
import StepList from '../step-list/step-list';
import Analytics from '../../config/analytics';
import classNames from 'classnames';
import './content-toggle.scss';

export default function ContentToggle({ primary }) {
  const [toggle, setToggle] = useState('toggle1');

  if (!primary) {
    return null;
  }

  const toggleOneLabel = primary.toggle_1[0].text;
  const toggleTwoLabel = primary.toggle_2[0].text;
  const content_1 = {
    content: primary.content_text_1,
    content_two: primary.content_media_1,
  };
  const content_2 = {
    content: primary.content_text_2,
    content_two: primary.content_media_2,
  };

  const handleToggleOneClick = (e) => {
    setToggle('toggle1');
    sendAnalytics(e);
  };

  const handleToggleTwoClick = (e) => {
    setToggle('toggle2');
    sendAnalytics(e);
  };

  const sendAnalytics = (e) => {
    const path = window.location.pathname;

    Analytics.link(
      { label: e.target.textContent },
      `www.${path}.contentToggle.toggleBtn`,
      true
    );
  };

  const layoutClasses = () => {
    let classes = {
      'layout-content-toggle': true,
      [`-${toggle}`]: true,
    };
    return classNames(classes);
  };

  const toggleClasses = (isActive) => {
    let classes = {
      button: true,
      '-toggle': true,
      '-active': isActive,
    };
    return classNames(classes);
  };

  const style = {
    display: 'none',
  };

  return (
    <section className={layoutClasses(primary)}>
      <div className='container -toggle'>
        <div className='buttons-toggle'>
          <button
            type='button'
            className={toggleClasses(toggle === 'toggle1')}
            onClick={handleToggleOneClick}>
            {toggleOneLabel}
          </button>
          <button
            type='button'
            className={toggleClasses(toggle === 'toggle2')}
            onClick={handleToggleTwoClick}>
            {toggleTwoLabel}
          </button>
        </div>
      </div>

      <div style={toggle === 'toggle1' ? null : style}>
        <TwoColumnLayout primary={content_1} />
        <SupportCards />
      </div>

      <div style={toggle === 'toggle2' ? null : style}>
        <TwoColumnLayout primary={content_2} />
        <StepList />
      </div>
    </section>
  );
}
