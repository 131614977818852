import React from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import ShapeBackground from './shape-background';
import Image from '../../components/image/image';
import { PrismicConfig } from '../../prismic/config';
import { getShapeFill, getThemeClass } from '../../helpers/campaignHelpers';

import '../../assets/scss/campaign.scss';
import './campaign-feature.scss';

const CampaignFeature = ({ primary }) => {
  return (
    <section
      className={classNames(
        'padding-section campaign-feature-section text-center',
        getThemeClass(primary.background_color)
      )}>
      <div className='shape-background'>
        <ShapeBackground fill={getShapeFill(primary.shape_color)} />
      </div>
      <div className='container'>
        <div className='section-gutter'>
          <div className='max-width-small gap-y-content'>
            {primary.content_header && primary.content_header.length > 0 ? (
              <div className='rich-text'>
                {PrismicRichText.render(
                  primary.content_header,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
              </div>
            ) : null}
            {primary.content_image ? (
              <div className='image-wrapper'>
                <Image primary={primary.content_image} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CampaignFeature;
