const primarySupport = {
  name: 'Education and employment',
  appName: 'Education, training, and employment',
  image:
    'https://images.prismic.io/hireup/a9b818ef-edd0-4f0b-aa40-3d2b00f44d79_Description%3DMel+Socialising.png',
  introBlock: {
    background_color: 'Dark',
    content: [
      {
        type: 'heading2',
        text: 'What is education and employment support?',
        spans: [],
      },
      {
        type: 'paragraph',
        text: 'Hireup support workers can assist with skills and work from your school, college or work. For NDIS participants, funding for lifelong learning within capacity building may fall in this category. Education and employment is one of eight areas of disability support that support workers can provide on Hireup.',
        spans: [],
      },
    ],
    content_two: [
      {
        type: 'paragraph',
        text: '',
        spans: [],
      },
    ],
    layout: '2:1',
  },
};

module.exports = primarySupport;
