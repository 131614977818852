import axios from 'axios';
import React, { useEffect } from 'react';
import accessibleAutocomplete from 'accessible-autocomplete';
import Analytics from '../../config/analytics';
import useSessionStorage from '../../helpers/useSessionStorage';
import './autocomplete.scss';

export default function Autocomplete(primary) {
  const {
    wrapperId,
    inputId,
    inputName,
    placeholder,
    required,
    setLocationSearched,
    setError,
  } = primary;

  const DEFAULT_INPUT_ID = 'search';
  const DEFAULT_INPUT_NAME = 'location';
  const DEFAULT_PLACEHOLDER = 'e.g Sydney, NSW 2000';
  const PATH = window.location.pathname;

  const [locationSearched] = useSessionStorage('hu-location', null);

  async function fetchSuggestions(query) {
    try {
      const res = await axios.post('/suggestions/', {
        query: query,
      });
      return res.data;
    } catch (err) {
      console.warn('Error retrieving autocomplete suggestions', err);
    }
  }

  useEffect(() => {
    const wrapper = document.querySelector(`#${wrapperId}`);
    const input = document.querySelector(`#${inputId}`);

    if (!input) {
      accessibleAutocomplete({
        defaultValue: locationSearched || '',
        element: wrapper,
        id: `${inputId}` || DEFAULT_INPUT_ID,
        source: async (query, populateResults) => {
          const res = await fetchSuggestions(query);
          populateResults(res);
        },
        name: inputName || DEFAULT_INPUT_NAME,
        placeholder: placeholder || DEFAULT_PLACEHOLDER,
        required: required,
        minLength: 3,
        displayMenu: 'overlay',
        autoselect: true,
        confirmOnBlur: false,
        onConfirm: (location) => {
          if (location) {
            setLocationSearched(location);

            // remove error message from previous failed attempts
            setError(false);

            Analytics.link(
              { label: `Autocomplete input` },
              `www.${PATH}.Autocomplete.input`,
              true
            );
          }
        },
      });
    }
  }, [
    wrapperId,
    placeholder,
    inputName,
    inputId,
    setLocationSearched,
    locationSearched,
    setError,
    required,
    PATH,
  ]);

  return <div id={`${wrapperId}`} className='autocomplete'></div>;
}
