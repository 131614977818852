import React from 'react';
import { RichText as PrismicRichText, Link } from 'prismic-reactjs';
import Analytics from '../../config/analytics';
import { PrismicConfig } from '../../prismic/config';
import Badge from '../badge/badge';
import Image from '../image/image';
import './product-card.scss';

const ProductCard = ({ primary }) => {
  if (!primary) {
    return null;
  }
  const {
    card_image,
    card_content,
    button_text,
    button_url,
    show_badge,
    badge_type,
  } = primary;

  const sendAnalytics = (e) => {
    const path = window.location.pathname;

    Analytics.link(
      { label: e.target.href },
      `www.${path}.productCard.button`,
      true
    );
  };

  const handleButtonClick = (e) => {
    sendAnalytics(e);
  };

  return (
    <div className='product-card'>
      {show_badge && (
        <Badge type={badge_type} className='new-product-badge'></Badge>
      )}
      {card_image && (
        <Image
          className='image-single'
          primary={card_image}
          width={349}
          height={250}
          lazyload={true}
        />
      )}

      <div className='card-inner'>
        <div className='card-content'>
          {PrismicRichText.render(
            card_content,
            PrismicConfig.linkResolver,
            PrismicConfig.htmlSerializer
          )}
        </div>
        <div className='card-cta'>
          <a
            href={Link.url(button_url, PrismicConfig.linkResolver)}
            className='button button-primary'
            onClick={(e) => handleButtonClick(e)}>
            {button_text}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
