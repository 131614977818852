import locales from '../config/locales';
import _find from 'lodash.find';

const HOST_LOCAL = 'localhost';
const HOST_LOCAL_DEVELOPMENT = 'local.hireup.com.au';
const HOST_STAGING = 'dev.hireup.com.au';
const HOST_PRODUCTION = 'https://hireup.com.au/';

const Utilities = {
  getScrollPercent: () => {
    let h = document.documentElement,
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  },

  scrollToAndFocusOn: (event, id, offset = 104) => {
    if (event) {
      event.preventDefault();
    }
    const element = document.getElementById(id);
    if (element) {
      element.focus();
      var elementTop = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo(0, elementTop - offset);
    }
  },

  isPrismicContentPopulated: (obj) => {
    return obj && obj.length && obj[0].text;
  },

  isObjectEmpty: (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  },

  flatten: (object, prefix = '', join = '__') => {
    return Object.keys(object).reduce((prev, element) => {
      return object[element] &&
        typeof object[element] == 'object' &&
        !Array.isArray(element)
        ? {
            ...prev,
            ...Utilities.flatten(object[element], `${prefix}${element}${join}`),
          }
        : { ...prev, ...{ [`${prefix}${element}`]: object[element] } };
    }, {});
  },

  getCurrentLocale: () => {
    const pathNameArray = window.location.pathname.split('/');
    const currentLocale =
      _find(locales, { code: pathNameArray[1] }) ||
      _find(locales, { default: true });
    return currentLocale;
  },

  buildImgixURL: (url, additionalParams) => {
    if (!additionalParams) {
      return url;
    }

    const baseUrl = url.split('?')[0];
    let originalParams = Object.fromEntries(
      new URLSearchParams(url.split('?')[1])
    );

    // Remove Prismic's auto tag, we'll set the compression ourselves
    delete originalParams.auto;

    // Merge the original params with our additional parameters, overriding any double ups
    const params = {
      ...originalParams,
      ...additionalParams,
    };

    // Rebuild the Query
    const query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + '=' + params[k])
      .join('&');

    return `${baseUrl}?${query}`;
  },

  toSentenceCase: (string) => {
    if (!string || typeof string !== 'string') {
      return null;
    }

    let newString =
      string.charAt(0).toUpperCase() +
      string
        .substr(1)
        .split(/(?=[A-Z][a-z])/)
        .join(' ')
        .toLowerCase()
        .replace(/-/g, ' ')
        .replace('hireup', 'Hireup');

    return Utilities.capitaliseAcronymns(newString);
  },

  capitaliseAcronymns: (string) => {
    if (!string || typeof string !== 'string') {
      return null;
    }

    string = string.replace(/ndis/gi, 'NDIS');
    string = string.replace(/adhd/gi, 'ADHD');
    return string;
  },

  removeWord: (string, word) => {
    if (!string || typeof string !== 'string') {
      return null;
    }

    let regExp = new RegExp(word, 'gi');

    string = string.replace(regExp, '');
    string = string.replace(/\s+/g, ' ');
    return string;
  },

  toKebabCase: (string) => {
    return string
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase();
  },

  isProduction: () => window.location.href.includes(HOST_PRODUCTION),
  isStaging: () => window.location.href.includes(HOST_STAGING),
  isLocal: () => window.location.href.includes(HOST_LOCAL),
  isLocalDevelopment: () =>
    window.location.href.includes(HOST_LOCAL_DEVELOPMENT),

  hello: () => {
    // eslint-disable-next-line no-console
    console.log(`
............................................
....................................######..
..............................############..
........................%#################..
.................####...##################..
...........##########...##################..
.....################...##################..
..###################...##################..
..###################...##################..
..###################...##################..
..###################...##################..
...##################...##################..
....#################...#################...
.....################...################....
.......##############...##############......
.........############...############........
.............%######%...%######%............
............................................

Want to work with us?
https://hireup.com.au/careers/

`);
  },
};

export default Utilities;
