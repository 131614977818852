import React, { Component } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { RichText as PrismicRichText, Link } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Image from '../image/image';
import Analytics from '../../config/analytics';
import './icon.scss';

export default class Icon extends Component {
  layoutClasses() {
    let classes = {
      'icon-single': true,
    };
    return classNames(classes);
  }

  handleClick = (event) => {
    const path = window.location.pathname;

    Analytics.link(
      { label: event.currentTarget.href },
      `www.${path}.Icon.link`
    );
  };

  renderLink(primary) {
    // TODO: Refactor SiteLink to allow for component children, not just text
    // Then, use the <SiteLink /> componnet here to avoid this duplicatoin

    if (!primary.link || !primary.link.link_type) {
      return null;
    }

    switch (primary.link.link_type) {
      case 'Document':
        return (
          <NavLink
            to={Link.url(primary.link, PrismicConfig.linkResolver)}
            onClick={this.handleClick}
            className={this.layoutClasses()}>
            {this.renderIcon(primary)}
          </NavLink>
        );

      case 'Media':
        return (
          <a
            href={Link.url(primary.link, PrismicConfig.linkResolver)}
            target='_blank'
            rel='noopener noreferrer'
            download
            onClick={this.handleClick}
            className={this.layoutClasses()}>
            {this.renderIcon(primary)}
          </a>
        );

      case 'Web':
        return (
          <a
            href={Link.url(primary.link, PrismicConfig.linkResolver)}
            className={this.layoutClasses()}
            target={primary.link.target}
            rel='noopener noreferrer'
            onClick={this.handleClick}>
            {this.renderIcon(primary)}
          </a>
        );

      case 'Any':
        return (
          <span className={this.layoutClasses()}>
            {this.renderIcon(primary)}
          </span>
        );

      default:
        console.warn('Unknown Link Type', primary);
        return null;
    }
  }

  renderIcon(primary) {
    const { size } = this.props;
    let imageSize;
    switch (size) {
      case 'large':
        imageSize = 128;
        break;
      case 'medium':
        imageSize = 64;
        break;
      default:
        imageSize = 32;
        break;
    }
    return (
      <>
        {primary.icon && primary.icon.url && (
          <Image
            primary={primary.icon}
            width={imageSize}
            height={imageSize}
            lazyload={true}
          />
        )}
        {primary.content &&
          PrismicRichText.render(
            primary.content,
            PrismicConfig.linkResolver,
            PrismicConfig.htmlSerializer
          )}
      </>
    );
  }

  render() {
    const { primary } = this.props;

    if (!primary) {
      return null;
    }

    return this.renderLink(primary);
  }
}
