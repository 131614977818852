import React from 'react';
import Analytics from '../../config/analytics';
import './table-of-contents.scss';

const TableOfContents = ({ items }) => {
  if (!items) {
    return null;
  }

  const handleClick = (event) => {
    const path = window.location.pathname;

    Analytics.link(
      { label: event.target.href },
      `www.${path}.tableOfContents.link`
    );
  };

  const renderTableOfContents = () => {
    return items.map((item) => (
      <li className='list-item' key={item.anchor_id}>
        <a onClick={handleClick} href={`#${item.anchor_id}`}>
          {item.anchor_text}
        </a>
      </li>
    ));
  };

  return (
    <div className='table-of-contents'>
      <p className='title'>Jump to section:</p>
      <ul className='list'>{renderTableOfContents()}</ul>
    </div>
  );
};

export default TableOfContents;
