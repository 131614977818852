import * as React from 'react';

const IconHairDryer = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='-0.75 -0.75 25.5 25.5'
        fill='none'
        stroke='currentColor'
        strokeWidth='1.5'
        xmlns='http://www.w3.org/2000/svg'>
        <g strokeLinecap='round' strokeLinejoin='round'>
          <path d='M.75 10.13a1.5 1.5 0 0 0 1.08 1.44c2.7.77 9.88 2.68 14.67 2.68a6.75 6.75 0 0 0 0-13.5c-4.79 0-12 1.91-14.67 2.68A1.5 1.5 0 0 0 .75 4.87ZM17.25.79v13.42' />
          <path d='M16.5 14.25a26.68 26.68 0 0 1-2.73-.16l-.65 5.83a3 3 0 0 0 3 3.33h1.15a3 3 0 0 0 3-3v-7.14a6.69 6.69 0 0 1-3.77 1.14ZM5.75 2.4v10.2' />
        </g>
      </svg>
    </span>
  );
};

export default IconHairDryer;
