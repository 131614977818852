import React, { useEffect, useState } from 'react';
import PageWrapper from '../pages/wrapper';
import ProfileCardsLayout from '../layouts/profile-cards/profile-cards';
import Loading from '../components/loading/loading';
import { withPrismic } from '../prismic/prismic';
import client from './data/client';
import TwoColumnLayout from '../layouts/two-column/two-column';
import SupportCards from '../layouts/support-cards/support-cards';
import Error400 from '../pages/error-400';
import headerImageFallback from '../assets/img/hireup-support-workers-and-ndis-participants.png';
import Subpage from '../pages/subpage';
import constants from '../config/constants';
import { getSupportWorkerData } from '../hireup/supportWorkerSearch';
import './location-shared.scss';

const LocationPage = ({ prismic, prismicContext, uid, error, content }) => {
  const [loading, setLoading] = useState(null);
  const [publicSearchResponse, setPublicSearchResponse] = useState(null);
  const [publicSearchProfiles, setPublicSearchProfiles] = useState(null);

  useEffect(() => {
    if (prismicContext && !content) {
      prismic.getLocation(uid);
    }
  }, [content, prismic, prismicContext, uid]);

  useEffect(() => {
    const getLocationSearchResults = async () => {
      setLoading(true);
      const locationRadius = 30000;
      const { location_location } = content?.data;

      const { publicSearchResponse, publicSearchProfiles } =
        await getSupportWorkerData({
          location: location_location,
          locationRadius,
        });

      setPublicSearchResponse(publicSearchResponse);
      setPublicSearchProfiles(publicSearchProfiles);
      setLoading(false);
    };

    if (content && !publicSearchResponse) {
      getLocationSearchResults();
    }
  }, [content, publicSearchResponse]);

  if (error) {
    return <Error400 path={`location/${uid}`} />;
  }

  if (!publicSearchResponse) {
    return <Loading full={true} testId='location-client' />;
  }

  const suburb = content?.data?.title[0]?.text;
  const state = content?.data?.location_state;
  const numberOfWorkers = Intl.NumberFormat().format(
    publicSearchResponse.totalResultCount
  );

  const meta = {
    title: `${numberOfWorkers} Disability Support Workers in ${suburb}, ${state}`,
    description: `Find disability support workers in ${suburb}, ${state} who meet your needs. Choose from ${numberOfWorkers} NDIS certified support workers available near you on Hireup.`,
  };

  const title = `${numberOfWorkers} disability support workers and carers in ${suburb}, ${state}`;
  const intro = `Hireup is an NDIS registered online platform connecting people in ${suburb}, ${state} with disability support workers that meet your needs and fit your interests.`;

  const { image } = content.data;
  const locationImage = image.url
    ? `${image.url}?h=650&q=75`
    : `${headerImageFallback}`;

  return (
    <PageWrapper
      title={meta.title}
      description={meta.description}
      context={`page -location -client -${content.uid}`}
      content={content}
      footer_content={'Hidden'}
      full={true}>
      <div className='location-header -with-background '>
        <div className='container'>
          <div className='header-grid'>
            <div className='header-content'>
              {title && <h1 className='heading'>{title}</h1>}
              {intro && <p>{intro}</p>}
              <div className='header-cta'>
                <a href={`${constants.registrationClient}`} className='button'>
                  Get started
                </a>
                <a href={`/how-hireup-works`} className='button -secondary'>
                  How Hireup works
                </a>
              </div>
              <p className='fineprint'>
                Or{' '}
                <a href={`/${constants.landing_worker}`}>
                  become a support worker
                </a>
              </p>
            </div>
            <div className='header-image-wrapper'>
              <img className='header-image' src={locationImage} alt={title} />
            </div>
          </div>
        </div>
      </div>

      {!loading && publicSearchProfiles && (
        <ProfileCardsLayout
          workerProfiles={publicSearchProfiles}
          location={suburb}
        />
      )}

      <div className='team-up'>
        <TwoColumnLayout primary={client.twoColumnLayout} />
        <SupportCards />
      </div>

      {/* Add in the default landing page layout */}
      <Subpage uid={constants.landing_uid} />
    </PageWrapper>
  );
};

export default withPrismic(LocationPage);
