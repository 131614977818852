import React, { Component } from 'react';
import PageWrapper from './wrapper';
import image from '../assets/img/error-404-500.png';
import './error.scss';

class Error400 extends Component {
  componentDidMount() {
    console.warn('<Error400 />', this.props.path);
  }

  render() {
    const content = {
      data: {
        title: [{ text: 'Page Not Found' }],
        description: [
          {
            text: 'We couldn’t find the page you were looking for. The URL may have been mis-typed or address of the page may have moved.',
          },
        ],
      },
    };

    return (
      <PageWrapper
        context='error'
        content={content}
        footer_content={'Hidden'}
        footer_cta={'Hidden'}>
        <div className='container'>
          <div className='content'>
            <h1>Page not found!</h1>
            <p>
              We couldn’t find the page you were searching for. We’re sorry
              about this. The URL may have been mistyped or address of the page
              may have moved.
            </p>
            <p>
              You can find answers to common questions about Hireup at the{' '}
              <a href='https://help.hireup.com.au'>Hireup Help Centre</a> or
              head back to the <a href='/'>Hireup Homepage</a>.
            </p>

            <div className='actions'>
              <a href='https://help.hireup.com.au' className='button '>
                Help Centre
              </a>
              <a href='/' className='button -primary'>
                Homepage
              </a>
            </div>
          </div>
          <div className='error-image'>
            <img src={image} alt='' />
          </div>
        </div>
      </PageWrapper>
    );
  }
}

export default Error400;
