const primarySupport = require('./primarySupport');

const bathing = {
  supportName: 'Bathing',
  supportIcon: 'bath',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = bathing;
