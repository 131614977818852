import React, { Component } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import active_marker from '../../assets/img/marker.active.svg';
import inactive_marker from '../../assets/img/marker.default.svg';
import './map.scss';

class MapWrapper extends Component {
  handleMarkerClick(value) {
    if (typeof this.props.handleMarkerClick === 'function') {
      this.props.handleMarkerClick(value);
    }
  }

  renderMarkers() {
    const { markers } = this.props;
    let output = [];
    markers &&
      markers.map((marker) => {
        return output.push(
          <Marker
            position={{ lat: marker.latitude, lng: marker.longitude }}
            key={marker.id}
            onClick={() => this.handleMarkerClick(marker.id)}
            icon={{
              scaledSize: {
                width: 20,
                height: 32,
              },
              url: marker.active ? active_marker : inactive_marker,
            }}>
            {marker.active && (
              <InfoBox
                options={{
                  closeBoxURL: ``,
                  enableEventPropagation: true,
                  pixelOffset: {
                    width: -60,
                    height: 0,
                  },
                }}>
                <div className='infobox-content'>
                  <p>{marker.content.title[0].text}</p>
                  {marker.uid && (
                    <a
                      href={`/${marker.type}/${marker.uid}`}
                      className='button -tiny'>
                      View
                    </a>
                  )}
                </div>
              </InfoBox>
            )}
          </Marker>
        );
      });
    return output;
  }

  render() {
    return (
      <GoogleMap
        className='google-map'
        defaultZoom={this.props.defaultZoom}
        defaultCenter={this.props.defaultCenter}
        defaultOptions={{
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          scaleControl: false,
        }}>
        {this.props.showCenter && (
          <Marker position={this.props.defaultCenter} />
        )}
        {this.renderMarkers()}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(MapWrapper));
