const primarySupport = require('./primarySupport');

const improvedLearning = {
  supportName: 'Learning improvement',
  supportIcon: 'computerBook',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'education-and-employment',
};

module.exports = improvedLearning;
