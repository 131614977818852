import React, { Component } from 'react';
import classNames from 'classnames';
import { timestamp } from '../../helpers/eventHelpers';
import { withPrismic } from '../../prismic/prismic';
import { withHireup } from '../../hireup/hireup';
import { Formik, Form } from 'formik';
import Field from '../../components/field/field';
import Loading from '../loading/loading';
import Analytics from '../../config/analytics';
import constants from '../../config/constants';
import Utilities from '../../config/utilities';
import qs from 'qs';
import _get from 'lodash.get';
import { ReactComponent as Warning } from '../../assets/svg/icon-triangle-warning.svg';

import './login-form.scss';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        email: '',
        password: '',
      },
      redirect: false,
      error: null,
      iframe: null,
      params: qs.parse(window.location.search.replace('?', '')),
    };
  }

  componentDidUpdate(props) {
    let user = _get(props, 'user');
    // Redirect logged in users to the application
    if (!this.state.redirect && _get(user, 'auth.id')) {
      this.identify(user);
      this.redirect();
    }
  }

  wrapperClasses = () => {
    let classes = {
      'login-form': true,
      '-redirecting': this.state.redirect,
    };
    return classNames(classes);
  };

  handleChange = (key, event) => {
    this.setState({ [key]: event.target.value });
  };

  handleSuccess = (user) => {
    if (user) {
      this.props.hireup.setUser(user);

      const properties = {
        id: _get(user, 'acnt.id'),
        type: constants.user.typeById[_get(user, 'acnt.type')],
        label: constants.user.typeById[_get(user, 'acnt.type')],
        status: _get(user, 'acnt.status'),
        accountManaged: !!_get(user, 'acnt.accountManager'),
        schemaVersion: 2,
        originTimestamp: timestamp(),
      };

      Analytics.track({ event: 'Logged In - FE', properties });
      Analytics.track({ event: 'user_loggedIn', properties });
    }
  };

  handleReject = (message, serverError) => {
    if (serverError) {
      console.warn('Login Error', message, serverError);
      // Track unsuccessful logins by attempted email\

      const properties = {
        error:
          serverError.errorMessage || serverError.message || 'Unknown Error',
        schemaVersion: 2,
      };

      Analytics.track({ event: 'Login Error - FE', properties });
      Analytics.track({ event: 'error_viewed', properties });
    }
    this.setState({ error: message || 'We could not log you in to Hireup' });
  };

  handleBack = () => {
    this.setState({ error: null });
  };

  identify = (user) => {
    const id = _get(user, 'acnt.id');
    const traits = {
      email: _get(user, 'auth.email'),
      firstName: _get(user, 'auth.firstName'),
      lastName: _get(user, 'auth.lastName'),
      name: _get(user, 'acnt.displayName'),
      type: _get(user, 'acnt.type'),
      status: _get(user, 'acnt.status'),
      birthday: _get(user, 'acnt.dob'),
      address: {
        state: _get(user, 'acnt.state'),
        postalCode: _get(user, 'acnt.postcode'),
        city: _get(user, 'acnt.suburb'),
        country: 'AU',
      },
      profilePicUrl: _get(user, 'acnt.profilePicUrl'),
    };

    // Identify the user
    Analytics.identify({ id, traits });
  };

  // Authenticate on the Zendesk API, setting the help centre cookie
  help_centre_auth = () => {
    this.setState({ redirect: true });
    this.setState({ iframe: `https://app.hireup.com.au` });

    setTimeout(() => {
      this.redirect();
    }, 3000);
  };

  redirect = () => {
    // Set the default redirect URL to the app dashboard.
    let url = constants.hireup_web;

    this.setState({ redirect: true });

    // Get the redirect URL or Help Centre flags from the URL
    let { redirectUrl, helpCentre } = this.state.params;
    // Override these values from the component props, if present
    if (this.props.redirectUrl) {
      redirectUrl = this.props.redirectUrl;
    }
    if (this.props.helpCentre) {
      helpCentre = this.props.helpCentre;
    }

    // Parse the redirect URL
    if (redirectUrl) {
      if (helpCentre === 'true') {
        url = `https://help.hireup.com.au/hc/en-us/signin?return_to=${encodeURI(
          redirectUrl
        )}`;
      } else {
        url = `${
          redirectUrl.includes('http') ? '' : constants.hireup_web
        }${redirectUrl}`;
      }
    }

    // Don't redirect if the component prop overrides the redirect
    const { disableRedirect } = this.props;
    if (!disableRedirect) {
      window.location = url;
    }
  };

  handleSubmit = async (values) => {
    const { email, password } = values;
    try {
      // TODO: Scroll to the top of the slice, not the screen
      window.scrollTo(0, 0);

      this.props.hireup
        .login({ email, password })
        .then((response) => {
          if (_get(response, 'data.success') === true) {
            this.handleSuccess(_get(response, 'data.user'));
            this.identify(_get(response, 'data.user'));
            if (this.state.params.helpCentre === 'true') {
              this.help_centre_auth();
            } else {
              this.redirect();
            }
          } else {
            this.handleReject('Login unsuccessful', response);
          }
        })
        .catch((error) => {
          this.handleReject(_get(error.response, 'data.errorMessage'), error);
        });
    } catch (e) {
      console.warn('Error submitting form', e);
    }
  };

  render() {
    const { primary } = this.props;
    const { initialValues, error, iframe } = this.state;

    return (
      <div className={this.wrapperClasses(primary)}>
        <h1 id='login-title'>Login to Hireup</h1>

        <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
          {({ errors, isSubmitting, submitCount }) => (
            <Form noValidate role='form' aria-labelledby='login-title'>
              <div className='form-content'>
                <Field
                  name='email'
                  type='email'
                  label='Email'
                  required={true}
                  hideRequiredIndicator={true}
                />
                <Field
                  name='password'
                  type='password'
                  label='Password'
                  required={true}
                  hideRequiredIndicator={true}
                />
              </div>
              <p>
                <a href='https://app.hireup.com.au/reset'>Forgot password</a>
              </p>
              <div className='form-actions'>
                <button type='submit' className='button -primary'>
                  {!isSubmitting && <span>Log in</span>}
                  {isSubmitting && <div className='spinner-indicator'></div>}
                </button>

                {submitCount > 0 && !Utilities.isObjectEmpty(errors) && (
                  <p className='form-error' role='alert'>
                    <Warning /> There are errors in the fields above
                  </p>
                )}

                {error && (
                  <p className='form-error' role='alert'>
                    <Warning /> {error}
                  </p>
                )}
              </div>

              <p className='form-signup'>
                New to Hireup?&nbsp;
                <a href={constants.registration}>Sign up for an account</a>
              </p>
            </Form>
          )}
        </Formik>

        <div className='redirecting'>
          <Loading align='left' />
          <span className='screen-reader-only'>Logging in...</span>
          {iframe && (
            <iframe
              width='1'
              height='1'
              src={iframe}
              className='auth-iframe'
              title='Authenticate'
            />
          )}
        </div>
      </div>
    );
  }
}

export default withPrismic(withHireup(LoginForm));
