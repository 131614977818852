import React from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Image from '../../components/image/image';
import _kebabCase from 'lodash.kebabcase';
import './testimonial.scss';

export default function Testimonial({ primary }) {
  if (!primary.content || !primary.author_name) {
    return null;
  }

  const layoutClasses = () => {
    let classes = {
      'layout-testimonial': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-padding-${_kebabCase(primary.padding)}`]: primary.padding,
      [`-layout-${_kebabCase(primary.layout)}`]: primary.layout,
    };
    return classNames(classes);
  };

  return (
    <section className={layoutClasses(primary)}>
      <div className='container'>
        <div className='testimonial'>
          <blockquote className='content -one'>
            <div className='quotation-mark'>‘‘</div>
            <div className='testimonial-body'>
              {primary.content &&
                PrismicRichText.render(
                  primary.content,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
            </div>
            <div className='author-name'>{primary.author_name}</div>
            {primary.author_title && (
              <div className='author-title'>{primary.author_title}</div>
            )}
          </blockquote>
          {primary.author_image?.url && (
            <div className='content -two'>
              <Image primary={primary.author_image} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
