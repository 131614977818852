import React from 'react';
import { RichText as PrismicRichText, Link } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Card from '../../components/card/card';
import './entry-points.scss';

const EntryPoints = ({ primary, items }) => {
  const { heading } = primary;

  // prevent content authors from using slice
  // with only one item
  if (!items || items.length === 1) {
    return null;
  }

  const renderItems = () => {
    return items.slice(0, 6).map((item) => (
      <Card borderRadius={100} borderWidth={2} key={item.link_text}>
        <a
          href={Link.url(item.link_url, PrismicConfig.linkResolver)}
          className='link-text'>
          {item.link_text}
        </a>
      </Card>
    ));
  };

  return (
    <section className='layout-entry-points'>
      <div className='container'>
        <div className='content'>
          {heading?.[0].text && (
            <>
              {PrismicRichText.render(
                heading,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </>
          )}
          <ul className='grid-6'>{renderItems()}</ul>
        </div>
      </div>
    </section>
  );
};

export default EntryPoints;
