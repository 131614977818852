import React, { Component } from 'react';
import Field from './field';
import classNames from 'classnames';
import { ReactComponent as Warning } from '../../assets/svg/icon-triangle-warning.svg';

import './fieldset.scss';

export default class Fieldset extends Component {
  fieldGroupClasses = (hasError) => {
    const { type, layout, options, required, helpText } = this.props;
    return classNames({
      'form-fieldset': true,
      [`-${type}`]: true,
      [`-layout-${layout}`]: layout,
      [`-count-${Object.keys(options).length}`]: Object.keys(options).length,
      '-invalid': hasError,
      '-required': required,
      '-with-help': helpText,
    });
  };

  render() {
    const {
      name,
      type,
      label,
      helpText,
      options,
      required,
      errors,
      touched,
      minimum,
      maximum,
      hideRequiredIndicator,
    } = this.props;
    const hasError = errors && touched[name] && errors[name];
    const firstOption = options[0];
    const lastOption = options[Object.keys(options).length - 1];
    const hasRangeDescription = minimum && maximum && firstOption && lastOption;
    const helpTextId = React.isValidElement(helpText) ? `${name}-help` : null;

    return (
      <fieldset className={this.fieldGroupClasses(hasError)}>
        <legend>
          {hasError && (
            <span className='screen-reader-only'>
              Error: This field is required.{' '}
            </span>
          )}
          {label}
          {hasRangeDescription && (
            <span className='screen-reader-only'>
              {`From ${firstOption.label}, ${minimum} to ${lastOption.label}, ${maximum}`}
            </span>
          )}
          {required && !hideRequiredIndicator && (
            <span className='field-required' aria-hidden={true}>
              &#65121;
            </span>
          )}
        </legend>
        <div className='form-fieldset-options-wrapper'>
          <div className={`form-fieldset-options`}>
            {hasRangeDescription && (
              <div className='form-fieldset-boundaries' aria-hidden={true}>
                <span className='minimum'>
                  {firstOption.label} - {minimum}
                </span>
                <span className='maximum'>
                  {lastOption.label} - {maximum}
                </span>
              </div>
            )}
            <div className='form-fieldset-fields'>
              {options.map((option, index) => (
                <Field
                  key={option.value}
                  required={required}
                  name={type === 'radio' ? name : `${name}.${option.value}`}
                  id={
                    type === 'radio'
                      ? `${name}-${index}`
                      : `${name}-${option.value}`
                  }
                  value={type === 'radio' ? option.value : null}
                  type={type}
                  label={option.label}
                  parentHelpId={helpTextId}
                  grouped={true}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='form-field-aside'>
          {hasError && (
            <p className='form-field-error' aria-hidden={true}>
              <Warning /> This field is required
            </p>
          )}
          {helpTextId && (
            <div className='form-field-help' id={helpTextId}>
              {helpText}
            </div>
          )}
        </div>
      </fieldset>
    );
  }
}
