import React from 'react';
import constants from '../../config/constants';
import { IconCalendar, IconClock, IconRefresh } from '../../components/icons';
import Analytics from '../../config/analytics';
import './platform-job-card.scss';

export const PlatformJobCard = ({
  title,
  location,
  time,
  day,
  frequency,
  salary,
}) => {
  const [dollars, cents] = salary.split('.');

  const handleClick = (event) => {
    const path = window.location.pathname;

    Analytics.link(
      { label: event.target.href },
      `www.${path}.jobCards.job`,
      true
    );
  };

  return (
    <li className='job-card-item'>
      <div className='title-salary-wrapper'>
        <a
          className='title'
          href={constants.registration}
          onClick={handleClick}>
          <h3>{title}</h3>
        </a>
        <p className='location -mobile'>{location}</p>
        <p className='salary-amount'>
          <span className='visually-hidden'>${salary} per shift</span>
          <span data-testid='salary' className='amount' aria-hidden={true}>
            <small>$</small>
            <strong>{dollars}</strong>
            <small>.{cents}</small>
            <span className='subtext'>per shift</span>
          </span>
        </p>
      </div>
      <p className='location -desktop'>{location}</p>

      <div className='job-labels'>
        <div className='label'>
          <IconClock height='18' width='18' />
          {time}
        </div>
        <div className='label'>
          <IconCalendar height='16' width='16' />
          {day}
        </div>
        <div className='label'>
          <IconRefresh height='16' width='16' />
          {frequency}
        </div>
      </div>
    </li>
  );
};
