import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './event-tabs.scss';

export default class EventTabs extends Component {
  render() {
    return (
      <Tabs defaultIndex={0}>
        <TabList>
          {this.props.content.map((data, index) => {
            return <Tab key={'tab_' + index}>{data.label}</Tab>;
          })}
        </TabList>
        {this.props.content.map((data, i) => {
          return <TabPanel key={'panel_' + i}>{data.content}</TabPanel>;
        })}
      </Tabs>
    );
  }
}
