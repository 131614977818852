import React, { Component } from 'react';
import classNames from 'classnames';
import _get from 'lodash.get';
import { PrismicConfig } from '../../prismic/config';
import { withPrismic } from '../../prismic/prismic';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import './rate-table.scss';

class RateTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rates: null,
    };
  }

  componentDidMount() {
    this.loadRates();
  }

  async loadRates() {
    await this.props.prismic.getRates();
    this.setState({ rates: this.props.rates });
  }

  layoutClasses() {
    let classes = {
      'rate-table-single': true,
    };
    return classNames(classes);
  }

  formatCurrency(amount) {
    const dollars = Math.floor(amount);
    const cents = (amount % 1).toFixed(2).substring(2);
    return { dollars, cents };
  }

  formatAmountToLocaleString(amount) {
    return amount.toLocaleString('en-AU', {
      style: 'currency',
      currency: 'AUD',
    });
  }

  renderWorkerTableRow(priceType, table) {
    let output = [];
    const rates = this.state.rates;

    // This is named to match WorkerTotal and WorkerGross in Prismic
    const superDetails = (grossAmount) => {
      return `excl. super $${grossAmount}`;
    };

    const rateData = {
      worker: {
        total: {
          day: {
            title: 'Weekdays daytime',
            description: rates.wg_day_desc,
            help: rates.wg_day_help,
            price: {
              before: null,
              amount: rates.wt_day_amount,
              description: superDetails(rates.wg_day_amount),
            },
          },
          evening: {
            title: 'Weekday evenings',
            description: rates.wg_evening_desc,
            help: rates.wg_evening_help,
            price: {
              before: null,
              amount: rates.wt_evening_amount,
              description: superDetails(rates.wg_evening_amount),
            },
          },
          night: {
            title: 'Weekday nights',
            description: rates.wg_night_desc,
            help: rates.wg_night_help,
            price: {
              before: null,
              amount: rates.wt_night_amount,
              description: superDetails(rates.wg_night_amount),
            },
          },
          saturday: {
            title: 'Saturdays',
            description: rates.wg_sat_desc,
            help: rates.wg_sat_help,
            price: {
              before: null,
              amount: rates.wt_sat_amount,
              description: superDetails(rates.wg_sat_amount),
            },
          },
          sunday: {
            title: 'Sundays',
            description: rates.wg_sun_desc,
            help: rates.wg_sun_help,
            price: {
              before: null,
              amount: rates.wt_sun_amount,
              description: superDetails(rates.wg_sun_amount),
            },
          },
          publicHoliday: {
            title: 'Public holidays',
            description: rates.wg_public_holiday_desc,
            help: rates.wg_public_holiday_help,
            price: {
              before: null,
              amount: rates.wt_public_holiday_amount,
              description: superDetails(rates.wg_public_holiday_amount),
            },
          },
          sleepoverWeekday: {
            title: 'Weekday sleepover',
            description: rates.wg_sleepover_wkday_desc,
            help: rates.wg_sleepover_wkday_help,
            price: {
              before: null,
              amount: rates.wt_sleepover_wkday_amount,
              description: superDetails(rates.wg_sleepover_wkday_amount),
            },
          },
          sleepoverSaturday: {
            title: 'Saturday sleepover',
            description: rates.wg_sleepover_sat_desc,
            help: rates.wg_sleepover_sat_help,
            price: {
              before: null,
              amount: rates.wt_sleepover_sat_amount,
              description: superDetails(rates.wg_sleepover_sat_amount),
            },
          },
          sleepoverSunday: {
            title: 'Sunday sleepover',
            description: rates.wg_sleepover_sun_desc,
            help: rates.wg_sleepover_sun_help,
            price: {
              before: null,
              amount: rates.wt_sleepover_sun_amount,
              description: superDetails(rates.wg_sleepover_sun_amount),
            },
          },
          sleepoverPublicHoliday: {
            title: 'Public holiday sleepover',
            description: rates.wg_sleepover_public_holiday_desc,
            help: rates.wg_sleepover_public_holiday_help,
            price: {
              before: null,
              amount: rates.wt_sleepover_public_holiday_amount,
              description: superDetails(
                rates.wg_sleepover_public_holiday_amount
              ),
            },
          },
          under2HoursDay: {
            title: 'Weekdays daytime',
            description: rates.wg_day_desc,
            help: null,
            price: {
              before: null,
              amount: rates.wt_under2hours_day_amount,
              description: superDetails(rates.wg_under2hours_day_amount),
            },
          },
          under2HoursEvening: {
            title: 'Weekday evenings',
            description: rates.wg_evening_desc,
            help: null,
            price: {
              before: null,
              amount: rates.wt_under2hours_evening_amount,
              description: superDetails(rates.wg_under2hours_evening_amount),
            },
          },
          under2HoursNight: {
            title: 'Weekday nights',
            description: rates.wg_night_desc,
            help: null,
            price: {
              before: null,
              amount: rates.wt_under2hours_night_amount,
              description: superDetails(rates.wg_under2hours_night_amount),
            },
          },
          under2HoursSaturday: {
            title: 'Saturdays',
            description: rates.wg_sat_desc,
            help: null,
            price: {
              before: null,
              amount: rates.wt_under2hours_sat_amount,
              description: superDetails(rates.wg_under2hours_sat_amount),
            },
          },
          under2HoursSunday: {
            title: 'Sundays',
            description: rates.wg_sun_desc,
            help: null,
            price: {
              before: null,
              amount: rates.wt_under2hours_sun_amount,
              description: superDetails(rates.wg_under2hours_sun_amount),
            },
          },
          under2HoursPublicHoliday: {
            title: 'Public holidays',
            description: rates.wg_public_holiday_desc,
            help: null,
            price: {
              before: null,
              amount: rates.wt_under2hours_public_holiday_amount,
              description: superDetails(
                rates.wg_under2hours_public_holiday_amount
              ),
            },
          },
        },
      },
    };

    table.rows &&
      table.rows.forEach((row, index) => {
        const rate = _get(rateData, `[worker][${priceType}][${row}]`);

        if (rate && rate.price.amount) {
          const price = this.formatAmountToLocaleString(rate.price.amount);
          const { dollars, cents } = this.formatCurrency(rate.price.amount);
          output.push(
            <>
              <tr
                key={index}
                className={rate.help ? '-with-helper-text' : null}>
                <td>
                  <h4 className='rate-table-row-heading'>{rate.title}</h4>
                  {rate.description && (
                    <p className='rate-table-row-description'>
                      {rate.description}
                    </p>
                  )}
                </td>
                <td className='rate-table-price'>
                  {priceType === 'savings' && (
                    <p className='rate-table-price-save'>Save</p>
                  )}
                  {rate.price.before && (
                    <p className='rate-table-price-before'>
                      {rate.price.before}
                    </p>
                  )}
                  <p className='rate-table-price-amount'>
                    <span className='visually-hidden'>{price}</span>
                    <span aria-hidden={true}>
                      <small>$</small>
                      <strong>{dollars}</strong>
                      <small>.{cents}</small>
                    </span>
                  </p>
                  {rate.price.description && (
                    <p className='rate-table-price-description'>
                      {rate.price.description}
                    </p>
                  )}
                </td>
              </tr>
              {rate.help && (
                <tr className='rate-table-row-help'>
                  <td colspan='2'>
                    <p className='helper-text'>{rate.help}</p>
                  </td>
                </tr>
              )}
            </>
          );
        } else {
          console.warn('Could not find selected rate for worker', priceType);
        }
      });

    return output;
  }

  renderClientTableRow(priceType, table) {
    let output = [];
    const rates = this.state.rates;

    const rateData = {
      client: {
        total: {
          day: {
            title: 'Weekdays daytime',
            description: rates.ct_day_desc,
            help: rates.ct_day_help,
            price: {
              before: null,
              amount: rates.ct_day_amount,
              description: 'Hourly',
            },
            specialised_support_price: {
              before: null,
              amount: rates.ct_ss_day_amount,
              description: 'Hourly',
            },
          },
          evening: {
            title: 'Weekday evenings',
            description: rates.ct_evening_desc,
            help: rates.ct_evening_help,
            price: {
              before: null,
              amount: rates.ct_evening_amount,
              description: 'Hourly',
            },
            specialised_support_price: {
              before: null,
              amount: rates.ct_ss_evening_amount,
              description: 'Hourly',
            },
          },
          night: {
            title: 'Weekday nights',
            description: rates.ct_night_desc,
            help: rates.ct_night_help,
            price: {
              before: null,
              amount: rates.ct_night_amount,
              description: 'Hourly',
            },
            specialised_support_price: {
              before: null,
              amount: rates.ct_ss_night_amount,
              description: 'Hourly',
            },
          },
          saturday: {
            title: 'Saturdays',
            description: rates.ct_sat_desc,
            help: rates.ct_sat_help,
            price: {
              before: null,
              amount: rates.ct_sat_amount,
              description: 'Hourly',
            },
            specialised_support_price: {
              before: null,
              amount: rates.ct_ss_sat_amount,
              description: 'Hourly',
            },
          },
          sunday: {
            title: 'Sundays',
            description: rates.ct_sun_desc,
            help: rates.ct_sun_help,
            price: {
              before: null,
              amount: rates.ct_sun_amount,
              description: 'Hourly',
            },
            specialised_support_price: {
              before: null,
              amount: rates.ct_ss_sun_amount,
              description: 'Hourly',
            },
          },
          publicHoliday: {
            title: 'Public holidays',
            description: rates.ct_public_holiday_desc,
            help: rates.ct_public_holiday_help,
            price: {
              before: null,
              amount: rates.ct_public_holiday_amount,
              description: 'Hourly',
            },
            specialised_support_price: {
              before: null,
              amount: rates.ct_ss_public_holiday_amount,
              description: 'Hourly',
            },
          },
          sleepoverWeekday: {
            title: 'Weekday sleepover',
            description: rates.ct_sleepover_wkday_desc,
            help: rates.ct_sleepover_wkday_help,
            price: {
              before: 'Approx',
              amount: rates.ct_sleepover_wkday_amount,
              description: null,
            },
            specialised_support_price: {
              before: 'Approx',
              amount: rates.ct_ss_sleepover_wkday_amount,
              description: null,
            },
          },
          sleepoverSaturday: {
            title: 'Saturday sleepover',
            description: rates.ct_sleepover_sat_desc,
            help: rates.ct_sleepover_sat_help,
            price: {
              before: 'Approx',
              amount: rates.ct_sleepover_sat_amount,
              description: null,
            },
            specialised_support_price: {
              before: 'Approx',
              amount: rates.ct_ss_sleepover_sat_amount,
              description: null,
            },
          },
          sleepoverSunday: {
            title: 'Sunday sleepover',
            description: rates.ct_sleepover_sun_desc,
            help: rates.ct_sleepover_sun_help,
            price: {
              before: 'Approx',
              amount: rates.ct_sleepover_sun_amount,
              description: null,
            },
            specialised_support_price: {
              before: 'Approx',
              amount: rates.ct_ss_sleepover_sun_amount,
              description: null,
            },
          },
          sleepoverPublicHoliday: {
            title: 'Public holiday sleepover',
            description: rates.ct_sleepover_public_holiday_desc,
            help: rates.ct_sleepover_public_holiday_help,
            price: {
              before: 'Approx',
              amount: rates.ct_sleepover_public_holiday_amount,
              description: null,
            },
            specialised_support_price: {
              before: 'Approx',
              amount: rates.ct_ss_sleepover_public_holiday_amount,
              description: null,
            },
          },
        },
        savings: {
          day: {
            title: 'Weekdays',
            description: rates.cs_day_desc,
            help: rates.cs_day_help,
            price: {
              before: null,
              amount: rates.cs_day_amount,
              description: 'Hourly',
            },
          },
          evening: {
            title: 'Weekday evenings',
            description: rates.cs_evening_desc,
            help: rates.cs_evening_help,
            price: {
              before: null,
              amount: rates.cs_evening_amount,
              description: 'Hourly',
            },
          },
          night: {
            title: 'Weekday nights',
            description: rates.cs_night_desc,
            help: rates.cs_night_help,
            price: {
              before: null,
              amount: rates.cs_night_amount,
              description: 'Hourly',
            },
          },
          saturday: {
            title: 'Saturdays',
            description: rates.cs_sat_desc,
            help: rates.cs_sat_help,
            price: {
              before: null,
              amount: rates.cs_sat_amount,
              description: 'Hourly',
            },
          },
          sunday: {
            title: 'Sundays',
            description: rates.cs_sun_desc,
            help: rates.cs_sun_help,
            price: {
              before: null,
              amount: rates.cs_sun_amount,
              description: 'Hourly',
            },
          },
          publicHoliday: {
            title: 'Public holidays',
            description: rates.cs_public_holiday_desc,
            help: rates.cs_public_holiday_help,
            price: {
              before: null,
              amount: rates.cs_public_holiday_amount,
              description: 'Hourly',
            },
          },
          sleepoverWeekday: {
            title: 'Weekday sleepover',
            description: rates.cs_sleepover_wkday_desc,
            help: rates.cs_sleepover_wkday_help,
            price: {
              before: null,
              amount: rates.cs_sleepover_wkday_amount,
              description: 'Hourly',
            },
          },
          sleepoverSaturday: {
            title: 'Saturday sleepover',
            description: rates.cs_sleepover_sat_desc,
            help: rates.cs_sleepover_sat_help,
            price: {
              before: null,
              amount: rates.cs_sleepover_sat_amount,
              description: 'Hourly',
            },
          },
          sleepoverSunday: {
            title: 'Sunday sleepover',
            description: rates.cs_sleepover_sun_desc,
            help: rates.cs_sleepover_sun_help,
            price: {
              before: null,
              amount: rates.cs_sleepover_sun_amount,
              description: 'Hourly',
            },
          },
          sleepoverPublicHoliday: {
            title: 'Public holiday sleepover',
            description: rates.cs_sleepover_public_holiday_desc,
            help: rates.cs_sleepover_public_holiday_help,
            price: {
              before: null,
              amount: rates.cs_sleepover_public_holiday_amount,
              description: 'Hourly',
            },
          },
        },
      },
    };

    table.rows &&
      table.rows.forEach((row, index) => {
        const rate = _get(rateData, `[client][${priceType}][${row}]`);

        if (rate && rate.price.amount && rate.specialised_support_price) {
          const price = this.formatAmountToLocaleString(rate.price.amount);
          const specialised_support_price = this.formatAmountToLocaleString(
            rate.specialised_support_price.amount
          );

          const { dollars: p_dollars, cents: p_cents } = this.formatCurrency(
            rate.price.amount
          );
          const { dollars: ssp_dollars, cents: ssp_cents } =
            this.formatCurrency(rate.specialised_support_price.amount);

          output.push(
            <>
              <tr
                key={index}
                className={rate.help ? '-with-helper-text' : null}>
                <td className='rate-table-description'>
                  <h4 className='rate-table-row-heading'>{rate.title}</h4>
                  {rate.description && (
                    <p className='rate-table-row-description'>
                      {rate.description}
                    </p>
                  )}
                  {rate.help && (
                    <p className='helper-text -mobile'>{rate.help}</p>
                  )}
                </td>
                <td className='rate-table-price'>
                  <div className='-mobile price-type'>
                    <span className='rate-table-row-heading'>Standard</span>
                  </div>
                  <div className='price-value'>
                    {priceType === 'savings' && (
                      <p className='rate-table-price-save'>Save</p>
                    )}
                    {rate.price.before && (
                      <p className='rate-table-price-before'>
                        {rate.price.before}
                      </p>
                    )}
                    <p className='rate-table-price-amount'>
                      <span className='visually-hidden'>{price}</span>
                      <span aria-hidden={true}>
                        <small>$</small>
                        <strong>{p_dollars}</strong>
                        <small>.{p_cents}</small>
                      </span>
                    </p>
                    {rate.price.description && (
                      <p className='rate-table-price-description'>
                        {rate.price.description}
                      </p>
                    )}
                  </div>
                </td>
                <td className='rate-table-specialised-support-price'>
                  <div className='-mobile price-type'>
                    <span className='rate-table-row-heading '>
                      Specialised support
                    </span>
                  </div>
                  <div className='price-value'>
                    {rate.specialised_support_price.before && (
                      <p className='rate-table-specialised-support-price-before'>
                        {rate.specialised_support_price.before}
                      </p>
                    )}
                    <p className='rate-table-specialised-support-price-amount'>
                      <span className='visually-hidden'>
                        {specialised_support_price}
                      </span>
                      <span aria-hidden={true}>
                        <small>$</small>
                        <strong>{ssp_dollars}</strong>
                        <small>.{ssp_cents}</small>
                      </span>
                    </p>
                    {rate.specialised_support_price.description && (
                      <p className='rate-table-specialised-support-price-description'>
                        {rate.specialised_support_price.description}
                      </p>
                    )}
                  </div>
                </td>
              </tr>
              {rate.help && (
                <tr className='rate-table-row-help'>
                  <td colspan='3'>
                    <p className='helper-text'>{rate.help}</p>
                  </td>
                </tr>
              )}
            </>
          );
        } else {
          console.warn('Could not find selected rate for client', priceType);
        }
      });

    return output;
  }

  renderClientTable(table, priceType, sleepover_introduction) {
    const priceLabel = 'Standard';
    const specialisedSupportLabel = 'Specialised support';
    return (
      <>
        {table.label !== 'Sleepovers' && (
          <div className='column-title'>
            <div className='column-title-description'>Description</div>
            <div className='column-title-amount'>{priceLabel}</div>
            <div className='column-title-specialised-support-amount'>
              {specialisedSupportLabel}
            </div>
          </div>
        )}

        {table.label === 'Sleepovers' && sleepover_introduction && (
          <>
            <div className='rate-table-introduction'>
              {PrismicRichText.render(
                sleepover_introduction,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
            <div className='column-title'>
              <div className='column-title-description'>Description</div>
              <div className='column-title-amount'>{priceLabel}</div>
              <div className='column-title-specialised-support-amount'>
                {specialisedSupportLabel}
              </div>
            </div>
          </>
        )}

        {this.state.rates && (
          <table role='grid'>
            <thead>
              <tr>
                <td className='screen-reader-only'>Description</td>
                <td className='screen-reader-only'>{priceLabel}</td>
                <div className='screen-reader-only'>
                  {specialisedSupportLabel}
                </div>
              </tr>
            </thead>
            <col className='column1'></col>
            <col className='column2'></col>
            <col className='column3'></col>
            <tbody>{this.renderClientTableRow(priceType, table)}</tbody>
          </table>
        )}
      </>
    );
  }

  getPriceLabel(tableLabel) {
    if (
      tableLabel === 'Sleepovers' ||
      tableLabel === 'Pay for shifts under 2 hours'
    ) {
      return 'Pay';
    }
    return 'Hourly pay';
  }

  renderWorkerTable(table, priceType, sleepover_introduction) {
    return (
      <>
        {table.label === 'Sleepovers' && sleepover_introduction && (
          <div className='rate-table-introduction'>
            {PrismicRichText.render(
              sleepover_introduction,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
          </div>
        )}
        <div className='column-title'>
          <div className='column-title-description'>Description</div>
          <div className='column-title-amount'>
            {this.getPriceLabel(table.label)}
          </div>
        </div>

        {this.state.rates && (
          <table role='grid'>
            <thead>
              <tr>
                <td className='screen-reader-only'>Description</td>
                <td className='screen-reader-only'>
                  {this.getPriceLabel(table.label)}
                </td>
              </tr>
            </thead>
            <col className='column1'></col>
            <col className='column2'></col>
            <tbody>{this.renderWorkerTableRow(priceType, table)}</tbody>
          </table>
        )}
      </>
    );
  }

  render() {
    const { priceType, table, userType } = this.props;
    if (!table) {
      return null;
    }

    const sleepover_introduction = _get(
      this.props,
      'primary.sleepover_introduction'
    );

    return (
      <div className='rate-table'>
        <h3 className='rate-table-heading'>{table.label}</h3>
        {userType === 'client' && (
          <div className='client'>
            {this.renderClientTable(table, priceType, sleepover_introduction)}
          </div>
        )}
        {userType === 'worker' && (
          <div className='worker'>
            {this.renderWorkerTable(table, priceType, sleepover_introduction)}
          </div>
        )}
      </div>
    );
  }
}
export default withPrismic(RateTable);
