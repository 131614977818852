import React, { useState, useEffect } from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { withPrismic } from '../../prismic/prismic';
import NewsWrap from '../../components/news-wrap/news-wrap';
import Loading from '../../components/loading/loading';
import Analytics from '../../config/analytics';
import './news-wrap.scss';

function NewsWrapLayout({ prismic, primary }) {
  const [loading, setLoading] = useState(false);
  const [currentPage] = useState(1);
  const [posts, setPosts] = useState(null);

  const RESULTS_PER_PAGE = primary.results_per_page || 3;

  const loadPosts = async (pageSize, page) => {
    setLoading(true);
    let items = await prismic.getNewsWraps(pageSize, page);

    // Add the incoming updates to the current state
    if (posts) {
      items.results = posts.results.concat(items.results);
    }
    setPosts(items);
    setLoading(false);
  };

  useEffect(() => {
    if (!posts) {
      loadPosts(RESULTS_PER_PAGE, currentPage);
    }
  });

  const loadMoreButton = () => {
    if (!loading && posts.page < posts.total_pages) {
      loadPosts(RESULTS_PER_PAGE, posts.page + 1);
    }
    const path = window.location.pathname;

    Analytics.link(
      { label: 'Load more news wraps' },
      `www.${path}.newsWrap.loadMore`,
      true
    );
  };

  const renderPosts = () => {
    return (
      posts.results &&
      posts.results.map((wrap, i) => <NewsWrap primary={wrap} key={i} />)
    );
  };

  if (!posts) {
    return null;
  }

  const hasTitle = primary.title.length !== 0 && primary.title[0].text !== '';
  const hasContent =
    primary.content.length !== 0 && primary.content[0].text !== '';

  return (
    <section className='layout-news-wrap'>
      {(hasTitle || hasContent) && (
        <div className='container'>
          <div className='content'>
            {hasTitle &&
              PrismicRichText.render(
                primary.title,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            {hasContent &&
              PrismicRichText.render(
                primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
          </div>
        </div>
      )}

      <div className='container'>
        <div className='content'>
          {renderPosts()}

          {loading && <Loading />}

          {!loading && posts.page !== posts.total_pages && (
            <div className='load-more-button'>
              <button onClick={loadMoreButton} className='button -primary'>
                Load more
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default withPrismic(NewsWrapLayout);
