const constants = {
  appName: 'www',
  appVersion: '2.4.22',

  locale: 'en-au',
  locale_og: 'en_AU',

  site_name: 'Hireup',
  domain: process.env.DOMAIN || 'https://hireup.com.au',
  react_app_domain: process.env.REACT_APP_DOMAIN || 'https://hireup.com.au',
  home_uid: process.env.REACT_APP_HOME_UID || 'home',
  events_uid: process.env.REACT_APP_EVENTS_UID || 'events',
  careers_uid: process.env.REACT_APP_CAREERS_UID || 'careers',
  careers_subpage_uid:
    process.env.REACT_APP_CAREERS_SUBPAGE_UID || 'careers-sub-page',
  glossary_uid: process.env.REACT_APP_GLOSSARY_UID || 'glossary',
  registration:
    process.env.REACT_APP_REGISTRATION_URL ||
    'https://app.hireup.com.au/registration',
  registrationClient:
    process.env.REACT_APP_REGISTRATION_CLIENT_URL ||
    'https://app.hireup.com.au/registration/looking-for-support',
  registrationWorker:
    process.env.REACT_APP_REGISTRATION_WORKER_URL ||
    'https://app.hireup.com.au/registration/support-worker',
  registrationCoordinator:
    process.env.REACT_APP_REGISTRATION_COORDINATOR_URL ||
    'https://app.hireup.com.au/registration/support-coordinator',
  landing_uid: process.env.REACT_APP_LANDING_UID || 'landing',
  landing_client: process.env.REACT_APP_LANDING_CLIENT || 'become-a-client',
  landing_worker:
    process.env.REACT_APP_LANDING_WORKER || 'become-a-support-worker',
  landing_provider: process.env.REACT_APP_LANDING_PROVIDER || 'providers',
  location_clients_path:
    process.env.REACT_APP_LOCATION_CLIENTS_PATH ||
    'disability-support-workers-carers',
  location_workers_path:
    process.env.REACT_APP_LOCATION_WORKERS_PATH ||
    'disability-support-worker-carer-jobs',
  type: 'website',
  static_post_tag: 'news-daily-wrap',

  status: 200,

  hireup_web: process.env.REACT_APP_HIREUP_WEB || 'https://app.hireup.com.au',
  prismic_api:
    process.env.REACT_APP_PRISMIC_API || 'https://hireup.cdn.prismic.io/api/v2',

  google_maps_api_key:
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    'AIzaSyC1gbjjHDzjXivkJITmQoa1kDzxG-kofBI',
  google_maps_default_center: { lat: -33.825055, lng: 151.189974 },
  hotjar_id: process.env.REACT_APP_HOTJAR_ID || '1181718',
  hotjar_snippet_version: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION || '6',

  twitter_handle: 'hireupaustralia',
  facebook_handle: 'hireupaus',
  facebook_admins: '577216334',
  facebook_app_id: '319939181935362',
  facebook_publisher: 'https://www.facebook.com/hireupaus',
  youtube_channel: 'UChwvGRddkKZf0_dEQaGx7xw',

  feature: {
    locale: process.env.REACT_APP_FEATURE_LOCALE || false,
    cookieName: 'x-hu-features',
  },

  defaults: {
    title: 'Hireup',
    description:
      'The online platform for Australians with disability to hire and manage the support workers they choose.',
    image: {
      url: 'https://hireup.com.au/assets/hireup.image.jpg',
      alt: 'Hireup',
      copyright: 'Hireup 2021',
      dimensions: {
        width: 1200,
        height: 630,
      },
    },
    schema: {
      '@context': 'http://schema.org',
      '@type': 'LocalBusiness',
      name: 'Hireup',
      logo: 'https://hireup.com.au/assets/hireup.image.jpg',
      image: 'https://hireup.com.au/assets/hireup.brand.jpg',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Ground Floor, 120 Pacific Highway',
        addressLocality: 'Greenwich',
        addressRegion: 'NSW',
        postalCode: '2065',
      },
      email: 'hello@hireup.com.au',
      telePhone: '0291135933',
      url: 'https://hireup.com.au/',
      geo: {
        '@type': 'GeoCoordinates',
        latitude: '-33.825122',
        longitude: '151.189761',
      },
      sameAs: [
        'https://www.facebook.com/hireupaus/',
        'https://www.instagram.com/hireupaustralia/',
        'https://www.youtube.com/channel/UChwvGRddkKZf0_dEQaGx7xw',
        'https://twitter.com/hireupaustralia',
        'https://www.linkedin.com/company/hireup/',
      ],
    },
    states: ['NSW', 'QLD', 'VIC', 'SA', 'ACT', 'WA', 'TAS', 'NT'],
  },
  user: {
    typeById: {
      1: 'cl',
      2: 'sw',
      3: 'am',
      4: 'admin',
    },
  },
};

module.exports = constants;
