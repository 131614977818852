import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import './question-answer.scss';

export default class QuestionAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  layoutClasses() {
    let classes = {
      'question-answer': true,
      '-active': this.state.open,
    };
    return classNames(classes);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  }

  render() {
    const { primary } = this.props;

    if (!primary) {
      return null;
    }

    return (
      <div
        className={this.layoutClasses()}
        itemScope
        itemProp='mainEntity'
        itemType='http://schema.org/Question'>
        <button className='question' onClick={(e) => this.handleClick(e)}>
          {primary.question && (
            <div itemProp='name'>
              {PrismicRichText.render(
                primary.question,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          )}
          {this.state.open && <MdKeyboardArrowUp />}
          {!this.state.open && <MdKeyboardArrowDown />}
        </button>
        <div
          className='answer'
          itemScope
          itemProp='acceptedAnswer'
          itemType='http://schema.org/Answer'
          aria-hidden={!this.state.open}>
          {primary.answer &&
            PrismicRichText.render(
              primary.answer,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
        </div>
      </div>
    );
  }
}
