import * as React from 'react';

const IconChevronDown = (props) => {
  return (
    <span className='icon'>
      <svg {...props} viewBox='0 0 24 24' fill='none'>
        <path
          d='M5.3 9.7a1 1 0 0 1 1.3-1.48l.1.08 5.3 5.3 5.3-5.3a1 1 0 0 1 1.31-.08l.1.08a1 1 0 0 1 .08 1.32l-.08.1-6 6a1 1 0 0 1-1.32.08l-.1-.08-6-6z'
          fill='currentColor'
        />
      </svg>
    </span>
  );
};

export default IconChevronDown;
