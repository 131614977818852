import React from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { ReactComponent as HireupLogo } from '../../assets/img/hireup.brand.master.svg';
import { ReactComponent as IconCheck } from '../../assets/svg/icon-table-check.svg';
import { ReactComponent as IconCross } from '../../assets/svg/icon-table-cross.svg';
import { getThemeClass } from '../../helpers/campaignHelpers';

import '../../assets/scss/campaign.scss';
import './campaign-comparison-table.scss';

const columnIconKeys = [
  'comparison_column_one',
  'comparison_column_two',
  'comparison_column_three',
];

const columnTextKeys = [
  'comparison_column_one_text',
  'comparison_column_two_text',
  'comparison_column_three_text',
];

const TableData = ({ value }) => {
  if (!value) {
    return null;
  }

  const valueLowerCase = value.toLowerCase();

  if (valueLowerCase === 'hireup') {
    return (
      <span className='logo'>
        <HireupLogo role='image' aria-label='Hireup' />
      </span>
    );
  }

  if (valueLowerCase === 'tick') {
    return (
      <span aria-label='Yes'>
        <IconCheck />
      </span>
    );
  }

  if (valueLowerCase === 'cross') {
    return (
      <span aria-label='No'>
        <IconCross />
      </span>
    );
  }

  return value;
};

const CampaignComparisonTable = ({ primary, items }) => {
  const tableRows = ['', ...items.map((item) => item.comparison_heading)];

  const tableHeadings = [
    primary.column_one_heading,
    primary.column_two_heading,
    primary.column_three_heading,
  ];

  const tableColumns = tableHeadings.map((heading, headingIndex) => [
    heading,
    ...items.map(
      (item) =>
        item[columnTextKeys[headingIndex]] || item[columnIconKeys[headingIndex]]
    ),
  ]);

  return (
    <section
      className={classNames(
        'padding-section comparison-table-section',
        getThemeClass(primary.background_color)
      )}>
      <div className='container'>
        <div className='section-gutter gap-y-content'>
          {primary.content_header && primary.content_header.length ? (
            <div className='rich-text text-center'>
              {PrismicRichText.render(
                primary.content_header,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          ) : null}
          <table className='comparison-layout comparison-table'>
            {tableRows.length ? (
              <thead className='table-head'>
                <tr className='table-row'>
                  <th className='table-column column-header' tabIndex={-1} />
                  {tableColumns.map((column, columnIndex) => (
                    <th
                      className={classNames(
                        'table-column column-header',
                        columnIndex === 0
                          ? getThemeClass(primary.column_color)
                          : null
                      )}
                      key={columnIndex}>
                      <TableData value={column[0]} />
                    </th>
                  ))}
                </tr>
              </thead>
            ) : null}
            {tableRows.length > 1 ? (
              <tbody className='table-body'>
                {tableRows.slice(1).map((row, rowIndex) => (
                  <tr className='table-row' key={rowIndex + 1}>
                    <td className='table-column column-data'>{row}</td>
                    {tableColumns.map((column, columnIndex) => (
                      <td
                        className={classNames(
                          'table-column column-data',
                          columnIndex === 0
                            ? getThemeClass(primary.column_color)
                            : null
                        )}
                        key={columnIndex}>
                        <TableData value={column[rowIndex + 1]} />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            ) : null}
          </table>
          <div className='comparison-layout comparison-cards'>
            {tableColumns.map((column, columnIndex) => (
              <div
                className={classNames(
                  'comparison-card',
                  columnIndex === 0 ? getThemeClass(primary.column_color) : null
                )}
                key={columnIndex}>
                {column.map((row, rowIndex) => (
                  <div
                    className={classNames(
                      'value-row',
                      rowIndex === 0 ? 'column-header' : ''
                    )}
                    key={rowIndex}>
                    <div className='label'>{tableRows[rowIndex]}</div>
                    <div className='value'>
                      <TableData value={row} />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          {primary.content_footer && primary.content_footer.length ? (
            <div className='rich-text'>
              {PrismicRichText.render(
                primary.content_footer,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default CampaignComparisonTable;
