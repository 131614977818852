import React from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { format, formatRelative } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import './news-wrap.scss';

export default function NewsPost({ primary }) {
  const publishedDate = primary.data.displayed_publication_date;

  if (!primary) {
    return null;
  }

  // override date-fns default including "at {time}"
  const formatRelativeLocale = {
    lastWeek: "'on' eeee",
    yesterday: "'yesterday'",
    today: "'today'",
    tomorrow: "'tomorrow'",
    nextWeek: "'next' eeee",
    other: 'd/MM/yyyy',
  };

  const locale = {
    ...enUS,
    formatRelative: (token) => formatRelativeLocale[token],
  };

  return (
    <div className='wrap-single'>
      {publishedDate && (
        <>
          <h2 className='title'>
            News on {format(new Date(publishedDate), 'EEEE, d MMMM yyyy')}
          </h2>
          <p className='relative-time'>
            Posted{' '}
            {formatRelative(new Date(publishedDate), new Date(), { locale })}
          </p>
        </>
      )}
      {primary.data.content && (
        <>
          {PrismicRichText.render(
            primary.data.content,
            PrismicConfig.linkResolver,
            PrismicConfig.htmlSerializer
          )}
        </>
      )}
    </div>
  );
}
