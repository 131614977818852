import * as React from 'react';

const IconCalendar = (props) => {
  return (
    <span className='icon'>
      <svg {...props} fill='none' viewBox='0 0 16 16'>
        <path
          d='M3.5 2v.75l.005.087a.75.75 0 0 0 1.49 0L5 2.75V2h6v.75l.005.087a.75.75 0 0 0 1.49 0l.005-.087V2H14a1.5 1.5 0 0 1 1.496 1.388l.004.112v9.75a2.25 2.25 0 0 1-2.118 2.246l-.132.004H2.75a2.25 2.25 0 0 1-2.246-2.118L.5 13.25V3.5A1.5 1.5 0 0 1 2 2h1.5ZM14 5H2v8.25a.75.75 0 0 0 .578.73l.085.015.087.005h10.5a.75.75 0 0 0 .745-.662L14 13.25V5Zm-2.25 5.25a.75.75 0 0 1 .088 1.495l-.088.005h-7.5a.75.75 0 0 1-.087-1.495l.087-.005h7.5Zm0-3a.75.75 0 0 1 .088 1.495l-.088.005h-7.5a.75.75 0 0 1-.087-1.495l.087-.005h7.5Zm0-6.75a.75.75 0 0 1 .745.663l.005.087V2H11v-.75a.75.75 0 0 1 .75-.75Zm-7.5 0a.75.75 0 0 1 .745.663L5 1.25V2H3.5v-.75A.75.75 0 0 1 4.25.5Z'
          fill='currentColor'
        />
      </svg>
    </span>
  );
};

export default IconCalendar;
