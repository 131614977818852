const primarySupport = require('./primarySupport');

const helpWithTasks = {
  supportName: 'Preassigned tasks',
  supportIcon: 'edit',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'education-and-employment',
};

module.exports = helpWithTasks;
