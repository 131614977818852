import * as React from 'react';

const IconMusic = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M9 46.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15ZM39 37.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z' />
        <path d='M16.5 39V13.438a6 6 0 0 1 4.1-5.692L42.696 1.61a3 3 0 0 1 3.8 2.89V30M16.5 17.438l30-9' />
      </svg>
    </span>
  );
};

export default IconMusic;
