import React, { Component } from 'react';
import Header from '../layouts/header/header';
import HeaderMinimal from '../layouts/header-minimal/header-minimal';
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs';
import Footer from '../layouts/footer/footer';
import Loading from '../components/loading/loading';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import constants from '../config/constants';
import locales from '../config/locales';
import { withPrismic } from '../prismic/prismic';
import Utilities from '../config/utilities';
import _find from 'lodash.find';
import _get from 'lodash.get';

class PageWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollStage: null,
      scrollStages: [25, 50, 75, 100],
    };
  }

  componentDidMount() {
    document.addEventListener(
      'scroll',
      () => {
        this.handleScroll();
      },
      true
    );
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', () => {
      this.handleScroll();
    });
  }

  componentDidUpdate() {
    let { set_locale } = this.props;
    if (set_locale !== this.props.locale) {
      this.props.prismic.setLocale(set_locale || constants.locale);
    }
  }

  mainLayoutClasses() {
    let type = this.props.context ? this.props.context : 'default';
    return classNames({
      main: true,
      ['-' + type]: true,
      transform: !this.props.noTransform,
    });
  }

  wrapperLayoutClasses() {
    let type = this.props.context ? this.props.context : 'default';
    return classNames({
      'page-wrapper': true,
      [`-${type}`]: true,
    });
  }

  generateTitle(content, title, locale) {
    let defaultTitle = `Disability support workers who love what you love`;

    let metaTitle = title || content.data?.title?.[0]?.text || defaultTitle;

    // Append Hireup
    metaTitle = `${metaTitle} | Hireup`;

    // Append the language if this is not the default site language
    if (!locale.default) {
      metaTitle = `${metaTitle} (${locale.label})`;
    }

    return metaTitle;
  }

  generateDescription(content, description) {
    let bodyContent = content.data?.body?.[0]?.primary?.content?.[1]?.text;

    if (bodyContent && bodyContent.length > 130) {
      bodyContent = `${bodyContent.substring(0, 130)}...`;
    }

    let metaDescription =
      description ||
      content.data?.description?.[0]?.text ||
      bodyContent ||
      constants.defaults.description;

    return metaDescription;
  }

  generateImage(content) {
    let metaImage;

    if (content.data?.image && content.data?.image?.url) {
      metaImage = content.data.image;
    } else {
      metaImage = constants.defaults.image;
    }
    return metaImage;
  }

  generateMeta = (content, title, description, locale) => {
    let meta = {};

    meta.title = this.generateTitle(content, title, locale);
    meta.description = this.generateDescription(content, description);
    meta.image = this.generateImage(content);
    meta.base_url = constants.domain;
    meta.facebookPublisher = `https://www.facebook.com/${constants.facebook_handle}/`;

    return meta;
  };

  createTranslationLink(locale, link) {
    return {
      text: locale.label,
      link: {
        id: link.id,
        uid: link.uid,
        lang: locale.code,
        link_type: 'Document',
        type: 'page',
      },
    };
  }

  generateTranslations = (content) => {
    let translations = [];

    content?.alternate_languages &&
      content.alternate_languages.forEach((translation) => {
        let locale = _find(locales, { code: translation.lang });
        if (locale) {
          translations.push(this.createTranslationLink(locale, translation));
        } else {
          console.warn('Unknown translation locale', translation.lang);
        }
      });

    // If translations exist, show the primary locale first
    if (translations.length) {
      let primary_locale = _find(locales, { code: content.lang });
      translations.unshift(this.createTranslationLink(primary_locale, content));
    }

    return translations.length > 0 ? translations : null;
  };

  handleScroll() {
    let percent = Math.round(Utilities.getScrollPercent()); // Get the abs value of the scroll percentage

    let currentStage = -1;
    this.state.scrollStages.forEach((stage) => {
      if (percent >= stage) {
        currentStage = stage;
      }
    });

    if (this.state.scrollStage === null) {
      // Set the initial value without triggering
      // We don't want to log a conversion if a page is loaded with a 75% scroll, for example
      this.setState({ scrollStage: currentStage });
    } else if (percent === 0) {
      // When navigating pages, or scrolling right to the top, and after the initial load reset the counter
      this.setState({ scrollStage: 0 });
    } else if (currentStage > this.state.scrollStage) {
      // Only record scroll increases, as people progress through the page
      // For a maximum of scrollStages.length events per page
      this.setState({ scrollStage: currentStage });
    }
  }

  render() {
    const { loading, content, title, description } = this.props;

    // Loading Screen
    if (loading) {
      return <Loading full={true} />;
    }

    // Find the locale from the content
    const locale =
      _find(locales, { code: this.props.locale }) ||
      _find(locales, { default: true });

    // Meta Information
    const meta = this.generateMeta(content, title, description, locale);

    const alternative_navigation =
      _get(content, 'data.alternative_navigation') || {};

    return (
      <div className={this.wrapperLayoutClasses(locale)}>
        <Helmet>
          <html lang={locale.code} />

          {/* Body Classes */}
          <body
            className={`direction-${locale.direction || 'ltr'} locale-${
              locale.code
            }`}
          />

          {/* Title */}
          <title>{meta.title}</title>
          <meta name='description' content={meta.description} />
          <meta name='image' content={meta.image.url} />

          {/* Google+ & Schema.org */}
          <meta itemProp='name' content={meta.title} />
          <meta itemProp='description' content={meta.description} />
          <meta itemProp='image' content={meta.image.url} />

          {/* Twitter */}
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:title' content={meta.title} />
          <meta name='twitter:description' content={meta.description} />
          <meta name='twitter:site' content={`@${constants.twitter_handle}`} />
          <meta
            name='twitter:creator'
            content={`@${constants.twitter_handle}`}
          />
          <meta name='twitter:image:src' content={meta.image.url} />

          {/* Facebook Open Graph */}
          <meta property='og:locale' content={constants.locale_og} />
          <meta property='og:site_name' content={constants.site_name} />
          <meta property='og:title' content={meta.title} />
          <meta property='og:url' content={meta.base_url} />
          <meta property='og:type' content={constants.type} />
          <meta property='og:description' content={meta.description} />
          <meta property='og:image' content={meta.image.url} />
          <meta
            property='og:image:width'
            content={meta.image.dimensions.width}
          />
          <meta
            property='og:image:height'
            content={meta.image.dimensions.height}
          />

          <meta
            property='article:publisher'
            content={constants.facebook_publisher}
          />
          <meta property='fb:admins' content={constants.facebook_admins} />
          <meta property='fb:app_id' content={constants.facebook_app_id} />

          {/* Conditionally load noindex tag */}
          {this.props.noindex && (
            <meta name='robots' content='noindex, follow' />
          )}

          {/* Conditionally load Urdu */}
          {this.props.locale === 'ur-pk' && (
            <link
              rel='stylesheet'
              type='text/css'
              href={`${process.env.PUBLIC_URL}/font-ur-pk.css`}
            />
          )}
          {/* This is needed for Typeform slice */}
          <script src='https://embed.typeform.com/next/embed.js'></script>
        </Helmet>

        {this.props.content.data?.settings_navigation_style === 'Minimal' ? (
          <HeaderMinimal />
        ) : (
          <Header
            banner={this.props.banner}
            translations={this.generateTranslations(content)}
          />
        )}

        {this.props.breadcrumbs && <Breadcrumbs />}

        <main id='main' className={this.mainLayoutClasses()}>
          {this.props.children}
        </main>

        <footer>
          <Footer
            alternative_navigation={alternative_navigation}
            footer_content={this.props.footer_content}
            footer_cta={this.props.footer_cta}
            translations={this.generateTranslations(content)}
          />
        </footer>
      </div>
    );
  }
}

export default withPrismic(PageWrapper);
