import axios from 'axios';
import startCase from 'lodash.startcase';
import React, { useState } from 'react';
import { getEnvBaseUrl, API_BASE_URL_V1_5 } from '../../config/api';
import Analytics from '../../config/analytics';
import classNames from 'classnames';
import _kebabCase from 'lodash.kebabcase';
import SearchResults from '../../components/search-results/searchResults';
import Autocomplete from '../../components/autocomplete/autocomplete';
import Contact from '../../components/contact/contact';
import Loading from '../../components/loading/loading';
import useSessionStorage from '../../helpers/useSessionStorage';
import './search.scss';

// react app does not have access to environment
// variables at run time, so we need to get the
// environment url based on the window.location
const ENV_BASE_URL = getEnvBaseUrl();

const coreV1_5Api = axios.create({
  baseURL: `${ENV_BASE_URL}${API_BASE_URL_V1_5}`,
});

export default function SearchLayout({ primary }) {
  const { version, spacer, large_heading, small_text, visibility } = primary;
  const [radius, setRadius] = useSessionStorage('hu-location-radius', 20000);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [placeName, setPlaceName] = useState('');
  const [publicSearchResponse, setPublicSearchResponse] = useState(null);
  const [locationSearched, setLocationSearched] = useSessionStorage(
    'hu-location',
    null
  );

  // TODO: Add tests

  const PATH = window.location.pathname;
  const MIN_WORKERS = 5;

  const searchClasses = () => {
    let classes = {
      'layout-search': true,
      '-expanded': publicSearchResponse || error,
      [`-spacer-${_kebabCase(spacer)}`]: spacer,
      '-hidden': visibility === 'Hidden',
    };
    return classNames(classes);
  };

  const titleClasses = () => {
    let classes = {
      'form-title': true,
      '-form-title-lg': primary.large_heading,
    };
    return classNames(classes);
  };

  const handleRadiusChange = (event) => {
    const radiusUpdated = event.target.value;

    setRadius(parseInt(radiusUpdated));

    Analytics.link(
      { label: `Search radius - ${radius / 1000}km` },
      `www.${PATH}.Search.radiusSelect`,
      true
    );
  };

  const handleSubmit_v1 = async (event) => {
    event.preventDefault();

    if (locationSearched === null) {
      setError(true);
      return;
    }

    try {
      setLoading(true);
      const locationSearchedArr = locationSearched.split(',');
      const locationSearchedFormatted = `${startCase(
        locationSearchedArr[0].toLowerCase()
      )}, ${locationSearchedArr[1]}`;

      // get the location lat/long from locations.json
      const searchResponse = await axios.post('/search/', {
        locationSearched: locationSearched,
      });

      const [location] = searchResponse.data;

      const response = await coreV1_5Api.post(
        '/search/public',
        {
          location: {
            latitude: location.latitude,
            longitude: location.longitude,
            radius: radius,
            placeName: locationSearchedFormatted,
          },
        },
        {
          headers: {
            'x-event-anonymous-Id': Analytics.getAnonymousId(),
          },
        }
      );

      setPublicSearchResponse(response.data);
      setPlaceName(response.data.filters.location.placeName);
      setLoading(false);

      Analytics.link(
        { label: 'Submit public search' },
        `www.${PATH}.Search.searchButton`,
        true
      );
    } catch {
      console.warn('Error retrieving search results');
      setLoading(false);
    }
  };

  const handleSubmit_v2 = async (event) => {
    if (locationSearched === null) {
      event.preventDefault();
      setError(true);
    } else {
      Analytics.link(
        { label: 'Submit public search' },
        `www.${PATH}.Search.searchButton`,
        true
      );
    }
  };

  const v1 = version === 'v1';
  const hasMinWorkers = publicSearchResponse?.totalResultCount >= MIN_WORKERS;
  const numberOfWorkers = Intl.NumberFormat().format(
    publicSearchResponse?.totalResultCount
  );
  const maxRadiusSelected = radius === 50000;
  const radiusInKm = radius / 1000;
  const defaultHeading = 'Find NDIS support workers near you:';

  return (
    <section className={searchClasses()}>
      <div className='container'>
        <div className='search'>
          <h2 className={titleClasses()}>
            {large_heading || small_text || defaultHeading}
          </h2>
          <form
            className='search-form'
            autoComplete='off'
            onSubmit={v1 ? handleSubmit_v1 : handleSubmit_v2}
            method='post'
            action='/pre-registration'>
            <div className='search-inputs'>
              <div className='search-location'>
                <label htmlFor={`search_${version}`}>
                  Enter suburb or postcode
                </label>
                <Autocomplete
                  wrapperId={version}
                  inputId={`search_${version}`}
                  required={true}
                  setError={setError}
                  setLocationSearched={setLocationSearched}
                />
              </div>
              <div className='search-radius'>
                <label htmlFor='radius-select'>Within</label>
                <select
                  id='radius-select'
                  name='radius'
                  defaultValue={radius}
                  onChange={handleRadiusChange}>
                  <option value='10000'>10km</option>
                  <option value='20000'>20km</option>
                  <option value='30000'>30km</option>
                  <option value='40000'>40km</option>
                  <option value='50000'>50km</option>
                </select>
              </div>
            </div>

            <div className='search-button'>
              <button type='submit' className='button' disabled={loading}>
                <span className='button-primary'>Search</span>
              </button>
            </div>
          </form>

          {loading && <Loading />}

          {!loading && error && (
            <div className='search-results'>
              <h3 role='alert' className='search-results-title'>
                Search location not found.
              </h3>
              <p className='search-results-message'>
                Select from the suggested location options before submitting
                your search.
              </p>
            </div>
          )}

          {!loading && publicSearchResponse !== null && (
            <>
              <div role='region' aria-live='polite' className='search-results'>
                <div className='container'>
                  {!error && (
                    <h3 className='search-results-title'>
                      We've found&nbsp;
                      {hasMinWorkers ? numberOfWorkers : `less than five`}
                      &nbsp;support workers
                      <span>
                        within {radiusInKm}km of&nbsp;{placeName}:
                      </span>
                    </h3>
                  )}

                  {hasMinWorkers ? (
                    <SearchResults
                      facetCounts={publicSearchResponse.facetCounts}
                    />
                  ) : (
                    <p className='search-results-message'>
                      You can{' '}
                      {!maxRadiusSelected
                        ? `increase your search radius or`
                        : null}{' '}
                      search in a different location.
                    </p>
                  )}
                </div>
              </div>
              {!hasMinWorkers && <Contact />}
            </>
          )}
        </div>
      </div>
      <div className='spacer'></div>
    </section>
  );
}
