const primarySupport = require('./primarySupport');

const hairwashing = {
  supportName: 'Hairwashing',
  supportIcon: 'hairDryer',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = hairwashing;
