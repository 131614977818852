import React from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { getThemeClass } from '../../helpers/campaignHelpers';

import '../../assets/scss/campaign.scss';

const CampaignOneColumn = ({ primary }) => {
  return (
    <section
      className={classNames(
        'padding-section one-column-section',
        getThemeClass(primary.background_color)
      )}>
      <div className='container'>
        <div className='section-gutter'>
          {primary.content && primary.content.length ? (
            <div className='max-width-small rich-text'>
              {PrismicRichText.render(
                primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default CampaignOneColumn;
