import React, { useState } from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { RichText as PrismicRichText, Link } from 'prismic-reactjs';
import _kebabCase from 'lodash.kebabcase';
import { PrismicConfig } from '../../prismic/config';
import Image from '../../components/image/image';
import Badge from '../../components/badge/badge';
import Analytics from '../../config/analytics';
import Utilities from '../../config/utilities';
import './banner.scss';

export default function Banner({ primary }) {
  const [headingId] = useState(uuid().slice(0, 8));
  const [buttonId] = useState(uuid().slice(0, 8));

  if (!primary.content && !primary.title) {
    return null;
  }

  const {
    title,
    content,
    cta_text,
    cta_link,
    image,
    background_color,
    show_badge,
    badge_type,
  } = primary;

  const handleClick = (event) => {
    const path = window.location.pathname;

    Analytics.link(
      { label: event.target.href },
      `www.${path}.banner.button`,
      true
    );
  };

  const backgroundColor = background_color || 'blue';
  const hasLink =
    cta_link?.link_type !== 'Any' && cta_link?.link_type !== undefined;

  const layoutClasses = () => {
    let classes = {
      'layout-banner': true,
      [`-${Utilities.toKebabCase(backgroundColor)}`]: true,
      [`-padding-${_kebabCase(primary.padding)}`]: primary.padding,
      '-with-badge': show_badge,
      '-with-image': image?.url,
      '-with-button-only': hasLink && !image?.url,
    };
    return classNames(classes);
  };

  const contentClasses = (id) => {
    let classes = {
      'banner-content': true,
      [`-${id}`]: true,
    };
    return classNames(classes);
  };

  const renderButton = () => (
    <div className='button-container'>
      <a
        id={buttonId}
        aria-labelledby={`${headingId} ${buttonId}`}
        className='content-cta button'
        href={Link.url(cta_link, PrismicConfig.linkResolver)}
        onClick={handleClick}>
        {cta_text || 'Learn more'}
      </a>
    </div>
  );

  return (
    <section className={layoutClasses(primary)}>
      <div className='banner-container'>
        <div className='banner'>
          <div className={contentClasses('one')}>
            <div className='content-wrapper'>
              <h2 id={headingId} className='content-title'>
                {title} {show_badge && <Badge type={badge_type} small></Badge>}
              </h2>
              {content &&
                PrismicRichText.render(
                  content,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
              {hasLink && image?.url && renderButton()}
            </div>
          </div>
          <div className={contentClasses('two')}>
            {image?.url && (
              <Image primary={image} width={500} height={320} lazyload={true} />
            )}
            {!image?.url && hasLink && <>{renderButton()} </>}
          </div>
        </div>
      </div>
    </section>
  );
}
