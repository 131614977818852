const primarySupport = require('./primarySupport');

const shaving = {
  supportName: 'Shaving',
  supportIcon: 'shaver',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = shaving;
