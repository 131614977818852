// Education and employment
const developSkills = require('./education/developSkills');
const findingAndKeepingAJob = require('./education/findingKeepingJob');
const helpWithHomework = require('./education/helpWithHomework');
const helpWithTasks = require('./education/helpWithTasks');
const improvedDailySkills = require('./education/improvedDailyLivingSkills');
const improvedLearning = require('./education/improvedLearning');
const studying = require('./education/studying');
const tutor = require('./education/tutor');

// Personal Care
const assistanceDailyLife = require('./personal-care/assistanceDailyLife');
const bathing = require('./personal-care/bathing');
const bedChanging = require('./personal-care/bedChanging');
const disabilityRelatedHealth = require('./personal-care/disabilityRelatedHealth');
const exercisePhysiology = require('./therapy-support/exercisePhysiology');
const feeding = require('./personal-care/feeding');
const hairwashing = require('./personal-care/hairwashing');
const hydrotherapy = require('./therapy-support/hydrotherapy');
const improvedHealth = require('./personal-care/improvedHealth');
const incontinenceLaundry = require('./personal-care/incontinenceLaundry');

// Therapy Supports
const musicTherapy = require('./therapy-support/musicTherapy');
const nailCare = require('./personal-care/nailCare');
const occupationalTherapy = require('./therapy-support/occupationalTherapy');
const shaving = require('./personal-care/shaving');
const showering = require('./personal-care/showering');
const speechTherapy = require('./therapy-support/speechTherapy');
const toileting = require('./personal-care/toileting');

// Complex supports
const adhdSupport = require('./specialised-complex/adhd');
const bowelCare = require('./specialised-complex/bowelCare');
const behaviourSupport = require('./specialised-complex/behaviourSupport');
const behaviourManagement = require('./specialised-complex/behaviourManagement');
const catheterCare = require('./specialised-complex/catheterCare');
const complexBowelCare = require('./specialised-complex/complexBowelCare');
const diabetesManagement = require('./specialised-complex/diabetesManagement');
const mealtimeManagement = require('./specialised-complex/mealtimeManagement');
const medicationManagement = require('./specialised-complex/medicationManagement');
const manualHandling = require('./specialised-complex/manualHandling');
const mentalHealthSupports = require('./specialised-complex/mentalHealthSupports');
const nutrition = require('./specialised-complex/nutrition');
const tubeFeeding = require('./specialised-complex/tubeFeeding');

const isValidSupportType = (primarySupport, secondarySupport) => {
  if (supportTypes[primarySupport]?.[secondarySupport]) {
    return true;
  } else {
    return false;
  }
};

const isArchivedSupport = (primarySupport, secondarySupport) => {
  if (supportTypes[primarySupport]?.[secondarySupport]?.archived) {
    return true;
  } else {
    return false;
  }
};

const supportTypes = {
  educationAndEmployment: {
    ndisDevelopSkills: developSkills,
    ndisFindingAndKeepingAJob: findingAndKeepingAJob,
    ndisHelpWithHomework: helpWithHomework,
    ndisHelpWithPreassignedTasks: helpWithTasks,
    ndisImprovedDailyLivingSkills: improvedDailySkills,
    ndisImprovedLearning: improvedLearning,
    ndisStudying: studying,
    ndisTutor: tutor,
  },
  therapySupport: {
    exercisePhysiology: exercisePhysiology,
    hydrotherapy: hydrotherapy,
    musicTherapy: musicTherapy,
    occupationalTherapy: occupationalTherapy,
    speechTherapy: speechTherapy,
  },
  personalCare: {
    ndisAssistanceWithDailyLife: assistanceDailyLife,
    ndisBathing: bathing,
    ndisBedChanging: bedChanging,
    ndisDisabilityRelatedHealth: disabilityRelatedHealth,
    ndisFeeding: feeding,
    ndisHairwashing: hairwashing,
    ndisImprovedHealthAndWellbeing: improvedHealth,
    ndisIncontinenceLaundry: incontinenceLaundry,
    ndisNailCare: nailCare,
    ndisShaving: shaving,
    ndisShowering: showering,
    ndisToileting: toileting,
  },
  specialisedComplex: {
    ndisAdhd: adhdSupport,
    ndisBehaviourManagement: behaviourManagement,
    ndisBowelCare: bowelCare,
    ndisCatheterCare: catheterCare,
    ndisComplexBowelCare: complexBowelCare,
    ndisDiabetesManagement: diabetesManagement,
    ndisPositiveBehaviourSupport: behaviourSupport,
    ndisManualHandling: manualHandling,
    ndisMealtimeManagement: mealtimeManagement,
    ndisMedicationManagement: medicationManagement,
    ndisMentalHealthSupports: mentalHealthSupports,
    ndisNutrition: nutrition,
    ndisTubeFeeding: tubeFeeding,
  },
};

module.exports = {
  supportTypes,
  isArchivedSupport,
  isValidSupportType,
};
