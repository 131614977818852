const primarySupport = require('./primarySupport');

const speechTherapy = {
  supportName: 'Speech therapy',
  supportIcon: 'speechBubbles',
  primarySupport: primarySupport,
  twoColumnLayout: {
    contentTitle: 'Speech therapy support happening throughout our community',
    content: [
      {
        alt: 'A female speech pathologist works with her client, a young girl. They are holding coloured pencils above their lips and looking at each other.',
        dimensions: {
          height: 541,
          width: 960,
        },
        linkTo: {
          id: 'YQkxbxEAACMAaCv_',
          type: 'news',
          lang: 'en-au',
          uid: 'the-value-of-support-workers-in-therapy-sessions',
        },
        type: 'image',
        url: 'https://images.prismic.io/hireup/a78a50d1-6f6e-4f03-928a-5168cf8419ec_AdobeStock_406960280.jpeg',
      },
    ],
    content_two: [
      {
        type: 'paragraph',
        text: '“Communication is a fundamental foundation for connection with others, independence in life, and participation in social, academic, workplace and community experiences.”',
        spans: [
          {
            start: 0,
            end: 380,
            type: 'label',
            data: {
              label: 'quote',
            },
          },
        ],
      },
      {
        type: 'paragraph',
        text: 'Read about speech therapy happening in our community',
        spans: [
          {
            start: 0,
            end: 150,
            type: 'hyperlink',
            data: {
              type: 'news',
              slug: 'the-value-of-support-workers-in-therapy-sessions',
              lang: 'en-au',
              uid: 'the-value-of-support-workers-in-therapy-sessions',
              link_type: 'Document',
            },
          },
        ],
      },
    ],
  },
  faqs: {
    content: [
      {
        type: 'heading2',
        text: 'Learn more about speech therapy support',
        spans: [],
      },
    ],
    items: [
      {
        question: [
          {
            type: 'heading3',
            text: 'What does speech therapy support involve?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'For speech therapy support, your support worker will come with you and assist you through the session. They can also help with any homework or follow-up tasks. Working with your speech or language therapist, your support worker can help you make the most of your therapy.',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'What can speech therapy be used to treat?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Speech therapy is used to treat a full range of speech and communication-related difficulties that include understanding, listening and speaking, verbally and non-verbally.',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'Why is speech therapy support beneficial?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'With your support worker by your side, assisting you and working with your therapist, speech therapy support allows you to make the most of your therapy.',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'Who runs a speech therapy session?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Speech therapy sessions are run by speech therapists, or speech-language pathologists. They work with their clients to help them improve their communication, as well as treat issues with feeding or swallowing.',
            spans: [],
          },
        ],
      },
    ],
  },
  relatedArticlesCategory: 'speech-therapy',
};

module.exports = speechTherapy;
