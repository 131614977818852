import * as React from 'react';

const IconHomework = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M17.634 39.338a39.918 39.918 0 0 0-13.32-2.614A3 3 0 0 1 1.5 33.71V7a3 3 0 0 1 3.214-3C17.752 4.69 24 9.958 24 12.956M46.5 23.456V7a2.999 2.999 0 0 0-3.214-3C30.248 4.69 24 9.958 24 12.956M24 12.956l.02 10.502M25.5 29.852v11.6a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-11.6' />
        <path d='m22.5 32.476 9.036-7.906a4.504 4.504 0 0 1 5.928 0l9.036 7.906M37.5 44.456h-6v-6a3 3 0 1 1 6 0v6Z' />
      </svg>
    </span>
  );
};

export default IconHomework;
