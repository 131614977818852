import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import PageWrapper from './wrapper';
import Loading from '../components/loading/loading';
import SearchFilters from '../components/search-filters/search-filters';
import ProfileCards from '../components/profile-cards/profile-cards';
import Contact from '../components/contact/contact';
import { withPrismic } from '../prismic/prismic';
import { withHireup } from '../hireup/hireup';
import { getEnvBaseUrl } from '../config/api';
import Error400 from './error-400';
import useSessionStorage from '../helpers/useSessionStorage';
import {
  getSupportWorkerData,
  getTotalWorkers,
  transformLocationString,
} from '../hireup/supportWorkerSearch';
import './pre-registration.scss';

// react app does not have access to environment
// variables at run time, so we need to get the
// environment url based on the window.location
const ENV_BASE_URL = getEnvBaseUrl();

function PreRegistrationPage({ prismicContext, hireup, user }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [supportTypeFilter, setSupportTypeFilter] = useState(null);
  const [state, setState] = useState({
    publicSearchResponse: null,
    publicSearchProfiles: null,
    placeName: null,
    radius: null,
  });

  const [locationSearched, setLocationSearched] = useSessionStorage(
    'hu-location',
    null
  );
  const [locationRadius] = useSessionStorage('hu-location-radius', null);

  const DEFAULT_LOCATION = 'Sydney, NSW 2000';
  const MIN_WORKERS = 6;

  const getInitialLocation = useCallback(() => {
    if (locationSearched) {
      return transformLocationString(locationSearched);
    } else {
      return DEFAULT_LOCATION;
    }
  }, [locationSearched]);

  const getLocationSearchResults = useCallback(async () => {
    setLoading(true);
    const initialLocation = getInitialLocation();

    try {
      // get the location lat/long from locations.json
      const searchResponse = await axios.post('/search/', {
        locationSearched: locationSearched || initialLocation.toUpperCase(),
      });

      const [location] = searchResponse.data;
      const { publicSearchResponse, publicSearchProfiles, placeName, radius } =
        await getSupportWorkerData({
          location,
          locationRadius,
          locationSearched,
          supportType: supportTypeFilter,
        });

      setState({
        publicSearchResponse,
        publicSearchProfiles,
        placeName,
        radius,
      });

      setLoading(false);
    } catch (err) {
      console.warn('Error retrieving public search API results', err);
      setError(true);
    }
  }, [locationSearched, getInitialLocation, locationRadius, supportTypeFilter]);

  useEffect(() => {
    if (user) {
      hireup.redirectLoggedInUsers(`${ENV_BASE_URL}/search`);
    } else {
      getLocationSearchResults();
    }
  }, [getLocationSearchResults, getInitialLocation, user, hireup]);

  const generateTitle = (totalWorkersToShow) => {
    const hasMinWorkers = totalWorkersToShow >= MIN_WORKERS;
    const showSupportType = supportTypeFilter && supportTypeFilter !== 'All';
    return (
      <h1>
        {hasMinWorkers ? (
          <>
            There are <em>{Intl.NumberFormat().format(totalWorkersToShow)}</em>{' '}
            support workers available
          </>
        ) : (
          <>There are less than {MIN_WORKERS} support workers available</>
        )}
        <span>
          {showSupportType
            ? ` near ${
                state.placeName
              } providing support with ${supportTypeFilter.toLowerCase()}:`
            : ` near ${state.placeName}:`}
        </span>
      </h1>
    );
  };

  // Wait for Prismic to initialize and search to return
  if (!prismicContext || (!state.publicSearchResponse && !error)) {
    return <Loading full={true} testId='pre-registration' />;
  }

  if (error) {
    return <Error400 path={`/${window.location.pathname.split('/')[1]}/`} />;
  }

  // totalWorkersToShow is not part of the overall state object as it was
  // causing the useCallback to be called multiple times, due to
  // supportTypeFilter being a dependency of useContext
  // and causing a retrigger when its updated
  const totalWorkersToShow = getTotalWorkers({
    publicSearchResponse: state.publicSearchResponse,
  });

  const postcode = state.placeName.slice(state.placeName.length - 4);
  const hasMinWorkers = totalWorkersToShow >= MIN_WORKERS;
  return (
    <PageWrapper
      banner='Hidden'
      context={`page -pre-registration`}
      noindex={true}
      content={prismicContext}
      footer_cta='Hidden'
      footer_content='Hidden'>
      <section className='layout-pre-registration'>
        <div className='container'>
          <SearchFilters
            loading={loading}
            locationSearched={locationSearched}
            setLocationSearched={setLocationSearched}
            setSupportTypeFilter={setSupportTypeFilter}
            onSubmit={getLocationSearchResults}
          />

          <div className='content search-results-header -padding-top'>
            {generateTitle(totalWorkersToShow)}
          </div>

          {loading && <Loading full={true} />}

          {hasMinWorkers && !loading && (
            <ProfileCards
              workerProfiles={state.publicSearchProfiles}
              selectedPostcode={postcode}
              showPromoCard={true}
            />
          )}

          {!hasMinWorkers && <Contact />}
        </div>
      </section>
    </PageWrapper>
  );
}

export default withPrismic(withHireup(PreRegistrationPage));
