import React from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import _kebabCase from 'lodash.kebabcase';
import './two-column.scss';

export default function TwoColumnLayout({ primary }) {
  const layoutClasses = () => {
    let classes = {
      'layout-two-column': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-padding-${_kebabCase(primary.padding)}`]: primary.padding,
      [`-layout-${_kebabCase(primary.layout)}`]: primary.layout,
    };
    return classNames(classes);
  };

  const hasPrismicContent = primary.content && primary.content.length !== 0;

  if (!primary || !hasPrismicContent) {
    return null;
  }

  return (
    <section className={layoutClasses(primary)}>
      <div className='container'>
        {primary.contentTitle && (
          <h2 className='content title'>{primary.contentTitle}</h2>
        )}
        <div className='content -one'>
          {hasPrismicContent &&
            PrismicRichText.render(
              primary.content,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
        </div>
        <div className='content -two'>
          {hasPrismicContent &&
            PrismicRichText.render(
              primary.content_two,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
        </div>
      </div>
    </section>
  );
}
