import React from 'react';
import './step-list.scss';

export default function StepList({ data }) {
  const defaultItems = {
    Apply: {
      description:
        "Complete and submit your support worker application. You'll need to fill out some personal details, upload a bio and profile photo, and undergo some pre-employment checks.",
    },
    Connect: {
      description:
        "Use our job board to find suitable shifts and clients in your area. You can also browse client profiles via our search function and message those you'd like to work with.",
    },
    Accept: {
      description:
        "Once you've connected with a client, have a meet and greet to get to know each other before a shift. Your client will create a booking request, which you can accept before working the shift.",
    },
    'Get paid': {
      description:
        "Submit your shift for your client to approve. You'll be emailed a payslip and be paid - including superannuation - fortnightly.",
    },
  };

  const renderStepList = () => {
    const stepList = data || defaultItems;

    return Object.entries(stepList).map(([item]) => (
      <li className='list-item-single' key={item}>
        <h4 className='list-item-title'>{item}</h4>
        <p>{stepList[item].description}</p>
      </li>
    ));
  };

  return (
    <section className='layout-step-list'>
      <div className='container'>
        <div className='content'>
          <h3 className='step-list-title'>Start providing support on Hireup</h3>
        </div>
        <ul className='step-list'>{renderStepList()}</ul>
      </div>
    </section>
  );
}
