import React from 'react';

const ShapeBackground = () => {
  return (
    <svg viewBox='0 0 1800 1133' preserveAspectRatio='none' aria-hidden='true'>
      <g>
        <path
          d='M1800,548.31v584.69H0V346.3l1777.39,199.45c7.99.9,15.51,1.74,22.61,2.56Z'
          fill='#1920e6'
        />
        <path
          d='M1800,18.85v1114.15H0v-212.09c152.04-220.02,374.62-392.84,645.9-480.6C922.26,119.44,1353.67-53.47,1800,18.85Z'
          fill='#2214b8'
        />
      </g>
    </svg>
  );
};

export default ShapeBackground;
