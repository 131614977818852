const client = {
  twoColumnLayout: {
    background_color: 'Light Blue',
    padding: 'medium',
    content: [
      {
        type: 'heading2',
        text: 'Team up on your terms',
        spans: [],
      },
      {
        type: 'paragraph',
        text: 'Build the right team of disability support workers for you. Because everyone needs something different from their support network.',
        spans: [],
      },
      {
        type: 'list-item',
        text: 'Choose your own support workers',
        spans: [],
      },
      {
        type: 'list-item',
        text: 'Option for Hireup to manage workers for you',
        spans: [],
      },
      {
        type: 'list-item',
        text: '24/7 incident monitoring',
        spans: [],
      },
      {
        type: 'list-item',
        text: 'Pricing below NDIS rates',
        spans: [],
      },
      {
        type: 'paragraph',
        text: 'How it works',
        spans: [
          {
            start: 0,
            end: 12,
            type: 'label',
            data: {
              label: 'button-primary',
            },
          },
          {
            start: 0,
            end: 12,
            type: 'hyperlink',
            data: {
              id: 'XlSnxRAAACYAsMIs',
              type: 'page',
              tags: ['Core Page'],
              lang: 'en-au',
              slug: 'how-hireup-works',
              first_publication_date: '2020-02-25T05:00:39+0000',
              last_publication_date: '2022-08-26T00:09:56+0000',
              uid: 'how-hireup-works',
              link_type: 'Document',
              isBroken: false,
            },
          },
        ],
      },
    ],
    content_two: [
      {
        type: 'embed',
        oembed: {
          is_plus: '0',
          account_type: 'live_premium',
          width: 640,
          height: 360,
          duration: 40,
          description: '',
          thumbnail_url_with_play_button:
            'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F855565989-75cd3b19a6a3ea2e6a88433e1de010c938ea540e6caef4fff6f97a41e39989d0-d_640&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png',
          upload_date: '2020-02-12 20:10:00',
          video_id: 391121168,
          uri: '/videos/391121168',
          embed_url:
            'https://player.vimeo.com/video/391121168?h=26d2bbab6a&app_id=122963',
          type: 'video',
          version: '1.0',
          title: 'How Hireup works',
          author_name: 'Hireup',
          author_url: 'https://vimeo.com/hireupaustralia',
          provider_name: 'Vimeo',
          provider_url: 'https://vimeo.com/',
          cache_age: null,
          thumbnail_url:
            'https://i.vimeocdn.com/video/855565989-75cd3b19a6a3ea2e6a88433e1de010c938ea540e6caef4fff6f97a41e39989d0-d_640',
          thumbnail_width: 640,
          thumbnail_height: 360,
          html: '<iframe src="https://player.vimeo.com/video/391121168?h=26d2bbab6a&amp;app_id=122963" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="How Hireup works"></iframe>',
        },
      },
    ],
  },
};

export default client;
