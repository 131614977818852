import React from 'react';
import { ReactComponent as Padlock } from '../../assets/svg/icon-padlock.svg';
import { ReactComponent as LocationPin } from '../../assets/svg/icon-location-pin.svg';
import sampleWorkerProfiles from '../../config/sampleWorkerProfiles';
import sampleProfile from '../../assets/img/profile-blurred-sample.jpg';
import Analytics from '../../config/analytics';
import constants from '../../config/constants';
import './profile-cards.scss';

function ProfileCards(props) {
  let { workerProfiles, showPromoCard, selectedPostcode } = props;
  let filteredRealProfiles;

  if (!workerProfiles) {
    return null;
  }

  const handleClick = (event) => {
    const path = window.location.pathname;

    switch (event.target.id) {
      case 'js-profile-cards-cta':
        Analytics.link(
          { label: event.target.href },
          `www.${path}.profileCards.cta`
        );
        break;
      case 'js-promo-card-cta':
        Analytics.link(
          { label: event.target.href },
          `www.${path}.profileCards.promo`
        );
        break;
      default:
        Analytics.link(
          { label: event.target.href },
          `www.${path}.profileCards.profile`
        );
    }
  };

  const promoCard = (
    <li
      className='profile-card-single promo-card'
      data-testid={'promo-card'}
      key='promo-card'>
      <span className='icon'>
        <Padlock aria-hidden='true' />
      </span>
      <p className='promo-title'>
        Sign up or log in to narrow your search further by:
      </p>
      <ul className='promo-list'>
        <li>age and gender</li>
        <li>COVID-19 vaccination status</li>
        <li>languages used</li>
        <li>keyword search, interests and more</li>
      </ul>
      <a
        id='js-promo-card-cta'
        href={`${constants.registrationClient}${
          selectedPostcode ? `?postcode=${selectedPostcode}` : ''
        }`}
        className='button button-primary'
        onClick={handleClick}>
        Sign up
      </a>
    </li>
  );

  // basic truncatation fallback for IE
  const truncateBio = (bio) => {
    if (bio.length > 150) {
      return bio.slice(0, 150) + '...';
    } else {
      return bio;
    }
  };

  const generateProfileCards = (profiles) => {
    return profiles.map((worker) => (
      <li
        className={
          worker.profile.sample
            ? 'profile-card-single -sample'
            : 'profile-card-single'
        }
        aria-hidden={worker.profile.hidden}
        key={worker.profile.id}>
        {worker.profile.sample && (
          <img
            className='ie-fallback-profile'
            src={sampleProfile}
            alt='Hidden profile'
          />
        )}

        <div
          className='profile-card-header'
          aria-hidden={worker.profile.sample}>
          <img
            src={
              worker.profile.pictureUrl + `?rect=0,0,200,200w=200&h=200&q=75`
            }
            alt={worker.profile.firstName + ' headshot'}
            className='avatar'
          />
          <div className='header-info'>
            <p className='name'>
              {worker.profile.hidden ? (
                `${worker.profile.firstName}`
              ) : (
                <a
                  data-testid='registration-link'
                  href={`${constants.registrationClient}${
                    selectedPostcode ? `?postcode=${selectedPostcode}` : ''
                  }`}
                  aria-label={
                    worker.profile.sample
                      ? 'Hidden profile. Sign up to view more support workers'
                      : null
                  }
                  onClick={handleClick}>
                  <span aria-hidden={worker.profile.sample}>
                    {worker.profile.firstName}
                  </span>
                </a>
              )}
            </p>
            <p className='location'>
              <span className='pin'>
                <LocationPin aria-hidden='true' />
              </span>{' '}
              {worker.profile.suburb}, {worker.profile.state}
            </p>
          </div>
        </div>
        <div className='profile-card-body' aria-hidden={worker.profile.sample}>
          <p className='bio'>{truncateBio(worker.profile.bio)}</p>
        </div>
        <div className='sign-up-banner'>
          <span className='badge'>
            <Padlock aria-hidden='true' />
          </span>
          Sign up to view full profile
        </div>
      </li>
    ));
  };

  const getSampleProfiles = () => {
    let sampleProfilesToAdd, length;
    if (showPromoCard) {
      length = filteredRealProfiles.length + 1;
    } else {
      length = filteredRealProfiles.length;
    }

    switch (length) {
      case 1:
      case 4:
      case 7:
        sampleProfilesToAdd = sampleWorkerProfiles.slice(-5);
        break;
      case 2:
      case 5:
      case 8:
        sampleProfilesToAdd = sampleWorkerProfiles.slice(-4);
        break;
      case 3:
      case 6:
      case 9:
        sampleProfilesToAdd = sampleWorkerProfiles.slice(-3);
        break;
      default:
        sampleProfilesToAdd = sampleWorkerProfiles;
    }

    return sampleProfilesToAdd;
  };

  const renderWorkerProfileCards = () => {
    filteredRealProfiles = showPromoCard
      ? workerProfiles.slice(0, 8)
      : workerProfiles.slice(0, 9);
    const realProfileCards = generateProfileCards(filteredRealProfiles);
    const sampleProfileCards = generateProfileCards(getSampleProfiles());

    if (showPromoCard) {
      realProfileCards.splice(2, 0, promoCard);
    }

    return realProfileCards.concat(sampleProfileCards);
  };

  return (
    <>
      <ul className='profile-cards' data-testid='profile-cards'>
        {renderWorkerProfileCards()}
      </ul>
      <div className='profile-cards-cta'>
        <div className='fade'></div>
        <div className='container'>
          <div className='cta-content'>
            <div className='cta-body'>
              <p>
                Sign up now to browse all support worker profiles and find the
                perfect fit for you.
              </p>

              <p>
                <a
                  id='js-profile-cards-cta'
                  className='button'
                  href={`${constants.registrationClient}${
                    selectedPostcode ? `?postcode=${selectedPostcode}` : ''
                  }`}
                  onClick={handleClick}>
                  Sign up
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ProfileCards);
