import React from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import Image from '../../components/image/image';
import ShapeBackgroundDesktop from './shape-background-desktop';
import ShapeBackgroundMobile from './shape-background-mobile';
import { PrismicConfig } from '../../prismic/config';
import { getShapeFill } from '../../helpers/campaignHelpers';

import '../../assets/scss/campaign.scss';
import './campaign-header.scss';

const CampaignHeader = ({ primary }) => {
  return (
    <section className='background-next-section campaign-header theme-blue'>
      <div className='shape-background'>
        <ShapeBackgroundMobile fill={getShapeFill(primary.shape_color)} />
        <div className='shape-background-desktop'>
          <div className='svg-wrapper'>
            <ShapeBackgroundDesktop fill={getShapeFill(primary.shape_color)} />
          </div>
        </div>
      </div>
      <div className='padding-section'>
        <div className='container'>
          <div className='grid-2 section-gutter'>
            <div className='column rich-text'>
              {primary.content
                ? PrismicRichText.render(
                    primary.content,
                    PrismicConfig.linkResolver,
                    PrismicConfig.htmlSerializer
                  )
                : null}
            </div>
            <div className='column foreground-image-column'>
              {primary.header_image ? (
                <div className='image-ratio'>
                  <div className='masked-image img-cover'>
                    <Image primary={primary.header_image} />
                  </div>
                  {primary.badge_image ? (
                    <div className='badge-image img-cover'>
                      <Image primary={primary.badge_image} />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CampaignHeader;
