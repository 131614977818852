import * as React from 'react';

const IconPersonWithCrutch = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M23.25 6.75a5.25 5.25 0 1 0 10.5 0 5.25 5.25 0 0 0-10.5 0v0ZM28.5 33v10.5M34.5 30v-6M16.622 22.52A9 9 0 0 1 25.5 15h6a9 9 0 0 1 9 9v6a3 3 0 0 1-6 0v13.5a3 3 0 0 1-6 0 3 3 0 0 1-6 0v-9M7.5 28.5h12M16.5 28.5V36a3 3 0 0 1-6 0v-7.5M13.5 39v7.5M12 46.5h3' />
      </svg>
    </span>
  );
};

export default IconPersonWithCrutch;
