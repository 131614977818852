import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Page from '../pages/page';
import PreRegistrationPage from '../pages/pre-registration';
import NewsPage from '../pages/news';
import EventPage from '../pages/event';
import FormPage from '../pages/form';
import CareersPage from '../pages/career';
import AuthorPage from '../pages/author';
import ClientLocationPage from '../seo/location-client';
import WorkerLocationPage from '../seo/location-worker';
import SupportPage from '../seo/secondary-support';
import SuburbLocationPage from '../seo/secondary-support-suburb';
import StateLocationPage from '../seo/secondary-support-state';
import IdentificationPage from '../pages/identification';
import Error400 from '../pages/error-400';
import { isValidSupportType } from '../seo/data/supportTypes';
import { isValidState } from '../seo/data/helpers';
import _camelCase from 'lodash.camelcase';
import Analytics from './analytics';
import Preview from '../prismic/preview';
import Utilities from './utilities';
import constants from '../config/constants';
import { isLocalePage } from '../prismic/prismic';

const Routing = () => {
  useEffect(() => {
    Utilities.hello();
    Analytics.initialize();
  }, []);

  const scrollToHashElement = () => {
    const { hash } = window.location;
    const elementToScroll = document.getElementById(hash?.replace('#', ''));
    const elementToScrollPosition = elementToScroll?.getBoundingClientRect();

    if (!elementToScroll) {
      return;
    }

    window.scrollTo({
      top: elementToScrollPosition.top + (window.scrollY - 100),
      behavior: 'smooth',
    });

    // move keyboard focus to anchor link and
    // remove browser default focus outline
    elementToScroll.tabIndex = -1;
    elementToScroll.setAttribute('style', 'outline: none');
    elementToScroll.focus();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    window.addEventListener('load', scrollToHashElement);
    return null;
  };

  const pageView = () => {
    setTimeout(() => Analytics.page(), 1000);
    return null;
  };

  const isSupportPageWithState = (primary, secondary, state) =>
    isValidSupportType(_camelCase(primary), _camelCase(secondary)) &&
    isValidState(state);

  return (
    <BrowserRouter>

        <Route component={scrollToTop} />
        <Route path='*' render={({ location }) => pageView(location)} />
        <Switch>
          <Route
            exact
            path='/'
            component={() => <Page uid={constants.home_uid} />}
          />
          <Route
            exact
            path='/preview'
            component={(routeProps) => <Preview {...routeProps} />}
          />
          <Route
            path='/pre-registration/'
            component={() => <PreRegistrationPage />}
          />
          <Route
            path='/news/:uid'
            component={({ match }) => <NewsPage uid={match.params.uid} />}
          />
          <Route
            path='/event/:uid'
            component={({ match }) => <EventPage uid={match.params.uid} />}
          />
          <Route
            path='/form/:uid'
            component={({ match }) => <FormPage uid={match.params.uid} />}
          />
          <Route
            path='/author/:uid'
            component={({ match }) => <AuthorPage uid={match.params.uid} />}
          />
          <Route
            path={`/${constants.location_clients_path}/:uid`}
            component={({ match }) => (
              <ClientLocationPage uid={match.params.uid} />
            )}
          />
          <Route
            path={`/${constants.location_workers_path}/:uid`}
            component={({ match }) => (
              <WorkerLocationPage uid={match.params.uid} />
            )}
          />
          <Route
            path='/careers/:uid'
            component={({ match }) => <CareersPage uid={match.params.uid} />}
          />
          <Route path='/id/' component={() => <IdentificationPage />} />
          <Route
            path={`/:primary/:secondary/:tertiary/:uid`}
            component={({ match }) => {
              if (
                isSupportPageWithState(
                  match.params.primary,
                  match.params.secondary,
                  match.params.tertiary
                )
              ) {
                return (
                  <SuburbLocationPage
                    uid={`${
                      match.params.uid
                    }-${match.params.tertiary.toLowerCase()}`}
                  />
                );
              } else {
                return <Page uid={match.params.uid} />;
              }
            }}
          />
          <Route
            path={`/:primary/:secondary/:uid`}
            component={({ match }) => {
              if (
                isSupportPageWithState(
                  match.params.primary,
                  match.params.secondary,
                  match.params.uid
                )
              ) {
                return <StateLocationPage />;
              } else {
                return <Page uid={match.params.uid} />;
              }
            }}
          />
          <Route
            path={`/:primary/:secondary`}
            component={({ match }) => {
              if (
                isValidSupportType(
                  _camelCase(match.params.primary),
                  _camelCase(match.params.secondary)
                )
              ) {
                return <SupportPage />;
              } else {
                return (
                  <Page
                    set_locale={
                      isLocalePage(match.params.primary)
                        ? match.params.primary
                        : undefined
                    }
                    uid={match.params.secondary}
                  />
                );
              }
            }}
          />
          <Route
            path='/:uid'
            component={({ match }) => <Page uid={match.params.uid} />}
          />
          <Route
            path='*'
            component={(routeProps) => <Error400 {...routeProps} />}
          />
        </Switch>
    </BrowserRouter>
  );
};

export default Routing;
