import React, { useState, useEffect, useCallback } from 'react';
import Nav from '../../components/nav/nav';
import BannerBar from '../../components/banner-bar/banner-bar';
import { withRouter } from 'react-router-dom';
import { withPrismic } from '../../prismic/prismic';
import { withHireup } from '../../hireup/hireup';
import { useWindowDimensions } from '../../helpers/useWindowDimensions';
import classNames from 'classnames';
import { MdChevronRight } from 'react-icons/md';
import './header.scss';

const Header = ({ prismic, header, translations, locale, location, path }) => {
  const [locationState, setLocationState] = useState('/');
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [scrolledToTop, setScrolledToTop] = useState(true);

  const { width } = useWindowDimensions();
  const isDesktopLayout = width > 768;

  const dismissMenu = useCallback(() => {
    if (isMenuActive) {
      setIsMenuActive(false);
    }
  }, [isMenuActive]);

  if (isDesktopLayout) {
    dismissMenu();
  }

  const handleMenuToggle = (state) => {
    setIsMenuActive(!state);
  };

  const handleScroll = (scrolledToTop) => {
    if (window.pageYOffset !== 0 && scrolledToTop) {
      setScrolledToTop(false);
    } else if (window.pageYOffset === 0 && !scrolledToTop) {
      setScrolledToTop(true);
    }
  };

  useEffect(() => {
    if (prismic && !header) {
      prismic.getHeader();
    }
  }, [header, prismic, locale]);

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll(scrolledToTop));
    return () => {
      window.removeEventListener('scroll', () => handleScroll(scrolledToTop));
    };
  }, [scrolledToTop]);

  useEffect(() => {
    if (locationState !== location?.pathname) {
      dismissMenu();
      setLocationState(location.pathname);
    }
  }, [dismissMenu, location, locationState]);

  const navClasses = () => {
    return classNames({
      'header-primary': true,
      [`-${path}`]: path,
      '-open': isMenuActive,
      '-scroll-top': scrolledToTop,
    });
  };

  const headerClasses = () => {
    return classNames({
      '-mobile-menu-active': isMenuActive,
    });
  };

  const menuClasses = () => {
    return classNames({
      'menu-primary': true,
      '-active': isMenuActive,
    });
  };

  return (
    <header className={headerClasses()}>
      {header && (
        <>
          <a href='#main' className='content-skip'>
            Skip to main content
            <MdChevronRight />
          </a>

          {/* Banner */}
          {header.banners && <BannerBar banners={header.banners} />}

          {/* Menu */}
          <div className={navClasses()}>
            <div className='container'>
              {header.default_navigation && (
                <div>
                  <Nav
                    uid={header.default_navigation.uid}
                    classes={menuClasses()}
                    collapsedSubmenus={true}
                    includeHomepageLogo={true}
                    translationsLink={!!translations}
                    isMenuActive={isMenuActive}
                    isDesktopLayout={isDesktopLayout}
                    handleMenuToggle={handleMenuToggle}
                    ariaLabel='Main navigation'
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </header>
  );
};
export default withRouter(withPrismic(withHireup(Header)));
