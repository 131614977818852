const primarySupport = {
  name: 'Personal care',
  appName: 'Personal care and hygiene',
  image:
    'https://images.prismic.io/hireup/eecbc3d2-959f-4d95-9bda-02d0b138189b_personal-care-hero-optimised.png',
  introBlock: {
    background_color: 'Dark',
    content: [
      {
        type: 'heading2',
        text: 'What is personal care?',
        spans: [],
      },
      {
        type: 'paragraph',
        text: 'Assistance with health care and hygiene falls into our personal care support category, such as showering, bathroom assistance and mealtime support. For NDIS participants, funding for daily life within core supports may fall under personal care. Personal care is one of eight areas of disability support that support workers can provide on Hireup."',
        spans: [],
      },
    ],
    content_two: [
      {
        type: 'paragraph',
        text: '',
        spans: [],
      },
    ],
    layout: '2:1',
  },
};

module.exports = primarySupport;
