import React from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import Image from '../../components/image/image';
import { PrismicConfig } from '../../prismic/config';
import { getThemeClass } from '../../helpers/campaignHelpers';

import '../../assets/scss/campaign.scss';
import './campaign-two-column.scss';

const CampaignTwoColumn = ({ primary }) => {
  const isEmbed = primary.embed?.html;

  return (
    <section
      className={classNames(
        'campaign-two-column padding-section',
        primary.background_color
          ? getThemeClass(primary.background_color)
          : 'theme-blue-60'
      )}>
      <div className='container'>
        <div className='grid-2 section-gutter grid-gap-large'>
          <div className='column content-column padding-section rich-text'>
            {primary.content && primary.content.length > 0 ? (
              <div className='max-width-small rich-text'>
                {PrismicRichText.render(
                  primary.content,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              'column media-column',
              `order-${(primary.alignment || 'right').toLowerCase()}`,
              isEmbed ? 'embed-column' : 'image-column'
            )}>
            {isEmbed ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: primary.embed.html,
                }}
                className='iframe-wrapper iframe-cover'
              />
            ) : (
              <>
                {primary.content_image ? (
                  <Image primary={primary.content_image} />
                ) : null}
                {primary.content_image_mobile ? (
                  <Image primary={primary.content_image_mobile} />
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CampaignTwoColumn;
