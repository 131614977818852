import React, { Component } from 'react';
import { getEnvBaseUrl, API_BASE_URL_V1 } from '../config/api';
import axios from 'axios';
import _get from 'lodash.get';

const defaultState = {
  hireupContext: null,
  terms: null,
  user: null,
};

// react app does not have access to environment
// variables at run time, so we need to get the
// environment url based on the window.location
const ENV_BASE_URL = getEnvBaseUrl();

const api = axios.create({
  baseURL: `${ENV_BASE_URL}${API_BASE_URL_V1}`,
  withCredentials: true,
});

const HireupContext = React.createContext(defaultState);

class HireupProvider extends Component {
  state = defaultState;
  _refs = {};

  componentDidMount = () => {
    this.getAuth()
      .then((response) => {
        let user = _get(response, 'response.data.user');
        this.setState({ user });
      })
      .catch((e) => {
        console.warn('Could not fetch user account', e);
        this.setState({ user: false });
      });
  };

  getAuth = () => {
    return api.get(`/auth/`).then((response) => ({ response }));
  };

  getBookings = (from, to) => {
    return api
      .get(
        `/booking/user/list?sort-by=from&sort-order=asc&to=${to}&from=${from}&page=1&count=100`
      )
      .then((response) => ({ response }));
  };

  getConstants = () => {
    return api.get(`/reference/constants/`).then((response) => ({ response }));
  };

  setUser = (user) => {
    this.setState({ user });
  };

  redirectLoggedInUsers = (url) => {
    console.log(`Redirecting logged in user to ${url}`);
    window.location.replace(url);
  };

  login = ({ email, password }) => {
    return api.post(`/auth/`, { email, password });
  };

  render() {
    const { children } = this.props;
    const context = {
      ...this.state,
      hireup: {
        getAuth: this.getAuth,
        setUser: this.setUser,
        getBookings: this.getBookings,
        redirectLoggedInUsers: this.redirectLoggedInUsers,
        login: this.login,
      },
    };

    return (
      <HireupContext.Provider value={context}>
        {children}
      </HireupContext.Provider>
    );
  }
}

const withHireup = (WrappedComponent) => {
  return function HireupConnectedComponent(props) {
    return (
      <HireupContext.Consumer>
        {(data) => <WrappedComponent {...props} {...data} />}
      </HireupContext.Consumer>
    );
  };
};

export default HireupProvider;
export { withHireup };
