const backgroundKeysToClass = {
  blue: 'theme-blue-60',
  grape: 'theme-grape',
  lavender: 'theme-lavender',
  midnight: 'theme-midnight',
  mint: 'theme-mint',
  mustard: 'theme-mustard',
  sand: 'theme-sand-10',
  white: 'theme-white',
};

export const getThemeClass = (key) => {
  return key && backgroundKeysToClass[key.toLowerCase()]
    ? backgroundKeysToClass[key.toLowerCase()]
    : 'theme-white';
};

const shapeFills = {
  grape: '#a90bb1',
  lavender: '#e7b1ff',
  'light mustard': '#fff1bd',
  mint: '#a3f5da',
  mustard: '#ffc905',
  sand: '#fcf2e8',
  white: '#ffffff',
};

export const getShapeFill = (key) => {
  return key && shapeFills[key.toLowerCase()]
    ? shapeFills[key.toLowerCase()]
    : shapeFills.mustard;
};
