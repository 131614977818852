const primarySupport = require('./primarySupport');

const toileting = {
  supportName: 'Toileting',
  supportIcon: 'toilet',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = toileting;
