import React, { useState, useEffect } from 'react';
import PageWrapper from './wrapper';
import Loading from '../components/loading/loading';
import Slices from '../prismic/slices';
import { withPrismic } from '../prismic/prismic';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../prismic/config';
import ShareLinks from '../components/share-links/share-links';
import Byline from '../components/byline/byline';
import Author from '../components/author/author';
import Separator from '../layouts/separator/separator';
import Sidebar from '../layouts/sidebar/sidebar';
import RelatedArticles from '../layouts/related-articles/related-articles';
import constants from '../config/constants';
import Error400 from './error-400';
import { format } from 'date-fns';
import './news.scss';

function NewsPage({ prismic, prismicContext, author, content, uid, error }) {
  const [state, setState] = useState(null);

  useEffect(() => {
    if (prismicContext && state == null) {
      setState(uid);
      prismic.getNewsPost(uid);
    }
  }, [prismicContext, uid, prismic, state]);

  useEffect(() => {
    if (content && !author) {
      const authorUid = content?.data?.author_page?.uid || '';

      prismic.getAuthor(authorUid);
    }
  }, [prismic, author, content]);

  if (error) {
    return <Error400 path={`news/${uid}`} />;
  }

  if (!content || !content.data) {
    return <Loading full={true} testId='news' />;
  }

  if (!prismicContext) {
    return;
  }
  const publishedDate = content?.data?.displayed_publication_date;
  const isStaticPost = content?.tags?.includes(constants.static_post_tag);
  const hasContent = content?.data?.content.length !== 0;
  const showSidebar = content?.data?.settings_sidebar === 'Visible';

  return (
    <PageWrapper
      banner={content.data.settings_banner}
      context={`page -news -${content.uid}`}
      noTransform={true}
      content={content}
      footer_content={content.data.settings_footer_content}
      footer_cta={content.data.settings_footer_cta}
      full={true}>
      <div className='post-header'>
        <div className='container'>
          <div className='content'>
            {content?.data?.title && (
              <h1 className='heading'>{content.data.title[0].text}</h1>
            )}

            {publishedDate && !isStaticPost && (
              <p className='date'>
                {format(new Date(publishedDate), 'do MMMM yyyy')}
              </p>
            )}
            <div className='byline-container'>
              {author && !isStaticPost && (
                <Byline
                  uid={author?.uid}
                  primary={author?.data}
                  articleType={content?.data?.article_type}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <section className='post-body'>
        <div className='container'>
          {hasContent && (
            <div className='content'>
              {PrismicRichText.render(
                content?.data?.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          )}

          {/* News wrap slice */}
          {content.data.slices && <Slices slices={content.data.slices} />}

          {/* Archived Content */}
          {content.data.body && <Slices slices={content.data.body} />}

          {author && <Author uid={author?.uid} primary={author?.data} />}

          {content.data.title &&
            content.data.settings_sharing === 'Visible' && (
              <div className='post-share'>
                <div className='container'>
                  <div className='content'>
                    <ShareLinks
                      message={content.data.title[0].text}
                      caption='Share Post:'
                    />
                  </div>
                </div>
              </div>
            )}
          {showSidebar && <Sidebar />}
        </div>
      </section>

      <Separator primary={content.data} />

      <RelatedArticles primary={content.data} tags={content.tags} />
    </PageWrapper>
  );
}

export default withPrismic(NewsPage);
