// Glossary for terms
// Language: Easy English

const terms = {
  Abuse: 'Abuse is when someone hurts you, or treats you very badly.',
  Authorities:
    'Authorities are parts of the government that make sure we follow the law in the right way.',
  'Biometric information':
    'Biometric information is information we might need to identify you for security reasons.',
  Complaint:
    'A complaint is when you tell someone about something that has gone wrong, or a problem you want fixed',
  Confidential: 'Information that should not be shared with everyone',
  Consent:
    'When you give your consent, you say it is ok for someone to do something.',
  Encrypting:
    'When you encrypt information, you change it into a code that it is hard for anyone to read.',
  Evidence: 'Evidence is proof that something is true and that it happened.',
  Guardian: 'A guardian is a person who makes decisions for you.',
  'Health professional': 'Someone that takes care of your health',
  Incident:
    'An incident is when something bad happens or  someone gets badly hurt.',
  Insurance:
    'Insurance is a service you pay for to protect you if you are sick, injured or killed, and to protect the things you own.',
  Invoice: 'A list of goods or services and how much they cost',
  Neglect:
    'Neglect is when someone does not help you or care for you in the way they are supposed to.',
  Policy:
    ' A policy is a plan for how we should do things. Policies are where rules come from.',
  Prevent: 'If you prevent something, you stop it from happening.',
  Qualification:
    'If you have a qualification, you have studied or trained, and learned how to do your job the right way.',
  Resolution:
    'A resolution is what we decide we must do to make things right again after an incident.',
  'Sensitive information':
    'Sensitive information is information about you that might be very private or personal.',
  'Service Agreement':
    'A document to help you understand our Service Agreement.',
  'Standards and Procedures':
    'A document that talks about the right way to behave when using Hireup',
  Superannuation:
    'Superannuation is money that is set aside for you to use when you retire.',
  'Zero Tolerance Policy':
    'A Zero Tolerance Policy means abuse and neglect. These are never ok. These must never happen.',
};

module.exports = terms;
