import React from 'react';
import Image from '../image/image';
import logo from '../../assets/img/hireup.avatar.svg';
import Badge from '../badge/badge';
import './byline.scss';

export default function Author({ primary, uid, articleType }) {
  const DEFAULT_ARTICLE_TYPE = 'News';
  const DEFAULT_AUTHOR = {
    title: [
      {
        type: 'heading2',
        text: 'Hireup',
        spans: [],
      },
    ],
    image: {
      dimensions: {
        width: 300,
        height: 300,
      },
      alt: 'Hireup',
      url: logo,
    },
  };

  return (
    <div className='byline container'>
      <div className='byline-content'>
        <Image
          primary={primary?.image || DEFAULT_AUTHOR.image}
          width={96}
          height={96}
          lazyload={true}
        />
        <div>
          <p className='author-name'>
            Written by{' '}
            {primary?.title?.[0]?.text ? (
              <a href={`/author/${uid}`} rel='author'>
                {primary?.title?.[0]?.text}
              </a>
            ) : (
              <span>{DEFAULT_AUTHOR.title?.[0]?.text}</span>
            )}
          </p>
          <Badge type={articleType || DEFAULT_ARTICLE_TYPE} />
        </div>
      </div>
    </div>
  );
}
