import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText, Link } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Image from '../../components/image/image';
import { format } from 'date-fns';
import Analytics from '../../config/analytics';
import _kebabCase from 'lodash.kebabcase';
import './link-list.scss';

export default class LinkList extends Component {
  layoutClasses(primary) {
    let classes = {
      'layout-links': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-count-${this.props.items.length}`]: true,
    };
    return classNames(classes);
  }

  render() {
    const { primary, items } = this.props;

    if (!primary && !items) {
      return null;
    }

    return (
      <section className={this.layoutClasses(primary)}>
        <div className='container'>
          <div className='content'>
            {primary.content &&
              PrismicRichText.render(
                primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
          </div>
        </div>
        <div className='container'>
          <div className='links'>
            {items &&
              items.map((item, i) => (
                <a
                  className='link'
                  href={Link.url(item.link, PrismicConfig.linkResolver)}
                  target='_blank'
                  key={i}
                  rel='noopener noreferrer'
                  onClick={() => Analytics.link(item.link)}>
                  {item.image && <Image primary={item.image} lazyload={true} />}

                  {item.date && (
                    <p className='date'>
                      {format(new Date(item.date), 'do MMMM yyyy')}
                    </p>
                  )}

                  {item.title &&
                    PrismicRichText.render(
                      item.title,
                      PrismicConfig.linkResolver,
                      PrismicConfig.htmlSerializer
                    )}
                </a>
              ))}
          </div>
        </div>
      </section>
    );
  }
}
