import React, { Component } from 'react';
import classNames from 'classnames';
import { withPrismic } from '../../prismic/prismic';
import { MdSearch } from 'react-icons/md';
import glossary from '../../config/glossary';
import Utilities from '../../config/utilities';
import './glossary.scss';
import _orderBy from 'lodash.orderby';
import _kebabCase from 'lodash.kebabcase';

class GlossaryLayout extends Component {
  state = {
    searchTerm: '',
  };

  componentDidMount() {
    const initialSearch = window.location.hash
      ? window.location.hash.replace('#', '')
      : '';
    this.setState({ searchTerm: initialSearch });
  }

  layoutClasses(terms) {
    let classes = {
      'layout-glossary': true,
      '-background-white': true,
    };
    if (terms && terms.length) {
      classes[`-count-${terms.length}`] = terms && terms.length;
    }
    return classNames(classes);
  }

  filterTerms() {
    const { searchTerm } = this.state;
    const locale = Utilities.getCurrentLocale();

    // If not glossary exists for the language, or the label is complex (eg a hyperlink) send no changes
    if (!glossary || !locale.code || !glossary[locale.code]) {
      return {};
    }
    const terms = glossary[locale.code];

    // Create an array of objects from the glossay
    let fullTerms = [];
    Object.keys(terms).forEach((term) => {
      fullTerms.push({
        term: term,
        definition: terms[term],
      });
    });

    if (!searchTerm) {
      return _orderBy(fullTerms, ['rank', 'term']);
    }

    // If there's a search term, filter the glossary terms
    let filteredTerms = [];
    fullTerms.forEach((t) => {
      let isIncluded = false;
      t.rank = 3;
      // Include defnition matches at a lower rank
      if (t.definition.toLowerCase().includes(searchTerm.toLowerCase())) {
        isIncluded = true;
        t.rank = 2;
      }
      // Inlcude direct matches at a higher rank
      if (t.term.toLowerCase().includes(searchTerm.toLowerCase())) {
        isIncluded = true;
        t.rank = 1;
      }
      // Push the result if it should be included
      if (isIncluded) {
        filteredTerms.push(t);
      }
    });

    // Sort the glossary alphabetically
    return _orderBy(filteredTerms, ['rank', 'term']);
  }

  renderTerms = (terms) => {
    let output = [];
    terms.forEach((term, index) => {
      output.push(
        <div className='term' key={index}>
          <dt id={_kebabCase(term.term)}>{term.term}</dt>
          <dd>{term.definition}</dd>
        </div>
      );
    });
    return output;
  };

  onChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  render() {
    const { searchTerm } = this.state;
    const terms = this.filterTerms();

    return (
      <section className={this.layoutClasses(terms)}>
        <div className='container'>
          <div className='content'>
            <div className='search'>
              <div className='form-field'>
                <label htmlFor='search-glossary'>
                  <MdSearch /> Search for a term
                </label>
                <input
                  id='search-glossary'
                  type='text'
                  value={searchTerm}
                  onChange={(e) => {
                    this.onChange(e);
                  }}
                />
              </div>
            </div>

            {(!terms || terms.length === 0) && (
              <p className='empty'>
                There are no terms
                {!searchTerm && <span> to display.</span>}
                {searchTerm && <span> that matched “{searchTerm}”.</span>}
              </p>
            )}

            {terms && <dl className='terms'>{this.renderTerms(terms)}</dl>}
          </div>
        </div>
      </section>
    );
  }
}

export default withPrismic(GlossaryLayout);
