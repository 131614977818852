import React, { Component } from 'react';
import classNames from 'classnames';
import { FastField as FormikFastField, Field as FormikField } from 'formik';
import {
  MdVisibility,
  MdVisibilityOff,
  MdCheckBoxOutlineBlank,
  MdCheckBox,
} from 'react-icons/md';
import { ReactComponent as Warning } from '../../assets/svg/icon-triangle-warning.svg';

import './field.scss';

export default class Field extends Component {
  state = {
    hasMultipleOptions: false,
    passwordInputType: 'password',
    invalid: false,
  };

  constructor(props) {
    super(props);
    this._input = React.createRef();
  }

  componentDidMount() {
    if (this.props.focusOnMount) {
      window.requestAnimationFrame(() => {
        this._input.current.select();
      });
    }
  }

  validate({ value, required }) {
    let error;
    if (required && !value) {
      error = 'This field is required';
    }
    return error;
  }

  setPasswordInputType = (e, type) => {
    e.preventDefault();
    this.setState({ passwordInputType: type });
  };

  fieldClasses(field, meta) {
    return classNames({
      'form-field': true,
      [`-${this.props.type}`]: true,
      '-touched': meta.touched,
      '-invalid': meta.error && meta.touched,
      '-checked': this.props.type === 'checkbox' && field.value === true,
      '-required': this.props.required,
      '-with-help': !!this.props.helpText,
    });
  }

  renderInput(field, meta, id, helpText) {
    const {
      name,
      type,
      value,
      placeholder,
      readOnly,
      required,
      options,
      parentHelpId,
    } = this.props;
    const { passwordInputType } = this.state;
    const describedBy = helpText ? `${name}-help` : parentHelpId || null;
    const isErroring = meta.touched && meta.error ? true : false;

    switch (type) {
      case 'text':
      case 'email':
        return (
          <input
            {...field}
            type={type}
            placeholder={placeholder}
            readOnly={readOnly}
            id={id}
            aria-describedby={describedBy}
            aria-required={required}
            aria-invalid={isErroring}
          />
        );
      case 'password':
        return (
          <div className='input-wrapper'>
            <input
              {...field}
              type={passwordInputType}
              placeholder={placeholder}
              readOnly={readOnly}
              id={id}
              aria-describedby={describedBy}
              aria-required={required}
              aria-invalid={isErroring}
            />
            {passwordInputType === 'password' && (
              <button
                className='password-visibility-toggle'
                onClick={(e) => this.setPasswordInputType(e, 'text')}
                title='Show Password'
                type='button'>
                <MdVisibility />
                <span className='screen-reader-only'>Show Password</span>
              </button>
            )}
            {passwordInputType === 'text' && (
              <button
                className='password-visibility-toggle'
                onClick={(e) => this.setPasswordInputType(e, 'password')}
                title='Hide Password'
                type='button'>
                <MdVisibilityOff />
                <span className='screen-reader-only'>Hide Password</span>
              </button>
            )}
          </div>
        );
      case 'checkbox':
        return (
          <>
            <input
              {...field}
              type={type}
              value={value || false}
              placeholder={placeholder}
              readOnly={readOnly}
              id={id}
              aria-describedby={describedBy}
              aria-required={required}
              aria-invalid={isErroring}
            />
            <MdCheckBoxOutlineBlank className='icon -unchecked' />
            <MdCheckBox className='icon -checked' />
          </>
        );
      case 'radio':
        return (
          <input
            {...field}
            type={type}
            value={value}
            placeholder={placeholder}
            readOnly={readOnly}
            id={id}
            aria-describedby={describedBy}
            aria-required={required}
            aria-invalid={isErroring}
          />
        );
      case 'textarea':
        return (
          <textarea
            {...field}
            placeholder={placeholder}
            readOnly={readOnly}
            id={id}
            aria-describedby={describedBy}
            aria-required={required}
            aria-invalid={isErroring}
          />
        );
      case 'select':
        return (
          <select
            {...field}
            placeholder={placeholder}
            readOnly={readOnly}
            id={id}
            aria-describedby={describedBy}
            aria-required={required}
            aria-invalid={isErroring}>
            {this.renderSelectOptions(options)}
          </select>
        );
      default:
        console.warn('unknown input type', type);
    }
  }

  renderSelectOptions(options) {
    let output = [];
    output.push(
      <option value='' key='0'>
        Select One...
      </option>
    );
    options.forEach((option) => {
      output.push(
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      );
    });
    return output;
  }

  render() {
    const {
      name,
      type,
      label,
      required,
      grouped,
      helpText,
      hideRequiredIndicator,
    } = this.props;
    const isRadioCheckbox = type === 'checkbox' || type === 'radio';
    // Password is the only field type requiring state updates.
    const FormikFieldComponent =
      type === 'password' ? FormikField : FormikFastField;
    const id = this.props.id || name;

    // Render Hidden Fields without labels and help
    if (type === 'hidden') {
      return (
        <FormikFieldComponent
          name={name}
          type={type}
          id={id}></FormikFieldComponent>
      );
    }

    return (
      <FormikFieldComponent
        name={name}
        validate={(value) => this.validate({ value, type, required })}>
        {({ field, meta }) => (
          <div className={this.fieldClasses(field, meta)}>
            {isRadioCheckbox && this.renderInput(field, meta, id, helpText)}
            {label && (
              <label htmlFor={id}>
                <span className='field-label'>
                  {meta.error && meta.touched && !isRadioCheckbox && (
                    <span className='screen-reader-only'>
                      Error: {meta.error}
                    </span>
                  )}
                  {label}
                </span>
                {required && !hideRequiredIndicator && (
                  <span className='field-required' aria-hidden={true}>
                    &#65121;
                  </span>
                )}
              </label>
            )}
            {!isRadioCheckbox && this.renderInput(field, meta, id, helpText)}
            <div className='form-field-aside'>
              {/* Visual error messages, these are already announced in the label for the input, given we already use describedBy for help text */}
              {meta.error && meta.touched && !grouped && (
                <p className='form-field-error' aria-hidden={true}>
                  <Warning /> {meta.error}
                </p>
              )}
              {helpText && (
                <div className='form-field-help' id={`${id}-help`}>
                  {helpText}
                </div>
              )}
            </div>
          </div>
        )}
      </FormikFieldComponent>
    );
  }
}
