import React from 'react';
import { Route, Link } from 'react-router-dom';
import constants from '../../config/constants';
import Utilities from '../../config/utilities';
import { formatSupportName } from '../../seo/data/helpers';
import { isValidSecondarySupport } from '../../seo/data/helpers';
import './breadcrumbs.scss';

export const formatLinkText = (uid) => {
  if (!uid) {
    return null;
  }

  if (constants.defaults.states.includes(uid.toUpperCase())) {
    return uid.toUpperCase();
  }

  if (isValidSecondarySupport(uid)) {
    return formatSupportName(uid);
  }

  return Utilities.toSentenceCase(uid);
};

function Breadcrumbs() {
  const BreadcrumbsItem = ({ match }) => (
    <>
      <li>
        <Link to={match.url || ''} aria-current={match.isExact ? 'page' : null}>
          {formatLinkText(match.params.path)}
        </Link>
      </li>
      <Route path={`${match.url}/:path`} component={BreadcrumbsItem} />
    </>
  );

  return (
    <div className='breadcrumbs-wrapper container'>
      <nav aria-label='Breadcrumb' className='breadcrumbs'>
        <ol className='content'>
          <li>
            <Link to={'/'}>Home</Link>
          </li>
          <Route path='/:path' component={BreadcrumbsItem} />
        </ol>
      </nav>
    </div>
  );
}

export default Breadcrumbs;
