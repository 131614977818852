import React, { useEffect } from 'react';
import PageWrapper from '../pages/wrapper';
import Loading from '../components/loading/loading';
import { withPrismic } from '../prismic/prismic';
import Error400 from '../pages/error-400';
import headerImageFallback from '../assets/img/hireup-support-workers-and-ndis-participants.png';
import constants from '../config/constants';
import PlatformJobs from '../layouts/platform-jobs/platform-jobs';
import supportWorker from './data/supportWorker';
import TwoColumnLayout from '../layouts/two-column/two-column';
import StepList from '../layouts/step-list/step-list';
import Subpage from '../pages/subpage';
import './location-shared.scss';

const LocationPage = ({ prismic, prismicContext, uid, error, content }) => {
  useEffect(() => {
    if (prismicContext && !content) {
      prismic.getLocation(uid);
    }
  }, [content, prismic, prismicContext, uid]);

  if (error) {
    return <Error400 path={`location/${uid}`} />;
  }

  if (!content || !content.data) {
    return <Loading full={true} testId='location-worker' />;
  }

  const suburb = content?.data?.title[0]?.text;
  const state = content?.data?.location_state;
  const title = `Disability support worker and carer jobs in ${suburb}, ${state}`;
  const intro = `Join Hireup's fast-growing network of support workers. Find local job opportunities in ${suburb} and join a network of like-minded support workers.`;
  const { image } = content.data;
  const locationImage = image.url
    ? `${image.url}?h=650&q=75`
    : `${headerImageFallback}`;

  const meta = {
    title: `NDIS Support Worker & Carer Jobs in ${suburb}, ${state}`,
    description: `Find disability support worker jobs in ${suburb}, ${state}  through Hireup, an NDIS registered platform which links you with clients in need of care or support.`,
  };

  return (
    <PageWrapper
      title={meta.title}
      description={meta.description}
      context={`page -location -worker -${content.uid}`}
      content={content}
      footer_content={'Hidden'}
      full={true}>
      <div className='location-header -with-background'>
        <div className='container'>
          <div className='header-grid'>
            <div className='header-content'>
              {title && <h1 className='heading'>{title}</h1>}
              {intro && <p>{intro}</p>}
              <div className='header-cta'>
                <a href={constants.registrationWorker} className='button'>
                  Become a Support Worker
                </a>
              </div>
              <p className='fineprint'>
                Or <a href='/become-a-client'>find support on Hireup</a>
              </p>
            </div>
            <div className='header-image-wrapper'>
              <img className='header-image' src={locationImage} alt={title} />
            </div>
          </div>
        </div>
      </div>

      <PlatformJobs />

      <div className='flexible-work'>
        <TwoColumnLayout primary={supportWorker.twoColumnLayout} />
        <StepList />
      </div>

      {/* Add in the default landing page layout */}
      <Subpage uid={constants.landing_uid} />
    </PageWrapper>
  );
};

export default withPrismic(LocationPage);
