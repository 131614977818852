import * as React from 'react';

const IconShaver = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M34.958 16.5H13.042a3 3 0 0 1-2.96-2.5L9 7.5h30L37.918 14a3 3 0 0 1-2.96 2.5ZM9 7.5v-6M15 10.502v-9M21 10.502v-9M27 10.502v-9M33 10.502v-9M39 1.5v6M15 31.5h18M22.5 37.5h3' />
        <path d='M33 37.5a9 9 0 1 1-18 0V21l3-4.5h12l3 4.5v16.5Z' />
        <path d='M24 24.75a.75.75 0 0 1 0-1.5M24 24.75a.75.75 0 0 0 0-1.5' />
      </svg>
    </span>
  );
};

export default IconShaver;
