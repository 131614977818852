import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { withPrismic } from '../../prismic/prismic';
import NewsPost from '../../components/news/news';
import Loading from '../../components/loading/loading';
import TrackVisibility from 'react-on-screen';
import constants from '../../config/constants';
import _kebabCase from 'lodash.kebabcase';
import './news.scss';

class NewsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      posts: null,
      currentPage: 1, // TODO: Load more pages in a slider or infinite scroll
      nextPage: 2,
    };
  }

  componentDidMount() {
    const { primary } = this.props;
    const { currentPage } = this.state;

    const pageSize = primary?.results_per_page || 3;
    if (primary?.static_post_first) {
      this.loadPosts(1, 1, constants.static_post_tag);
      this.loadPosts(pageSize - 1, currentPage, primary?.filter_category);
    } else {
      this.loadPosts(
        pageSize,
        currentPage,
        primary?.filter_category,
        primary?.author
      );
    }
  }

  layoutClasses(primary, posts) {
    let classes = {
      'layout-posts': true,
      [`-background-${_kebabCase(primary?.background_color)}`]:
        primary?.background_color,
    };
    if (posts && posts.length) {
      classes[`-count-${posts.length}`] = posts && posts.length;
    }
    return classNames(classes);
  }

  async loadPosts(
    pageSize = 3,
    page = 1,
    filterCategory = false,
    author = false
  ) {
    this.setState({ loading: true });
    let posts = await this.props.prismic.getNews(
      pageSize,
      page,
      filterCategory,
      author
    );
    // Add the incoming posts to the current state
    if (this.state.posts) {
      posts.results = this.state.posts.results.concat(posts.results);
    }
    this.setState({ posts });
    this.setState({ loading: false });
  }

  loadMoreInfinite(isVisible) {
    const { posts } = this.state;
    if (isVisible && !this.state.loading && posts.page < posts.total_pages) {
      this.loadPosts(
        this.props.primary?.results_per_page || 3,
        posts.page + 1,
        this.props.primary?.filter_category
      );
    }
  }

  loadMoreButton() {
    const { posts } = this.state;
    if (!this.state.loading && posts.page < posts.total_pages) {
      this.loadPosts(
        this.props.primary?.results_per_page || 3,
        posts.page + 1,
        this.props.primary?.filter_category
      );
    }
  }

  renderPosts() {
    const { posts } = this.state;
    return (
      posts.results &&
      posts.results.map((post, i) => <NewsPost primary={post} key={i} />)
    );
  }

  render() {
    const { primary } = this.props;
    const { posts } = this.state;

    const hasTitle = primary?.title[0]?.text !== '';
    const hasContent = primary?.content[0]?.text !== '';

    if (!posts || posts.results_size === 0) {
      return null;
    }

    return (
      <section className={this.layoutClasses(primary, posts)}>
        {(hasTitle || hasContent) && (
          <div className='container'>
            <div className='content'>
              {hasTitle &&
                PrismicRichText.render(
                  primary?.title,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
              {hasContent &&
                PrismicRichText.render(
                  primary?.content,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
            </div>
          </div>
        )}

        <div className='container'>
          <div className='posts'>{this.renderPosts()}</div>

          {this.state.loading && <Loading />}

          {!this.state.loading && primary?.load_more === 'Infinite scroll' && (
            <div>
              {posts.page !== posts.total_pages && (
                <TrackVisibility className='load-more'>
                  {({ isVisible }) => this.loadMoreInfinite(isVisible)}
                </TrackVisibility>
              )}

              {posts.page === posts.total_pages && (
                <div className='posts-loaded'></div>
              )}
            </div>
          )}

          {!this.state.loading &&
            primary?.load_more === 'Button' &&
            posts.page !== posts.total_pages && (
              <div className='load-more-button'>
                <button
                  onClick={this.loadMoreButton.bind(this)}
                  className='button -primary'>
                  Load more
                </button>
              </div>
            )}
        </div>
      </section>
    );
  }
}

export default withPrismic(NewsLayout);
