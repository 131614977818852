import React, { Component } from 'react';
import PageWrapper from './wrapper';
import Loading from '../components/loading/loading';
import { withPrismic } from '../prismic/prismic';
import axios from 'axios';
import constants from '../config/constants';
import Subpage from './subpage';
import ShareLinks from '../components/share-links/share-links';
import { formatDistanceToNow } from 'date-fns';
import { MdLocationOn, MdChevronLeft } from 'react-icons/md';
import { IoMdBriefcase } from 'react-icons/io';
import _get from 'lodash.get';
import './career.scss';

class CareerPage extends Component {
  state = {
    loading: true,
    error: false,
    careers: null,
    career: null,
  };

  async componentDidMount() {
    await this.loadCareers();
  }

  loadCareers = async () => {
    const { uid } = this.props;

    // Get all careers from the Jobadder XML file
    await axios
      .get(`https://jobs.hireup.link/xml/`)
      .then((response) => {
        if (response.data) {
          let careers = response.data;
          this.setState({ careers });
          // Find the career based on the uid
          if (careers) {
            let foundCareer = null;
            // Only cycle through all jobs if careers.Job is an array
            if (careers.Job && careers.Job.constructor === Array) {
              careers.Job.forEach((career) => {
                if (career['@attributes'].jid === uid) {
                  foundCareer = career;
                }
              });
            }
            if (foundCareer) {
              this.setState({ career: foundCareer });
            } else if (_get(careers, 'Job.@attributes.jid') === uid) {
              // Cater for only one-career being placed into careers.Job as an object, not an array item
              this.setState({ career: careers.Job });
            } else {
              this.setState({ error: true });
            }
          }
        } else {
          this.setState({ error: 'true' });
        }
      })
      .catch((error) => {
        console.warn('Careers.loadCareers() error', error);
        this.setState({ error: 'true' });
      });
    this.setState({ loading: false });
  };

  determineClassificationValue(name) {
    const { careers, career } = this.state;
    let values = [],
      classifications = [];

    // Find the available values for this classification (eg the values for 'Location')
    careers.Fields &&
      careers.Fields.Field.forEach((field) => {
        if (field['@attributes'].name === name) {
          values = field.Values.Value;
        }
      });

    // Find all classifications for type 'name' for the supplied career
    career.Classifications &&
      career.Classifications.Classification.forEach((classification) => {
        values.forEach((value) => {
          if (value['@attributes'].name === classification) {
            classifications.push(classification);
            // For the moment, just return the one matching categorisation
            return classification;
          }
        });
      });
    // TODO: If multiple categorisations exist on careers, return them all and render them differently
    return classifications;
  }

  renderPoints(BulletPoints) {
    let output = [];
    if (BulletPoints && BulletPoints.BulletPoint) {
      BulletPoints.BulletPoint &&
        BulletPoints.BulletPoint.forEach((point, key) => {
          output.push(
            <li className='join-point' key={key}>
              {point}
            </li>
          );
        });
    }
    return output;
  }

  render() {
    const { prismicContext } = this.props;
    const { loading, error } = this.state;

    // Wait for Prismic to initialize
    if (!prismicContext) {
      return <Loading full={true} testId='career' />;
    }

    if (loading) {
      return <Loading />;
    }

    if (error) {
      let content = {
        data: {
          title: [{ text: 'Career Not Found' }],
          description: [
            { text: 'We could not find the role you were looking for.' },
          ],
        },
      };
      return (
        <PageWrapper context={`page -career`} content={content} full={true}>
          <section className='career-listing'>
            <div className='container'>
              <div className='career-content -error'>
                <a
                  className='navigation-back'
                  href={`/${constants.careers_uid}/`}>
                  <MdChevronLeft /> All Roles
                </a>

                <h1 className='heading'>We couldn’t find that role</h1>
                <p>Try finding the role again on our careers page:</p>
                <a href={`/${constants.careers_uid}/`}>Careers Page</a>
              </div>
            </div>
          </section>
        </PageWrapper>
      );
    }

    // If not loading or error, assign career variables
    const { career } = this.state;

    let postedDate = career['@attributes']?.datePosted + `T00:00:00`,
      location = this.determineClassificationValue('Location'),
      workType = this.determineClassificationValue('Work Type'),
      content = {
        data: {
          title: [{ text: career.Title }],
          description: [{ text: career.Summary }],
        },
      };

    return (
      <PageWrapper context={`page -career`} content={content} full={true}>
        <section className='career-listing'>
          <div className='container'>
            <div className='career-content'>
              <a
                className='navigation-back'
                href={`/${constants.careers_uid}/`}>
                <MdChevronLeft /> All Roles
              </a>

              {career.Title && <h1 className='heading'>{career.Title}</h1>}

              <p className='meta'>
                {location && (
                  <span className='classification'>
                    <MdLocationOn /> {location}
                  </span>
                )}
                {workType && (
                  <span className='classification'>
                    <IoMdBriefcase /> {workType}
                  </span>
                )}
              </p>

              {postedDate && (
                <p className='meta'>
                  Posted{' '}
                  {formatDistanceToNow(new Date(postedDate), {
                    addSuffix: true,
                  })}
                </p>
              )}

              <p className='summary'>{career.Summary}</p>

              {career.BulletPoints && (
                <ul className='career-bullets'>
                  {this.renderPoints(career.BulletPoints)}
                </ul>
              )}

              {career.Apply && career.Apply.Url && (
                <div className='career-apply'>
                  <a
                    className='button -primary'
                    href={career.Apply.Url}
                    target='_blank'
                    rel='noopener noreferrer'>
                    Apply Today
                  </a>
                </div>
              )}

              {career.Description && (
                <>
                  <div className='description'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: career.Description,
                      }}></div>
                  </div>

                  {career.Apply && career.Apply.Url && (
                    <div className='career-apply'>
                      <a
                        className='button -primary'
                        href={career.Apply.Url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        Apply Today
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </section>

        {/* Share Links */}
        <div className='career-share'>
          <div className='container'>
            <div className='content'>
              <ShareLinks
                message={`${career.Title} at Hireup`}
                caption='Share This Role:'
              />
            </div>
          </div>
        </div>

        {/* Insert the other careers */}

        <div className='career-subpage'>
          <Subpage uid={constants.careers_subpage_uid} />
        </div>
      </PageWrapper>
    );
  }
}

export default withPrismic(CareerPage);
