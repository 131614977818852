import React, { useState, useEffect } from 'react';
import Slices from '../prismic/slices';
import * as prismic from '@prismicio/client';

const SubPage = ({ uid }) => {
  const [state, setState] = useState(null);
  const client = prismic.createClient('hireup');

  useEffect(() => {
    const getSubpage = async (uid) => {
      await client
        .getByUID('page', uid)
        .then((response) => {
          setState(response.data.body);
        })
        .catch((e) => {
          console.error(`Subpage not returned: ${e}`);
        });
    };

    if (!state) {
      getSubpage(uid);
    }
  }, [client, state, uid]);

  if (!uid || !state) {
    return null;
  }

  return <Slices slices={state} subpage={true} />;
};

export default SubPage;
