import * as React from 'react';

const IconNutrition = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M34.5 30a1.5 1.5 0 0 0-1.5-1.5h-4.5V24a1.5 1.5 0 0 0-1.5-1.5h-6a1.5 1.5 0 0 0-1.5 1.5v4.5H15a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h4.5V42a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5v-4.5H33a1.5 1.5 0 0 0 1.5-1.5v-6ZM40.5 1.5v45M46.5 1.5V15a6.018 6.018 0 0 1-6 6 6.018 6.018 0 0 1-6-6V1.5M7.5 21v25.5M7.5 21a6.018 6.018 0 0 1-6-6V7.5a6.018 6.018 0 0 1 6-6v0a6.018 6.018 0 0 1 6 6V15a6.018 6.018 0 0 1-6 6Z' />
      </svg>
    </span>
  );
};

export default IconNutrition;
