import React from 'react';

const ShapeBackground = ({ fill }) => {
  return (
    <svg
      width='1800'
      height='1340'
      viewBox='0 0 1800 1340'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0 9.78076L1590.02 106.72C1620.68 108.589 1644.73 135.466 1642.86 166.127C1601.72 850.263 1013.53 1370.33 329.705 1328.63L0 1308.53V9.78076Z'
        fill={fill || '#A3F5DA'}
      />
    </svg>
  );
};

export default ShapeBackground;
