import utilities from './utilities';

const SEGMENT_KEYS = {
  local: 'C227SGb7CQmHpk0dZGSl37OTkHajHFSr',
  dev: '0TeNrr1ep0gs2SQtBpg8bg9MkdwZyKh1',
  staging: 'w524sRYcTg86cgX6Ov0seZ7uh6Cz8xBd',
  prod: 'uFv0nn9sQNwleCCUmWOsKgrs0Q8jM9u3',
};

export const getSegmentAPIKey = () => {
  if (utilities.isProduction()) {
    return SEGMENT_KEYS.prod;
  }
  if (utilities.isLocal() || utilities.isLocalDevelopment()) {
    return SEGMENT_KEYS.local;
  }
  return SEGMENT_KEYS.staging;
};
