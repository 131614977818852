import * as React from 'react';

const IconSwimming = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M46.5 44.89c-4.902 1.02-7.944-3.872-7.944-3.872a8.614 8.614 0 0 1-7.084 4.022 7.48 7.48 0 0 1-6.484-4.022v0a10.336 10.336 0 0 1-7.69 4.022 9.013 9.013 0 0 1-7.074-4.022v0S6.296 46 1.5 44.97M46.5 35.89c-4.902 1.02-7.944-3.872-7.944-3.872a8.614 8.614 0 0 1-7.084 4.022 7.48 7.48 0 0 1-6.484-4.022 10.336 10.336 0 0 1-7.69 4.022 9.013 9.013 0 0 1-7.074-4.022v0S6.296 37 1.5 35.97M39 22.39a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5ZM14.758 28l7.662-4.8-5.62-6.412a6 6 0 0 1 1.832-9.318l8.526-4.27a3 3 0 1 1 2.684 5.366l-6.8 3.4a1.504 1.504 0 0 0-.442 2.332l10.754 12.29' />
      </svg>
    </span>
  );
};

export default IconSwimming;
