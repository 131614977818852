import React from 'react';
import { ReactComponent as Clipboard } from '../../assets/svg/icon-clipboard.svg';
import { ReactComponent as Backpack } from '../../assets/svg/icon-backpack.svg';
import { ReactComponent as SprayBottle } from '../../assets/svg/icon-spray-bottle.svg';
import { ReactComponent as GraduationHat } from '../../assets/svg/icon-graduation-hat.svg';
import { ReactComponent as Certificate } from '../../assets/svg/icon-certificate.svg';
import { ReactComponent as Teapot } from '../../assets/svg/icon-teapot.svg';
import { ReactComponent as Bath } from '../../assets/svg/icon-bath.svg';
import { ReactComponent as Car } from '../../assets/svg/icon-car.svg';
import Analytics from '../../config/analytics';
import './support-cards.scss';

export default function SupportCards({ data }) {
  const handleClick = (event) => {
    const path = window.location.pathname;

    Analytics.link(
      { label: event.target.href },
      `www.${path}.supportCards.card`,
      true
    );
  };

  const defaultCards = {
    'Help around the house': {
      icon: <SprayBottle />,
      href: '/help-around-the-house/',
    },
    'In-home care': {
      icon: <Teapot />,
      href: '/in-home-care/',
    },
    'Personal care': {
      icon: <Bath />,
      href: '/personal-care/',
    },
    'Specialised care': {
      icon: <Certificate />,
      href: '/specialised-complex/',
    },
    Transport: {
      icon: <Car />,
      href: '/transport/',
    },
    'Out and about': {
      icon: <Backpack />,
      href: '/out-and-about/',
    },
    'Therapy support': {
      icon: <Clipboard />,
      href: '/therapy-support/',
    },
    'Education and employment': {
      icon: <GraduationHat />,
      href: '/education-and-employment/',
    },
  };

  const renderSupportCards = () => {
    const supportCards = data || defaultCards;

    return Object.entries(supportCards).map(([card]) => (
      <li className='support-card-single' key={card}>
        <a
          href={supportCards[card].href}
          onClick={handleClick}
          className='support-card-body'>
          <span className='support-card-icon'>{supportCards[card].icon}</span>
          <span className='support-card-title'>{card}</span>
        </a>
      </li>
    ));
  };

  return (
    <section className='layout-support-cards'>
      <div className='container'>
        <div className='content'>
          <h3>Types of support available</h3>
          <ul className='support-cards'>{renderSupportCards()}</ul>
        </div>
      </div>
    </section>
  );
}
