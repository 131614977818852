import * as React from 'react';

const IconRefresh = (props) => {
  return (
    <span className='icon'>
      <svg {...props} fill='none' viewBox='0 0 14 16'>
        <path
          d='M13 7.25a.75.75 0 0 1 .75.75A6.75 6.75 0 0 1 7 14.75a6.873 6.873 0 0 1-4.5-1.682v1.682a.75.75 0 0 1-1.5 0v-3.762l.003-.051L1 11a.755.755 0 0 1 .05-.268l.007-.018a.592.592 0 0 1 .02-.044l.007-.014a.738.738 0 0 1 .154-.204l.005-.005a.77.77 0 0 1 .124-.092l.008-.004.014-.009a.856.856 0 0 1 .031-.016l.019-.008-.064.033a.769.769 0 0 1 .288-.096l.023-.002a.753.753 0 0 1 .047-.003H5.5a.75.75 0 0 1 0 1.5H3.282A5.371 5.371 0 0 0 7 13.25c2.9 0 5.25-2.35 5.25-5.25a.75.75 0 0 1 .75-.75ZM12.25.5a.75.75 0 0 1 .75.75v3.775l-.002.026L13 5a.761.761 0 0 1-.05.27l-.011.027a.6.6 0 0 1-.022.047l-.005.009-.009.016a.741.741 0 0 1-.102.14l-.02.021a.722.722 0 0 1-.162.123l.069-.044-.036.024a.82.82 0 0 1-.02.013l-.013.007a.851.851 0 0 1-.065.033l-.013.005-.009.004-.029.011a.756.756 0 0 1-.022.008l-.021.006-.018.005a.748.748 0 0 1-.167.025H8.5a.75.75 0 1 1 0-1.5h2.236A5.322 5.322 0 0 0 7 2.75 5.25 5.25 0 0 0 1.75 8a.75.75 0 1 1-1.5 0A6.75 6.75 0 0 1 7 1.25c1.7 0 3.29.61 4.5 1.664V1.25a.75.75 0 0 1 .75-.75Z'
          fill='currentColor'
        />
      </svg>
    </span>
  );
};

export default IconRefresh;
