import React, { Component } from 'react';
import { CookiesProvider } from 'react-cookie';
import Routing from './config/routing';
import Prismic from './prismic/prismic';
import Hireup from './hireup/hireup';

class App extends Component {
  render() {
    return (
      <CookiesProvider>
        <Prismic>
          <Hireup>
            <Routing />
          </Hireup>
        </Prismic>
      </CookiesProvider>
    );
  }
}

export default App;
