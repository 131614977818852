import * as React from 'react';

const IconMentalHealth = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)'>
          <path d='M9.535 47.27V34.74A18.73 18.73 0 0 1 22.283 2.27c14.278 0 17.36 11.756 22.45 24.96a1.503 1.503 0 0 1-1.4 2.04h-3.798v6a6 6 0 0 1-6 6h-3v6' />
          <path d='M33.535 18.77a1.5 1.5 0 0 0-1.5-1.5h-4.5v-4.5a1.5 1.5 0 0 0-1.5-1.5h-6a1.5 1.5 0 0 0-1.5 1.5v4.5h-4.502a1.5 1.5 0 0 0-1.498 1.5v6a1.5 1.5 0 0 0 1.5 1.5h4.5v4.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5v-4.5h4.5a1.5 1.5 0 0 0 1.5-1.5v-6Z' />
        </g>
        <defs>
          <clipPath id='a'>
            <path ransform='translate(.335 .77)' d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconMentalHealth;
