import * as React from 'react';

const IconToilet = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)' strokeLinecap='round'>
          <path d='M31.5 25.506h9a3 3 0 0 0 3-3v-18a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v21ZM7.5 19.506a3 3 0 0 0 0 6h24v-6h-24ZM36.4 31.2a6 6 0 0 0 4.094-5.69H7.5a16.5 16.5 0 0 0 9 14.7l-1.5 6.3h19.5V34.07a2.98 2.98 0 0 1 1.9-2.87v0Z' />
        </g>
        <defs>
          <clipPath id='a'>
            <path fill='#fff' d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconToilet;
