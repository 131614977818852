import * as React from 'react';

const IconUserSearch = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)'>
          <path d='M21 31.5a10.5 10.5 0 1 0 21 0 10.5 10.5 0 0 0-21 0v0ZM46.5 46.5l-7.576-7.576M20.044 19.884A12.153 12.153 0 0 0 13.5 18a12 12 0 0 0-12 10.5M6.75 8.25a6.75 6.75 0 1 0 13.5 0 6.75 6.75 0 0 0-13.5 0v0ZM27.75 6.75a5.25 5.25 0 1 0 10.5 0 5.25 5.25 0 0 0-10.5 0v0ZM40.8 18a9 9 0 0 0-14.568-1.42' />
        </g>
        <defs>
          <clipPath id='a'>
            <path d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconUserSearch;
