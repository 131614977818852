import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import _kebabCase from 'lodash.kebabcase';
import check from '../../assets/img/illustration.check.png';
import cross from '../../assets/img/illustration.cross.png';
import shield from '../../assets/img/illustration.shield.png';
import check_white from '../../assets/img/illustration.check.white.png';
import cross_white from '../../assets/img/illustration.cross.white.png';
import shield_white from '../../assets/img/illustration.shield.white.png';
import './checklist.scss';

const illustrations = { check, cross, shield };
const inverse_illustrations = {
  check: check_white,
  cross: cross_white,
  shield: shield_white,
};

export default class Checklist extends Component {
  layoutClasses(primary) {
    let classes = {
      'layout-checklist': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
    };
    return classNames(classes);
  }

  itemClasses(item) {
    let classes = {
      'checklist-item': true,
      [`-type-${_kebabCase(item.type)}`]: this.forceUpdate,
    };
    return classNames(classes);
  }

  layoutBackground(primary) {
    return {
      backgroundImage:
        primary.background_image && primary.background_image.url
          ? `url(${primary.background_image.url})`
          : null,
    };
  }

  render() {
    const { primary, items } = this.props;

    if (!primary || !items) {
      return null;
    }

    return (
      <section
        className={this.layoutClasses(primary)}
        style={this.layoutBackground(primary)}>
        <div className='container'>
          <div className='content'>
            {primary &&
              PrismicRichText.render(
                primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            {items && (
              <ul>
                {items.map((item, i) => (
                  <li className={this.itemClasses(item)} key={i}>
                    {item.type && illustrations[_kebabCase(item.type)] && (
                      <img
                        className='illustration'
                        loading='lazy'
                        src={illustrations[_kebabCase(item.type)]}
                        alt={_kebabCase(item.type)}
                      />
                    )}
                    {item.type &&
                      inverse_illustrations[_kebabCase(item.type)] && (
                        <img
                          className='illustration -inverse'
                          loading='lazy'
                          src={inverse_illustrations[_kebabCase(item.type)]}
                          alt={_kebabCase(item.type)}
                        />
                      )}
                    {item.text && (
                      <div>
                        {PrismicRichText.render(
                          item.text,
                          PrismicConfig.linkResolver,
                          PrismicConfig.htmlSerializer
                        )}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </section>
    );
  }
}
