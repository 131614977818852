import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText, Link } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Analytics from '../../config/analytics';
import _kebabCase from 'lodash.kebabcase';
import './downloads.scss';

export default class Downloads extends Component {
  layoutClasses(primary) {
    let classes = {
      'layout-downloads': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-count-${this.props.items.length}`]: true,
    };
    return classNames(classes);
  }

  render() {
    const { primary, items } = this.props;

    if (!primary && !items) {
      return null;
    }

    return (
      <section className={this.layoutClasses(primary)}>
        <div className='container'>
          <div className='content'>
            {primary.content &&
              PrismicRichText.render(
                primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
          </div>
        </div>
        <div className='container'>
          <div className='downloads'>
            {items &&
              items.map((item, i) => (
                <a
                  className='download'
                  href={Link.url(item.link, PrismicConfig.linkResolver)}
                  target='_blank'
                  key={i}
                  rel='noopener noreferrer'
                  onClick={() => Analytics.link(item.link)}>
                  {item.title &&
                    PrismicRichText.render(
                      item.title,
                      PrismicConfig.linkResolver,
                      PrismicConfig.htmlSerializer
                    )}
                </a>
              ))}
          </div>
        </div>
      </section>
    );
  }
}
