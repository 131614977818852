import React from 'react';
import { IconBathtub } from '../../components/icons';
import { IconBed } from '../../components/icons';
import { IconBookPointing } from '../../components/icons';
import { IconBowel } from '../../components/icons';
import { IconBowelComplex } from '../../components/icons';
import { IconCaringHands } from '../../components/icons';
import { IconComputerBook } from '../../components/icons';
import { IconDogWalking } from '../../components/icons';
import { IconDumbell } from '../../components/icons';
import { IconEdit } from '../../components/icons';
import { IconHairDryer } from '../../components/icons';
import { IconHandBrain } from '../../components/icons';
import { IconHandBook } from '../../components/icons';
import { IconHoldingHands } from '../../components/icons';
import { IconHomework } from '../../components/icons';
import { IconIroning } from '../../components/icons';
import { IconIvBag } from '../../components/icons';
import { IconKidney } from '../../components/icons';
import { IconMassage } from '../../components/icons';
import { IconMeal } from '../../components/icons';
import { IconMedicationHealth } from '../../components/icons';
import { IconMentalHealth } from '../../components/icons';
import { IconMind } from '../../components/icons';
import { IconMusic } from '../../components/icons';
import { IconNailPolish } from '../../components/icons';
import { IconNutrition } from '../../components/icons';
import { IconPersonWithCrutch } from '../../components/icons';
import { IconPersonWithIv } from '../../components/icons';
import { IconShaver } from '../../components/icons';
import { IconShower } from '../../components/icons';
import { IconSpeechBubbles } from '../../components/icons';
import { IconSwimming } from '../../components/icons';
import { IconToilet } from '../../components/icons';
import { IconUserSearch } from '../../components/icons';
import { IconWheelchairComplex } from '../../components/icons';

export const renderIcon = (type, size = 48, hidden = true) => {
  const props = {
    height: size,
    width: size,
    'aria-hidden': hidden,
    'data-testid': type,
  };

  switch (type) {
    case 'bath':
      return <IconBathtub {...props} />;
    case 'bed':
      return <IconBed {...props} />;
    case 'bookPointing':
      return <IconBookPointing {...props} />;
    case 'bowel':
      return <IconBowel {...props} />;
    case 'bowelComplex':
      return <IconBowelComplex {...props} />;
    case 'caringHands':
      return <IconCaringHands {...props} />;
    case 'computerBook':
      return <IconComputerBook {...props} />;
    case 'dogWalking':
      return <IconDogWalking {...props} />;
    case 'dumbell':
      return <IconDumbell {...props} />;
    case 'edit':
      return <IconEdit {...props} />;
    case 'hairDryer':
      return <IconHairDryer {...props} />;
    case 'handBrain':
      return <IconHandBrain {...props} />;
    case 'handBook':
      return <IconHandBook {...props} />;
    case 'holdingHands':
      return <IconHoldingHands {...props} />;
    case 'homework':
      return <IconHomework {...props} />;
    case 'ironing':
      return <IconIroning {...props} />;
    case 'ivBag':
      return <IconIvBag {...props} />;
    case 'kidney':
      return <IconKidney {...props} />;
    case 'massage':
      return <IconMassage {...props} />;
    case 'meal':
      return <IconMeal {...props} />;
    case 'medicationHealth':
      return <IconMedicationHealth {...props} />;
    case 'mentalHealth':
      return <IconMentalHealth {...props} />;
    case 'mind':
      return <IconMind {...props} />;
    case 'music':
      return <IconMusic {...props} />;
    case 'nailPolish':
      return <IconNailPolish {...props} />;
    case 'nutrition':
      return <IconNutrition {...props} />;
    case 'personWithCrutch':
      return <IconPersonWithCrutch {...props} />;
    case 'personWithIv':
      return <IconPersonWithIv {...props} />;
    case 'shaver':
      return <IconShaver {...props} />;
    case 'shower':
      return <IconShower {...props} />;
    case 'speechBubbles':
      return <IconSpeechBubbles {...props} />;
    case 'swimming':
      return <IconSwimming {...props} />;
    case 'toilet':
      return <IconToilet {...props} />;
    case 'userSearch':
      return <IconUserSearch {...props} />;
    case 'wheelchairComplex':
      return <IconWheelchairComplex {...props} />;
    default:
      return undefined;
  }
};
