import React from 'react';
import classNames from 'classnames';
import Utilities from '../../config/utilities';
import './badge.scss';

const Badge = ({ type, small }) => {
  const badgeType = type || 'New';

  const layoutClasses = () => {
    let classes = {
      [`-${Utilities.toKebabCase(badgeType)}`]: true,
      [`-small`]: small,
    };
    return classNames(classes);
  };

  return (
    <div className='badge'>
      <span className={layoutClasses()}>{badgeType}</span>
    </div>
  );
};

export default Badge;
