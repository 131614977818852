import React, { Component } from 'react';
import NavItem from '../nav-item/nav-item';
import locales from '../../config/locales';
import _find from 'lodash.find';
import _orderBy from 'lodash.orderby';
import { MdLanguage } from 'react-icons/md';

class NavLocale extends Component {
  renderItems(translations, currentLocale) {
    let items = [];
    translations.forEach((translation) => {
      const translationLocale = _find(locales, { code: translation.link.lang });
      const translationPriority = locales.findIndex(
        (locale) => locale.code === translation.link.lang
      );
      const isCurrent = translationLocale.code === currentLocale.code;
      if (translationLocale) {
        items.push({
          id: isCurrent ? 'current-locale' : null,
          isCurrent: isCurrent || null,
          itemClass: translationLocale.has_alternative_font
            ? 'system-font'
            : 'base-font',
          link: translation.link,
          label_en: translationLocale.label_en,
          priority: translationPriority,
          text: `${translationLocale.label} (${translationLocale.label_en})`,
        });
      }
    });

    items = _orderBy(items, ['isCurrent', 'priority']);
    return items;
  }

  render() {
    const { translations, locale } = this.props;

    if (!translations || !locale) {
      return null;
    }

    const currentLocale = _find(locales, { code: locale });

    // Create the non-linked Nav Item Object in Prismic format
    let primary = {
      text: currentLocale.language_toggle,
    };

    return (
      <NavItem
        primary={primary}
        items={this.renderItems(translations, currentLocale)}
        icon={<MdLanguage />}
        hideText={true}
      />
    );
  }
}

export default NavLocale;
