const primarySupport = require('./primarySupport');

const tutor = {
  supportName: 'Tutoring',
  supportIcon: 'handBook',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'education-and-employment',
};

module.exports = tutor;
