const keys = {
  tab: 'Tab',
  escape: 'Escape',
  enter: 'Enter',
  space: ' ',
  arrowUp: 'ArrowUp',
  arrowDown: 'ArrowDown',
};

export default keys;
