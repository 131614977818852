import React from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import _kebabCase from 'lodash.kebabcase';
import './campaign-image-content.scss';
import Image from '../../components/image/image';

const ImageAndContent = ({ primary, items }) => {
  const layoutClasses = () => {
    let classes = {
      'layout-campaign-image': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-layout-${_kebabCase(primary.layout)}`]: primary.layout,
    };
    return classNames(classes);
  };

  const hasPrismicContent = primary.content && primary.content.length !== 0;

  if (!primary || !hasPrismicContent) {
    return null;
  }

  return (
    <section className={layoutClasses(primary)}>
      <div className="container text-center flex">
        <div className="content content-image">
          <Image primary={primary.image_hero} lazyload={true} />
        </div>
        <div className="content">
          {hasPrismicContent &&
            PrismicRichText.render(
              primary.content_header,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
          <div className="container">
            <div className="grid">
              {items &&
                items.map((item, i) => (
                  <div className="flex" key={i}>
                    <Image primary={primary.list_style_image} lazyload={true} />
                    <p>{item.text}</p>
                  </div>
                ))}
            </div>
          </div>
          {hasPrismicContent &&
            PrismicRichText.render(
              primary.content,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
        </div>
      </div>
    </section>
  );
};

export default ImageAndContent;
