const primarySupport = require('./primarySupport');

const developSkills = {
  supportName: 'Developing skills',
  supportIcon: 'handBrain',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'education-and-employment',
};

module.exports = developSkills;
