import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Statistic from '../../components/statistic/statistic';
import Banner from '../banner/banner';
import _kebabCase from 'lodash.kebabcase';
import './statistics.scss';

export default class StatisticsLayout extends Component {
  layoutClasses(primary) {
    let classes = {
      'layout-statistics': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
    };
    return classNames(classes);
  }

  getBannerProps(primary) {
    const { banner_content, cta_text, cta_link, background_color } = primary;
    return { content: banner_content, cta_text, cta_link, background_color };
  }

  render() {
    const { primary, items } = this.props;

    if (!primary && !items) {
      return null;
    }

    return (
      <section className={this.layoutClasses(primary)}>
        <div className='container'>
          {primary.content && (
            <div className='content'>
              {PrismicRichText.render(
                primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          )}
        </div>
        <div className='container statistic-group'>
          {items &&
            items.map((item, i) => <Statistic primary={item} key={i} />)}
        </div>
        <div className='container'>
          {primary.content_below && (
            <div className='content'>
              {PrismicRichText.render(
                primary.content_below,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          )}
        </div>
        {primary.show_banner && (
          <div className='statistics-banner-wrapper'>
            <Banner primary={this.getBannerProps(primary)} />
          </div>
        )}
      </section>
    );
  }
}
