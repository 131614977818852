import * as React from 'react';

const IconClock = (props) => {
  return (
    <span className='icon'>
      <svg {...props} fill='none' viewBox='0 0 18 18'>
        <path
          d='M9 .75a8.25 8.25 0 1 1 0 16.5A8.25 8.25 0 0 1 9 .75Zm0 1.5a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM9 4.5a.75.75 0 0 1 .745.663l.005.087v3.439l2.78 2.78a.75.75 0 0 1-.99 1.124l-.07-.063-3-3-.055-.061-.018-.023a.774.774 0 0 1-.037-.056l-.016-.027a.787.787 0 0 1-.024-.046l-.017-.039a.74.74 0 0 1-.048-.19l-.002-.021a.759.759 0 0 1-.003-.052V5.25A.75.75 0 0 1 9 4.5Z'
          fill='currentColor'
        />
      </svg>
    </span>
  );
};

export default IconClock;
