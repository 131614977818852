export const generateFaqs = (support, location) => {
  const faqs = {
    content: [
      {
        type: `heading2`,
        text: `FAQs about ${support} support in ${location}`,
        spans: [],
      },
    ],
    items: [
      {
        question: [
          {
            type: `heading3`,
            text: `Is Hireup NDIS approved in ${location}? `,
            spans: [],
          },
        ],
        answer: [
          {
            type: `paragraph`,
            text: `Hireup is a registered NDIS provider of ${support} support in ${location}. Hireup works with all forms of NDIS funding, including:`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `NDIA managed`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `plan-managed`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `self-managed.`,
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: `heading3`,
            text: `How much does Hireup pay support workers providing ${support} support?`,
            spans: [],
          },
        ],
        answer: [
          {
            type: `paragraph`,
            text: `The Hireup standard pay rate for our support workers providing ${support} support is $35.54 (including superannuation) and comes in above level 3.2 (home care) of the SCHADS award. `,
            spans: [],
          },
          {
            type: `paragraph`,
            text: `The standard cost to clients seeking ${support} support is $51.07. The difference allows us to take care of comprehensive insurance, super and tax payments so our users don’t have to. Keeping costs low for the individual while paying workers industry-leading wages is our commitment to building a fair and accessible service.`,
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: `heading3`,
            text: `Are Hireup support workers providing ${support} support insured?`,
            spans: [],
          },
        ],
        answer: [
          {
            type: `paragraph`,
            text: `Hireup provides comprehensive insurance cover to protect all Hireup users seeking ${support} support. For all shifts booked and worked through the Hireup platform, we provide the following insurance:`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `Workers Compensation Insurance. This is provided for all support workers providing ${support} support who are fully registered and actively providing support through the Hireup platform`,
            spans: [
              {
                start: 0,
                end: 31,
                type: `strong`,
              },
            ],
          },
          {
            type: `list-item`,
            text: `Public Liability Insurance. This is provided to cover any harm caused to those seeking ${support} support on the Hireup platform. This also covers any property damage that may occur as a result of support being provided through Hireup`,
            spans: [
              {
                start: 0,
                end: 27,
                type: `strong`,
              },
            ],
          },
          {
            type: `list-item`,
            text: `Professional Indemnity Insurance. This coverage is for all registered support workers providing ${support} support.`,
            spans: [
              {
                start: 0,
                end: 33,
                type: `strong`,
              },
            ],
          },
          {
            type: `paragraph`,
            text: `Our comprehensive insurance is here to cover everyone from:`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `Support workers providing `,
            spans: [],
          },
          {
            type: `list-item`,
            text: `People seeking on the Hireup platform`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `General public`,
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: `heading3`,
            text: `Can I use Hireup for ${support} support if I’m self-managed?`,
            spans: [],
          },
        ],
        answer: [
          {
            type: `paragraph`,
            text: `To use Hireup, people with disability can either use a disability support package or their own personal income to pay for ${support} support. All NDIS participants (including self-managed participants) in ${location}, can use Hireup.`,
            spans: [],
          },
          {
            type: `paragraph`,
            text: `If you are an NDIS participant and do not live in these states, your funding (in full or in part) must be plan-managed or self-managed to use Hireup.`,
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: `heading3`,
            text: `What qualifications do you need as a support worker providing ${support} support for Hireup in ${location}?`,
            spans: [],
          },
        ],
        answer: [
          {
            type: `paragraph`,
            text: `To keep our clients and our support workers in ${location} safe and to comply with NDIS requirements as a registered provider, all of our support workers providing ${support} support will be asked to upload the following documents, along with Photo ID. If you already have the below checks, great! We’ll ask you to upload them during the application process. `,
            spans: [],
          },
          {
            type: `list-item`,
            text: `Drivers license or passport`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `Police Criminal Record Check`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `NDIS Worker Screening Check or other relevant state-based checks`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `CPR and/or First Aid Qualification`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `NDIS Worker Orientation Module Certificate`,
            spans: [],
          },
        ],
      },
    ],
  };
  return faqs;
};
