import * as React from 'react';

const IconWheelchairComplex = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)'>
          <path d='M10.416 46.56a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM11.416 26.56a22.282 22.282 0 0 1 9-2l7.154.092M37.55 11.098 35.04 32.79M37.164 14.224l1.518-12.326S31.376-.634 29.888 6.2c-1.488 6.834 7.276 8.024 7.276 8.024ZM11.416 18.56v13M34.27 31.728l2.288-.168c3.788 0 6.858 2.442 6.858 5.454v1.676M9.376 38.696l2.04-7.136M4.83 15.6l4.112-2.746a2 2 0 0 0 .788-2.296l-1.764-4.6A2 2 0 0 0 5.584 4.65l-4 1.104 2.832 8.8a5.336 5.336 0 0 0 5 4H21.09' />
          <path d='M42.416 46.56a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM27.416 46.56a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z' />
          <path d='M27.416 40.56a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM11.416 31.56h9.294M28.4 28.616s-2.328-7.184.02-13.056a9.692 9.692 0 0 1 2.686-4.246' />
        </g>
        <defs>
          <clipPath id='a'>
            <path d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconWheelchairComplex;
