import React from 'react';

const ShapeBackground = ({ fill }) => {
  return (
    <svg
      width='1800'
      height='1027'
      viewBox='0 0 1800 1027'
      fill='none'
      aria-hidden='true'>
      <path
        d='M1800 0.387207L453.134 335.327C432.896 340.36 420.156 361.272 425.189 381.51C537.109 833.152 993.979 1107.73 1445.34 995.485L1800 907.288V0.387207Z'
        fill={fill}
      />
    </svg>
  );
};

export default ShapeBackground;
