import React, { useState } from 'react';
import Analytics from '../../config/analytics';
import Autocomplete from '../autocomplete/autocomplete';
import { ReactComponent as IconCircleFilter } from '../../assets/svg/icon-circle-filter.svg';
import { ReactComponent as IconCross } from '../../assets/svg/icon-cross.svg';
import './search-filters.scss';

export default function SearchFilters({
  loading,
  setLocationSearched,
  setSupportTypeFilter,
  onSubmit,
  defaultSupportType,
  locationSearched,
  showFullSearch = true,
}) {
  const [error, setError] = useState(false);
  const [supportType, setSupportType] = useState(defaultSupportType);
  const [showFullSearchInput, setShowFullSearchInput] =
    useState(showFullSearch);
  const PATH = window.location.pathname;
  const DEFAULT_LOCATION = 'Sydney, NSW 2000';

  const handleSupportTypeChange = (event) => {
    const selected = event.target.value;
    setSupportType(selected);
    setSupportTypeFilter(selected);
    Analytics.link(
      { label: `Filter support type - ${selected}` },
      `www.${PATH}.SearchFilter.supportTypeSelect`,
      true
    );
  };

  const handleFilterClick = () => {
    setShowFullSearchInput(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSupportTypeFilter(supportType);
    onSubmit();

    Analytics.link(
      { label: `Search filters submit button` },
      `www.${PATH}.SearchFilter.submit`,
      true
    );
  };

  const renderOptions = () => {
    const data = [
      {
        value: 'All',
        name: 'All support types',
      },
      {
        value: 'Help around the house',
        name: 'Help around the house',
      },
      {
        value: 'In home care',
        name: 'In-home care',
      },
      {
        value: 'Personal care and hygiene',
        name: 'Personal care',
      },
      {
        value: 'Specialised support',
        name: 'Specialised support',
      },
      {
        value: 'Transport',
        name: 'Transport',
      },
      {
        value: 'Out and about',
        name: 'Out and about',
      },
      {
        value: 'Therapy support',
        name: 'Therapy support',
      },
      {
        value: 'Education, training, and employment',
        name: 'Education and employment',
      },
    ];

    return data.map((option) => (
      <option value={option.value} key={option.value}>
        {option.name}
      </option>
    ));
  };

  return (
    <>
      {!showFullSearchInput && (
        <div className='filter-sm'>
          <div className='flex'>
            <div
              className='search-location-sm'
              aria-label='Location search input'
              onClick={() => handleFilterClick()}>
              {locationSearched ? locationSearched : DEFAULT_LOCATION}
              <IconCross
                className='icon-cross'
                aria-hidden={true}
                onClick={() => handleFilterClick()}
              />
            </div>
            <button
              className='button -secondary filter-button'
              onClick={() => handleFilterClick()}>
              <IconCircleFilter
                className='icon-circle-filter'
                aria-hidden={true}
              />
              <span className='filter-text'>Filters</span>
            </button>
          </div>
        </div>
      )}

      {showFullSearchInput && (
        <div className='search-filters'>
          <form
            className={error ? 'search-error search-form' : 'search-form'}
            autoComplete='off'
            onSubmit={handleSubmit}
            data-testid='search-filters-form'
            method='get'
            action='/pre-registration'>
            <div className='search-inputs'>
              <div className='search-location'>
                <label htmlFor={`input`}>Enter suburb or postcode</label>
                <Autocomplete
                  wrapperId={`search-filter`}
                  inputId={`input`}
                  setError={setError}
                  setLocationSearched={setLocationSearched}
                />
              </div>
              <div className='search-support'>
                <label htmlFor='support-type'>Type of Support</label>
                <select
                  id='support-type'
                  name='support'
                  defaultValue={defaultSupportType || 'All support types'}
                  onChange={handleSupportTypeChange}>
                  {renderOptions()}
                </select>
              </div>
              <div className='search-button'>
                <button
                  type='submit'
                  className='button -secondary'
                  disabled={loading}>
                  Search
                </button>
              </div>
            </div>
          </form>

          {error && (
            <p role='alert' className='error-message'>
              Please select from the suggested location options before
              submitting your search.
            </p>
          )}
        </div>
      )}
    </>
  );
}
