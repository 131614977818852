import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import _kebabCase from 'lodash.kebabcase';
import './call-to-action.scss';

export default class CallToAction extends Component {
  layoutClasses(primary) {
    let classes = {
      'layout-call-to-action': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-layout-${_kebabCase(primary.layout)}`]: primary.layout,
    };
    return classNames(classes);
  }

  layoutBackground(primary) {
    return {
      backgroundImage:
        primary.background_image && primary.background_image.url
          ? `url(${primary.background_image.url})`
          : null,
    };
  }

  render() {
    const { primary } = this.props;

    if (!primary) {
      return null;
    }

    return (
      <section
        className={this.layoutClasses(primary)}
        style={this.layoutBackground(primary)}>
        <div className='container'>
          <div className='content'>
            {primary &&
              PrismicRichText.render(
                primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
          </div>
        </div>
      </section>
    );
  }
}
