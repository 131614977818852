// Glossary for terms
// Language: English

const terms = {
  'Agency Managed':
    'Funding managed by the NDIA. The NDIA will pay providers through the NDIS myplace portal.',
  Anaphylaxis:
    'An allergic reaction, often involving more than one body system (skin, respiratory, cardiovascular). It can be life threatening.',
  'Behaviour Practictioner':
    'A behaviour practitioner writes behaviour support plans and is NDIS registered.',
  'Behaviours of Concern':
    'Behaviours and actions that may have a negative impact on an individual and those around them.',
  'Booking Feedback':
    'Feedback from people seeking support and support workers after every Hireup shift. Booking feedback is shared with Hireup only.',
  'Bowel Care':
    'Intervention needed to assist someone to maintain a healthy bowel routine. It often involves medication.',
  'CPR Certificate':
    'A Cardiopulmonary Resuscitation Certificate that is required for Hireup support workers.',
  CRES: 'SA Child Related Employment Screening: A state based requirement to support people under 18 years of age, in a paid capacity. Support workers will also need a DSEC.',
  'Capacity Building':
    "A type of budgeting that can be funded in an NDIS plan. It's designed to assist with developing independence and skills.",
  'Capital Budget':
    'Supports for one-off purchases, including Specialised Disability Accommodation (SDA), equipment, assistive technology.',
  'Core Supports':
    'A type of budgeting that can be funded in an NDIS plan. Core supports are designed to assist with everyday activities.',
  DSEC: 'SA Disability Services Employment Check: A state based requirement to support people over 18 years of age, in a paid capacity.',
  'Day Shift': 'A shift between 6am and 8pm.',
  Deaf: 'Those who use Auslan (Australian Sign Language) to communicate.',
  EAP: 'Employee Assistance Program.',
  ECEI: 'Early Childhood Early Intervention.',
  Epilepsy:
    'A neurological condition in which the brain activity becomes abnormal and change can cause a seizure.',
  'Essential Support': 'Support work required to meet immediate basic needs',
  'Evening Shift': 'A shift between 8pm and midnight.',
  'Incident Report':
    'An form to document an event (or a near-miss) that has happened while on shift. The Hireup team will receive the form.',
  Insurance:
    'Hireup provides comprehensive insurance cover. This is for all shifts booked and worked on the platform.',
  'Job Board':
    'A Hireup feature to connect support workers with people seeking support on the Hireup platform.',
  LAC: 'Local Area Coordinator.',
  Messaging:
    'A feature to connect support workers with those seeking support on the Hireup platform.',
  NDIA: 'National Disability Insurance Agency',
  'NDIS Rate':
    'The NDIS rate is the financial cost for a line item on the NDIS Price Guide. It can be per hour or per item.',
  'NDIS Module': 'NDIS Worker Orientation Module for support workers.',
  NDIS: 'National Disability Insurance Scheme',
  'Night Shift': 'A shift between midnight and 6am.',
  'Non-Essential Support':
    'Support work that is not required to meet immediate basic needs',
  'PEG Feeding':
    'A procedure where a tube is placed into the stomach. Used to provide nutrition, fluids or medication by bypassing the mouth.',
  'Pay Rates':
    'The rate a support worker is paid. Rates vary based on day, time and length.',
  'Personal Care':
    'One of eight areas of support Hireup offers. Involves assistance with tasks such as showering, toileting, or mealtime support.',
  'Plan-managed':
    "One of three types of NDIS funding. A plan manager will manage a person seeking support's funding on their behalf.",
  'Quality and Safeguards Commission':
    'An independent agency established to improve the quality and safety of NDIS supports and services.',
  SCHADS: 'Social, Community, Home Care and Disbaility Services.',
  SDA: 'Specialised Disability Accommodation.',
  SIL: 'Supported Independent Living.',
  Search:
    'A feature to connect support workers with those seeking support on the Hireup platform.',
  'Self-managed':
    'Personal management of NDIS funds. Working with providers and paying invoices directly.',
  'Service Booking':
    'The way a person seeking support allocates funding from their NDIS plan. Hireup can claim for services provided.',
  'Sleepover Booking':
    'An overnight booking of eight consecutive hours. Charged at a flat rate for the night, not an hourly charge.',
  'Specialised Support Plan':
    'Additional support plans for support including manual handling, epilepsy or other specific instructions.',
  'Specialised Support':
    'One of eight areas of support Hireup offers. Involves assistance with more complex requirements and high needs.',
  'Support Plan':
    'A document that outlines key information on support needs. The person seeking support or account manager will create this.',
  TTP: 'Temporary Transformation Payment.',
  'Therapy Support':
    'One of eight areas of support Hireup offers. Involves assistance with tasks such as OT and hydrotherapy.',
  Verification:
    'The checks Hireup performs on everyone joining the community to keep you safe and secure.',
};

module.exports = terms;
