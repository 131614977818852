import React, { Component } from 'react';
import Prismic from 'prismic-javascript';
import { PrismicConfig } from './config';
import constants from '../config/constants';
import Utilities from '../config/utilities';
import _camelCase from 'lodash.camelcase';
import { sub } from 'date-fns';
import locales from '../config/locales';
import {
  isValidSupportType,
  isArchivedSupport,
  supportTypes,
} from '../seo/data/supportTypes';

const defaultLocale = Utilities.getCurrentLocale();
const defaultState = {
  prismicContext: null,
  terms: null,
  pages: {},
  locale: defaultLocale.code,
};

const PrismicContext = React.createContext(defaultState);

const getPathArray = () => {
  return window.location.pathname.split('/').filter((path) => path !== '');
};

export const isLocalePage = () => {
  const pathArray = getPathArray();
  const localesArray = Object.values(locales);
  return localesArray.some((locale) =>
    Object.values(locale).includes(pathArray[0])
  );
};

export const isValidPageUrl = (response) => {
  const pathArray = getPathArray();
  const isParentMatch =
    response?.data?.page_parent?.uid === pathArray[pathArray.length - 2];
  const isGrandparentMatch =
    response?.data?.page_grandparent?.uid === pathArray[pathArray.length - 3];

  return isParentMatch && isGrandparentMatch;
};

class PrismicProvider extends Component {
  state = defaultState;
  _refs = {};

  componentDidMount() {
    this.buildContext()
      .then((prismicContext) => {
        this.setState({ prismicContext });
        // TODO: Write core page settings / options.
        // this.getOptions();
      })
      .catch((e) => {
        // TODO: Render an error page if the API does not load (eg when internet not connected, prismic outage)
        console.error(
          `Cannot contact the API, check your prismic configuration:\n${e}`
        );
      });
  }

  buildContext() {
    const accessToken = PrismicConfig.accessToken;
    return Prismic.api(PrismicConfig.apiEndpoint, {
      accessToken,
    }).then((api) => ({
      api,
      endpoint: PrismicConfig.apiEndpoint,
      accessToken,
      linkResolver: PrismicConfig.linkResolver,
      toolbar: this.refreshToolbar,
    }));
  }

  setLocale = (locale) => {
    if (locale && locale !== this.state.locale) {
      this.setState({ locale: locale || constants.locale });
    }
  };

  getOptions = async () => {
    const options = await this.state.prismicContext.api.getSingle('options');
    this.setState({
      options: options ? options.data : {},
    });
  };

  getHeader = async () => {
    const header = await this.state.prismicContext.api.getSingle('header', {
      lang: this.state.locale,
    });
    this.setState({
      header: header ? header.data : {},
    });
  };

  getFooter = async () => {
    const footer = await this.state.prismicContext.api.getSingle('footer', {
      lang: this.state.locale,
    });
    this.setState({
      footer: footer ? footer.data : {},
    });
  };

  getNavigation = (uid) => {
    if (!uid) {
      return;
    }
    this.state.prismicContext.api
      .getByUID('navigation', uid, { lang: this.state.locale })
      .then((response) => {
        this.setState({
          navigation: {
            ...this.state.navigation,
            [uid]: response || null,
          },
        });
      });
  };

  getPage = (uid, checkUrlIsValid = false) => {
    const { locale, pages } = this.state;

    // Set the content to 'loading'
    this.setState({
      pages: {
        ...pages,
        [`${uid}_${locale}`]: 'loading',
      },
    });

    this.state.prismicContext.api
      .getByUID('page', uid, { lang: locale })
      .then((response) => {
        if (!checkUrlIsValid || isLocalePage() || isValidPageUrl(response)) {
          this.setState({ error: false });
          // Populate the page content
          this.setState({
            pages: {
              ...pages,
              [`${uid}_${locale}`]: response || null,
            },
          });
        } else {
          this.setState({ error: true });
        }
      })
      .catch((e) => {
        console.error(e);
        this.setState({ error: true });
      });
  };

  getRates = async () => {
    const rates = await this.state.prismicContext.api.getSingle('rates');
    this.setState({
      rates: rates ? rates.data : {},
    });
  };

  getNews = async (
    pageSize,
    page,
    fromCategory = false,
    fromAuthor = false
  ) => {
    let posts = false;
    let query = [
      Prismic.Predicates.at('document.type', 'news'),
      Prismic.Predicates.has('my.news.displayed_publication_date'),
    ];

    // Filter to one specific tag, if it exists.
    if (fromCategory) {
      query.push(Prismic.Predicates.at('document.tags', [fromCategory]));
    }

    // Filter to one specific author, if it exists.
    if (fromAuthor) {
      query.push(Prismic.Predicates.at('my.news.author_name', fromAuthor));
    }

    await this.state.prismicContext.api
      .query(query, {
        orderings:
          '[my.news.displayed_publication_date desc, document.first_publication_date desc]',
        pageSize: pageSize,
        page: page,
      })
      .then(async (response) => {
        if (response) {
          posts = response;
        }
      });
    return posts;
  };

  getNewsPost = (uid) => {
    this.state.prismicContext.api.getByUID('news', uid).then((content) => {
      this.setState(content ? { content, error: false } : { error: true });
    });
  };

  getAuthor = async (uid, throwError = false) => {
    await this.state.prismicContext.api
      .getByUID('author', uid)
      .then((author) => {
        if (author) {
          this.setState({ author });
        } else {
          this.setState(
            throwError ? { error: true, author: {} } : { author: {} }
          );
        }
      });
  };

  getNewsWraps = async (pageSize = 100, page = 10) => {
    let wraps = false;
    let query = [
      Prismic.Predicates.at('document.type', 'news_wrap'),
      Prismic.Predicates.has('my.news_wrap.displayed_publication_date'),
    ];
    await this.state.prismicContext.api
      .query(query, {
        orderings: '[my.news_wrap.displayed_publication_date desc]',
        pageSize: pageSize,
        page: page,
      })
      .then(async (response) => {
        if (response) {
          wraps = response;
        }
      });
    return wraps;
  };

  getEvents = async (type, pageSize, page) => {
    let posts = false;
    let query = [Prismic.Predicates.at('document.type', 'event')];
    let sortOrder = '';
    switch (type) {
      case 'past':
        query.push(
          Prismic.Predicates.dateBefore(
            'my.event.event_date',
            sub(new Date(), { days: 1 })
          )
        ); // Subtract one day to display today's events in 'Upcoming'
        sortOrder = 'desc';
        break;
      case 'upcoming':
      default:
        query.push(
          Prismic.Predicates.dateAfter(
            'my.event.event_date',
            sub(new Date(), { days: 1 })
          )
        ); // Subtract one day to display today's events in 'Upcoming'
        break;
    }
    await this.state.prismicContext.api
      .query(query, {
        orderings: `[my.event.event_date ${sortOrder}, document.first_publication_date ${sortOrder}]`,
        pageSize: pageSize,
        page: page,
      })
      .then(async (response) => {
        if (response) {
          posts = response;
        }
      });
    return posts;
  };

  getEvent = (uid) => {
    this.state.prismicContext.api.getByUID('event', uid).then((content) => {
      this.setState(content ? { content, error: false } : { error: true });
    });
  };

  getForm = (uid) => {
    const { locale } = this.state;
    this.state.prismicContext.api
      .getByUID('form', uid, { lang: locale })
      .then((content) => {
        this.setState(content ? { content, error: false } : { error: true });
      });
  };

  getUpdates = async (pageSize, page) => {
    let updates = false;
    let query = [
      Prismic.Predicates.at('document.type', 'update'),
      Prismic.Predicates.has('my.update.displayed_publication_date'),
    ];
    await this.state.prismicContext.api
      .query(query, {
        orderings:
          '[my.update.displayed_publication_date desc, document.first_publication_date desc]',
        pageSize: pageSize,
        page: page,
      })
      .then(async (response) => {
        if (response) {
          updates = response;
        }
      });
    return updates;
  };

  getUpdate = (uid) => {
    this.state.prismicContext.api.getByUID('update', uid).then((content) => {
      this.setState(content ? { content, error: false } : { error: true });
    });
  };

  // TODO : getLocations which loads all locations for a 'where we work' section

  getLocation = (uid) => {
    this.state.prismicContext.api.getByUID('location', uid).then((content) => {
      this.setState(content ? { content, error: false } : { error: true });
    });
  };

  getLocationLinks = async (latitude = -33.86882, longitude = 151.20929) => {
    let links = false;
    // lat/long falls back to Sydney CBD coordinates
    let query = [
      Prismic.Predicates.near(
        'my.location.location_location',
        latitude,
        longitude,
        20000
      ),
    ];

    await this.state.prismicContext.api
      .query(query, {
        pageSize: 100,
      })
      .then(async (response) => {
        if (response) {
          links = response;
        }
      });
    return links;
  };

  getSupportType = () => {
    const pathArray = window.location.pathname.split('/');
    const primarySupport = _camelCase(pathArray[1]);
    const secondarySupport = _camelCase(pathArray[2]);
    const isValidSupport =
      isValidSupportType(primarySupport, secondarySupport) &&
      !isArchivedSupport(primarySupport, secondarySupport);

    if (isValidSupport) {
      this.setState({
        support: supportTypes[primarySupport][secondarySupport],
      });
    } else {
      console.warn(
        `Secondary support type "${secondarySupport}" does not exist or has been archived`
      );
      this.setState({ error: true });
    }
  };

  // TODO : getPlaces which loads places based on geo coordinates

  getPlace = (uid) => {
    this.state.prismicContext.api.getByUID('place', uid).then((content) => {
      this.setState(content ? { content, error: false } : { error: true });
    });
  };

  // Search for places based on a {latitude, longitude} location object
  getPlaces = async (location, radius) => {
    const places = await this.state.prismicContext.api.query(
      [
        Prismic.Predicates.at('document.type', 'place'),
        Prismic.Predicates.near(
          'my.place.place_location',
          location.latitude,
          location.longitude,
          radius
        ),
      ],
      { orderings: '[my.place.accessibility_rating desc]' }
    );
    return places;
  };

  // TODO : getGuides which loads places based on geo coordinates

  getGuide = async (uid) => {
    const content = await this.state.prismicContext.api.getByUID('guide', uid);
    this.setState(content ? { content, error: false } : { error: true });
  };

  render() {
    const { children } = this.props;
    const context = {
      ...this.state,
      prismic: {
        getPage: this.getPage,
        getNavigation: this.getNavigation,
        getRates: this.getRates,
        getNews: this.getNews,
        getNewsPost: this.getNewsPost,
        getAuthor: this.getAuthor,
        getNewsWraps: this.getNewsWraps,
        getEvents: this.getEvents,
        getEvent: this.getEvent,
        getForm: this.getForm,
        getUpdates: this.getUpdates,
        getUpdate: this.getUpdate,
        getLocation: this.getLocation,
        getLocationLinks: this.getLocationLinks,
        getSupportType: this.getSupportType,
        getPlace: this.getPlace,
        getPlaces: this.getPlaces,
        getGuide: this.getGuide,
        getOptions: this.getOptions,
        getHeader: this.getHeader,
        getFooter: this.getFooter,
        setLocale: this.setLocale,
      },
    };

    return (
      <PrismicContext.Provider value={context}>
        {children}
      </PrismicContext.Provider>
    );
  }
}

const withPrismic = (WrappedComponent) => {
  return function PrismicConnectedComponent(props) {
    return (
      <PrismicContext.Consumer>
        {(data) => <WrappedComponent {...props} {...data} />}
      </PrismicContext.Consumer>
    );
  };
};

export default PrismicProvider;
export { withPrismic };
