import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import QuestionAnswer from '../../components/question-answer/question-answer';
import _kebabCase from 'lodash.kebabcase';
import './faqs.scss';

export default class FAQsLayout extends Component {
  layoutClasses(primary) {
    let classes = {
      'layout-faqs': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-count-${this.props.items.length}`]: true,
    };
    return classNames(classes);
  }

  render() {
    const { primary, items } = this.props;
    const hasContent =
      primary.content[0]?.text !== '' && primary.content?.length !== 0;
    const hasItems = items.length !== 0;

    if (!hasContent || !hasItems) {
      return null;
    }

    return (
      <section className={this.layoutClasses(primary)}>
        {hasContent && (
          <div className='container'>
            <div className='content'>
              {PrismicRichText.render(
                primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          </div>
        )}
        {hasItems && (
          <div className='container'>
            <div className='questions'>
              {items.map((item, i) => (
                <QuestionAnswer primary={item} key={i} />
              ))}
            </div>
          </div>
        )}
      </section>
    );
  }
}
