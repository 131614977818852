import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import './pull-quote.scss';

export default class Resource extends Component {
  componentDidMount() {}

  layoutClasses() {
    let classes = {
      'pull-quote': true,
      [`-${this.props.content.color}`]: true,
    };
    return classNames(classes);
  }

  render() {
    const content = this.props.content;

    if (!content || !content.body) {
      return;
    }

    return (
      <div className={this.layoutClasses()}>
        {PrismicRichText.render(
          content.body,
          PrismicConfig.linkResolver,
          PrismicConfig.htmlSerializer
        )}
      </div>
    );
  }
}
