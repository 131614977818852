import React, { Component } from 'react';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import defaultImage from '../../assets/img/default-event.jpg';
import { format } from 'date-fns';
import './event.scss';

export default class Event extends Component {
  layoutClasses() {
    let classes = {
      'event-single': true,
    };
    return classNames(classes);
  }

  render() {
    const { primary } = this.props;

    if (!primary || !primary.data) {
      return null;
    }

    const { image, event_date, event_suburb, event_state, title, description } =
      primary.data;

    return (
      <a className={this.layoutClasses()} href={`/event/${primary.uid}`}>
        {image && (
          <LazyLoad>
            <div
              className='event-image'
              style={{
                backgroundImage: `url(${image.url ? image.url : defaultImage}`,
              }}></div>
          </LazyLoad>
        )}
        <div className='event-content'>
          <p className='meta'>
            {event_date && (
              <span className='date'>
                {format(new Date(event_date), 'do MMMM')}
              </span>
            )}
            {(event_suburb || event_state) && (
              <span className='place'>
                <span className='separator'> &middot; </span>
                {event_suburb && (
                  <span className='suburb'>
                    {event_suburb}
                    {event_state && <span>, </span>}
                  </span>
                )}
                {event_state && <span className='state'>{event_state}</span>}
              </span>
            )}
          </p>
          {title && (
            <div className='title'>
              {PrismicRichText.render(
                title,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          )}
          {description && (
            <div className='description'>
              {PrismicRichText.render(
                description,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          )}
          <span className='button -primary -small'>View Event</span>
        </div>
      </a>
    );
  }
}
