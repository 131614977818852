import React, { Component } from 'react';
import { timestamp } from '../helpers/eventHelpers';
import PageWrapper from './wrapper';
import Loading from '../components/loading/loading';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../prismic/config';
import { withPrismic } from '../prismic/prismic';
import { withHireup } from '../hireup/hireup';
import classNames from 'classnames';
import Field from '../components/field/field';
import Fieldset from '../components/field/fieldset';
import Error400 from './error-400';
import Analytics from '../config/analytics';
import Utilities from '../config/utilities';
import { Formik, Form } from 'formik';
import axios from 'axios';
import qs from 'qs';
import _get from 'lodash.get';
import _find from 'lodash.find';
import { format } from 'date-fns';
import { ReactComponent as Warning } from '../assets/svg/icon-triangle-warning.svg';
import { getEnvBaseUrl } from '../config/api';

import './form.scss';
import { getSegmentAPIKey } from '../config/segment';

const fieldsetSliceTypes = ['scale', 'checkboxes', 'radio'];

class FormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: null,
      initialValues: null,
      isCommentVisible: {},
      submitted: false,
      error: null,
      submitError: null,
    };
  }

  componentDidUpdate() {
    if (this.props.content && !this.state.initialValues) {
      this.setInitialValues();
    }
    if (this.state.uid && this.props.uid === this.state.uid) {
      return null;
    }
    if (!this.props.prismicContext) {
      return null;
    }
    this.loadForm();
  }

  loadForm() {
    this.setState({ uid: this.props.uid });
    this.props.prismic.getForm(this.props.uid);
  }

  setInitialValues() {
    const questions = _get(this.props, 'content.data.body');
    const urlParameters = qs.parse(window.location.search.replace('?', ''));
    let initialValues = {};

    questions.forEach((q) => {
      let name = q.primary.name;
      // Dont' set a value for slices with no name (separators, headings, etc).
      if (!name) {
        return;
      }

      switch (q.slice_type) {
        case 'checkboxes':
          // No initial values, these populate in the fieldgroup
          break;
        case 'checkbox':
        case 'radio':
          initialValues[name] = urlParameters[name] === 'true';
          break;
        case 'scale':
          initialValues[name] = urlParameters[name] || false;
          break;
        default:
          initialValues[name] = urlParameters[name] || '';
      }
    });
    this.setState({ initialValues });
  }

  generateScale(range) {
    // Accept a range in the form `min-max`
    const r = range.split('-');
    let i = r[0];
    let items = [];
    while (i <= r[1]) {
      items.push({ value: i, label: i });
      i++;
    }
    return items;
  }

  toggleCommentVisibility = (event, name) => {
    event.preventDefault();
    let { isCommentVisible } = this.state;
    isCommentVisible[name] = !isCommentVisible[name];
    this.setState({ isCommentVisible });
    if (isCommentVisible[name]) {
      document.getElementById(name).focus();
    }
  };

  handleSubmit = async (values) => {
    this.setState({ submitError: null });

    try {
      const { uid, content, user } = this.props;
      const database = content.data.send_database;
      const dataset = content.data.send_database_dataset;
      const table = content.data.send_database_table;

      const properties = {
        ...Utilities.flatten(values),
        formId: uid,
        formSubmissionTime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx"),
        originTimestamp: timestamp(),
      };

      // Track the submission through Segment
      if (content.data.send_segment) {
        const anonymousId =
          window.analytics && window.analytics.user().anonymousId();
        const userId = _get(user, 'auth.id');
        const segmentKey = getSegmentAPIKey();

        const postData = {
          anonymousId,
          segmentKey,
          properties,
          userId,
        };

        await axios
          .post(`/api/v1/form/event/`, {
            ...postData,
            event: 'form_submitted',
          })
          .catch((error) => {
            throw new Error(error);
          });

        await axios
          .post(`/api/v1/form/event/`, {
            ...postData,
            event: 'Form Submission',
          })
          .catch((error) => {
            throw new Error(error);
          });
      }

      // Send the submission to Zendesk
      if (content.data.send_zendesk && content.data.send_zendesk_form_id) {
        const subject = `Form: ${_get(content, 'data.title[0].text')}`;
        const body = `A new response for the Hireup website form, ${_get(
          content,
          'data.title[0].text'
        )}, was submitted at ${format(new Date(), 'do MMM yyyy, h:mmaaa')} :`;
        await axios
          .post(`/api/v1/request/`, {
            subject,
            body,
            data: properties,
            user:
              !content.data.send_zendesk_anonymously && user ? user.auth : null,
            zendeskFormId: content.data.send_zendesk_form_id,
          })
          .catch((error) => {
            throw new Error(error);
          });
      }

      // Send the submission to Google Cloud
      if (database && dataset && table) {
        await axios
          .post(`/api/v1/form/cloud/`, {
            data: {
              ...properties,
              userId:
                !content.data.send_zendesk_anonymously && user
                  ? _get(user, 'auth.id')
                  : null,
            },
            dataset: dataset,
            table: table,
          })
          .catch((error) => {
            throw new Error(error);
          });
      }

      // Send the submission to Google Cloud
      if (content.data.send_webhook && content.data.send_webhook_url) {
        await axios
          .post(`/api/v1/form/webhook/`, {
            data: {
              ...properties,
            },
            user:
              !content.data.send_zendesk_anonymously && user ? user.auth : null,
            webhook: content.data.send_webhook_url,
          })
          .catch((error) => {
            throw new Error(error);
          });
      }

      // Scroll the user to the top, ready to see the conclusion message
      this.setState({ submitted: true });

      // Send a de-identified Analytics event
      Analytics.event('form', 'submit', uid);

      Utilities.scrollToAndFocusOn(null, 'conclusion');
    } catch (e) {
      console.warn('Error submitting form', e);
      this.setState({ submitError: true });
      Utilities.scrollToAndFocusOn(null, 'form-validation');
    }
  };

  layoutClasses(primary) {
    let classes = {
      'layout-form': true,
      '-submitting': this.state.submitting,
      '-submitted': this.state.submitted,
      [`-${primary.uid}`]: true,
    };
    return classNames(classes);
  }

  renderQuestions(uid, questions, values, errors, touched) {
    let output = [];
    questions.forEach((q, inputIndex) => {
      const { isCommentVisible } = this.state;
      const type = q.slice_type;
      const name = q.primary.name || `${uid}-${inputIndex}`;
      switch (type) {
        case 'content':
          return output.push(
            <div className='question -content' key={inputIndex}>
              {PrismicRichText.render(
                q.primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          );
        case 'separator':
          return output.push(<hr key={inputIndex} />);
        case 'scale':
          return output.push(
            <div className={`question -${type}`} key={inputIndex}>
              <Fieldset
                key={inputIndex}
                name={name}
                type={'radio'}
                layout={
                  q.primary.layout ? q.primary.layout.toLowerCase() : null
                }
                label={q.primary.question}
                required={q.primary.required === 'Required'}
                helpText={
                  q.primary.help_text &&
                  q.primary.help_text[0] &&
                  q.primary.help_text[0].text
                    ? PrismicRichText.render(
                        q.primary.help_text,
                        PrismicConfig.linkResolver,
                        PrismicConfig.htmlSerializer
                      )
                    : null
                }
                options={this.generateScale(q.primary.range)}
                minimum={q.primary.range_minimum || null}
                maximum={q.primary.range_maximum || null}
                value={values ? values[name] : {}}
                errors={errors}
                touched={touched}
              />
            </div>
          );
        case 'checkboxes':
        case 'radio':
          return output.push(
            <div className={`question -${type}`} key={inputIndex}>
              <Fieldset
                key={inputIndex}
                name={name}
                type={type === 'checkboxes' ? 'checkbox' : type}
                label={q.primary.question}
                layout={
                  q.primary.layout ? q.primary.layout.toLowerCase() : null
                }
                required={q.primary.required === 'Required'}
                helpText={
                  q.primary.help_text &&
                  q.primary.help_text[0] &&
                  q.primary.help_text[0].text
                    ? PrismicRichText.render(
                        q.primary.help_text,
                        PrismicConfig.linkResolver,
                        PrismicConfig.htmlSerializer
                      )
                    : null
                }
                options={q.items}
                value={values ? values[name] : {}}
                errors={errors}
                touched={touched}
              />
            </div>
          );
        case 'comment':
          return output.push(
            <div
              className={`question -${type} ${
                isCommentVisible[name] ? '-visible' : ''
              }`}
              key={inputIndex}>
              <button
                aria-hidden='true'
                className='button -small -neutral toggle'
                onClick={(e) => {
                  this.toggleCommentVisibility(e, name);
                }}>
                {q.primary.button_text}
              </button>
              <Field
                name={name}
                type='textarea'
                label={q.primary.button_text}
              />
            </div>
          );
        default:
          return output.push(
            <div className={`question -${type}`} key={inputIndex}>
              <Field
                name={name}
                type={type}
                label={q.primary.question}
                required={q.primary.required === 'Required'}
                helpText={
                  q.primary.help_text && q.primary.help_text.length
                    ? PrismicRichText.render(
                        q.primary.help_text,
                        PrismicConfig.linkResolver,
                        PrismicConfig.htmlSerializer
                      )
                    : null
                }
                options={q.items || null}
              />
            </div>
          );
      }
    });
    return output;
  }

  renderErrors(errors, questions) {
    let output = [];
    for (const name in errors) {
      const question = _find(questions, { primary: { name: name } });
      if (question) {
        const id = fieldsetSliceTypes.includes(question.slice_type)
          ? `${name}-0`
          : name;
        output.push(
          <p key={name}>
            <a
              href={`#${id}`}
              onClick={(e) => Utilities.scrollToAndFocusOn(e, id, 120)}>
              Error in the field{' '}
              <strong className='label-value'>
                “{question.primary.question}”
              </strong>{' '}
              - {errors[name]}
            </a>
          </p>
        );
      }
    }
    return output;
  }

  render() {
    if (this.props.error) {
      return <Error400 path={`event/${this.props.uid}`} />;
    }

    const { content, user } = this.props;
    if (!content || !content.data) {
      return <Loading full={true} testId='form' />;
    }
    if (user === null) {
      return <Loading full={true} />;
    }

    const { initialValues, submitted, submitError } = this.state;
    const { introduction, conclusion } = content.data;
    const hasConclusion = conclusion && conclusion.length > 0;
    const isLoginRequired = content.data.require_login === 'Require';
    const isLoginDisplayed = isLoginRequired && !user;

    if (isLoginDisplayed) {
      const loginPath = '/login';
      const currentUrl = window.location.href;
      const baseUrl = getEnvBaseUrl();
      const loginUrl = `${baseUrl}${loginPath}?redirectUrl=${encodeURIComponent(
        currentUrl
      )}`;
      window.location.replace(loginUrl);
    }

    return isLoginDisplayed ? (
      <div className='content login-required'>
        <Loading full={true} text='Redirecting...' />
      </div>
    ) : (
      <PageWrapper
        banner={content.data.settings_banner}
        content={content}
        context={`form -${content.uid}`}
        footer_content={content.data.settings_footer_content}
        footer_cta={content.data.settings_footer_cta}
        full={true}>
        <section className={this.layoutClasses(content)}>
          <div className='container'>
            <>
              <div className='content'>
                {initialValues && (
                  <Formik
                    initialValues={initialValues}
                    onSubmit={this.handleSubmit}>
                    {({
                      values,
                      errors,
                      touched,
                      isValidating,
                      isSubmitting,
                      submitCount,
                    }) => (
                      <Form role='form' aria-labelledby='introduction'>
                        {introduction && (
                          <div className='introduction' id='introduction'>
                            {PrismicRichText.render(
                              introduction,
                              PrismicConfig.linkResolver,
                              PrismicConfig.htmlSerializer
                            )}
                          </div>
                        )}

                        {content.data.body && (
                          <div className='questions'>
                            {this.renderQuestions(
                              content.uid,
                              content.data.body,
                              values,
                              errors,
                              touched
                            )}
                          </div>
                        )}
                        <div className='form-actions'>
                          {/* As validation is happening, focus on the validation screen */}
                          {isValidating &&
                            isSubmitting &&
                            Utilities.scrollToAndFocusOn(
                              null,
                              'form-validation'
                            )}

                          {!isSubmitting && (
                            <div className='actions'>
                              <button type='submit' className='button -primary'>
                                Submit
                              </button>
                            </div>
                          )}
                          <div id='form-validation' tabIndex='-1'>
                            {submitCount > 0 &&
                              !Utilities.isObjectEmpty(errors) && (
                                <div className='form-error'>
                                  <p>
                                    <Warning /> There are errors in the fields
                                    above:
                                  </p>
                                  {this.renderErrors(errors, content.data.body)}
                                </div>
                              )}
                            {submitError && (
                              <div className='form-error'>
                                <p>
                                  <Warning /> There was an error submitting this
                                  form. Please disable any adblockers and try
                                  again in Chrome, Safari or Edge browser.
                                </p>
                              </div>
                            )}
                          </div>
                          {isSubmitting && (
                            <div className='submitting'>
                              <Loading />
                            </div>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>

              <div id='conclusion' tabIndex='-1'>
                {submitted && (
                  <div className='conclusion'>
                    {hasConclusion &&
                      PrismicRichText.render(
                        conclusion,
                        PrismicConfig.linkResolver,
                        PrismicConfig.htmlSerializer
                      )}
                    {!hasConclusion && (
                      <p>Thank you, your response has been recorded.</p>
                    )}
                  </div>
                )}
              </div>
            </>
          </div>
        </section>
      </PageWrapper>
    );
  }
}

export default withPrismic(withHireup(FormPage));
