import * as React from 'react';

const IconMassage = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M18.75 6.75a5.25 5.25 0 1 0 10.5 0 5.25 5.25 0 0 0-10.5 0v0ZM34.5 40.5a6 6 0 1 0 12 0 6 6 0 0 0-12 0v0ZM7.5 31.5v6M24 31.5v6M28.5 22.758l-.622.62a3.001 3.001 0 1 0 4.244 4.244l1.5-1.5A3.001 3.001 0 0 0 34.5 24v-3a6 6 0 0 0-6-6h-9a6 6 0 0 0-6 6v3a3 3 0 0 0 .878 2.122l1.5 1.5a3.001 3.001 0 1 0 4.244-4.244l-.622-.62M1.5 37.5H27a4.5 4.5 0 1 1 0 9H1.5' />
      </svg>
    </span>
  );
};

export default IconMassage;
