import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { IconChevronDown } from '../../components/icons';
import { IconChevronDownSmall } from '../../components/icons';
import classNames from 'classnames';
import Analytics from '../../config/analytics';
import ConditionalWrapper from '../../helpers/conditionalWrapper';
import Badge from '../badge/badge';

class SiteLink extends Component {
  getLinkLocation = (link) => {
    const header = document.querySelector('header');
    const footer = document.querySelector('footer');

    if (header.contains(link)) {
      return 'Header';
    }
    if (footer.contains(link)) {
      return 'Footer';
    }
    return null;
  };

  trackClick = (event) => {
    const path = window.location.pathname;
    const location = this.getLinkLocation(event.target);

    Analytics.link(
      { label: event.currentTarget.href },
      `www.${path}.${location}Navigation.link`
    );
  };

  itemWithSubmenuClasses() {
    const hasActiveSubitem = this.props.hasActiveSubitem;
    let classes = {
      '-with-submenu': true,
      '-with-active-subitem': hasActiveSubitem,
    };
    return classNames(classes);
  }

  renderIconFromPrismic(item) {
    if (!item.image.url) {
      return null;
    }
    return (
      <img
        className='nav-item-icon'
        src={item.image.url}
        width={20}
        height={20}
        alt={item.image.alt || ''}
      />
    );
  }

  renderIconFromProps(icon) {
    return <span className='sitelink-icon'>{icon}</span>;
  }

  renderHiddenText(item) {
    return <span className='screen-reader-only'>{item.text}</span>;
  }

  renderSubmenuToggle() {
    return (
      <span className='toggle-submenu'>
        {this.props.isDesktopLayout ? (
          <IconChevronDownSmall
            width={24}
            height={24}
            aria-hidden={true}
            data-testid='icon-toggle'
          />
        ) : (
          <IconChevronDown
            width={24}
            height={24}
            aria-hidden={true}
            data-testid='icon-toggle'
          />
        )}
      </span>
    );
  }

  renderBadge(item) {
    return <Badge type={item.badge_type} small />;
  }

  renderCardSubtitle(item) {
    if (!item.description) {
      return null;
    }
    return <span className='description'>{item.description}</span>;
  }

  renderLink(item) {
    if (!item || !item.text) {
      return null;
    }

    const {
      ariaExpanded,
      ariaHaspopup,
      hasSubmenu,
      icon,
      id,
      forwardRef,
      handleClick,
      handleKeyDown,
    } = this.props;

    const isCard = item.style?.includes('Card');
    const hideText = item.style?.includes('Hide text') || this.props.hideText;

    const commonProps = {
      title: item.link?.kind
        ? `${item.description || item.text} (${item.link.kind})`
        : `${item.description || item.text}`,
      id: id,
      'aria-expanded': ariaExpanded,
      'aria-haspopup': ariaHaspopup,
    };

    const eventHandlers = {
      onKeyDown: (e) => handleKeyDown && handleKeyDown(e),
      onClick: (e) => handleClick && handleClick(e),
    };

    const renderNavItemWithSubmenu = () => {
      if (!ariaHaspopup) {
        return (
          <span className='a'>
            {!hideText && item.text}
            {hideText && <>{this.renderHiddenText(item)}</>}
          </span>
        );
      }

      return (
        <a
          {...commonProps}
          {...eventHandlers}
          href={Link.url(item.link, PrismicConfig.linkResolver)}
          className={this.itemWithSubmenuClasses()}>
          {!hideText && item.text}
          {hideText && <>{this.renderHiddenText(item)}</>}
          {ariaHaspopup && <>{this.renderSubmenuToggle()}</>}
        </a>
      );
    };

    if (!item.link) {
      return (
        <span className='a'>
          {!hideText && item.text}
          {icon && <>{this.renderIconFromProps(icon)}</>}
          {item.image && <>{this.renderIconFromPrismic(item)}</>}
          {hideText && <>{this.renderHiddenText(item)}</>}
          {hasSubmenu && <>{renderNavItemWithSubmenu()}</>}
        </span>
      );
    }

    switch (item.link.link_type) {
      case 'Document':
        return (
          <>
            {!hasSubmenu && (
              <ConditionalWrapper
                condition={isCard}
                wrapper={(children) => (
                  <div className='card-container'>{children}</div>
                )}>
                <>
                  <NavLink
                    {...commonProps}
                    ref={forwardRef}
                    to={Link.url(item.link, PrismicConfig.linkResolver)}
                    onClick={this.trackClick}
                    target='_parent'>
                    {icon && <>{this.renderIconFromProps(icon)}</>}
                    {item.image && <>{this.renderIconFromPrismic(item)}</>}
                    {!hideText && item.text}
                    {hideText && <>{this.renderHiddenText(item)}</>}
                  </NavLink>
                  {isCard && item.show_badge && <>{this.renderBadge(item)}</>}
                  {isCard && <>{this.renderCardSubtitle(item)}</>}
                </>
              </ConditionalWrapper>
            )}

            {hasSubmenu && <>{renderNavItemWithSubmenu()}</>}
          </>
        );

      case 'Media':
        return (
          <a
            {...commonProps}
            href={Link.url(item.link, PrismicConfig.linkResolver)}
            ref={forwardRef}
            target='_blank'
            rel='noopener noreferrer'
            download
            onClick={this.trackClick}>
            {icon && <>{this.renderIconFromProps(icon)}</>}
            {item.image && <>{this.renderIconFromPrismic(item)}</>}
            {!hideText && item.text}
            {hideText && <>{this.renderHiddenText(item)}</>}
            {hasSubmenu && <>{renderNavItemWithSubmenu()}</>}
          </a>
        );

      case 'Web':
        return (
          <>
            {!hasSubmenu && (
              <ConditionalWrapper
                condition={isCard}
                wrapper={(children) => (
                  <div className='card-container'>{children}</div>
                )}>
                <a
                  {...commonProps}
                  href={Link.url(item.link, PrismicConfig.linkResolver)}
                  ref={forwardRef}
                  target={item.link.target}
                  rel='noopener'
                  onClick={this.trackClick}>
                  {icon && <>{this.renderIconFromProps(icon)}</>}
                  {item.image && <>{this.renderIconFromPrismic(item)}</>}
                  {hideText && <>{this.renderHiddenText(item)}</>}
                  {!hideText && item.text}
                </a>
                {isCard && item.show_badge && <>{this.renderBadge(item)}</>}
                {isCard && <>{this.renderCardSubtitle(item)}</>}
              </ConditionalWrapper>
            )}

            {hasSubmenu && <>{renderNavItemWithSubmenu()}</>}
          </>
        );

      case 'Any':
        return (
          <>
            {!hasSubmenu && <span className='a -any'>{item.text}</span>}

            {hasSubmenu && <>{renderNavItemWithSubmenu()}</>}
          </>
        );
      default:
        console.warn('Unknown Link Type', item);
        return null;
    }
  }

  render() {
    const { link } = this.props;

    if (!link) {
      return null;
    }

    return this.renderLink(link);
  }
}
export default React.forwardRef((props, ref) => (
  <SiteLink {...props} forwardRef={ref} />
));
