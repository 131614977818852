import React, { useState, useEffect } from 'react';
import { MdChevronRight } from 'react-icons/md';
import classNames from 'classnames';
import { ReactComponent as HireupLogo } from '../../assets/img/hireup.brand.master.svg';
import Analytics from '../../config/analytics';

import './header-minimal.scss';

const HeaderMinimal = () => {
  const [scrolledToTop, setScrolledToTop] = useState(true);

  const handleScroll = (scrolledToTop) => {
    if (window.pageYOffset !== 0 && scrolledToTop) {
      setScrolledToTop(false);
    } else if (window.pageYOffset === 0 && !scrolledToTop) {
      setScrolledToTop(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll(scrolledToTop));
    return () => {
      window.removeEventListener('scroll', () => handleScroll(scrolledToTop));
    };
  }, [scrolledToTop]);

  return (
    <header className='header-minimal'>
      <div
        className={classNames('header-minimal-fixed', {
          '-scroll-top': scrolledToTop,
        })}>
        <a href='#main' className='content-skip'>
          Skip to main content
          <MdChevronRight />
        </a>
        <div className='container'>
          <div className='section-gutter inner-row'>
            <div className='brand'>
              <a href='/' onClick={() => Analytics.link('/')}>
                <HireupLogo role='image' aria-label='Home - Hireup' />
              </a>
            </div>
            <a
              className='button -inverse'
              href='/'
              onClick={() => Analytics.link('/')}>
              View main site
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderMinimal;
