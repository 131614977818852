const supportWorker = {
  twoColumnLayout: {
    background_color: 'Mint Section Full',
    padding: 'none',
    content: [
      {
        type: 'heading2',
        text: 'Flexible work. Paid well.  ',
        spans: [],
      },
      {
        type: 'paragraph',
        text: 'Work your own hours while supporting people with disability near you. We employ our support workers, providing better pay and benefits, and removing the hassle of contract work. ',
        spans: [],
      },
      {
        type: 'list-item',
        text: 'Choose your own hours and clients',
        spans: [],
      },
      {
        type: 'list-item',
        text: 'Insurance, sick pay, annual leave and super covered',
        spans: [],
      },
      {
        type: 'list-item',
        text: 'Paid training, development and learning opportunities',
        spans: [],
      },
      {
        type: 'paragraph',
        text: 'Apply today',
        spans: [
          {
            start: 0,
            end: 11,
            type: 'label',
            data: {
              label: 'button-primary',
            },
          },
          {
            start: 0,
            end: 11,
            type: 'hyperlink',
            data: {
              link_type: 'Web',
              url: 'https://app.hireup.com.au/registration/support-worker',
            },
          },
        ],
      },
    ],
    content_two: [
      {
        type: 'image',
        url: 'https://images.prismic.io/hireup/cad83b51-e3f9-4ed8-8d0a-228bfa09fada_hireup-support-workers-build-your-team.png?auto=compress,format',
        alt: 'Three Hireup support workers are standing together. A woman is wearing a yellow top, another woman is wearing a orange and brown HIjab and a man is wearing a blue Hireup t-shirt.',
        copyright: null,
        dimensions: {
          width: 412,
          height: 452,
        },
      },
    ],
  },
};

export default supportWorker;
