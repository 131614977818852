import * as React from 'react';

const IconBookPointing = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)'>
          <path d='M16.34 36.44a42.001 42.001 0 0 0-12-2.12 2.98 2.98 0 0 1-2.82-3V4.6a3 3 0 0 1 3.18-3C17.36 2.26 24 6.78 24 10.22c0-3.42 7.44-7.9 19.28-8.6a2.98 2.98 0 0 1 3.22 3v25.2M24 10.22V18' />
          <path d='M18.68 13.1a48.32 48.32 0 0 0-11.14-2.6M38.82 18.44c.7-.12 1.4-.22 2.14-.3M18.68 20.72a49.878 49.878 0 0 0-11.14-2.58M18.68 28.82A50.434 50.434 0 0 0 7.44 26.2M29.8 13.12a50.383 50.383 0 0 1 11.26-2.62M46.28 46.5l.2-2.24a6 6 0 0 0-4.68-6.4l-7.3-1.62V24.76a3.76 3.76 0 1 0-7.5 0v18.4l-3-2.22a3.06 3.06 0 0 0-4.28 4.3l.94 1.26' />
        </g>
        <defs>
          <clipPath id='a'>
            <path d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconBookPointing;
