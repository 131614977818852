import * as React from 'react';

const IconBathtub = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M27.5 28.5h19l-1.32 8a12 12 0 0 1-11.84 10H14.66a12 12 0 0 1-11.84-10l-1.32-8h11' />
        <path d='M26 39.5H14a1.52 1.52 0 0 1-1.5-1.5v-9.5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3V38a1.52 1.52 0 0 1-1.5 1.5ZM31.5 7.5a6 6 0 1 1 12 0v21' />
        <path d='M25.5 13.5a6 6 0 1 1 12 0h-12Z' strokeLinecap='round' />
      </svg>
    </span>
  );
};

export default IconBathtub;
