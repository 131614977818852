// Prismic restricts the locales to a limited set available languages.
// If more open up in the future, we should:
// 1. Explicitly provide script variations (eg zh-hant)
// 2. Set the country of all of these to Australia
// This would enable a much more representative language code, eg
// zhhant-au for Australian Cantonese speakers
// zh-au for Australian Mandarin speakers
// ta-au for Australian Tamil speakers
// Because you can be Australian and speak a non-English language, obviously.

// The order of these languages in the array will be maintained when available translations are presented

const locales = [
  {
    label: 'English',
    label_en: 'English',
    code: 'en-au', // An ISO-639 language code and ISO-03166 country code
    language_toggle: 'Change Language',
    default: true,
  },
  {
    label: 'Easy English',
    label_en: 'Easy English',
    code: 'ee-au', // No iso code exists, creating one here
    language_toggle: 'Change Language',
  },
  {
    label: 'اَلْعَرَبِيَّةُ',
    label_en: 'Arabic',
    code: 'ar-lb',
    language_toggle: 'العربيةتغيير اللغة',
    direction: 'rtl',
    has_alternative_font: true,
  },
  {
    label: '简体中文',
    label_en: 'Chinese - Simplified',
    code: 'zh-cn',
    language_toggle: '改变语言',
    has_alternative_font: true,
  },
  {
    label: '繁體中文',
    label_en: 'Chinese - Traditional',
    code: 'zh-hk',
    language_toggle: '改變語言',
    has_alternative_font: true,
  },
  {
    label: 'Ελληνικά',
    label_en: 'Greek',
    code: 'el-gr',
    language_toggle: 'άλλαξε γλώσσα',
  },
  {
    label: 'हिंदी',
    label_en: 'Hindi',
    code: 'hi-in',
    language_toggle: 'भाषा बदलो',
  },
  {
    label: 'Tiếng Việt',
    label_en: 'Vietnamese',
    code: 'vi-vn',
    language_toggle: 'thay đổi ngôn ngữ',
    has_alternative_font: true,
  },
];

module.exports = locales;
