import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import './assets/scss/base.scss';

ReactDOM.render(<App />, document.getElementById('root'));

// Service worker remains unimplemented
serviceWorker.unregister();
