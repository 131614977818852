import React, { useEffect } from 'react';
import { withPrismic } from '../../prismic/prismic';
import NewsPost from '../../components/news/news';
import './related-pages.scss';

const RelatedArticles = ({ prismic, uid, navigation }) => {
  useEffect(() => {
    if (prismic && uid && !navigation) {
      prismic.getNavigation(uid);
    }
  });

  if (!navigation || !navigation[uid]) {
    return null;
  }

  const isNotCurrentPage = (item) => {
    const path = window.location.pathname.split('/')[1];
    return item.primary.link.uid !== path;
  };

  const renderPosts = () => {
    const navItems =
      navigation[uid].data.related_pages.filter(isNotCurrentPage);

    return navItems.map((post) => (
      <NewsPost primary={post.primary} key={post.primary.title[0].text} />
    ));
  };

  return (
    <>
      <div className='related-pages-nav'>
        <div className='container'>
          <div className='content'>
            <h2 className='title'>Related pages</h2>
          </div>
          <nav className='posts'>{renderPosts()}</nav>
        </div>
      </div>
    </>
  );
};

export default withPrismic(RelatedArticles);
