import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import _kebabCase from 'lodash.kebabcase';
import './text.scss';

export default class TextLayout extends Component {
  layoutClasses() {
    const { primary } = this.props;

    let classes = {
      'layout-text': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-padding-${_kebabCase(primary.padding)}`]: primary.padding,
      [`-container-${_kebabCase(primary.container)}`]:
        primary.container !== 'None',
      [`-align-${_kebabCase(primary.alignment)}`]: !!primary.alignment,
    };
    return classNames(classes);
  }

  render() {
    const { primary } = this.props;

    if (!primary) {
      return null;
    }

    return (
      <section className={this.layoutClasses()}>
        <div className='container'>
          <div className='content'>
            {primary.content &&
              PrismicRichText.render(
                primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
          </div>
        </div>
      </section>
    );
  }
}
