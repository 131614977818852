const primarySupport = require('./primarySupport');

const helpWithHomework = {
  supportName: 'Homework',
  supportIcon: 'homework',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'education-and-employment',
};

module.exports = helpWithHomework;
