const primarySupport = require('./primarySupport');

const musicTherapy = {
  supportName: 'Music therapy',
  supportIcon: 'music',
  primarySupport: primarySupport,
  twoColumnLayout: {
    contentTitle: 'Music therapy support happening throughout our community',
    content: [
      {
        alt: 'Andrew smiling outside on a rope bridge above a naturescape',
        dimensions: {
          height: 541,
          width: 960,
        },
        linkTo: {
          id: 'YQkxbxEAACMAaCv_',
          type: 'news',
          lang: 'en-au',
          uid: 'the-mental-health-benefits-of-music-therapy',
        },
        type: 'image',
        url: 'https://images.prismic.io/hireup/f06d3aa8-47ce-4221-8d7b-70c35ba390dc_Andrew1.JPG',
      },
    ],
    content_two: [
      {
        type: 'paragraph',
        text: '“Andrew’s music therapy sessions are focused on improving Andrew’s speech, particularly his expression, volume and confidence.”',
        spans: [
          {
            start: 0,
            end: 380,
            type: 'label',
            data: {
              label: 'quote',
            },
          },
        ],
      },
      {
        type: 'paragraph',
        text: 'Read about music therapy happening in our community',
        spans: [
          {
            start: 0,
            end: 150,
            type: 'hyperlink',
            data: {
              type: 'news',
              slug: 'the-mental-health-benefits-of-music-therapy',
              lang: 'en-au',
              uid: 'the-mental-health-benefits-of-music-therapy',
              link_type: 'Document',
            },
          },
        ],
      },
    ],
  },
  faqs: {
    content: [
      {
        type: 'heading2',
        text: 'Learn more about music therapy support',
        spans: [],
      },
    ],
    items: [
      {
        question: [
          {
            type: 'heading3',
            text: 'What does music therapy support involve?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Music therapy may help with speech, communication and social skills, physical ability,  grief and loss, and pain management. Music therapy can also have a number of mental health benefits including:',
            spans: [],
          },
          {
            type: `list-item`,
            text: `improving confidence and self esteem`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `decreasing anxiety`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `increased motivation`,
            spans: [],
          },
          {
            type: `list-item`,
            text: `emotional release`,
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'What can music therapy be used to treat?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Music therapy aims to achieve physical, cognitive or socio-emotional goals, and may include singing, song writing, musical improvisation, receptive music listening or many other music based interventions.',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'Why is music therapy support beneficial?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Music therapy can provide a range of emotional, physical and cognitive benefits. Hireup disability support worker, Freya, says: ',
            spans: [],
          },
          {
            type: 'paragraph',
            text: '“As a disability support worker and music therapist, I’ve witnessed first-hand how learning an instrument develops motivation, social skills and engagement, attention and memory, coordination and motor skills, emotional regulation and expression, academic skills in language and maths, self-discipline and feelings of achievement.”',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'Who runs a music therapy session?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Music therapy support sessions are usually run by a musical therapist. Hireup clients can also book their support workers to assist them during the therapy.',
            spans: [],
          },
        ],
      },
    ],
  },
  relatedArticlesCategory: 'music-therapy',
};

module.exports = musicTherapy;
