import React, { Component } from 'react';
import { getEnvBaseUrl } from '../config/api';
import Loading from '../components/loading/loading';
import { withHireup } from '../hireup/hireup';
import Helmet from 'react-helmet';
import Error400 from './error-400';
import _get from 'lodash.get';
import _kebabCase from 'lodash.kebabcase';
import {
  parseISO,
  format,
  isBefore,
  isAfter,
  subHours,
  addDays,
} from 'date-fns';
import './identification.scss';

class IdentificationPage extends Component {
  state = {
    loading: false,
    loaded: false,
    settings: null,
    recentBooking: null,
    currentBooking: null,
    nextBooking: null,
    error: null,
  };

  componentDidMount() {
    // Update the bookings and time every minute
    this.interval = setInterval(() => this.loadBookings(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate() {
    if (!this.props.user) {
      return null;
    }
    if (!this.state.loading && !this.state.loaded) {
      this.loadBookings();
    }
  }

  isBookingRecent = (booking, recentBooking) => {
    const bookingTo = new Date(booking.to);

    const threeHoursAgo = subHours(new Date(), 3);
    // If the booking has ended within the last three hours
    if (isAfter(bookingTo, threeHoursAgo) && isBefore(bookingTo, new Date())) {
      // If a more recent booking already exists, check if this is more recent than that
      if (recentBooking && isAfter(bookingTo, new Date(recentBooking.to))) {
        return false;
      }
      return true;
    }
    return false;
  };

  isBookingNext = (booking, nextBooking) => {
    const bookingFrom = new Date(booking.from);

    // If the booking is in the future
    if (isAfter(bookingFrom, new Date())) {
      // If there's no nextBooking already set, this booking is next
      if (!nextBooking) {
        return true;
      }
      // If a next booking is set, if this starts earlier, set the next booking to this one
      if (isBefore(bookingFrom, new Date(nextBooking.from))) {
        return true;
      }
    }
    return false;
  };

  isBookingCurrent = (booking) => {
    // Assume only one booking can be current, so don't check against any previously matching currentBooking
    return (
      isBefore(parseISO(booking.from), new Date()) &&
      isAfter(parseISO(booking.to), new Date())
    );
  };

  loadBookings = async () => {
    this.setState({ loading: true });
    const from = subHours(new Date(), 12).toISOString();
    const to = addDays(new Date(), 14).toISOString();

    this.props.hireup
      .getBookings(from, to)
      .then((response) => {
        let bookings = _get(response, 'response.data.rows');

        // Sort the bookings by their from date, newest first
        bookings = bookings.sort((a, b) => {
          return new Date(a.from) - new Date(b.from);
        });

        this.setState({ bookings });
        let recentBooking = null;
        let currentBooking = null;
        let nextBooking = null;
        bookings.forEach((b) => {
          if (b.status === 1) {
            return null;
          } else if (this.isBookingRecent(b, recentBooking)) {
            recentBooking = b;
          } else if (this.isBookingCurrent(b)) {
            currentBooking = b;
          } else if (this.isBookingNext(b, nextBooking)) {
            nextBooking = b;
          }
        });
        this.setState({ loaded: true });
        this.setState({ recentBooking });
        this.setState({ currentBooking });
        this.setState({ nextBooking });
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  renderBooking(booking, state) {
    const clientName = _get(booking, 'client.displayName');
    const bookingSuburb = _get(booking, 'suburb');
    const bookingState = _get(booking, 'state');
    const bookingFrom = format(new Date(booking?.from), 'do MMM, h:mm aaa');
    const bookingTo = format(new Date(booking?.to), 'do MMM, h:mm aaa');

    return (
      <div className={`booking ${_kebabCase(state)}`} key={booking.from}>
        <p className='type'>{state} booking:</p>
        <p className='details'>
          <span className='client'>
            <span className='client-name'>{clientName}</span>
            <span className='address'>
              {' '}
              in {bookingSuburb}, {bookingState}
            </span>
          </span>
          <span className='time -from'>from {bookingFrom}</span>
          <span className='time -to'>to {bookingTo}</span>
        </p>
      </div>
    );
  }

  renderBookings() {
    const { recentBooking, currentBooking, nextBooking } = this.state;
    let output = [];
    if (recentBooking) {
      output.push(this.renderBooking(recentBooking, 'Recent'));
    }
    if (currentBooking) {
      output.push(this.renderBooking(currentBooking, 'Current'));
    }
    if (nextBooking) {
      output.push(this.renderBooking(nextBooking, 'Next'));
    }
    return output;
  }

  renderIdentification() {
    const { user } = this.props;
    const {
      recentBooking,
      currentBooking,
      nextBooking,
      loading,
      loaded,
      error,
    } = this.state;
    if (_get(user, 'auth.type') !== 2) {
      return (
        <p className='empty'>
          <strong>You are not logged in with a support worker account.</strong>
          <br />
          <br />
          Digital identification is only available for Hireup support workers
        </p>
      );
    }
    if (_get(user, 'acnt.status') !== 4) {
      return (
        <p className='empty'>
          <strong>
            You are logged in with a support worker account that is not yet
            approved.
          </strong>
          <br />
          <br />
          Digital identification is only available for approved Hireup support
          workers
        </p>
      );
    }
    if (error) {
      return <p className='error'>Error loading Bookings</p>;
    }
    if (loading && !loaded) {
      return <Loading full={false} testId='id' />;
    }

    const workerFullName = `${_get(user, 'auth.firstName')} ${_get(
      user,
      'auth.lastName'
    )}`;
    const workerProfile = _get(user, 'acnt.profilePicUrl');
    const workerSuburb = _get(user, 'acnt.suburb');
    const workerState = _get(user, 'acnt.state');

    return (
      <>
        <p className='live'>
          Live Identification {format(new Date(), 'h:mm aaa')}
        </p>
        <img
          src={workerProfile}
          className='worker-profile'
          alt={workerFullName}
        />
        <h1 className='worker-name'>{workerFullName}</h1>
        <p className='worker-address'>
          of {workerSuburb}, {workerState}
        </p>

        <div className='authority'>
          Employed by <a href='/'>Hireup Pty Ltd</a> to provide registered{' '}
          <strong>NDIS disability support work</strong>.
        </div>

        {!recentBooking && !currentBooking && !nextBooking && (
          <p className='empty'>
            <strong>No current or upcoming bookings in the next 14 days</strong>
            .
          </p>
        )}

        {(recentBooking || currentBooking || nextBooking) && (
          <div>{this.renderBookings()}</div>
        )}
      </>
    );
  }

  render() {
    if (this.props.error) {
      return <Error400 path={`event/${this.props.uid}`} />;
    }
    const { user } = this.props;
    if (user === null) {
      return <Loading full={true} testId='id' />;
    }
    const { recentBooking, currentBooking } = this.state;

    const meta = {
      title: 'Hireup Identification',
      description: 'Digital Identification for Hireup Support Workers',
    };
    
    if (!user) {
      const loginPath = '/login';
      const currentUrl = window.location.href;
      const baseUrl = getEnvBaseUrl();
      const loginUrl = `${baseUrl}${loginPath}?redirectUrl=${encodeURIComponent(
        currentUrl
      )}`;
      window.location.replace(loginUrl);
    }

    return (
      <>
        <Helmet>
          <title>{meta.title}</title>
          <meta name='description' content={meta.description} />
          <meta itemProp='name' content={meta.title} />
          <meta itemProp='description' content={meta.description} />
        </Helmet>

        {!user ? (
        <div className='content login-required'>
          <Loading full={true} text='Redirecting...' />
        </div>
        ) : (
        <section className='identification'>
          <div className='container'>
            <div className='content'>
              <div
                className={`stripe ${
                  user && (recentBooking || currentBooking)
                    ? '-active'
                    : '-inactive'
                }`}></div>

              <div className='content-wrapper'>
                <div className='id'>{this.renderIdentification()}</div>
              </div>
            </div>

            <div className='return'>
              <a href='https://app.hireup.com.au'>Hireup Dashboard</a>
              <a href='/form/usability-feedback/'>Report a problem</a>
            </div>
          </div>
        </section>
        )}
      </>
    );
  }
}

export default withHireup(IdentificationPage);
