import * as React from 'react';

const IconDogWalking = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M8.336 11.972a5.236 5.236 0 1 0 0-10.472 5.236 5.236 0 0 0 0 10.472Z' />
        <path d='M8.335 15.71a3.864 3.864 0 0 0-3.864 3.865v23.06a3.864 3.864 0 0 0 7.728 0V25.31l3.88 2.442a3.397 3.397 0 0 0 3.62-5.749l-9.71-5.922a3.849 3.849 0 0 0-1.654-.37Z' />
        <path d='M28.72 46.5a3.439 3.439 0 0 1-3.434-3.547c.025-.781.316-1.53.824-2.123l3.26-3.802c.21-.246.384-.52.518-.815l4.273-9.402a4.475 4.475 0 0 1 4.464-3.132 2.722 2.722 0 0 1 2.477 1.628 1.893 1.893 0 0 0 1.774 1.056 2.051 2.051 0 0 1 2.024 2.075 3.26 3.26 0 0 1-3.222 3.295 3.637 3.637 0 0 0-3.515 4.726L40.426 46.5h1.79' />
        <path d='M32.37 39.34a6.128 6.128 0 0 1 .591 5.682l-.59 1.478h2.684M25.358 43.78l-.147.035a4.23 4.23 0 0 1-4.95-2.53l-.42-1.05M32.719 29.984l6.465 2.683' />
        <path d='M20.209 27.366c3.721 3.577 7.729 3.134 11.479 3.134h.812' />
      </svg>
    </span>
  );
};

export default IconDogWalking;
