import React from 'react';
import { supportTypes } from './supportTypes';
import _camelCase from 'lodash.camelcase';
import Utilities from '../../config/utilities';
import constants from '../../config/constants';

export const formatSupportName = (support) => {
  if (!support) {
    return null;
  }
  let string = Utilities.removeWord(support, 'NDIS-');

  return Utilities.toSentenceCase(string);
};

export const isValidPrimarySupport = (primarySupport) => {
  if (supportTypes[_camelCase(primarySupport)]) {
    return true;
  } else {
    return false;
  }
};

export const isValidSecondarySupport = (secondarySupport) => {
  const secondarySupportsArray = Object.values(supportTypes);

  return secondarySupportsArray.some((obj) =>
    Object.keys(obj).includes(_camelCase(secondarySupport))
  );
};

export const isValidState = (state) =>
  constants.defaults.states.includes(state.toUpperCase());

export const generateLocationText = (state, suburb) => {
  if (!suburb && !state) {
    return '';
  }

  if (suburb) {
    return ` in ${suburb}, ${state}`;
  }

  if (state) {
    return ` in ${state}`;
  }
};

export const generateHeadingText = (support, state, suburb) => {
  if (!support) {
    return null;
  }

  let headingText;

  switch (support.primarySupport.appName) {
    case 'Therapy support':
      headingText = `Find disability support workers who can help with your ${support.supportName.toLowerCase()} goals${generateLocationText(
        state,
        suburb
      )}`;
      break;

    default:
      headingText = `Find support workers who can assist with ${support.supportName.toLowerCase()}${generateLocationText(
        state,
        suburb
      )}`;
      break;
  }
  return headingText;
};

export const generateIntroText = (support, numberOfWorkers) => {
  if (!support) {
    return null;
  }

  let introText;

  switch (support.primarySupport.appName) {
    case 'Therapy support':
      introText = (
        <>
          <p>
            Hireup is Australia’s largest NDIS registered online platform,
            connecting you with trusted and verified support workers.
          </p>
          <p>
            {`We have ${
              numberOfWorkers ? `${numberOfWorkers}` : ''
            } support workers who can help you work towards your ${support.supportName.toLowerCase()} goals.`}
          </p>
          <p>Join today to find the right support worker near you.</p>
        </>
      );
      break;

    default:
      introText = (
        <>
          <p>
            Hireup is Australia’s largest NDIS registered online platform,
            connecting you with trusted and verified support workers.
          </p>
          <p>
            {`We have ${
              numberOfWorkers ? `${numberOfWorkers}` : ''
            } support workers who can assist with ${support.primarySupport?.name.toLowerCase()} support, many of whom are experienced with ${support.supportName.toLowerCase()}.`}
          </p>
          <p>Join today to find the right support worker near you.</p>
        </>
      );
      break;
  }
  return introText;
};
