import React, { Component } from 'react';
import classNames from 'classnames';
import './trustpilot.scss';

export default class Trustpilot extends Component {
  layoutClasses() {
    let classes = {
      'layout-trustpilot': true,
    };
    return classNames(classes);
  }

  loadTrustpilot = (callback) => {
    const existingScript = document.getElementById('trustpilotScript');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src =
        '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      script.id = 'trustpilotScript';
      document.head.appendChild(script);
      script.onload = () => {
        if (callback) {
          callback();
        }
      };
    }
    if (existingScript && callback) {
      callback();
    }
  };

  createMarkup(widget) {
    return { __html: widget };
  }

  render() {
    const { primary } = this.props;

    const fallback = `<!-- TrustBox widget - Carousel -->
			<div class="trustpilot-widget" data-locale="en-AU" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5d300c7a0249cc000124a84c" data-style-height="140px" data-style-width="100%" data-theme="light" data-tags="PSS" data-stars="4,5" data-review-languages="en" data-text-color="#131b39">
			  <a href="https://au.trustpilot.com/review/hireup.com.au" target="_blank" rel="noopener">Trustpilot</a>
			</div>
			<!-- End TrustBox widget -->`;

    this.loadTrustpilot();

    return (
      <section className={this.layoutClasses()}>
        <div className='container'>
          <div
            dangerouslySetInnerHTML={this.createMarkup(
              primary?.widget || fallback
            )}
          />
        </div>
      </section>
    );
  }
}
