import React, { Component } from 'react';
import classNames from 'classnames';
import SiteLink from '../site-link/site-link';
import { withHireup } from '../../hireup/hireup';
import Analytics from '../../config/analytics';
import keys from '../../helpers/keys';
import _kebabCase from 'lodash.kebabcase';
import './nav-item.scss';

class NavItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
    this.ref = React.createRef();
    this.childRef = React.createRef();
  }

  handleClickOutside = (event) => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.collapseSubmenu();
    }
  };

  handleKeyOutside = (event) => {
    const relevantKeys = [keys.tab, keys.escape];

    if (!relevantKeys.includes(event.key)) {
      return;
    }

    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.collapseSubmenu();
    }

    if (event.key === keys.escape) {
      this.collapseSubmenu();
    }
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    document.addEventListener('keyup', this.handleKeyOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('keyup', this.handleKeyOutside, true);
  }

  trackClick = (event) => {
    const path = window.location.pathname;

    Analytics.link(
      { label: event?.target?.textContent },
      `www.${path}.HeaderNavigation.menuItem`,
      true
    );
  };

  expandSubmenu = () => {
    this.setState({ isFocused: true });
  };

  collapseSubmenu = () => {
    this.setState({ isFocused: false });
  };

  toggleSubmenu = (event) => {
    if (this.state.isFocused) {
      this.collapseSubmenu();
    } else {
      this.expandSubmenu();
      this.trackClick(event);
    }
  };

  focusFirstItem() {
    if (!this.childRef.current) {
      return;
    }

    setTimeout(() => {
      this.childRef.current.focus();
    }, 100);
  }

  handleClick = (event) => {
    event.preventDefault();
    this.toggleSubmenu(event);
  };

  handleKeyDown = (event) => {
    const relevantKeys = [keys.enter, keys.space, keys.arrowDown, keys.arrowUp];

    if (!relevantKeys.includes(event.key)) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    switch (event.key) {
      case keys.space:
      case keys.enter:
        this.toggleSubmenu();
        this.focusFirstItem();

        break;
      case keys.arrowDown:
        this.expandSubmenu();
        this.focusFirstItem();

        break;
      case keys.arrowUp:
        this.collapseSubmenu();
        break;
      default:
        break;
    }
  };

  layoutClasses(primary) {
    let classes = {
      'nav-item': this.props.collapsedSubmenus,
      [`-style-${_kebabCase(primary.style)}`]: primary.style,
      [`-subnav-focus`]: this.state.isFocused,
      [`-hide-text`]: this.props.hideText,
    };
    return classNames(classes);
  }

  submenuClasses(primary, numberOfCards) {
    const isCard = primary.style?.includes('Card');
    let classes = {
      'subnav-item': true,
      [`-style-${_kebabCase(primary.style)}`]: primary.style,
      [`-has-badge`]: primary.show_badge,
      [`-grid-row-2`]: isCard && numberOfCards === 2,
      [`-grid-row-4`]: isCard && numberOfCards === 1,
    };
    return classNames(classes);
  }

  getNumberOfCardsInSubmenu(items) {
    return items.filter((item) => item.style?.includes('Card')).length;
  }

  renderSubmenu = (items) => {
    const { collapsedSubmenus } = this.props;
    const numberOfCards = collapsedSubmenus
      ? this.getNumberOfCardsInSubmenu(items)
      : null;
    let menu = [];

    items.map((item, i) => {
      const isFirstItem = i === 0;
      if (item.text && item.link) {
        return menu.push(
          <li
            key={i}
            className={
              collapsedSubmenus
                ? this.submenuClasses(item, numberOfCards)
                : null
            }>
            <SiteLink
              ref={isFirstItem && collapsedSubmenus ? this.childRef : null}
              link={item}
              id={item.id}
            />
          </li>
        );
      } else {
        return false;
      }
    });
    return menu;
  };

  checkActiveSubitem = (items) => {
    const path = window.location.pathname.replace(/\//g, '');
    const filter = items.filter((item) => item.link?.uid === path);

    if (filter.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const {
      primary,
      items,
      user,
      collapsedSubmenus,
      isDesktopLayout,
      icon,
      hideText,
    } = this.props;
    const { isFocused } = this.state;

    if (!primary || !items) {
      return null;
    }

    const isLoggedIn = user && user.auth;
    const hasSubmenu = items?.[0]?.text ? true : false;

    if (primary.display === 'No users') {
      return null;
    }

    if (primary.display === 'Logged out users' && isLoggedIn) {
      return null;
    }

    if (primary.display === 'Logged in users' && !isLoggedIn) {
      return null;
    }

    // Items are hidden with inline styles instead of
    // stylesheet due to testing library limitation
    const style = {
      display: 'none',
    };

    const hideSubmenu = collapsedSubmenus && !isFocused;

    return (
      <li ref={this.ref} className={this.layoutClasses(primary)}>
        <SiteLink
          link={primary}
          isDesktopLayout={isDesktopLayout}
          handleClick={this.handleClick}
          handleKeyDown={this.handleKeyDown}
          hasSubmenu={hasSubmenu}
          hasActiveSubitem={this.checkActiveSubitem(items)}
          icon={icon}
          hideText={hideText}
          ariaHaspopup={hasSubmenu && collapsedSubmenus ? true : null}
          ariaExpanded={
            hasSubmenu && collapsedSubmenus ? isFocused : null
          }></SiteLink>
        {hasSubmenu && (
          <>
            {collapsedSubmenus && <div className='submenu-bg'></div>}
            <ul
              style={hideSubmenu ? style : null}
              className='submenu'
              aria-label={primary.text}>
              {this.renderSubmenu(items)}
            </ul>
          </>
        )}
      </li>
    );
  }
}

export default withHireup(NavItem);
