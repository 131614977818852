import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { withPrismic } from '../../prismic/prismic';
import Update from '../../components/update/update';
import Loading from '../../components/loading/loading';
import TrackVisibility from 'react-on-screen';
import _kebabCase from 'lodash.kebabcase';
import './updates.scss';

class UpdatesLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updates: null,
      currentPage: 1, // TODO: Load more pages in a slider or infinite scroll
      nextPage: 2,
    };
  }

  componentDidMount() {
    this.loadUpdates(
      this.props.primary.results_per_page || 20,
      this.state.currentPage,
      this.props.primary.filter_category
    );
  }

  layoutClasses(primary, updates) {
    let classes = {
      'layout-updates': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
    };
    if (updates && updates.length) {
      classes[`-count-${updates.length}`] = updates && updates.length;
    }
    return classNames(classes);
  }

  async loadUpdates(pageSize = 20, page = 1, filterCategory = false) {
    this.setState({ loading: true });
    let updates = await this.props.prismic.getUpdates(
      pageSize,
      page,
      filterCategory
    );
    // Add the incoming updates to the current state
    if (this.state.updates) {
      updates.results = this.state.updates.results.concat(updates.results);
    }
    this.setState({ updates });
    this.setState({ loading: false });
  }

  loadMore(isVisible) {
    const { updates } = this.state;
    if (
      isVisible &&
      !this.state.loading &&
      updates.page < updates.total_pages
    ) {
      this.loadUpdates(
        this.props.primary.results_per_page || 3,
        updates.page + 1,
        this.props.primary.filter_category
      );
    }
  }

  renderUpdates() {
    const { updates } = this.state;
    return (
      updates.results &&
      updates.results.map((update, i) => <Update primary={update} key={i} />)
    );
  }

  render() {
    const { primary } = this.props;
    const { updates } = this.state;

    if (!updates) {
      return null;
    }

    return (
      <section className={this.layoutClasses(primary, updates)}>
        {(primary.title || primary.content) && (
          <div className='container'>
            <div className='content'>
              {primary.title &&
                PrismicRichText.render(
                  primary.title,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
              {primary.content &&
                PrismicRichText.render(
                  primary.content,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
            </div>
          </div>
        )}

        <div className='container'>
          <div className='updates'>{this.renderUpdates()}</div>

          {this.state.loading && <Loading />}

          {!this.state.loading && primary.infinite_scroll === 'On' && (
            <div>
              {updates.page !== updates.total_pages && (
                <TrackVisibility className='load-more'>
                  {({ isVisible }) => this.loadMore(isVisible)}
                </TrackVisibility>
              )}

              {updates.page === updates.total_pages && (
                <div className='updates-loaded'></div>
              )}
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default withPrismic(UpdatesLayout);
