const primarySupport = {
  name: 'Specialised (complex)',
  appName: 'Specialised support',
  image:
    'https://images.prismic.io/hireup/b081b241-39b7-4647-b88a-39b084bcd440_Description%3DDescription17.png',
  introBlock: {
    background_color: 'Dark',
    content: [
      {
        type: 'heading2',
        text: 'What is specialised support?',
        spans: [],
      },
      {
        type: 'paragraph',
        text: 'Hireup disability support workers can help with more complex personal requirements and support for high needs, from medication management to manual handling and behaviour management. Specialised and complex support is one of eight areas of disability support that support workers can provide on Hireup. ',
        spans: [],
      },
    ],
    content_two: [
      {
        type: 'paragraph',
        text: '',
        spans: [],
      },
    ],
    layout: '2:1',
  },
};

module.exports = primarySupport;
