import * as React from 'react';

const IconShower = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M21.75 21.75a5.25 5.25 0 1 0 10.5 0 5.25 5.25 0 0 0-10.5 0v0Z' />
        <path d='M33 46.5v-9.1c5.374-.6 13.5-4.516 13.5-16.4a3 3 0 0 0-6 0c0 9.864-7.508 10.476-9.02 10.5H18a6 6 0 1 0 0 12h6a3 3 0 0 0 0-6h-6M21 43.5v3M1.5 1.5h3l2.804 2.804' />
        <path d='M8.546 11.548a1.481 1.481 0 0 1-2.346-.284A6 6 0 0 1 14.268 3.2a1.478 1.478 0 0 1 .282 2.346l-6.004 6.002ZM17.15 9.36l2.232 1.24M20.744 4.758l3.23.88M29.18 7.058l3.23.882M12.706 14.048l1.154 2.076M8.016 17.486l.88 3.23M10.316 25.922l.49 1.792' />
      </svg>
    </span>
  );
};

export default IconShower;
