import * as React from 'react';

const IconHandBook = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M1.5 26.144v18M1.5 41.144h30a6 6 0 0 0-6-6H18a6 6 0 0 0-6-6H1.5M12 35.144h6M22.052 27.926c6.766 1.016 8.948 3.4 8.948 3.4' />
        <path d='M15.5 21.776V4.56a.704.704 0 0 1 .714-.704C27.618 4 31 7.704 31 7.704v23.624s3.386-3.702 14.8-3.848a.706.706 0 0 0 .696-.704V4.56a.704.704 0 0 0-.714-.704c-2.12.022-4.237.198-6.332.524A19.664 19.664 0 0 0 31 7.704' />
      </svg>
    </span>
  );
};

export default IconHandBook;
