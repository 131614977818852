const primarySupport = require('./primarySupport');

const improvedHealth = {
  supportName: 'Improved health and wellbeing',
  supportIcon: 'mind',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = improvedHealth;
