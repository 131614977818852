import React from 'react';

const ShapeBackgroundMobile = ({ fill }) => {
  return (
    <svg
      className='shape-background-mobile'
      width='375'
      height='425'
      viewBox='0 0 375 425'
      fill='none'
      aria-hidden='true'
      preserveAspectRatio='xMinYMin slice'>
      <g>
        <path d='M0 328.556V425H375V3L0 328.556Z' fill='#2214B8' />
        <path
          d='M375 275.629V425H126.552C140.056 398.054 158.324 372.92 181.327 350.867L267.982 267.795C278.414 257.794 283.608 252.815 289.709 250.204C295.109 247.839 301.001 246.876 306.863 247.304C313.351 247.738 319.746 250.616 332.314 256.273L333.24 256.69L375 275.629Z'
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default ShapeBackgroundMobile;
