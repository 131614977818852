import React from 'react';
import Analytics from '../config/analytics';
import constants from '../config/constants';
import Utilities from '../config/utilities';
import classNames from 'classnames';
import glossary from '../config/glossary';
import _get from 'lodash.get';
import _has from 'lodash.has';
import _kebabCase from 'lodash.kebabcase';

export const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

export const transformWebLink = (url) => {
  if (url.startsWith('https://#')) {
    return url.replace('https://', '');
  } else if (url.startsWith('https://hireup.com.au')) {
    return url.replace('https://hireup.com.au', '');
  } else {
    return url;
  }
};

export const PrismicConfig = {
  apiEndpoint: constants.prismic_api,

  linkResolver: function (document) {
    const locale =
      document.lang !== constants.locale ? `/${document.lang}` : ``;

    if (document.link_type === 'Web') {
      return transformWebLink(document.url);
    }

    if (document.link_type === 'Media') {
      return document.url;
    }

    switch (document.type) {
      case 'page':
        return `${locale}/${document.uid}/`;

      case 'location':
        return `${locale}/${constants.location_clients_path}/${document.uid}/`;

      case 'news':
      case 'event':
      case 'place':
      case 'guide':
      case 'form':
        return `${locale}/${document.type}/${document.uid}/`;

      default:
        console.warn('Link to unknown content type:', document);
        return '/';
    }
  },

  // eslint-disable-next-line no-unused-vars
  htmlSerializer: (type, element, content, children, key) => {
    let props = {};

    if (!element) {
      return null;
    }

    switch (type) {
      // Don't put an ID on heading one. There should only be one.
      case 'heading1':
        return null;

      // Heading Two
      case 'heading2':
        return React.createElement(
          'h2',
          propsWithUniqueKey(
            {
              id: _kebabCase(
                typeof children[0] === 'string' ? children[0] : element.text
              ),
            },
            key
          ),
          children
        );

      // Heading Three
      case 'heading3':
        return React.createElement(
          'h3',
          propsWithUniqueKey(
            {
              id: _kebabCase(
                typeof children[0] === 'string' ? children[0] : element.text
              ),
            },
            key
          ),
          children
        );

      case 'heading4':
        return React.createElement(
          'h4',
          propsWithUniqueKey(
            {
              id: _kebabCase(
                typeof children[0] === 'string' ? children[0] : element.text
              ),
            },
            key
          ),
          children
        );

      case 'heading5':
        return React.createElement(
          'h5',
          propsWithUniqueKey(
            {
              id: _kebabCase(
                typeof children[0] === 'string' ? children[0] : element.text
              ),
            },
            key
          ),
          children
        );

      // Paragraph Elements
      case 'paragraph':
        return React.createElement(
          'p',
          propsWithUniqueKey({ className: 'p' }, key),
          children
        );

      // Span elements
      case 'span':
        return null;

      case 'strong':
        return React.createElement(
          'strong',
          propsWithUniqueKey({ className: 'strong' }, key),
          children
        );

      // Labels
      case 'label':
        // Glossary definitions
        if (element.data && element.data.label === 'define-term') {
          const locale = Utilities.getCurrentLocale();
          const text = _get(children, '[0][0]');
          // If not glossary exists for the language, or the label is complex (eg a hyperlink) send no changes
          if (!glossary || !locale.code || !glossary[locale.code] || !text) {
            return null;
          }
          let result = children;
          const terms = Object.keys(glossary[locale.code]);

          terms.forEach((term) => {
            // Replace only the first instance of a term in a phrase with a glossary definition.
            if (
              text.toLowerCase().match(new RegExp(`${term.toLowerCase()}`), 'g')
            ) {
              const glossaryUrl = `/${constants.glossary_uid}/#${_kebabCase(
                term
              )}`;
              result = React.createElement(
                'a',
                propsWithUniqueKey({ href: glossaryUrl, target: 'self' }, key),
                text
              );
            }
          });
          return result;
        }
        return null;

      // Inline Images
      case 'image':
        props = {
          src: element.url,
          alt: element.alt || '',
          loading: 'lazy',
          style: { width: element.dimensions.width },
          width: element.dimensions.width,
          height: element.dimensions.height,
          className: 'content-image',
        };
        const img = React.createElement('img', propsWithUniqueKey(props, key));
        if (element.linkTo && element.linkTo.url) {
          return React.createElement(
            'a',
            propsWithUniqueKey(
              { href: element.linkTo.url, target: element.linkTo.target },
              key
            ),
            img
          );
        }
        // Allow images to link to Prismic documents
        if (element.linkTo && element.linkTo.id) {
          return React.createElement(
            'a',
            propsWithUniqueKey(
              { href: PrismicConfig.linkResolver(element.linkTo) },
              key
            ),
            img
          );
        }

        return img;

      // Add a class to hyperlinks
      case 'hyperlink':
        const isButton = _has(children, '[0].props.className')
          ? children[0].props.className.includes('button')
          : false;
        const targetAttr = _has(element, 'data.target')
          ? { target: element.data.target }
          : {};
        const relAttr = _has(element, 'data.target') ? { rel: 'noopener' } : {};
        props = Object.assign(
          {
            className: classNames({
              'prismic-link': true,
              '-with-button': isButton,
            }),
            role: isButton ? 'button' : null,
            href:
              _has(element, 'data.url') ||
              _get(element, 'data.link_type') === 'Document'
                ? PrismicConfig.linkResolver(element.data)
                : null,
            target:
              _has(element, 'data.url') && element.data.url.endsWith('.pdf')
                ? '_blank'
                : '_self',
            onClick: () => {
              const path = window.location.pathname;

              if (isButton) {
                return Analytics.link(
                  element.data,
                  `www.${path}.PrismicRichText.button`,
                  true
                );
              }
              if (!isButton) {
                return Analytics.link(
                  element.data,
                  `www.${path}.PrismicRichText.link`
                );
              }
            },
          },
          targetAttr,
          relAttr
        );
        return React.createElement(
          'a',
          propsWithUniqueKey(props, key),
          children
        );

      // Embeds

      case 'embed':
        if (element.oembed) {
          if (element.oembed.provider_name === 'YouTube') {
            // TODO: Check if PLYR is able to toggle YouTube captions properly before implementation.
            // return <Video type="youtube" url={element.oembed.embed_url} captions={true} autoPlay={false} loop={false} muted={false} key={0} title={element.oembed.title} />
          }
        }
        return null;

      // Return null to stick with the default behavior
      default:
        return null;
    }
  },
};
