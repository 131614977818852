import React from 'react';
import ProfileCards from '../../components/profile-cards/profile-cards';
import './profile-cards.scss';

const ProfileCardsLayout = ({
  location,
  primarySupport,
  secondarySupport,
  workerProfiles,
  numberOfWorkers,
}) => {
  if (!workerProfiles) {
    return null;
  }

  const showSupportType = () => {
    if (primarySupport === 'Therapy support') {
      return secondarySupport.toLowerCase();
    } else {
      return primarySupport.toLowerCase();
    }
  };

  const generateTitle = () => {
    return (
      <h2 className='content'>
        Browse some of our {numberOfWorkers ? numberOfWorkers : ''} support
        workers
        {secondarySupport && (
          <span>
            {' '}
            assisting with <span className='bold'>
              {showSupportType()}
            </span>{' '}
            support
          </span>
        )}
        {location && (
          <span>
            {' '}
            in <span className='bold'>{location}</span>
          </span>
        )}
      </h2>
    );
  };

  return (
    <section className='layout-profile-cards -background-dark'>
      <div className='container'>
        {generateTitle()}
        <ProfileCards
          primarySupport={primarySupport}
          workerProfiles={workerProfiles}
        />
      </div>
    </section>
  );
};

export default ProfileCardsLayout;
