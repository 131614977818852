const primarySupport = require('./primarySupport');

const incontinenceLaundry = {
  supportName: 'Incontinence laundry',
  supportIcon: 'ironing',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = incontinenceLaundry;
