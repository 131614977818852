import * as React from 'react';

const IconSpeechBubbles = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)' strokeLinecap='round'>
          <path d='M46.5 27A10.497 10.497 0 0 0 36 16.5h-6a10.5 10.5 0 1 0 0 21h1.5l9 9V36.452a10.474 10.474 0 0 0 6-9.452ZM13.5 25.5l-6 6V21.452A10.477 10.477 0 0 1 12 1.5h6A10.504 10.504 0 0 1 28.066 9' />
        </g>
        <defs>
          <clipPath id='a'>
            <path fill='#fff' d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconSpeechBubbles;
