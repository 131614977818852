const sampleWorkerProfiles = [
  {
    profile: {
      bio: 'I love being able to support people to achieve their goals. Making people happy is one of the greatest rewards in life.',
      firstName: 'Matt',
      id: '61586e17877745698d08ac1fa',
      pictureUrl:
        'https://images.prismic.io/hireup/d6094fad-5654-4a41-a3cc-d322f4c913fc_Support+worker+Matt.png',
      state: 'NSW',
      suburb: 'Sydney',
      supportAreas: [
        'Help around the house',
        'In home care',
        'Personal care and hygiene',
        'Out and about',
        'Education, training, and employment',
        'Therapy support',
        'Transport',
        'Specialised support',
      ],
      sample: true,
    },
  },
  {
    profile: {
      bio: 'I love being able to support people to help them achieve their goals. Making people happy is one of the greatest rewards in life.',
      firstName: 'Danielle',
      id: '60e79a9ac659c74c0be7902d',
      pictureUrl:
        'https://hireup-sams-prod.s3.amazonaws.com/storage/main/profile-pics/b99ceef2-c83d-48e9-828e-dc5e142ad45c.png',
      state: 'NSW',
      suburb: 'St Leonards',
      supportAreas: [
        'Help around the house',
        'In home care',
        'Personal care and hygiene',
        'Out and about',
        'Education, training, and employment',
        'Therapy support',
        'Transport',
        'Specialised support',
      ],
      sample: true,
    },
  },
  {
    profile: {
      bio: 'There is nothing more rewarding than helping people achieve their goals. I have lived experience in disabilities and children’s development.',
      firstName: 'Salaam',
      id: '5d1c1cc165646ebcfc20c12b',
      pictureUrl:
        'https://images.prismic.io/hireup/9b4fd611-ec03-4581-b870-503c47134dc3_Salaam+support+worker.png',
      state: 'NSW',
      suburb: 'St Leonards',
      supportAreas: [
        'Help around the house',
        'In home care',
        'Personal care and hygiene',
        'Out and about',
        'Education, training, and employment',
        'Therapy support',
        'Transport',
        'Specialised support',
      ],
      sample: true,
    },
  },
  {
    profile: {
      bio: `I enjoy meeting people from all walks of life and supporting them to live the lives they choose. I get to do this every day at Hireup.`,
      firstName: `Joe`,
      id: '61586e17877845689768ac1fa',
      state: `VIC`,
      suburb: 'Melbourne',
      pictureUrl:
        'https://images.prismic.io/hireup/69f095c2-5ebe-4305-92a5-7c56a37995ef_Support+worker+Joe+-+Melbourne.jpg',
      supportAreas: [
        'Help around the house',
        'In home care',
        'Personal care and hygiene',
        'Out and about',
        'Education, training, and employment',
        'Therapy support',
        'Transport',
        'Specialised support',
      ],
      sample: true,
      hidden: true,
    },
  },
  {
    profile: {
      bio: `I am passionate about helping people become independent through teaching/practicing life skills and helping them connect to their local communities.`,
      firstName: `Emma`,
      id: '61586e6593677965ad08ac1fa',
      state: `VIC`,
      suburb: 'Melbourne',
      pictureUrl:
        'https://images.prismic.io/hireup/5a8959f4-1ad5-4fcb-9393-7dcfd77b498f_Support+worker+Emma+-+Melbourne.png',
      supportAreas: [
        'Help around the house',
        'In home care',
        'Personal care and hygiene',
        'Out and about',
        'Education, training, and employment',
        'Therapy support',
        'Transport',
        'Specialised support',
      ],
      sample: true,
      hidden: true,
    },
  },
  {
    profile: {
      bio: `My particular passion is working with young adults in order to support growth, development, independence, and autonomy. I’m also bilingual and speak English and Vietnamese.`,
      firstName: `Lan`,
      id: '615861456777593ad08ac1fa',
      state: `VIC`,
      suburb: 'Melbourne',
      pictureUrl:
        'https://images.prismic.io/hireup/0c7ce25e-a9d5-4810-951d-25ffb31abad7_Support+worker+Lan+-+Melbourne.png',
      supportAreas: [
        'Help around the house',
        'In home care',
        'Personal care and hygiene',
        'Out and about',
        'Education, training, and employment',
        'Therapy support',
        'Transport',
        'Specialised support',
      ],
      sample: true,
      hidden: true,
    },
  },
];

module.exports = sampleWorkerProfiles;
