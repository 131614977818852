import * as React from 'react';

const IconDumbell = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M16.5 23h15M13.5 35a3 3 0 0 1-3-3V14a3 3 0 0 1 6 0v18a3 3 0 0 1-3 3ZM4.5 23h-3M7.5 17a3 3 0 0 0-3 3v6a3 3 0 0 0 6 0v-6a3 3 0 0 0-3-3ZM34.5 35a3 3 0 0 0 3-3V14a3 3 0 0 0-6 0v18a3 3 0 0 0 3 3ZM43.5 23h3M40.5 17a3 3 0 0 1 3 3v6a3 3 0 0 1-6 0v-6a3 3 0 0 1 3-3Z' />
      </svg>
    </span>
  );
};

export default IconDumbell;
