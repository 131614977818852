import React from 'react';
import { withPrismic } from '../../prismic/prismic';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import LoginForm from '../../components/login-form/login-form';
import classNames from 'classnames';
import Utilities from '../../config/utilities';
import './login.scss';

function LoginLayout({ primary }) {
  if (!primary) {
    return null;
  }

  const { content, background_image, background_color } = primary;
  const backgroundColor = background_color || 'Sand';

  const layoutClasses = () => {
    let classes = {
      'layout-login': true,
      [`-${Utilities.toKebabCase(backgroundColor)}`]: true,
    };
    return classNames(classes);
  };

  const layoutBackground = () => {
    if (content[0]?.text) {
      return null;
    }

    return {
      backgroundImage: background_image?.url
        ? `url(${background_image.url}&w=1200&q=75&auto=format)`
        : null,
    };
  };

  return (
    <section className={layoutClasses()}>
      <div className='bg-desktop' style={layoutBackground()}></div>

      <div className='container'>
        <div className='form'>
          <LoginForm />
        </div>

        <aside className='aside bg-mobile' style={layoutBackground()}>
          <div className='content'>
            {content &&
              PrismicRichText.render(
                content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
          </div>
        </aside>
      </div>
    </section>
  );
}

export default withPrismic(LoginLayout);
