import * as React from 'react';

const IconComputerBook = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)'>
          <path d='M40.5 10.502v18a3 3 0 0 1-3 3h-27a3 3 0 0 1-3-3v-18M43.496 46.502H4.504a3 3 0 0 1-2.684-4.34l3.672-7.342a6 6 0 0 1 5.366-3.318h26.284a6 6 0 0 1 5.366 3.318l3.672 7.342a3 3 0 0 1-2.684 4.34ZM21 40.502h6' />
          <path d='M24 4.824s.954-3 9.122-3.32a1.27 1.27 0 0 1 1.378 1.17v12.348a1.268 1.268 0 0 1-1.312 1.16c-8.22.294-9.188 3.32-9.188 3.32V4.824Zm0 0s-.954-3.01-9.122-3.32a1.27 1.27 0 0 0-1.378 1.17v12.348a1.268 1.268 0 0 0 1.312 1.16c8.22.294 9.188 3.32 9.188 3.32' />
        </g>
        <defs>
          <clipPath id='a'>
            <path d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconComputerBook;
