import React from 'react';

import './typeform.scss';

const TypeForm = ({ primary }) => {
  const { data_tf_live_id } = primary;
  if (!data_tf_live_id) {
    console.error('Unable to load Typeform: Missing data_tf_live_id');
    return null;
  }

  return (
    <section className='layout-typeform'>
      <div className='container'>
        <div className='content frame-container'>
          <div data-tf-live={data_tf_live_id}></div>
        </div>
      </div>
    </section>
  );
};

export default TypeForm;
