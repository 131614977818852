import React, { useEffect, useState } from 'react';
import Error400 from '../pages/error-400';
import FAQs from '../layouts/faqs/faqs';
import Loading from '../components/loading/loading';
import PageWrapper from '../pages/wrapper';
import ProfileCardsLayout from '../layouts/profile-cards/profile-cards';
import SEOCardsLayout from '../layouts/seo-cards/seo-cards';
import SEOLinksLayout from '../layouts/seo-links/seo-links';
import Subpage from '../pages/subpage';
import TrustPilotLayout from '../layouts/trustpilot/trustpilot';
import TwoColumnLayout from '../layouts/two-column/two-column';
import constants from '../config/constants';
import { generateFaqs } from './data/faqs';
import { generateHeadingText, generateIntroText } from './data/helpers';
import _startCase from 'lodash.startcase';
import { getSupportWorkerData } from '../hireup/supportWorkerSearch';
import utilities from '../config/utilities';
import { withPrismic } from '../prismic/prismic';
import './secondary-support-shared.scss';

function LocationPage({
  prismic,
  prismicContext,
  content,
  support,
  uid,
  error,
}) {
  const [loading, setLoading] = useState(false);
  const [publicSearchResponse, setPublicSearchResponse] = useState(null);
  const [publicSearchProfiles, setPublicSearchProfiles] = useState(null);

  const primarySupport = support?.primarySupport?.name;
  const location_name = content?.data?.title[0]?.text;
  const location_state = content?.data?.location_state;
  const { latitude, longitude } = content?.data?.location_location || {};

  useEffect(() => {
    if (prismicContext && !support) {
      prismic.getSupportType();
    }
  }, [prismic, prismicContext, support]);

  useEffect(() => {
    if (prismicContext && !content) {
      prismic.getLocation(uid);
    }
  }, [content, prismic, prismicContext, uid]);

  useEffect(() => {
    const getLocationSearchResults = async () => {
      setLoading(true);
      const locationRadius = 30000;
      const { location_location } = content?.data;

      const supportType = support?.primarySupport?.appName;
      const { publicSearchResponse, publicSearchProfiles } =
        await getSupportWorkerData({
          location: location_location,
          locationRadius,
          supportType,
        });
      setPublicSearchResponse(publicSearchResponse);
      setPublicSearchProfiles(publicSearchProfiles);
      setLoading(false);
    };

    if (content && support && !publicSearchResponse) {
      getLocationSearchResults();
    }
  }, [content, primarySupport, publicSearchResponse, support]);

  if (error) {
    return <Error400 path={`/${window.location.pathname.split('/')[1]}/`} />;
  }

  if (!publicSearchResponse) {
    return <Loading full={true} testId='location-support' />;
  }

  const numberOfWorkers = Intl.NumberFormat().format(
    publicSearchResponse.totalResultCount
  );

  const meta = {
    title: `${numberOfWorkers} ${_startCase(
      support?.supportName
    )} Support Workers in ${location_name}, ${location_state}`,
    description: `There are ${numberOfWorkers} ${support?.supportName} support workers available in ${location_name}, ${location_state}. Find, hire and manage your NDIS disability support workers through Hireup.`,
  };

  const primarySupportFormatted = utilities.capitaliseAcronymns(
    support?.supportName.toLowerCase()
  );

  const title = generateHeadingText(support, location_state, location_name);
  const intro = generateIntroText(support, numberOfWorkers);

  const headerImage = support?.supportImage || support?.primarySupport?.image;

  const faqs = generateFaqs(
    primarySupportFormatted,
    content.data.title[0]?.text
  );

  return (
    <PageWrapper
      title={meta.title}
      description={meta.description}
      context={`page -location-support -${content.uid}`}
      content={content}
      breadcrumbs={true}>
      <section className='location-header -with-background'>
        <div className='container'>
          <div className='content'>
            {title && <h1 className='heading'>{title}</h1>}
            <>{intro}</>
            <div className='cta'>
              <a href={`${constants.registration}`} className='button'>
                Get started
              </a>
            </div>
          </div>
        </div>
        <div
          className='layout-background'
          style={{
            backgroundImage: `url(${headerImage}?auto=format&h=600&q=75)`,
          }}></div>
      </section>

      {!loading && publicSearchProfiles && (
        <ProfileCardsLayout
          primarySupport={primarySupport}
          secondarySupport={support.supportName}
          workerProfiles={publicSearchProfiles}
          numberOfWorkers={numberOfWorkers}
          location={location_name}
        />
      )}

      <TrustPilotLayout />

      {support.twoColumnLayout && (
        <TwoColumnLayout primary={support.twoColumnLayout} />
      )}

      {faqs && <FAQs primary={faqs} items={faqs.items} />}

      <SEOCardsLayout background='white' />

      <SEOLinksLayout latitude={latitude} longitude={longitude} />

      <Subpage uid={'join-our-weekly-webinar-beginners-guide-to-hireup'} />

      <Subpage uid='subpage-support-type' />
    </PageWrapper>
  );
}

export default withPrismic(LocationPage);
