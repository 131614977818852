import * as React from 'react';

const IconHoldingHands = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)'>
          <path d='M27.866 43.426a10.712 10.712 0 0 0 3.9-.754l4.286 3.828M46.048 30.878l-3.282-2.456a12.6 12.6 0 0 0-.022-4.78l-2.71-13.544a3.23 3.23 0 0 0-6.376.28l-.726 6.518L18.46 2.42A3.152 3.152 0 0 0 14 6.874l-2.22-2.226A3.149 3.149 0 0 0 7.326 9.1l2.228 2.228A3.148 3.148 0 1 0 5.1 15.78L7.326 18a3.151 3.151 0 0 0-4.452 4.46l4.16 4.258M18.81 11.678 14.002 6.87M12.626 14.4l-3.078-3.078' />
          <path d='m9.304 44.32 1.428-1.786a9.6 9.6 0 0 0 4.718-.168l8.632-1.894a2.33 2.33 0 0 0 1.682-1.962 2.291 2.291 0 0 0-2.112-2.496 1.806 1.806 0 0 0-.2-.01l-4.816-.05 6.92-7.896a2.225 2.225 0 0 0-.304-3.134c-8.238-7.498-11.87-5.512-12.996-4.386l-7.43 8.8a7.455 7.455 0 0 0-1.788 5.576 7.17 7.17 0 0 0 .578 2.132l-1.568 1.568' />
        </g>
        <defs>
          <clipPath id='a'>
            <path d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconHoldingHands;
