import React from 'react';
import constants from '../../config/constants';
import './contact.scss';

export default function Contact({ topMargin }) {
  return (
    <div
      className={`search-results-contact ${topMargin ? '-margin-top' : ''}`}
      data-testid='contact'>
      <h3>We're here to help</h3>
      <p>
        Hundreds of support workers join Hireup each week, and our team can help
        you connect with the right support:
      </p>
      <a href='https://help.hireup.com.au/#contact' className='button -primary'>
        Get in touch
      </a>
      <a href={constants.registrationClient} className='button -secondary'>
        Sign up today
      </a>
    </div>
  );
}
