import React from 'react';
import { supportTypes } from '../../seo/data/supportTypes';
import { isValidPrimarySupport } from '../../seo/data/helpers';
import { renderIcon } from '../../seo/data/icons';
import Card from '../../components/card/card';
import _camelCase from 'lodash.camelcase';
import _kebabCase from 'lodash.kebabcase';
import classNames from 'classnames';
import utilities from '../../config/utilities';
import Analytics from '../../config/analytics';
import './seo-cards.scss';

export const filterSupports = (primarySupportArray) =>
  primarySupportArray.filter(
    (support) => support[1]?.supportIcon && !support[1]?.archived
  );

const SEOCardsLayout = ({ background }) => {
  const path = window.location.pathname;
  const [, primarySupport] = path.split('/');
  const backgroundColor = background || 'dark';

  const primarySupportFormatted = utilities.toSentenceCase(primarySupport);

  if (!isValidPrimarySupport(_camelCase(primarySupport))) {
    return null;
  }

  const secondarySupportsWithIcons = filterSupports(
    Object.entries(supportTypes[_camelCase(primarySupport)])
  );

  if (secondarySupportsWithIcons.length === 0) {
    return null;
  }

  const handleClick = (event) => {
    Analytics.link(
      { label: event.currentTarget.href },
      `www.${path}.seoCards.card`
    );
  };

  const layoutClasses = () => {
    let classes = {
      'layout-seo-cards': true,
      [`-background-${_kebabCase(backgroundColor)}`]: true,
    };
    return classNames(classes);
  };

  const renderSecondarySupportLinks = () => {
    return secondarySupportsWithIcons.map((support) => (
      <Card key={support[1].supportName} borderWidth={2}>
        <div className='icon-wrapper'>{renderIcon(support[1].supportIcon)}</div>
        <div className='link-wrapper'>
          <a
            href={`/${primarySupport}/${_kebabCase(support[0])}/`}
            onClick={handleClick}>
            {utilities.toSentenceCase(support[1].supportName)}
          </a>
        </div>
      </Card>
    ));
  };

  return (
    <section className={layoutClasses()}>
      <div className='container'>
        <div className='content'>
          <h2 className='title'>
            {primarySupportFormatted} services on Hireup
          </h2>
          <ul className='grid-5'>
            {renderSecondarySupportLinks(primarySupport)}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SEOCardsLayout;
