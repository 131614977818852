import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { withPrismic } from '../../prismic/prismic';
import NewsPost from '../../components/news/news';
import constants from '../../config/constants';
import './related-articles.scss';

function RelatedArticles({ prismic, primary, category, tags, hideStaticPost }) {
  const [posts, setPosts] = useState(null);
  const [currentPage] = useState(1);

  const PAGE_SIZE = 6;
  const isStaticPost = tags?.includes(constants.static_post_tag);

  const loadPosts = async (pageSize, page, filterCategory = 'news-latest') => {
    let items = await prismic.getNews(pageSize, page, filterCategory);

    // Add the incoming posts to the current state
    if (posts) {
      items.results = posts.results.concat(items.results);
    }
    setPosts(items);
  };

  const getStaticPost = () => {
    if (isStaticPost || hideStaticPost) {
      setPosts({ results: [] });
    } else {
      loadPosts(1, 1, constants.static_post_tag);
    }
  };

  useEffect(() => {
    if (!posts) {
      getStaticPost();
    }
    // if no static post, load default number of posts
    if (posts?.results?.length === 0) {
      loadPosts(PAGE_SIZE, currentPage, primary?.related_category || category);
    }

    // if only static post, load up to default number of posts
    if (posts?.results?.length === 1) {
      loadPosts(
        PAGE_SIZE - 1,
        currentPage,
        primary?.related_category || category
      );
    }
  });

  const layoutClasses = (posts) => {
    let classes = {
      'layout-related-articles': true,
    };
    if (posts && posts.length) {
      classes[`-count-${posts.length}`] = posts && posts.length;
    }
    return classNames(classes);
  };

  const renderPosts = () => {
    return (
      posts.results &&
      posts.results.map((post, i) => <NewsPost primary={post} key={i} />)
    );
  };

  if (!posts) {
    return null;
  }

  return (
    <>
      {posts.results_size !== 0 && (
        <section className={layoutClasses(primary, posts)}>
          <div className='container'>
            <div className='content'>
              <h2>
                {primary?.content?.title
                  ? primary?.content?.title
                  : 'Discover more'}
              </h2>
            </div>
            <div className='posts'>{renderPosts()}</div>
          </div>
        </section>
      )}
    </>
  );
}

export default withPrismic(RelatedArticles);
