import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Profile from '../../components/profile/profile';
import _kebabCase from 'lodash.kebabcase';
import './profiles.scss';

export default class ProfilesLayout extends Component {
  layoutClasses(primary) {
    let classes = {
      'layout-profiles': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-count-${this.props.items.length}`]: true,
    };
    return classNames(classes);
  }

  render() {
    const { primary, items } = this.props;

    if (!primary && !items) {
      return null;
    }

    return (
      <section className={this.layoutClasses(primary)}>
        <div className='container'>
          <div className='content'>
            {PrismicRichText.render(
              primary.content,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
          </div>
        </div>
        <div className='container'>
          <div className='grid'>
            {items &&
              items.map((item, i) => <Profile primary={item} key={i} />)}
          </div>
        </div>
      </section>
    );
  }
}
