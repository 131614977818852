const primarySupport = require('./primarySupport');

const bedChanging = {
  supportName: 'Bed changing',
  supportIcon: 'bed',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = bedChanging;
