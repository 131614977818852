import React, { Component } from 'react';
import classNames from 'classnames';
import './instagram.scss';

export default class InstagramLayout extends Component {
  layoutClasses() {
    let classes = {
      'layout-instagram': true,
    };
    return classNames(classes);
  }

  render() {
    const { primary } = this.props;

    return (
      <section className={this.layoutClasses(primary)}>
        <div className='instagram-container'>
          <iframe
            src={`https://cdn.lightwidget.com/widgets/${
              primary.instagram_lightwidget_id ||
              'e198c9230f555051aa55405a013293a9'
            }.html`}
            scrolling='no'
            className='lightwidget-widget'
            title="Hireup's Instagram Content"></iframe>
        </div>
      </section>
    );
  }
}
