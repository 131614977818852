const primarySupport = require('./primarySupport');

const occupationalTherapy = {
  supportName: 'Occupational therapy',
  supportIcon: 'edit',
  primarySupport: primarySupport,
  twoColumnLayout: {
    contentTitle:
      'Occupational therapy support happening throughout our community',
    content: [
      {
        alt: 'A smiling father, young son and mother huddle together on the porch of a house',
        dimensions: {
          height: 541,
          width: 960,
        },
        linkTo: {
          id: 'YQkxbxEAACMAaCv_',
          type: 'news',
          lang: 'en-au',
          uid: 'this-family-couldnt-find-culturally-relevant-disability',
        },
        type: 'image',
        url: 'https://images.prismic.io/hireup/e7b3f336-7f66-44a1-a748-d30d30730dd6_Davis+Family++%281%29.JPG?auto=compress,format',
      },
    ],
    content_two: [
      {
        type: 'paragraph',
        text: '"Our son, Slade, was diagnosed with autism at two-and-a-half. We had no idea what autism was, what it means for Slade or how to support him. We immediately sought professional advice and support to better understand autism, because we want Slade to live his best life.”" - Tanika Davis, advocate for autism spectrum disorder awareness and founder of The I Am, Movement',
        spans: [
          {
            start: 0,
            end: 380,
            type: 'label',
            data: {
              label: 'quote',
            },
          },
        ],
      },
      {
        type: 'paragraph',
        text: 'Read about occupational therapy happening in our community',
        spans: [
          {
            start: 0,
            end: 150,
            type: 'hyperlink',
            data: {
              type: 'news',
              slug: 'this-family-couldnt-find-culturally-relevant-disability',
              lang: 'en-au',
              uid: 'this-family-couldnt-find-culturally-relevant-disability',
              link_type: 'Document',
            },
          },
        ],
      },
    ],
  },
  faqs: {
    content: [
      {
        type: 'heading2',
        text: 'Learn more about occupational therapy support',
        spans: [],
      },
    ],
    items: [
      {
        question: [
          {
            type: 'heading3',
            text: 'What does occupational therapy support involve?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Occupational therapy support is about making sure you’re able to participate in the things you find the most meaningful. Like with every support type you can use on Hireup, every person’s support needs are different. The activities you’d like to access are unique to you. They can include taking care of yourself or others, working, your hobbies or interests, volunteering and going along to social events.',
            spans: [],
          },
          {
            type: 'paragraph',
            text: 'Our disability support workers will work with your occupational therapist to meet your needs.',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'What does an Occupational Therapist do?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Occupational therapy is considered a health profession and involves ongoing assessments and tools to understand the things you can do now, and the things you would like to do in the future.',
            spans: [],
          },
          {
            type: 'paragraph',
            text: 'Disability support workers providing therapy support might help plan or practice your occupational therapy tasks, accompany you to and participate in therapy sessions and help follow up any tasks set by your occupational therapist.',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'How many years does it take to be an occupational therapist?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'In Australia, there are a number of ways you can become a qualified occupational therapist and depending on how an occupational therapist begins their qualification process, it can take anywhere from 4-6 years to become a certified OT.',
            spans: [],
          },
        ],
      },
      {
        question: [
          {
            type: 'heading3',
            text: 'Why is occupational therapy support beneficial?',
            spans: [],
          },
        ],
        answer: [
          {
            type: 'paragraph',
            text: 'Occupational Therapy is a support type that puts you in the centre of your support, so it is beneficial in getting the most out of your NDIS funding. In particular, Occupational Therapy can help you build on your own wants in life. For example, if you’re trying to become more independent, OT is the only therapy whose primary focus is to improve skills for independence, like: eating, dressing, toileting, bathing and hygiene tasks.',
            spans: [],
          },
          {
            type: 'paragraph',
            text: 'Our disability support workers will work with you and your occupational therapist throughout that journey to help you get the most out of your therapy.',
            spans: [],
          },
        ],
      },
    ],
  },
  relatedArticlesCategory: 'occupational-therapy',
};

module.exports = occupationalTherapy;
