import React from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { getThemeClass } from '../../helpers/campaignHelpers';

import '../../assets/scss/campaign.scss';
import './campaign-columns.scss';

const CampaignColumn = ({ primary, items }) => {
  return (
    <section
      className={classNames(
        'padding-section section-campaign-columns text-center themed',
        getThemeClass(primary.background_color)
      )}>
      <div className='container'>
        <div className='gap-y-content section-gutter'>
          {primary.content_header && primary.content_header.length ? (
            <div className='max-width-small rich-text'>
              {PrismicRichText.render(
                primary.content_header,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          ) : null}
          {items ? (
            <div className='cards-section-gutter'>
              <div
                className={classNames(
                  'spaced-columns',
                  primary.column_count_mobile === '2' && 'spaced-columns-2',
                  primary.column_count === '2' &&
                    'column-gap-large t:spaced-columns-2',
                  primary.column_count === '3' &&
                    'column-gap-large t:spaced-columns-3',
                  primary.column_count === '4' && 't:spaced-columns-4'
                )}>
                {items.map((item, i) => (
                  <div className='card-column rich-text' key={`column-${i}`}>
                    {PrismicRichText.render(
                      item.column_content,
                      PrismicConfig.linkResolver,
                      PrismicConfig.htmlSerializer
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {primary.content_footer && primary.content_footer.length ? (
            <div className='max-width-small rich-text'>
              {PrismicRichText.render(
                primary.content_footer,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default CampaignColumn;
