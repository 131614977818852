import React, { useRef } from 'react';
import { A11y, Navigation } from 'swiper';
import { Link, RichText as PrismicRichText } from 'prismic-reactjs';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from '../../components/image/image';
import ShapeBackground from './shape-background';
import ShapeFeature from './shape-feature';
import { PrismicConfig } from '../../prismic/config';
import { getShapeFill } from '../../helpers/campaignHelpers';

import 'swiper/scss';
import 'swiper/scss/a11y';
import 'swiper/scss/navigation';
import '../../assets/scss/campaign.scss';
import './campaign-feature-profiles.scss';

const CampaignFeatureProfiles = ({ primary, items }) => {
  const swiperRef = useRef(null);

  const swiperNextRef = useRef(null);

  const swiperPrevRef = useRef(null);

  return (
    <section className='feature-profiles-section padding-section theme-white'>
      <div className='shape-background'>
        <ShapeBackground />
      </div>
      <div className='feature-profiles-container'>
        <div className='shape-feature'>
          <ShapeFeature fill={getShapeFill(primary.shape_color)} />
        </div>
        <div className='feature-profiles-box padding-section theme-sand-10'>
          <div className='gap-y-content'>
            {primary.content_header && primary.content_header.length ? (
              <div className='section-gutter'>
                <div className='rich-text text-center'>
                  {PrismicRichText.render(
                    primary.content_header,
                    PrismicConfig.linkResolver,
                    PrismicConfig.htmlSerializer
                  )}
                </div>
              </div>
            ) : null}
            {items ? (
              <div className='swiper-container'>
                <div className='section-gutter swiper-container-inner'>
                  <Swiper
                    a11y={{
                      enabled: true,
                      slideLabelMessage:
                        'Featured member {{index}} of {{slidesLength}}',
                    }}
                    breakpoints={{
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                      },
                      980: {
                        slidesPerView: 3,
                        spaceBetween: 18,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 24,
                      },
                      1280: {
                        slidesPerView: 3,
                        spaceBetween: 35,
                      },
                    }}
                    modules={[A11y, Navigation]}
                    navigation={{
                      nextEl: swiperNextRef.current,
                      prevEl: swiperPrevRef.current,
                    }}
                    ref={swiperRef}
                    slidesPerView={1}
                    spaceBetween={24}>
                    {items.map((item, index) => {
                      const attributes = [
                        {
                          icon: item.profile_attribute_icon_one,
                          label: item.profile_attribute_one_text,
                        },
                        {
                          icon: item.profile_attribute_icon_two,
                          label: item.profile_attribute_two_text,
                        },
                        {
                          icon: item.profile_attribute_icon_three,
                          label: item.profile_attribute_three_text,
                        },
                      ].filter((attribute) => attribute.label);

                      return (
                        <SwiperSlide
                          className='feature-profile-slide'
                          key={index}>
                          <div className='feature-profile-card'>
                            <div className='card-image-wrapper'>
                              <div
                                className='card-image img-cover'
                                role='presentation'>
                                <Image primary={item.profile_image} />
                              </div>
                            </div>
                            <div className='card-content'>
                              {item.profile_name || item.profile_demographic ? (
                                <div>
                                  {item.profile_name ? (
                                    <h5>{item.profile_name}</h5>
                                  ) : null}
                                  {item.profile_demographic ? (
                                    <p className='card-demographic'>
                                      {item.profile_demographic}
                                    </p>
                                  ) : null}
                                </div>
                              ) : null}
                              {attributes && attributes.length > 0 ? (
                                <div className='card-attributes-group'>
                                  {attributes.map((attribute) => (
                                    <div
                                      className='card-attribute'
                                      key={attribute.label}>
                                      {attribute.icon ? (
                                        <span className='icon'>
                                          <Image primary={attribute.icon} />
                                        </span>
                                      ) : null}
                                      <p className='card-attribute-text'>
                                        {attribute.label}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                              {item.profile_bio ? (
                                <p className='card-bio'>{item.profile_bio}</p>
                              ) : null}
                              {item.profile_button_text &&
                              item.profile_button_link ? (
                                <div className='button-wrapper rich-content'>
                                  <a
                                    className='button -neutral-hollow button text-center'
                                    href={Link.url(
                                      item.profile_button_link,
                                      PrismicConfig.linkResolver
                                    )}
                                    target={item.profile_button_link.target}
                                    rel='noopener noreferrer'>
                                    {item.profile_button_text}
                                  </a>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
                <div className='swiper-absolute-controls'>
                  <button
                    className='swiper-circle-control'
                    aria-label='Previous slide'
                    ref={swiperPrevRef}
                    tabIndex='0'>
                    <MdChevronLeft role='presentation' />
                  </button>
                  <button
                    className='swiper-circle-control'
                    aria-label='Next slide'
                    ref={swiperNextRef}
                    tabIndex='0'>
                    <MdChevronRight />
                  </button>
                </div>
              </div>
            ) : null}
            {primary.content_footer && primary.content_footer.length ? (
              <div className='section-gutter'>
                <div className='rich-text text-center'>
                  {PrismicRichText.render(
                    primary.content_footer,
                    PrismicConfig.linkResolver,
                    PrismicConfig.htmlSerializer
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CampaignFeatureProfiles;
