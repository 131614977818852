import * as React from 'react';

const IconMeal = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#a)' strokeLinecap='round'>
          <path d='M4.5 18v27M40.494 30H45a1.5 1.5 0 0 0 1.5-1.496c.016-7.662.056-16.916-3.118-24.578a1.5 1.5 0 0 0-2.886.574V45M1.5 3v12a3 3 0 0 0 6 0V3M33 16.566a14.346 14.346 0 0 0-19.488-1.4M12 37.434a14.356 14.356 0 0 0 21 0' />
        </g>
        <defs>
          <clipPath id='a'>
            <path fill='#fff' d='M0 0h48v48H0z' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default IconMeal;
