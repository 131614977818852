import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Image from '../image/image';
import './profile.scss';

export default class Profile extends Component {
  layoutClasses() {
    let classes = {
      'profile-single': true,
    };
    return classNames(classes);
  }

  render() {
    const { primary } = this.props;

    if (!primary) {
      return null;
    }

    return (
      <div className={this.layoutClasses()}>
        {primary.image && (
          <Image primary={primary.image} width={512} lazyload={true} />
        )}
        <div className='profile-content'>
          {primary.content &&
            PrismicRichText.render(
              primary.content,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
          {primary.location && (
            <p className='profile-location'>{primary.location}</p>
          )}
        </div>
      </div>
    );
  }
}
