import * as React from 'react';

const IconNailPolish = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='m37.566 30.17-4.044 14.156a3 3 0 0 1-2.884 2.174H17.162a3 3 0 0 1-2.884-2.174L10.234 30.17a5.98 5.98 0 0 1 3.518-7.218 27.354 27.354 0 0 1 20.296 0 5.98 5.98 0 0 1 3.518 7.218v0Z' />
        <path d='m17.848 21.684 1.446-18.8A1.5 1.5 0 0 1 20.8 1.5h6.222a1.5 1.5 0 0 1 1.494 1.386l1.446 18.8M28.4 40.5h-9l-1.5-12h12l-1.5 12Z' />
      </svg>
    </span>
  );
};

export default IconNailPolish;
