import React, { useState } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Image from '../../components/image/image';
import Analytics from '../../config/analytics';
import _kebabCase from 'lodash.kebabcase';
import './content-tabbed.scss';

const ContentTabbed = ({ primary, items }) => {
  const [tab, setTab] = useState('tab1');
  const tabOneLabel = primary.section_1;
  const tabTwoLabel = primary.section_2;
  const tabThreeLabel = primary.section_3;

  if (!primary) {
    return null;
  }

  const content1 = items.find((s) => s.section === tabOneLabel);
  const content2 = items.find((s) => s.section === tabTwoLabel);
  const content3 = items.find((s) => s.section === tabThreeLabel);

  const layoutClasses = () => {
    let classes = {
      'layout-content-tabbed': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
    };
    return classNames(classes);
  };

  const sectionTabClasses = (primary) => {
    let classes = {
      'section-toggle': true,
      '-background-light-gray': primary.background_color === 'White',
      '-background-white': primary.background_color !== 'White',
    };
    return classNames(classes);
  };
  const tabClasses = (isActive) => {
    let classes = {
      button: true,
      '-toggle': true,
      '-active': isActive,
    };
    return classNames(classes);
  };

  const handleTabClick = (e, tab) => {
    setTab(tab);
    sendAnalytics(e);
  };

  const sendAnalytics = (e) => {
    const path = window.location.pathname;
    Analytics.link(
      { label: e.target.textContent },
      `www.${path}.contentTabbed.tabBtn`,
      true
    );
  };

  const SubContentSection = ({ content }) => {
    const { section_content } = content;

    const renderImageContentSectionItems = () => {
      let items = [];
      for (let i = 1; i < 7; i++) {
        items.push(
          <ImageContentSection
            key={i}
            image={content[`icon_content_${i}`]}
            textContent={content[`content_${i}`]}
          />
        );
      }
      return <>{items}</>;
    };

    const ImageContentSection = ({ image, textContent }) => {
      if (textContent.length === 0 || Object.keys(image).length === 0) {
        return null;
      }
      return (
        <div className='icon-content-section'>
          <div className='icon-image'>
            {image && image.url && (
              <Image primary={image} width={48} height={48} lazyload={true} />
            )}
          </div>
          <div className='text-content'>
            {PrismicRichText.render(
              textContent,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
          </div>
        </div>
      );
    };

    return (
      <div>
        <div className='sub-content-section -two-thirds'>
          {PrismicRichText.render(
            section_content,
            PrismicConfig.linkResolver,
            PrismicConfig.htmlSerializer
          )}
        </div>
        <div className='icon-content-section-wrapper'>
          {renderImageContentSectionItems()}
        </div>
      </div>
    );
  };

  return (
    <section className={layoutClasses(primary)}>
      <div className='container'>
        <div className='content -two-thirds'>
          {PrismicRichText.render(
            primary.top_content,
            PrismicConfig.linkResolver,
            PrismicConfig.htmlSerializer
          )}
        </div>
        <div className='content'>
          <div className={sectionTabClasses(primary)}>
            <button
              type='button'
              className={tabClasses(tab === 'tab1')}
              onClick={(e) => handleTabClick(e, 'tab1')}>
              {tabOneLabel}
            </button>
            <button
              type='button'
              className={tabClasses(tab === 'tab2')}
              onClick={(e) => handleTabClick(e, 'tab2')}>
              {tabTwoLabel}
            </button>
            <button
              type='button'
              className={tabClasses(tab === 'tab3')}
              onClick={(e) => handleTabClick(e, 'tab3')}>
              {tabThreeLabel}
            </button>
          </div>
        </div>
        <div className='content'>
          {tab === 'tab1' && (
            <SubContentSection content={content1}></SubContentSection>
          )}
          {tab === 'tab2' && (
            <SubContentSection content={content2}></SubContentSection>
          )}
          {tab === 'tab3' && (
            <SubContentSection content={content3}></SubContentSection>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContentTabbed;
