import React from 'react';
import { ReactComponent as Search } from '../../assets/svg/icon-search-message.svg';
import { ReactComponent as Handshake } from '../../assets/svg/icon-handshake.svg';
import { ReactComponent as Clipboard } from '../../assets/svg/icon-clipboard.svg';
import { ReactComponent as Identification } from '../../assets/svg/icon-identification.svg';
import Analytics from '../../config/analytics';
import constants from '../../config/constants';
import './audience-cards.scss';

export default function AudienceCards({ data }) {
  const handleClick = (event) => {
    const path = window.location.pathname;

    Analytics.link(
      { label: event.target.href },
      `www.${path}.audienceCards.card`,
      true
    );
  };

  const defaultCards = {
    "I'm looking for support": {
      icon: <Search aria-hidden={true} />,
      href: constants.registrationClient,
    },
    'I want to provide support': {
      icon: <Handshake aria-hidden={true} />,
      href: constants.registrationWorker,
    },
    "I'm a support coordinator": {
      icon: <Identification aria-hidden={true} />,
      href: constants.registrationCoordinator,
    },
    "I'm a disability services provider": {
      icon: <Clipboard aria-hidden={true} />,
      href: `${constants.domain}/${constants.landing_provider}`,
    },
  };

  const renderAudienceCards = () => {
    const audienceCards = data || defaultCards;

    return Object.entries(audienceCards).map(([card]) => (
      <li className='audience-card-single' key={card}>
        <a
          href={audienceCards[card].href}
          onClick={handleClick}
          className='audience-card-body'>
          <span className='audience-card-icon'>{audienceCards[card].icon}</span>
          <span className='audience-card-description'>{card}</span>
        </a>
      </li>
    ));
  };

  return (
    <>
      <ul className='audience-cards'>{renderAudienceCards()}</ul>
      <p className='text-link'>
        <a href='/how-hireup-works'>How Hireup works</a>
      </p>
    </>
  );
}
