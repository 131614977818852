import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Video from '../../components/video/video';
import _kebabCase from 'lodash.kebabcase';
import './video.scss';

export default class VideoLayout extends Component {
  layoutClasses(primary) {
    let classes = {
      'layout-video': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-text-align-${_kebabCase(primary.text_alignment)}`]:
        primary.text_alignment,
      [`-style-${_kebabCase(primary.video_style)}`]: primary.video_style,
      [`-layout-${_kebabCase(primary.layout)}`]: primary.layout,
    };
    return classNames(classes);
  }

  layoutBackground(primary) {
    return {
      backgroundImage:
        primary.video_poster && primary.video_poster.url
          ? `url(${primary.video_poster.url})`
          : null,
    };
  }

  render() {
    const { primary } = this.props;

    if (!primary) {
      return null;
    }

    const isBackground = primary.video_style === 'Background';
    const hasCaptions =
      primary.video_captions && primary.video_captions.url ? true : false;

    return (
      <section className={this.layoutClasses(primary)}>
        {primary.video_file && primary.video_file.url && (
          <div className='video'>
            <Video
              type='file'
              autoPlay={isBackground}
              loop={isBackground}
              muted={isBackground}
              controls={
                isBackground
                  ? []
                  : [
                      'play-large',
                      'play',
                      'progress',
                      'current-time',
                      'mute',
                      'volume',
                      'captions',
                      'fullscreen',
                    ]
              }
              file={primary.video_file.url}
              captions={hasCaptions ? primary.video_captions.url : null}
              poster={
                primary.video_poster && primary.video_poster.url
                  ? primary.video_poster.url
                  : 'false'
              }
            />
          </div>
        )}
        {primary.content && (
          <div className='container'>
            <div className='content'>
              {PrismicRichText.render(
                primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          </div>
        )}
      </section>
    );
  }
}
