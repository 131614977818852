import React, { Component } from 'react';
import constants from '../../config/constants';
import getVideoID from 'get-video-id';
import './video.scss';

export default class Video extends Component {
  render() {
    const { title, type, url, file, captions, poster, muted, autoPlay } =
      this.props;

    if (!file && !url) {
      return null;
    }

    if (type === 'file') {
      return (
        <div className='video-component'>
          <video
            poster={poster}
            playsInline
            crossOrigin='true'
            allow='autoplay'
            autoPlay={autoPlay}
            muted={muted}
            controls={!muted}>
            <source src={file} type='video/mp4' />
            <track
              kind='captions'
              label='English captions'
              src={captions}
              srcLang='en'
              default
            />
          </video>
        </div>
      );
    }

    let external = getVideoID(url);
    if (type === 'youtube' && external.id) {
      let src = `https://www.youtube.com/embed/${external.id}?origin=https://${constants.domain}&iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1&cc_load_policy=1`;
      return (
        <div className='video-component'>
          <iframe
            title={title || 'A Hireup YouTube Video'}
            src={src}
            allowFullScreen={true}
            allow='autoplay'></iframe>
        </div>
      );
    }
  }
}
