const primarySupport = require('./primarySupport');

const findingAndKeepingAJob = {
  supportName: 'Finding and keeping a job',
  supportIcon: 'userSearch',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'education-and-employment',
};

module.exports = findingAndKeepingAJob;
