import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { A11y, Controller, EffectCreative, Navigation } from 'swiper';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from '../../components/image/image';
import ShapeBackground from './shape-background';
import { PrismicConfig } from '../../prismic/config';
import { getShapeFill, getThemeClass } from '../../helpers/campaignHelpers';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/effect-creative';
import '../../assets/scss/campaign.scss';
import './campaign-testimonials.scss';

const CampaignTestimonials = ({ primary, items }) => {
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const imageSwiperRef = useRef(null);

  const contentSwiperRef = useRef(null);

  const swiperNextRef = useRef(null);

  const swiperPrevRef = useRef(null);

  return (
    <section
      className={classNames(
        'testimonial-section padding-section',
        getThemeClass(primary.background_color)
      )}>
      <div className='absolute-half-background'>
        <div
          className={classNames(
            'fill',
            getThemeClass(primary.shape_color)
          )}></div>
        <div
          className={classNames(
            'fill',
            getThemeClass(primary.background_color)
          )}></div>
      </div>
      <div
        className={classNames(
          'container top-content',
          getThemeClass(primary.shape_color)
        )}>
        <div className='section-gutter'>
          <div className='max-width-xsmall text-center'>
            <div className='rich-text'>
              {primary.content_header && primary.content_header.length
                ? PrismicRichText.render(
                    primary.content_header,
                    PrismicConfig.linkResolver,
                    PrismicConfig.htmlSerializer
                  )
                : null}
            </div>
          </div>
        </div>
      </div>
      <div className='testimonial-image-wrapper'>
        <div
          className={classNames(
            'absolute-half-background',
            getThemeClass(primary.shape_color)
          )}>
          <div
            className={classNames(
              'fill',
              getThemeClass(primary.shape_color)
            )}></div>
          <ShapeBackground
            fill={
              primary.background_color
                ? getShapeFill(primary.background_color)
                : '#FCF2E8'
            }
          />
          <div
            className={classNames(
              'fill',
              getThemeClass(primary.background_color)
            )}></div>
        </div>
        <div className='container'>
          <div className='section-gutter'>
            <div className='max-width-xsmall swiper-container'>
              <div className='swiper-container-inner'>
                <Swiper
                  a11y={{
                    enabled: true,
                    slideLabelMessage:
                      'Testimonial {{index}} of {{slidesLength}}',
                  }}
                  allowTouchMove={false}
                  controller={{ control: controlledSwiper }}
                  modules={[A11y, Controller, Navigation]}
                  navigation={{
                    nextEl: swiperNextRef.current,
                    prevEl: swiperPrevRef.current,
                  }}
                  onSlideChangeTransitionEnd={({
                    slides,
                    realIndex,
                    wrapperEl,
                  }) => {
                    wrapperEl?.classList.remove('swiper-transitioning');

                    slides.forEach((slide, index) => {
                      slide
                        .querySelectorAll('iframe')
                        ?.forEach((iframeElement) => {
                          if (index !== realIndex) {
                            // reset iframe to stop video
                            // eslint-disable-next-line no-self-assign
                            iframeElement.src = iframeElement.src;
                          }
                        });
                    });
                  }}
                  onSlideChangeTransitionStart={({ wrapperEl }) => {
                    wrapperEl?.classList.add('swiper-transitioning');
                  }}
                  ref={imageSwiperRef}
                  slidesPerView={1}
                  spaceBetween={24}
                  speed={500}
                  watchSlidesProgress={true}>
                  {items.map((item, index) => (
                    <SwiperSlide key={index}>
                      {item.testimonial_embed?.html ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.testimonial_embed.html,
                          }}
                          className='slide-inner iframe-cover'
                        />
                      ) : (
                        <div className='slide-inner img-cover'>
                          <Image primary={item.testimonial_image} />
                        </div>
                      )}
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className='swiper-absolute-controls'>
                  <button
                    className='swiper-circle-control'
                    ref={swiperPrevRef}
                    aria-label='Previous slide'
                    tabIndex='0'>
                    <MdChevronLeft />
                  </button>
                  <button
                    className='swiper-circle-control'
                    ref={swiperNextRef}
                    aria-label='Next slide'
                    tabIndex='0'>
                    <MdChevronRight />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='container'>
          <div className='section-gutter gap-y-content'>
            <div
              className={classNames(
                'max-width-xsmall content-slides-group',
                primary.icon_color
                  ? `quote-${primary.icon_color.toLowerCase()}`
                  : null
              )}>
              <Swiper
                allowTouchMove={false}
                creativeEffect={{
                  prev: {
                    translate: [0, '-120%', 0],
                  },
                  next: {
                    translate: [0, '120%', -160],
                  },
                }}
                effect='creative'
                modules={[Controller, EffectCreative]}
                onSlideChangeTransitionEnd={({ wrapperEl }) => {
                  wrapperEl?.classList.remove('swiper-transitioning');
                }}
                onSlideChangeTransitionStart={({ wrapperEl }) => {
                  wrapperEl?.classList.add('swiper-transitioning');
                }}
                onSwiper={setControlledSwiper}
                ref={contentSwiperRef}
                slidesPerView={1}
                spaceBetween={24}
                watchSlidesProgress={true}>
                {items.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className='content-slide'>
                      {item.testimonial_content
                        ? PrismicRichText.render(
                            item.testimonial_content,
                            PrismicConfig.linkResolver,
                            PrismicConfig.htmlSerializer
                          )
                        : null}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {primary.content_footer && primary.content_footer.length ? (
              <div className='rich-text text-center'>
                {PrismicRichText.render(
                  primary.content_footer,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CampaignTestimonials;
