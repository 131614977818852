import React, { Component } from 'react';
import classNames from 'classnames';
import _kebabCase from 'lodash.kebabcase';
import './separator.scss';

export default class Separator extends Component {
  layoutClasses(primary) {
    let classes = {
      'layout-separator': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
    };
    return classNames(classes);
  }
  render() {
    const { primary } = this.props;

    if (!primary) {
      return null;
    }

    return (
      <section className={this.layoutClasses(primary)}>
        <div className='container'>
          <div className='content'>
            <hr />
          </div>
        </div>
      </section>
    );
  }
}
