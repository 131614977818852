import React from 'react';
import { withRouter } from 'react-router-dom';
import { withPrismic } from '../../prismic/prismic';
import classNames from 'classnames';
import { ReactComponent as Globe } from '../../assets/svg/icon-globe.svg';
import { ReactComponent as Phone } from '../../assets/svg/icon-phone-outline.svg';
import NavLocale from '../nav-locale/nav-locale';
import { ReactComponent as HireupLogo } from '../../assets/img/hireup.brand.master.svg';
import Analytics from '../../config/analytics';
import Slices from '../../prismic/slices';
import Utilities from '../../config/utilities';
import constants from '../../config/constants';
import ConditionalWrapper from '../../helpers/conditionalWrapper';
import FocusTrap from 'focus-trap-react';
import locales from '../../config/locales';
import _find from 'lodash.find';
import './nav.scss';
import NavItem from '../nav-item/nav-item';

class Nav extends React.Component {
  state = {
    // Set the initial locale to the site default
    currentLocale: _find(locales, { code: constants.locale }),
  };

  menuClasses() {
    return classNames({
      'menu-primary': true,
      [this.props.classes]: this.props.classes,
    });
  }

  toggleClasses() {
    return classNames({
      'menu-toggle': true,
      '-active': this.props.isMenuActive,
    });
  }

  componentDidMount() {
    const { uid, prismic } = this.props;
    prismic.getNavigation(uid);
  }

  componentDidUpdate(old) {
    const { uid, locale, prismic } = this.props;
    if (!uid) {
      return null;
    }
    if (old.uid !== uid) {
      prismic.getNavigation(uid);
    }
    if (old.locale !== locale) {
      prismic.getNavigation(uid);
    }
    if (locale !== this.state.currentLocale.code) {
      this.setState({ currentLocale: _find(locales, { code: locale }) });
    }
  }

  handleScrollToLocale(e) {
    Utilities.scrollToAndFocusOn(e, 'current-locale');
  }

  setElementRef(refName) {
    return (element) => {
      if (element && (!this[refName] || this[refName] !== element)) {
        this[refName] = element;
        this.forceUpdate(); // re-render
      }
    };
  }

  unmountTrap = () => {
    setTimeout(() => {
      this.element1.firstChild.focus();
    }, 100);
  };

  render() {
    const {
      ariaLabel,
      collapsedSubmenus,
      includeHomepageLogo,
      locale,
      navigation,
      translationsLink,
      uid,
      isDesktopLayout,
      isMenuActive,
      handleMenuToggle,
    } = this.props;

    const { currentLocale } = this.state;

    if (!navigation || !navigation[uid]) {
      return null;
    }

    const { menu_items, action_items, header_items } = navigation[uid].data;
    const hasActionItems = action_items.length !== 0;
    const hasHeaderItems = header_items.length !== 0;

    return (
      <ConditionalWrapper
        condition={isMenuActive}
        wrapper={(children) => (
          <FocusTrap
            containerElements={[this.element1, this.element2, this.element3]}
            focusTrapOptions={{
              onDeactivate: this.unmountTrap,
            }}>
            {children}
          </FocusTrap>
        )}>
        <nav
          className={this.props.classes || `menu-actions`}
          aria-label={ariaLabel}>
          {/* top bar nav - only used in primary navigation mega menu*/}
          <div className='top-nav'>
            {includeHomepageLogo && (
              <div className='brand'>
                <a href='/' onClick={() => Analytics.link('/')}>
                  <HireupLogo role='image' aria-label='Home - Hireup' />
                </a>
              </div>
            )}

            {/* mobile menu header */}
            {hasHeaderItems && !isMenuActive && (
              <ul className='menu -header'>
                {header_items.map((item, i) => {
                  const isContact = item.primary.link.uid === 'contact';
                  // eslint-disable-next-line no-unused-vars
                  const { image, ...primaryFields } = item.primary;
                  return isContact ? (
                    <NavItem
                      primary={primaryFields}
                      items={[]}
                      key={i}
                      icon={<Phone aria-hidden={true} />}
                    />
                  ) : (
                    <NavItem primary={primaryFields} items={[]} key={i} />
                  );
                })}
              </ul>
            )}

            {handleMenuToggle && (
              <div ref={this.setElementRef('element1')}>
                <button
                  className={this.toggleClasses()}
                  aria-expanded={isMenuActive}
                  aria-haspopup={true}
                  aria-label='Main Menu'
                  onClick={() => {
                    handleMenuToggle(isMenuActive);
                  }}>
                  <div className='box'>
                    <div className='inner' />
                  </div>
                </button>
              </div>
            )}

            {/* top nav actions */}
            {(hasActionItems || translationsLink) && (
              <ul
                className='menu -actions'
                ref={this.setElementRef('element2')}>
                {hasActionItems && (
                  <Slices
                    slices={action_items}
                    collapsedSubmenus={collapsedSubmenus}
                  />
                )}

                {/* Locale Switcher */}
                {isDesktopLayout && translationsLink && (
                  <li className='nav-item -hide-text'>
                    <a
                      href='#current-locale'
                      onClick={(e) => this.handleScrollToLocale(e)}>
                      <Globe aria-hidden={true} />
                      <span className='screen-reader-only'>
                        {currentLocale.language_toggle}
                      </span>
                    </a>
                  </li>
                )}
                {translationsLink && <NavLocale locale={locale} />}
              </ul>
            )}
          </div>

          {/* regular nav items - used in primary nav and footer nav */}
          <div className='nav-menus' ref={this.setElementRef('element3')}>
            {menu_items && (
              <ul className='menu -items'>
                <Slices
                  slices={menu_items}
                  collapsedSubmenus={collapsedSubmenus}
                  isDesktopLayout={isDesktopLayout}
                />
              </ul>
            )}
          </div>
        </nav>
      </ConditionalWrapper>
    );
  }
}

export default withPrismic(withRouter(Nav));
