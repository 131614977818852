import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Icon from '../../components/icon/icon';
import _kebabCase from 'lodash.kebabcase';
import './icons.scss';

export default class IconsLayout extends Component {
  layoutClasses(primary) {
    let classes = {
      'layout-icons': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-size-${_kebabCase(primary.icon_size)}`]: primary.icon_size,
      [`-columns-${_kebabCase(primary.icon_columns)}`]: primary.icon_columns,
      [`-columns-auto`]: !primary.icon_columns,
      [`-count-${this.props.items.length}`]: true,
    };
    return classNames(classes);
  }

  render() {
    const { primary, items } = this.props;
    const hasContent =
      primary.content[0]?.text !== '' && primary.content.length !== 0;
    const hasItems = items.length !== 0;

    if (!hasContent && !hasItems) {
      return null;
    }

    return (
      <section className={this.layoutClasses(primary)}>
        {hasContent && (
          <div className='container'>
            <div className='content'>
              {PrismicRichText.render(
                primary.content,
                PrismicConfig.linkResolver,
                PrismicConfig.htmlSerializer
              )}
            </div>
          </div>
        )}
        {hasItems && (
          <div className='container'>
            {items.map((item, i) => (
              <Icon
                primary={item}
                key={i}
                size={
                  primary.icon_size ? primary.icon_size.toLowerCase() : 'large'
                }
              />
            ))}
          </div>
        )}
      </section>
    );
  }
}
