import React, { Component } from 'react';
import { RichText as PrismicRichText, Link } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { format } from 'date-fns';
import Image from '../image/image';
import Analytics from '../../config/analytics';
import constants from '../../config/constants';
import './news.scss';

export default class NewsPost extends Component {
  handleClick = (event) => {
    const path = window.location.pathname;
    const type = this.props.primary.link ? 'relatedPages' : 'newsCard';

    Analytics.link({ label: event.target.href }, `www.${path}.${type}.link`);
  };

  transformLink(primary) {
    let link;

    // transform news items into object
    // consumable by prismic link resolver
    if (!primary.link && primary.uid) {
      const newsArticleLink = {
        type: primary.type,
        lang: 'en-au',
        uid: primary.uid,
        link_type: 'Document',
        isBroken: false,
      };

      link = newsArticleLink;
    } else {
      link = primary.link;
    }
    return Link.url(link, PrismicConfig.linkResolver);
  }

  render() {
    const { primary } = this.props;
    const image = primary.data?.image || primary.image;
    const title = primary.data?.title || primary.title;
    const publishedDate = primary.data?.displayed_publication_date;

    if (!primary || !image?.url) {
      return null;
    }

    return (
      <a
        className='post-single'
        href={this.transformLink(primary)}
        onClick={this.handleClick}>
        {image && (
          <Image primary={image} lazyload={true} width={600} height={315} />
        )}
        {publishedDate && (
          <p className='date'>
            {primary.tags?.includes(constants.static_post_tag)
              ? `Recently updated`
              : `${format(new Date(publishedDate), 'do MMMM yyyy')}`}
          </p>
        )}
        {title && (
          <div className='title'>
            {PrismicRichText.render(
              title,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
          </div>
        )}
      </a>
    );
  }
}
