import React, { Component } from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import LoginForm from '../../components/login-form/login-form';
import Loading from '../../components/loading/loading';
import { withPrismic } from '../../prismic/prismic';
import { withHireup } from '../../hireup/hireup';
import classNames from 'classnames';
import axios from 'axios';
import Field from '../../components/field/field';
import Utilities from '../../config/utilities';
import { Formik, Form } from 'formik';
import _get from 'lodash.get';
import { MdWarning } from 'react-icons/md';
import './refer.scss';

class ReferLayout extends Component {
  state = {
    initialValues: {
      email: '',
      name: '',
    },
    error: null,
    submitted: false,
  };

  submit = async (values) => {
    const { email, name } = values;
    const { user } = this.props;
    const userId = _get(user, 'acnt.id');
    const properties = {
      refereeEmail: email,
      refereeName: name,
      referrerEmail: _get(user, 'auth.email'),
      referrerName: _get(user, 'auth.firstName'),
      referralURL: window.location.pathname,
    };

    await axios.post(`/api/v1/form/event/`, {
      event: 'user_referred',
      properties,
      userId,
    });

    await axios
      .post(`/api/v1/form/event/`, {
        event: 'Referral',
        properties,
        userId,
      })
      .then(() => {
        this.setState({ submitted: true });
        this.setState({ error: null });
        Utilities.scrollToAndFocusOn(null, 'conclusion');
      })
      .catch(() => {
        this.setState({ error: 'An error ocurred, please try again later.' });
        this.setState({ submitted: false });
      });
  };

  reset = async (handleReset) => {
    this.setState({ initialValues: { email: '', name: '' } });
    this.setState({ submitted: false });
    this.setState({ error: null });
    handleReset();
  };

  layoutClasses() {
    let classes = {
      'layout-refer': true,
      '-submitted': this.state.submitted,
    };
    return classNames(classes);
  }

  render() {
    const { user, primary } = this.props;
    if (user === null) {
      return <Loading full={true} />;
    }
    const { initialValues, error, submitted } = this.state;
    const hasConclusion =
      primary.conclusion_content && primary.conclusion_content.length > 0;

    return (
      <section className={this.layoutClasses()}>
        <div className='container'>
          {!user && (
            <div className='content'>
              <div className='introduction'>
                <p>You need to be logged in to invite a friend:</p>
                <LoginForm disableRedirect={true} />
              </div>
            </div>
          )}

          {user && (
            <>
              <Formik initialValues={initialValues} onSubmit={this.submit}>
                {({ errors, isSubmitting, submitCount, handleReset }) => (
                  <>
                    <div className='content'>
                      <Form
                        noValidate
                        role='form'
                        aria-labelledby='introduction'>
                        <div className='form-content'>
                          <div id='introduction' className='form-introduction'>
                            {PrismicRichText.render(
                              primary.introduction_content,
                              PrismicConfig.linkResolver,
                              PrismicConfig.htmlSerializer
                            )}
                          </div>
                          <Field
                            name='name'
                            type='text'
                            label='Their Name'
                            required={true}
                          />
                          <Field
                            name='email'
                            type='email'
                            label='Their Email'
                            required={true}
                          />
                        </div>
                        <div className='form-actions'>
                          <button type='submit' className='button -primary'>
                            {!isSubmitting && <span>Send Invite</span>}
                            {isSubmitting && (
                              <>
                                <span className='spinner-container'>
                                  <span className='spinner-indicator'></span>
                                </span>{' '}
                                <span>Inviting</span>
                              </>
                            )}
                          </button>
                          {submitCount > 0 &&
                            !Utilities.isObjectEmpty(errors) && (
                              <p className='form-error' role='alert'>
                                <MdWarning /> There are errors in the fields
                                above
                              </p>
                            )}
                          {error && (
                            <p className='form-error' role='alert'>
                              <MdWarning /> {error}
                            </p>
                          )}
                        </div>
                      </Form>
                    </div>

                    <div id='conclusion' tabIndex='-1'>
                      {submitted && (
                        <div className='conclusion'>
                          {hasConclusion &&
                            PrismicRichText.render(
                              primary.conclusion_content,
                              PrismicConfig.linkResolver,
                              PrismicConfig.htmlSerializer
                            )}
                          {!hasConclusion && (
                            <p>Thank you, your invtiation has been sent!.</p>
                          )}
                          <button
                            onClick={() => this.reset(handleReset)}
                            className='button -neutral'>
                            Refer somone else
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Formik>
            </>
          )}
        </div>
      </section>
    );
  }
}

export default withPrismic(withHireup(ReferLayout));
