import React from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import ProductCard from '../../components/product-card/product-card';
import './product-offerings.scss';

const ProductOfferingsLayout = ({ primary, items }) => {
  return (
    <section className='product-offerings-layout'>
      <div className='container'>
        <div className='content'>
          {PrismicRichText.render(
            primary.title_content,
            PrismicConfig.linkResolver,
            PrismicConfig.htmlSerializer
          )}
        </div>

        <div className='content'>
          <div className='product-items'>
            {items &&
              items.map((item, i) => <ProductCard primary={item} key={i} />)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductOfferingsLayout;
