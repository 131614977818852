import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Loading from '../../components/loading/loading';
import ProfileCards from '../../components/profile-cards/profile-cards';
import Contact from '../../components/contact/contact';
import SearchFilters from '../../components/search-filters/search-filters';
import useSessionStorage from '../../helpers/useSessionStorage';
import {
  transformLocationString,
  getSupportWorkerData,
  getTotalWorkers,
} from '../../hireup/supportWorkerSearch';
import './profile-cards-filters.scss';

const SupportWorkerProfilesLayout = ({ primary }) => {
  const { filter_type } = primary;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [supportTypeFilter, setSupportTypeFilter] = useState(filter_type);
  const [state, setState] = useState({
    publicSearchResponse: null,
    publicSearchProfiles: null,
    placeName: null,
  });

  const [locationSearched, setLocationSearched] = useSessionStorage(
    'hu-location',
    null
  );

  const [locationRadius] = useSessionStorage('hu-location-radius', 30000);

  const MIN_WORKERS = 6;
  const DEFAULT_LOCATION = 'Sydney, NSW 2000';

  const getInitialLocation = useCallback(() => {
    if (locationSearched) {
      return transformLocationString(locationSearched);
    } else {
      return DEFAULT_LOCATION;
    }
  }, [locationSearched]);

  const generateTitle = (totalWorkersToShow) => {
    const hasMinWorkers = totalWorkersToShow >= MIN_WORKERS;
    const showSupportType = supportTypeFilter && supportTypeFilter !== 'All';
    const subtitle = ` within ${locationRadius / 1000}km of ${
      state.placeName
    } ${showSupportType ? `offering ${supportTypeFilter.toLowerCase()}` : ''}`;

    return (
      <h2>
        {hasMinWorkers ? (
          <>
            There are <em>{Intl.NumberFormat().format(totalWorkersToShow)}</em>{' '}
            support workers available
          </>
        ) : (
          <>There are less than {MIN_WORKERS} support workers available</>
        )}
        <span>{subtitle}</span>
      </h2>
    );
  };

  const getLocationSearchResults = useCallback(async () => {
    setLoading(true);
    const initialLocation = getInitialLocation();

    try {
      // get the location lat/long from locations.json
      const searchResponse = await axios.post('/search/', {
        locationSearched: locationSearched || initialLocation.toUpperCase(),
      });

      const [location] = searchResponse.data;

      const { publicSearchResponse, publicSearchProfiles, placeName, radius } =
        await getSupportWorkerData({
          location,
          locationRadius,
          locationSearched,
          supportType: supportTypeFilter,
        });

      setState({
        publicSearchResponse,
        publicSearchProfiles,
        placeName,
        radius,
      });

      setLoading(false);
    } catch (err) {
      console.warn('Error retrieving public search API results', err);
      setError(true);
    }
  }, [locationSearched, getInitialLocation, locationRadius, supportTypeFilter]);

  useEffect(() => {
    async function getData() {
      await getLocationSearchResults();
    }
    getData();
  }, [getLocationSearchResults, getInitialLocation, locationRadius]);

  if (!state.publicSearchResponse && !error) {
    return <Loading full={true} />;
  }

  // totalWorkersToShow is not part of the overall state object as it was
  // causing the useCallback to be called multiple times, due to
  // supportTypeFilter being a dependency of useContext
  // and causing a retrigger when its updated
  const totalWorkersToShow = getTotalWorkers({
    publicSearchResponse: state.publicSearchResponse,
  });
  const hasMinWorkers = totalWorkersToShow >= MIN_WORKERS;

  return (
    <section className='layout-profile-cards-filter -background-light-gray'>
      <div className='container'>
        <div className='content search-results-header'>
          {generateTitle(totalWorkersToShow)}
        </div>
        <SearchFilters
          loading={loading}
          locationSearched={locationSearched}
          setLocationSearched={setLocationSearched}
          defaultSupportType={supportTypeFilter}
          setSupportTypeFilter={setSupportTypeFilter}
          onSubmit={getLocationSearchResults}
          showFullSearch={false}
        />

        {loading && <Loading />}

        {!hasMinWorkers && <Contact topMargin />}

        {hasMinWorkers && !loading && (
          <ProfileCards workerProfiles={state.publicSearchProfiles} />
        )}
      </div>
    </section>
  );
};

export default SupportWorkerProfilesLayout;
