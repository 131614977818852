const primarySupport = {
  name: 'Therapy support',
  appName: 'Therapy support',
  image:
    'https://images.prismic.io/hireup/65383694-ccd4-4a32-9c1d-625603f3bc9a_Description%3DDescription8.png',
  introBlock: {
    background_color: 'Dark',
    content: [
      {
        type: 'heading2',
        text: 'What is therapy support?',
        spans: [],
      },
      {
        type: 'paragraph',
        text: 'Therapy support involves NDIS participants bringing support workers along to their therapy to get the most out of their sessions. Therapy support is one of eight areas of disability support that support workers can provide on Hireup.',
        spans: [],
      },
    ],
    content_two: [
      {
        type: 'paragraph',
        text: '',
        spans: [],
      },
    ],
    layout: '2:1',
  },
};

module.exports = primarySupport;
