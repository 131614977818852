import { hotjar } from 'react-hotjar';
import constants from '../config/constants';
import _startCase from 'lodash.startcase';
import { timestamp } from '../helpers/eventHelpers';
import { getSegmentAPIKey } from './segment';

const Analytics = {
  // Initialize site-wide Analytics
  initialize: () => {
    // Segment
    const segmentKey = getSegmentAPIKey();
    window.analytics && window.analytics.load(segmentKey);

    // Hotjar
    hotjar &&
      hotjar.initialize(constants.hotjar_id, constants.hotjar_snippet_version);
  },

  // Log actions on each pageview
  page: () => {
    window.analytics && window.analytics.page();
  },

  // Send the identify call to Segment
  identify: ({ id, traits }) => {
    const uuid = id || traits.email || null;
    window.analytics && window.analytics.identify(uuid, traits);
  },

  getAnonymousId: () => {
    if (!window.analytics) {
      return undefined;
    }
    if (!window.analytics.user) {
      return undefined;
    }
    if (typeof window.analytics.user !== 'function') {
      return undefined;
    }
    return window.analytics.user().anonymousId();
  },

  // Send the track call to Segment
  track: ({ event, properties }) => {
    window.analytics &&
      window.analytics.track(event, {
        ...properties,
        appName: 'www',
        appVersion: constants.appVersion,
      });
  },

  // Log asynchronous Google Analytics events through Segment
  event: (category, action, label, value = 1, nonInteraction = false) => {
    window.analytics &&
      window.analytics.track(
        `${_startCase(category)} - ${_startCase(action)}`,
        { category, action, label, value, nonInteraction }
      );
  },

  // Track link clicks within the app
  link: (link, uiContext = undefined, button = false) => {
    const label = link?.url || link?.uid || link?.label || '';
    let action = 'link';

    // Explicitly state the action for known links, including the removal of the hash
    if (label.includes('app.hireup.com.au') && label.includes('register')) {
      action = 'register';
    }
    if (label.includes('app.hireup.com.au') && label.includes('registration')) {
      action = 'register';
    }
    if (label.includes('app.hireup.com.au') && label.includes('login')) {
      action = 'login';
    }
    if (label.endsWith('.pdf')) {
      action = 'download';
    }
    if (button) {
      action = 'button';
    }

    const commonProperties = {
      category: 'click',
      label: label,
      nonInteraction: false,
      originTimestamp: timestamp(),
      value: 1,
    };

    switch (action) {
      case 'download':
        Analytics.event('click', 'download', label);
        Analytics.track({
          event: 'websiteResource_downloaded',
          properties: {
            ...commonProperties,
            action: 'download',
          },
        });
        break;
      case 'login':
        Analytics.event('click', 'login', label);
        Analytics.track({
          event: 'button_pressed',
          properties: {
            ...commonProperties,
            action: 'login',
          },
        });
        break;
      case 'register':
        Analytics.event('click', 'register', label);
        Analytics.track({
          event: 'button_pressed',
          properties: {
            ...commonProperties,
            action: 'register',
            category: uiContext || commonProperties.category,
          },
        });
        break;
      case 'link':
        Analytics.event('click', 'link', label);
        Analytics.track({
          event: 'link_pressed',
          properties: {
            ...commonProperties,
            action: 'link',
            category: uiContext || commonProperties.category,
          },
        });
        break;
      case 'button':
        Analytics.event('click', 'button', label);
        Analytics.track({
          event: 'button_pressed',
          properties: {
            ...commonProperties,
            action: 'button',
            category: uiContext || commonProperties.category,
          },
        });
        break;
      default:
      // do nothing
    }
  },
};

export default Analytics;
