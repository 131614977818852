import * as React from 'react';

const IconChevronDownSmall = (props) => {
  return (
    <span className='icon'>
      <svg {...props} viewBox='0 0 24 24'>
        <path
          d='M16.7 10.7a1 1 0 0 0-1.31-1.49l-.1.08L12 12.6 8.7 9.3a1 1 0 0 0-1.31-.08l-.1.08a1 1 0 0 0-.08 1.32l.08.1 4 4a1 1 0 0 0 1.32.08l.1-.08 4-4z'
          fill='currentColor'
        />
      </svg>
    </span>
  );
};

export default IconChevronDownSmall;
