import React from 'react';
import SearchLite from '../../components/search-lite/search-lite';
import TableOfContents from '../../components/table-of-contents/table-of-contents';
import AudienceCards from '../../components/audience-cards/audience-cards';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import Utilities from '../../config/utilities';
import _kebabCase from 'lodash.kebabcase';
import './full.scss';

const FullLayout = ({ primary, items }) => {
  if (!primary) {
    return null;
  }

  const isVisible = (property) => {
    // visibility as a string deprecated September 2022
    if (property === true || property === 'Visible') {
      return true;
    } else {
      return false;
    }
  };

  const showBackgroundImage = isVisible(primary.background_image_mobile);
  const showSearch = isVisible(primary.search_lite);
  const showAudienceCards = isVisible(primary.audience_cards);
  const showTableOfContents = items?.[0]?.anchor_text !== null;

  const layoutClasses = () => {
    let classes = {
      'layout-full': true,
      '-with-background':
        primary.background_image && primary.background_image.url,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
      [`-background-position-${_kebabCase(primary.background_image_position)}`]:
        primary.background_image && primary.background_image_position,
      [`-background-hidden`]: !showBackgroundImage,
      [`-padding-${_kebabCase(primary.padding)}`]: primary.padding,
    };
    return classNames(classes);
  };

  const layoutBackground = () => {
    return {
      backgroundImage:
        primary.background_image && primary.background_image.url
          ? `url(${Utilities.buildImgixURL(primary.background_image.url, {
              auto: 'format',
              h: 600,
              q: 75,
            })}`
          : null,
    };
  };

  return (
    <section className={layoutClasses()}>
      <div className='container'>
        <div className='content'>
          {primary &&
            PrismicRichText.render(
              primary.content,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
          {showSearch && <SearchLite />}
          {showAudienceCards && <AudienceCards />}
          {showTableOfContents && <TableOfContents items={items} />}
        </div>
      </div>
      {primary.background_image && (
        <LazyLoad>
          <div className='layout-background' style={layoutBackground()}></div>
        </LazyLoad>
      )}
    </section>
  );
};

export default FullLayout;
