const primarySupport = require('./primarySupport');

const disabilityRelatedHealth = {
  supportName: 'Disability related health',
  supportIcon: 'personWithCrutch',
  primarySupport: primarySupport,
  relatedArticlesCategory: 'personal-care',
};

module.exports = disabilityRelatedHealth;
