import React, { Component } from 'react';
import { MdLink } from 'react-icons/md';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoWhatsapp,
} from 'react-icons/io';
import './share-links.scss';

export default class ShareLinks extends Component {
  state = {
    showCopyLinkModal: false,
    url: this.props.url || window.location.href,
    message: this.props.message || document.title,
    frame:
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600',
    caption: this.props.caption || 'Share:',
    copied: false,
  };

  copyLink = (e) => {
    e.preventDefault();
    const { url } = this.state;

    // Create an invisible textarea to copy content from
    const el = document.createElement('textarea');
    el.value = url;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);

    // Check if there is any content selected previously
    // Store selection if found
    // Mark as false to know no selection existed before
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;

    // Copy to the clipboard, remove the element, reselect the preselected element
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }

    this.setState({ copied: true });
  };

  share = (e, channel) => {
    e.preventDefault();
    const { url, message, frame } = this.state;
    let path = null;

    switch (channel) {
      case 'facebook':
        path = `https://www.facebook.com/sharer/sharer.php?u=${escape(
          url
        )}&t=${message}&quote=${message}`;
        break;
      case 'twitter':
        path = `https://twitter.com/share?url=${escape(
          url
        )}&text="${message}" via @hireupaustralia`;
        break;
      case 'linkedin':
        path = `https://www.linkedin.com/sharing/share-offsite/?url=${escape(
          url
        )}`;
        break;
      case 'whatsapp':
        path = `whatsapp://send?text=${message}%20${url}`;
        break;
      default:
        return;
    }
    if (path) {
      window.open(path, '', frame);
    }
  };

  render() {
    const { caption, copied } = this.state;
    const {
      showFacebook,
      showTwitter,
      showWhatsapp,
      showLinkedin,
      showCopy,
      size,
    } = this.props;
    const isLarge = size === 'large';

    return (
      <>
        {caption && isLarge && <h3>{caption}</h3>}
        <div className={`share-links ${isLarge ? '-large' : ''}`}>
          {caption && !isLarge && <div className='caption'>{caption}</div>}
          {showFacebook !== false && (
            <a
              className={`button ${isLarge ? '' : '-small'} -facebook`}
              href='/'
              onClick={(e) => this.share(e, 'facebook')}>
              <IoLogoFacebook /> <span className='channel-name'>Facebook</span>
            </a>
          )}
          {showTwitter !== false && (
            <a
              className={`button ${isLarge ? '' : '-small'} -twitter`}
              href='/'
              onClick={(e) => this.share(e, 'twitter')}>
              <IoLogoTwitter /> <span className='channel-name'>Twitter</span>
            </a>
          )}
          {showLinkedin !== false && (
            <a
              className={`button ${isLarge ? '' : '-small'} -linkedin`}
              href='/'
              onClick={(e) => this.share(e, 'linkedin')}>
              <IoLogoLinkedin /> <span className='channel-name'>LinkedIn</span>
            </a>
          )}
          {showWhatsapp !== false && (
            <a
              className={`button ${isLarge ? '' : '-small'} -whatsapp`}
              href='/'
              onClick={(e) => this.share(e, 'whatsapp')}>
              <IoLogoWhatsapp /> <span className='channel-name'>WhatsApp</span>
            </a>
          )}
          {showCopy !== false && (
            <a
              className={`button ${isLarge ? '' : '-small'} -secondary`}
              href='/'
              onClick={this.copyLink}>
              <MdLink />{' '}
              <span className='channel-name'>
                {copied ? 'Copied!' : 'Copy Link'}
              </span>
            </a>
          )}
        </div>
      </>
    );
  }
}
