import utilities from './utilities';

export const API_BASE_URL_V1 = '/api/v1';

export const API_BASE_URL_V1_5 = '/api/v1.5';

export const ENV_BASE_URL = {
  local: 'http://app.local.hireup.com.au',
  dev: `https://app-${window.location.hostname}`,
  staging: 'https://staging.dev.hireup.com.au',
  prod: 'https://app.hireup.com.au',
};

export const getEnvBaseUrl = () => {
  if (utilities.isProduction()) {
    return ENV_BASE_URL.prod;
  }
  if (utilities.isStaging()) {
    return ENV_BASE_URL.staging;
  }
  if (utilities.isLocalDevelopment()) {
    return ENV_BASE_URL.dev;
  }
  return ENV_BASE_URL.local;
};
