import React, { Component } from 'react';
import classNames from 'classnames';
import fallbackDefaultImage from '../../assets/img/default-placeholder.gif';
import LazyLoad from 'react-lazyload';
import Utilities from '../../config/utilities';
import './image.scss';

export default class Image extends Component {
  layoutClasses() {
    const { lazyload } = this.props;
    let classes = {
      'image-single': true,
      '-lazyload': lazyload,
    };
    return classNames(classes);
  }

  render() {
    const { primary, width, height, lazyload } = this.props;

    if (!primary) {
      return null;
    }

    let defaultImage = this.props.defaultImage || fallbackDefaultImage;

    // Lazy-load images
    if (lazyload && primary.dimensions) {
      // Set parameters to append to Imgix,
      // Using our own quality setting of 75
      // Using an explitly set width and height, or the default set by the author in Prismic
      let params = {
        q: 75,
        w: width || primary.dimensions.width,
        h: height || primary.dimensions.height,
      };

      return (
        <div className={this.layoutClasses()}>
          <LazyLoad
            placeholder={
              <img
                src={fallbackDefaultImage}
                alt={primary.alt || ''}
                className='-placeholder'
              />
            }
            width={params.w}
            height={params.h}
            offset={800}
            once={true}>
            <img
              src={
                primary.url
                  ? Utilities.buildImgixURL(primary.url, params)
                  : defaultImage
              }
              alt={primary.alt || ''}
              width={params.w}
              height={params.h}
            />
          </LazyLoad>
        </div>
      );
    }

    return (
      <div className={this.layoutClasses()}>
        <img
          src={
            primary.url
              ? `${primary.url}${width ? '&w=' + width : ''}`
              : defaultImage
          }
          alt={primary.alt || ''}
        />
      </div>
    );
  }
}
