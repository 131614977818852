import React, { Component } from 'react';
import classNames from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { PrismicConfig } from '../../prismic/config';
import { withPrismic } from '../../prismic/prismic';
import Event from '../../components/event/event';
import EventTabs from '../../components/tabs/event-tabs';
import Loading from '../../components/loading/loading';
import TrackVisibility from 'react-on-screen';
import _kebabCase from 'lodash.kebabcase';
import './events.scss';

// Map the Prismic event types to short, usable names.
const eventTypes = {
    'All Events': 'all',
    'Upcoming Events': 'upcoming',
    'Past Events': 'past',
  },
  loadUpcoming = {
    all: true,
    upcoming: true,
    past: false,
  },
  loadPast = {
    all: true,
    upcoming: false,
    past: true,
  };

class EventsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'all',
      loading: true,
      upcomingEvents: null,
      pastEvents: null,
      currentPage: 1,
      nextPage: 2,
    };
  }

  componentDidMount() {
    // Set the events type
    this.setState({ type: eventTypes[this.props.primary.type] || 'all' });
    this.loadEvents(
      this.state.type,
      this.props.primary.results_per_page || 3,
      this.state.currentPage
    );
  }

  layoutClasses(primary, events = '') {
    let classes = {
      'layout-events': true,
      [`-background-${_kebabCase(primary.background_color)}`]:
        primary.background_color,
    };
    if (events && events.length) {
      classes[`-count-${events.length}`] = events && events.length;
    }
    return classNames(classes);
  }

  async loadEvents(type, pageSize = 3, page = 1) {
    // If we should load upcoming events
    if (loadUpcoming[type]) {
      this.setState({ loading: 'upcoming' });
      let response = await this.props.prismic.getEvents(
        'upcoming',
        pageSize,
        page
      );
      if (this.state.upcomingEvents) {
        response.results = this.state.upcomingEvents.results.concat(
          response.results
        );
      }
      this.setState({ upcomingEvents: response });
    }
    // If we should load past events
    if (loadPast[type]) {
      this.setState({ loading: 'past' });
      let response = await this.props.prismic.getEvents('past', pageSize, page);
      if (this.state.pastEvents) {
        response.results = this.state.pastEvents.results.concat(
          response.results
        );
      }
      this.setState({ pastEvents: response });
    }

    this.setState({ loading: false });
  }

  loadMoreInfinite(isVisible, type) {
    const { upcomingEvents, pastEvents } = this.state;
    if (loadUpcoming[type]) {
      if (
        isVisible &&
        !this.state.loading &&
        upcomingEvents.page < upcomingEvents.total_pages
      ) {
        this.loadEvents(
          type,
          this.props.primary.results_per_page || 3,
          upcomingEvents.page + 1
        );
      }
    }
    if (loadPast[type]) {
      if (
        isVisible &&
        !this.state.loading &&
        pastEvents.page < pastEvents.total_pages
      ) {
        this.loadEvents(
          type,
          this.props.primary.results_per_page || 3,
          pastEvents.page + 1
        );
      }
    }
  }

  loadMoreButton(type) {
    const { upcomingEvents, pastEvents } = this.state;
    if (loadUpcoming[type]) {
      if (
        !this.state.loading &&
        upcomingEvents.page < upcomingEvents.total_pages
      ) {
        this.loadEvents(
          type,
          this.props.primary.results_per_page || 3,
          upcomingEvents.page + 1
        );
      }
    }
    if (loadPast[type]) {
      if (!this.state.loading && pastEvents.page < pastEvents.total_pages) {
        this.loadEvents(
          type,
          this.props.primary.results_per_page || 3,
          pastEvents.page + 1
        );
      }
    }
  }

  renderEvents(events, type) {
    if (!events || !events.results) {
      return (
        <div className='empty'>
          <p>No events found</p>
        </div>
      );
    }

    const hasInfiniteScroll =
      this.props.primary.load_more === 'Infinite scroll';
    const hasLoadMoreButton = this.props.primary.load_more === 'Button';

    let count = 0;
    let output = events.results.map((event, i) => {
      count++;
      return <Event primary={event} key={i} />;
    });

    if (count === 0) {
      output.push(
        <div className='events-empty'>
          <p>No events found</p>
        </div>
      );
    }

    if (this.state.loading === type) {
      output.push(<Loading />);
    } else if (events.page !== events.total_pages && hasInfiniteScroll) {
      output.push(
        <TrackVisibility className='load-more'>
          {({ isVisible }) => this.loadMoreInfinite(isVisible, type)}
        </TrackVisibility>
      );
    } else if (events.page !== events.total_pages && hasLoadMoreButton) {
      output.push(
        <div className='load-more-button'>
          <button
            onClick={this.loadMoreButton.bind(this, type)}
            className='button -primary'>
            Load more
          </button>
        </div>
      );
    } else {
      output.push(<div className='events-loaded'></div>);
    }

    return output;
  }

  renderTabbedEvents() {
    const { upcomingEvents, pastEvents, type } = this.state;
    let tabs = [];

    if (loadUpcoming[type]) {
      const label = 'Upcoming Events';
      const content = this.renderEvents(upcomingEvents, 'upcoming');
      tabs.push({ label, content });
    }
    if (loadPast[type]) {
      const label = 'Past Events';
      const content = this.renderEvents(pastEvents, 'past');
      tabs.push({ label, content });
    }
    return <EventTabs content={tabs} />;
  }

  render() {
    const { primary } = this.props;
    const { pastEvents, upcomingEvents } = this.state;
    if (!pastEvents && !upcomingEvents) {
      return null;
    }

    return (
      <section className={this.layoutClasses(primary)}>
        {(primary.title || primary.content) && (
          <div className='container'>
            <div className='content'>
              {primary.title &&
                PrismicRichText.render(
                  primary.title,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
              {primary.content &&
                PrismicRichText.render(
                  primary.content,
                  PrismicConfig.linkResolver,
                  PrismicConfig.htmlSerializer
                )}
            </div>
          </div>
        )}
        <div className='container'>{this.renderTabbedEvents()}</div>
      </section>
    );
  }
}

export default withPrismic(EventsLayout);
