import * as React from 'react';

const IconBowel = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 48 48'
        fill='none'
        stroke='currentColor'
        strokeWidth='3'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M10.5 46.5v-3a3 3 0 0 1 3-3c1.5 0 1.5 6 15 6s18-12 18-18c0-13.5-9-21-21-21a3 3 0 0 1-3-3v-3' />
        <path d='M1.5 46.5v-3a12.496 12.496 0 0 1 12-12c3.012 0 12.078-7.564 7.442-18.136' />
        <path d='M13.5 1.5v3a9.01 9.01 0 0 0 9 9h3' />
      </svg>
    </span>
  );
};

export default IconBowel;
