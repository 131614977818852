import React from 'react';
import { withCookies } from 'react-cookie';
import { withPrismic } from '../../prismic/prismic';
import { withHireup } from '../../hireup/hireup';
import { PrismicConfig } from '../../prismic/config';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import _get from 'lodash.get';
import { MdClose } from 'react-icons/md';
import './banner-bar.scss';

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cookie: props.cookies.get('hireup_www') || {},
    };
  }

  dismissBanner = (banner) => {
    const { cookies } = this.props;
    const { cookie } = this.state;
    let updatedCookie = {
      ...cookie,
      [`banner_${banner.banner_group || 'default'}`]: new Date().toISOString(),
    };
    cookies.set('hireup_www', updatedCookie, { path: '/' });
    this.setState({ cookie: updatedCookie });
  };

  isBannerActive = (banner) => {
    // Hide if document settings banner is hidden
    if (this.props.banner === 'Hidden') {
      return false;
    }

    // Hide if the banner is inactive
    if (!banner.banner_active) {
      return false;
    }

    // Hide if it's already been already dismissed
    if (this.state.cookie[`banner_${banner.banner_group || 'default'}`]) {
      return false;
    }

    // Show if the banner is for all visitors
    if (banner.banner_display === 'All Visitors') {
      return true;
    }

    // If user is logged in
    if (this.props.user) {
      // Only if they match the user type
      switch (banner.banner_display) {
        case 'All Logged In Users':
          return true;
        case 'Logged In People Seeking Support':
          return (
            _get(this.props.user, 'acnt.type') === 1 &&
            !_get(this.props.user, 'user.accountManager')
          );
        case 'Logged In Account Managers':
          return (
            _get(this.props.user, 'acnt.type') === 1 &&
            _get(this.props.user, 'user.accountManager')
          );
        case 'Logged In Support Workers':
          return _get(this.props.user, 'acnt.type') === 2;
        case 'Approved People Seeking Support':
          return (
            _get(this.props.user, 'acnt.type') === 1 &&
            _get(this.props.user, 'acnt.status') === 4 &&
            !_get(this.props.user, 'acnt.accountManager')
          );
        case 'Approved Account Managers':
          return (
            _get(this.props.user, 'acnt.type') === 1 &&
            _get(this.props.user, 'acnt.status') === 4 &&
            _get(this.props.user, 'acnt.accountManager')
          );
        case 'Approved Support Workers':
          return (
            _get(this.props.user, 'acnt.type') === 2 &&
            _get(this.props.user, 'acnt.status') === 4
          );
        default:
          return false;
      }
    }

    // Or else don't show the banner
    return false;
  };

  displayBanner = () => {
    const { banners } = this.props;
    if (!banners) {
      return false;
    }
    let selectedBanner = null;
    banners.forEach((banner) => {
      if (!selectedBanner && this.isBannerActive(banner)) {
        selectedBanner = banner;
      }
    });
    return selectedBanner;
  };

  render() {
    const { banners } = this.props;
    const banner = this.displayBanner();

    if (!banners || !banner) {
      return null;
    }

    return (
      <div className='banner-bar'>
        <div className='container'>
          <div className='text'>
            {PrismicRichText.render(
              banner.banner_text,
              PrismicConfig.linkResolver,
              PrismicConfig.htmlSerializer
            )}
          </div>
          {banner.close_button && (
            <div className='close'>
              <button onClick={() => this.dismissBanner(banner)}>
                <MdClose />
                <span className='screen-reader-only'>Dismiss Banner</span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withCookies(withPrismic(withHireup(Banner)));
