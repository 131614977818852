import React, { Component } from 'react';
import constants from '../../config/constants';
import MapWrapper from './wrapper';
import Loading from '../loading/loading';

export default class Map extends Component {
  render() {
    return (
      <MapWrapper
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${constants.google_maps_api_key}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<Loading />}
        containerElement={<div className='map-wrapper' />}
        mapElement={<div style={{ height: `100%` }} />}
        defaultCenter={
          this.props.center
            ? {
                lat: this.props.center.latitude,
                lng: this.props.center.longitude,
              }
            : constants.google_maps_default_center
        }
        showCenter={this.props.showCenter}
        defaultZoom={this.props.zoom || 15}
        {...this.props}
      />
    );
  }
}
