import * as React from 'react';

const IconCaringHands = (props) => {
  return (
    <span className='icon'>
      <svg
        {...props}
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='1.5'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12.042' cy='3.997' r='3.25' />
        <path d='M17.1 12.247a5.321 5.321 0 0 0-10 0M3.75 23.253l-2.014-2.518a4.5 4.5 0 0 1-.986-2.811v-5.171a1.5 1.5 0 0 1 1.5-1.5h0a1.5 1.5 0 0 1 1.5 1.5V16.5' />
        <path d='M8.25 23.116v-3a4.492 4.492 0 0 0-.757-2.5l-1.142-1.71a1.4 1.4 0 0 0-1.977-.444h0a1.4 1.4 0 0 0-.474 1.791L5.25 19.5M20.25 23.253l2.014-2.518a4.5 4.5 0 0 0 .986-2.811v-5.171a1.5 1.5 0 0 0-1.5-1.5h0a1.5 1.5 0 0 0-1.5 1.5V16.5' />
        <path d='M15.75 23.116v-3a4.492 4.492 0 0 1 .757-2.5l1.142-1.713a1.4 1.4 0 0 1 1.977-.444h0a1.4 1.4 0 0 1 .476 1.791L18.75 19.5' />
      </svg>
    </span>
  );
};

export default IconCaringHands;
